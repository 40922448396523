const useStorage = () => {
  const setItem = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  const getItem = (key: string) => {
    return localStorage.getItem(key);
  };

  const removeItem = (key: string) => {
    localStorage.removeItem(key);
  };

  const isExistItem = (key: string) => {
    return getItem(key) !== null;
  };

  return { setItem, getItem, removeItem, isExistItem };
};

export { useStorage };
