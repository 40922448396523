import { useState } from 'react';
import ContractBasePage from '.';
import ContractSubscriptionUpsert from '../../../components/user/Contract/Subscription/ContractSubscriptionUpsert';

const SubscriptionPage = () => {
  const [title, setTitle] = useState<string>('');

  return (
    <ContractBasePage isIncludeTab={false} title={title}>
      <ContractSubscriptionUpsert setTitle={setTitle} />
    </ContractBasePage>
  );
};

export default SubscriptionPage;
