import React from 'react';
import RequiredBox from './RequiredBox';
import classNames from 'classnames';

type FormItemProps = {
  children: React.ReactNode;
  label: string;
  withRequired?: boolean;
  labelClass?: string;
  bodyClass?: string;
};

const FormItem = ({ label, withRequired = false, children, labelClass, bodyClass }: FormItemProps) => {
  return (
    <div className="border-b border-secondary-lighter flex mb-[3px]">
      <div className={classNames(
        labelClass,
        'flex justify-between items-center text-[14px] font-[500] leading-[100%] bg-success-lighter pl-[14px] pr-[10px] min-h-[40px] w-full max-w-[350px]'
        )}
      >
        {label}
        {withRequired && <RequiredBox />}
      </div>
      <div className={classNames(
        bodyClass,
        'w-full py-[5px] pl-[9px] pr-[32px]'
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default FormItem;
