import React, { FC } from 'react';
import logo_login from '../../assests/images/logo_login.webp';
import classNames from "classnames";

interface DivProps {
  otherStyle?: string;
}

const Logo: FC<DivProps> = (props) => {
  return (
    <div
      className={
        classNames({
          "w-[250px] h-[53.61px]": true,
          [`${props.otherStyle}`]: props.otherStyle !== null
        })
      }
    >
      <img className="w-full h-full" src={logo_login} alt='None'/>
    </div>
  )
}

export default Logo
