/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import {
  SUBSCRIPTION_CONST,
  CONTRACT_SUBSCRIPTION_PERIOD,
  SUBSCRIPTION_STEP,
} from '../../../../../services/constants/user/pages/contract';
import BasicSubscriptionForm from './Form';
import { Contract, ContractSubscription } from '../../../../../types/user/contract';
import BasicSubscriptionConfirmation from './Confirmation';
import { getBasicBilledPrice } from '../../../../../services/utils/contract';

type BasicSubscriptionProps = {
  subscription?: ContractSubscription;
  subscribedPriceList?: Contract[];
  latestPriceList?: Contract[];
  isCancelSubscription?: number;
};

const BasicContractSubscription = ({ ...props }: BasicSubscriptionProps) => {
  const [step, setStep] = useState(SUBSCRIPTION_STEP.FORM_FILL);
  const [formData, setFormData] = useState<ContractSubscription>(props.subscription || {});
  const [originSubscribed, setOriginSubscribed] = useState<ContractSubscription>(
    props?.subscription || {},
  );

  useEffect(() => {
    let initialForm: typeof formData = {};

    if (props?.latestPriceList?.length && !props?.subscription?.id) {
      const finalPrice = getBasicBilledPrice(
        props.latestPriceList,
        SUBSCRIPTION_CONST.REQUIRED_ACCOUNT_QUANTITY,
        false,
      );

      initialForm = {
        billed_amount: finalPrice,
        period_type: CONTRACT_SUBSCRIPTION_PERIOD.MONTHLY,
        user_quantity: SUBSCRIPTION_CONST.REQUIRED_ACCOUNT_QUANTITY,
      };
    }

    setOriginSubscribed(props?.subscription || {});
    setFormData({ ...props.subscription, ...initialForm });
  }, [props?.subscription, props?.latestPriceList]);

  useEffect(() => {
    if(props?.isCancelSubscription) {
      setStep(SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE)
    }
  }, [props?.isCancelSubscription])

  switch (step) {
    case SUBSCRIPTION_STEP.FORM_FILL:
      return (
        <BasicSubscriptionForm
          setStep={setStep}
          formData={formData}
          originSubscribed={originSubscribed}
          setFormData={setFormData}
          {...props}
        />
      );
    case SUBSCRIPTION_STEP.CONFIRMATION:
    case SUBSCRIPTION_STEP.CONFIRMATION_CHANGE:
    case SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE:
      return (
        <BasicSubscriptionConfirmation
          formData={
            step === SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE ? originSubscribed : formData
          }
          originSubscribed={originSubscribed}
          setStep={setStep}
          step={step}
          {...props}
        />
      );
    default:
      return <></>;
  }
};

export default BasicContractSubscription;
