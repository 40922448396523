import React from "react";

const Pencil = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_5967_31920)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.329 8.47955C21.6219 8.18666 21.6219 7.71178 21.329 7.41889L16.3793 2.46914C16.0864 2.17625 15.6115 2.17625 15.3186 2.46914L2.88361 14.9042C2.55542 15.2324 2.37104 15.6775 2.37105 16.1416L2.37105 20.6771C2.37105 21.0914 2.70683 21.4271 3.12105 21.4271L7.65658 21.4271C8.12071 21.4271 8.56583 21.2428 8.89402 20.9146L21.329 8.47955ZM19.7381 7.94922L17.9703 9.71699L14.0812 5.8279L15.849 4.06013L19.7381 7.94922ZM13.0205 6.88856L16.9096 10.7776L7.83336 19.8539C7.78647 19.9008 7.72288 19.9271 7.65658 19.9271L3.87105 19.9271L3.87105 16.1416C3.87105 16.0753 3.89739 16.0117 3.94427 15.9648L13.0205 6.88856Z"
          fill={props.color || "#BEBEBE"}
        />
      </g>
      <defs>
        <clipPath id="clip0_5967_31920">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Pencil;
