import { Link, useNavigate } from "react-router-dom";
import { DIRECTION } from "../../services/constants/globals";
import { SidebarItem as SidebarItemProps } from "../../types/user/main";
import SidebarDirection from "./SidebarDirection";
import { useEffect, useState } from "react";
import { PATH_USER_ACCOUNT } from "../../services/constants/route/router";

type SidebarProps = {
  data: SidebarItemProps;
  currentPath: string;
  isOpen: boolean;
  onItemClick: () => void;
  isMinimize?: boolean;
};

const SidebarItem = ({ data, currentPath, isOpen, onItemClick, isMinimize }: SidebarProps) => {
  const { label, href, childs, icon: IconComponent } = data;
  const [isChooseParent, setIsChooseParent] = useState<boolean>(false);
  const parentPath = currentPath.split("/")[1];
  const navigate = useNavigate();

  const isChooseChild = (href: string) => {
    if (href === currentPath) return true;
    return false;
  };

  useEffect(() => {
    if (!childs.length) {
      if (currentPath === href) setIsChooseParent(true);
    }
    else {
      childs.forEach((child) => {
        setIsChooseParent(child.href.includes(parentPath));
      });
    }
  }, [currentPath]);

  const handleOnClick = () => {
    onItemClick();
    if (href.length && !isMinimize) {
      navigate(href);
    }
  }


  return (
    <>
      <div
        className={`bg-success-lighter flex justify-between items-center cursor-pointer min-h-[40px] px-[10px] py-[15px] ${(isMinimize || !isOpen || !childs.length) && isChooseParent ? "!bg-primary" : ""
          } `}
        onClick={() => {
          handleOnClick();
        }}
      >
        <div
          className={`flex items-center text-[13px] font-[500] leading-[100%] text-[#5A5A5A] ${isMinimize ? "w-full justify-center" : "gap-[8px]"
            } ${(!isOpen || !childs.length) && isChooseParent ? "!text-white" : ""}`}
        >
          <IconComponent
            size={22}
            className=""
            color={
              (isMinimize || !isOpen || !childs.length) && isChooseParent ? "#FFFFFF" : "#BEBEBE"
            }
          />
          {!isMinimize && label}
        </div>
        {!isMinimize && childs.length ? (
          <SidebarDirection
            onDirection={isOpen ? DIRECTION.UP : DIRECTION.DOWN}
            color={!isOpen && isChooseParent ? "#FFFFFF" : ""}
          />
        ) : <></>}
      </div>
      {!isMinimize && isOpen && childs.length > 0 && (
        <>
          {childs.map(({ href, label }, index) => (
            <Link to={href} className="w-full cursor-pointer" key={index}>
              <div
                className={`min-h-[36px] flex items-center pl-[42px] text-[12px] font-[500] leading-[100%] bg-white border-b border-success-extralight ${isChooseChild(href)
                  ? "text-white !bg-primary"
                  : "text-[#5A5A5A]"
                  } hover:bg-primary hover:text-white`}
              >
                {label}
              </div>
            </Link>
          ))}
        </>
      )}
    </>
  );
};

export default SidebarItem;
