import { SidebarChildItem, SidebarItem } from '../../../types/user/main';
import { PATH_SUPER_ADMIN } from '../route/router';
import { MdOutlineAssessment } from 'react-icons/md';
import UserSuperAdmin from '../../../components/commons/icons/SideBar/UserSuperAdmin';
import Section from '../../../components/commons/icons/SideBar/Section';
import Link from '../../../components/commons/icons/SideBar/Link';
import Monitor from '../../../components/commons/icons/SideBar/Monitor';

const child = (href: SidebarChildItem['href'], label: SidebarChildItem['label']) => ({
  href: href,
  label: label,
});

const item = (label: SidebarItem['label'], href: SidebarItem['href'], icon: React.FC, ...childs: SidebarItem['childs']) => ({
  label: label,
  href: href,
  icon: icon,
  childs: childs.map(({ href, label }: SidebarChildItem) => child(href, label)),
});

const USER_SIDEBAR_DATA = [
  item(
    'ホーム',
    PATH_SUPER_ADMIN.USER_ACCOUNT.DEFAULT,
    Monitor,
  ),
  item(
    'パレット管理者管理',
    '',
    UserSuperAdmin,
    child(PATH_SUPER_ADMIN.USER_ACCOUNT.DEFAULT, 'パレット管理者一覧'),
    child(PATH_SUPER_ADMIN.USER_ACCOUNT.CREATE, '新規パレット管理者新規追加'),
  ),

  item(
    '契約企業管理',
    '',
    Section,
    child(PATH_SUPER_ADMIN.ORGANIZATION.DEFAULT, '契約企業一覧'),
    child(PATH_SUPER_ADMIN.ORGANIZATION.CREATE, '契約企業新規追加'),
  ),
  item('リンク先', '', Link, child(PATH_SUPER_ADMIN.LINK_ADDRESS.LIST, 'URL一覧')),

  item(
    '契約料金設定',
    '',
    MdOutlineAssessment,
    child(PATH_SUPER_ADMIN.CONTRACT_PRICE_SETTING.MANABITE, 'パレットLMS契約料金'),
    child(PATH_SUPER_ADMIN.CONTRACT_PRICE_SETTING.TOKITE, 'パレットCBT契約料金'),
  ),

  // SidebarItem hidden for future phases
  // item(
  //   "契約状況",
  //   Pencil,
  //   child("/#", "契約管理"),
  //   child("/#", "請求履歴"),
  //   child("/#", "お支払い方法")
  // ),
  // item("会社基本情報", City, child("/#", "企業情報"), child("/#", "連絡先")),
  // item("お知らせ", Sound, child("/#", "お知らせ"), child("/#", "特定商取引について")),
  // item(
  //   "販売方法設定",
  //   City,
  //   child(PATH_COMPANY_INFO.PAYMENT_SETTING, "決済方法設定"),
  //   // child(PATH_COMPANY_INFO.RECEIPT_SETTING, "領収書設定")
  // )
];

export { USER_SIDEBAR_DATA };
