import { SettingFormData } from '../../components/SuperAdmin/ContractSetting/Form/ContractSettingForm';
import { Contract, ContractSubscription } from '../../types/user/contract';
import { PALETTE_FEATURE } from '../constants/globals';
import { PATH_CONTRACT } from '../constants/route/router';
import {
  CONTRACT_SUBSCRIPTION_PERIOD,
  CONTRACT_SUBSCRIPTION_STATUS,
  CONTRACT_SUBSCRIPTION_TYPE,
  CONTRACT_SUBSCRIPTION_TYPE_NAME,
  SUBSCRIPTION_CHECKOUT_TYPE,
  SUBSCRIPTION_CONST,
  SUBSCRIPTION_STEP,
  SUBSCRIPTION_TOOLTIPS,
} from '../constants/user/pages/contract';
import { addMonths } from '../helpers/date';
import { sortArrayByKey } from '../helpers/parseData';
import qs from 'qs';
import dayjs from '../../services/dayjs';

const getSubscriptionPeriodLabel = (
  periodIdentifier: number = CONTRACT_SUBSCRIPTION_PERIOD.MONTHLY,
) => {
  return periodIdentifier === CONTRACT_SUBSCRIPTION_PERIOD.MONTHLY ? '月額' : '年額';
};

const getSubscriptionPeriodShortLabel = (
  periodIdentifier: number = CONTRACT_SUBSCRIPTION_PERIOD.MONTHLY,
) => {
  return periodIdentifier === CONTRACT_SUBSCRIPTION_PERIOD.MONTHLY ? '月' : '年';
};

const getAbsoluteUserQuantity = (quantityResult: number) => {
  if (quantityResult < 0) return 'ー';
  return quantityResult;
};

const getContractNameByType = (type?: number, feature_id?: number) => {
  switch (type) {
    case CONTRACT_SUBSCRIPTION_TYPE.BASIC:
      if (feature_id && feature_id === PALETTE_FEATURE.TOKITE.ID) {
        return CONTRACT_SUBSCRIPTION_TYPE_NAME.CBT_BASIC_NAME;
      }
      return CONTRACT_SUBSCRIPTION_TYPE_NAME.BASIC_NAME;

    case CONTRACT_SUBSCRIPTION_TYPE.STORAGE:
      return CONTRACT_SUBSCRIPTION_TYPE_NAME.STORAGE_NAME;

    case CONTRACT_SUBSCRIPTION_TYPE.PRODUCT:
      return CONTRACT_SUBSCRIPTION_TYPE_NAME.PRODUCT_NAME;

    case CONTRACT_SUBSCRIPTION_TYPE.SUPPORT:
      return CONTRACT_SUBSCRIPTION_TYPE_NAME.SUPPORT_NAME;

    default:
      return 'ー';
  }
};

const getFeatureContractNameByType = (type?: number) => {
  switch (type) {
    case CONTRACT_SUBSCRIPTION_TYPE.BASIC:
      return 'アカウント追加';

    case CONTRACT_SUBSCRIPTION_TYPE.STORAGE:
      return '追加ストレージ';

    case CONTRACT_SUBSCRIPTION_TYPE.PRODUCT:
      return '講座販売';

    case CONTRACT_SUBSCRIPTION_TYPE.SUPPORT:
      return '導入サポート';

    default:
      return 'ー';
  }
};

const getContractDescriptionByType = (type?: number) => {
  switch (type) {
    case CONTRACT_SUBSCRIPTION_TYPE.STORAGE:
      return ' 100GBあたり';

    case CONTRACT_SUBSCRIPTION_TYPE.SUPPORT:
      return '2時間・5人まで・ 交通費別途';

    default:
      return 'ー';
  }
};

const getExpiredBasicSubscriptionLabel = (
  subscribed?: ContractSubscription,
  storageCapacity = 0,
) => {
  if (!subscribed || !subscribed.id) return '';
  let suffix = '';

  switch (subscribed.status) {
    case CONTRACT_SUBSCRIPTION_STATUS.INUSE_INTERVAL:
      suffix = 'に自動更新';
      break;

    case CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD:
      suffix = '';
      if (subscribed.in_scheduled_subscribed && subscribed.in_scheduled_subscribed.id) {
        const inScheduledSubscribed = subscribed.in_scheduled_subscribed;
        let nowDiffText = '';
        let futureDiffText = '';

        switch (subscribed.contract_type) {
          case CONTRACT_SUBSCRIPTION_TYPE.BASIC:
            nowDiffText = `${subscribed.user_quantity}／${getSubscriptionPeriodShortLabel(
              subscribed.period_type,
            )}`;
            futureDiffText = `${
              inScheduledSubscribed.user_quantity
            }／${getSubscriptionPeriodShortLabel(inScheduledSubscribed.period_type)}`;
            break;
          case CONTRACT_SUBSCRIPTION_TYPE.SUPPORT:
            nowDiffText = `${getSubscriptionPeriodLabel(subscribed.period_type)}`;
            futureDiffText = `${getSubscriptionPeriodLabel(inScheduledSubscribed.period_type)}`;
            break;
          case CONTRACT_SUBSCRIPTION_TYPE.STORAGE:
            nowDiffText = `${
              Number(subscribed.product_quantity) * Number(storageCapacity)
            }GB／${getSubscriptionPeriodShortLabel(subscribed.period_type)}`;
            futureDiffText = `${
              Number(inScheduledSubscribed.product_quantity) * Number(storageCapacity)
            }GB／${getSubscriptionPeriodShortLabel(inScheduledSubscribed.period_type)}`;
            break;
          default:
            break;
        }

        suffix += `までに${nowDiffText}\nその後${futureDiffText}\nに更新`;
      }
      break;

    default:
      return '';
  }

  return suffix;
};

const getBasicBilledPrice = (priceList?: Contract[], userQuantity?: number, isYearly?: boolean) => {
  if (!priceList || !userQuantity) return 0;

  let result = 0;
  let remainingAmount = userQuantity || 0;
  let totalDiff = 0;
  let totalDiffDelayed = 0;

  sortArrayByKey(priceList, 'user_limit_start').forEach((price, index) => {
    let priceAmount = Number(price.price_monthly);
    let userQuantityAmount = userQuantity;

    if (isYearly) {
      priceAmount = Number(price.price_yearly);
    }

    let userQuantityDiff =
      Number(price.user_limit_end) -
      Number(price.user_limit_start) +
      (!!price.user_limit_start ? 1 : 0);

    //Last item
    if (!Number(price.user_limit_end)) {
      userQuantityDiff = 0;
    }

    let userQuantityOldDiff = 0;

    if (priceList?.[index - 1]) {
      userQuantityOldDiff =
        Number(priceList?.[index - 1]?.user_limit_end || 0) -
        Number(priceList?.[index - 1]?.user_limit_start || 0) +
        1;
    }

    totalDiff += userQuantityDiff;
    totalDiffDelayed += userQuantityOldDiff;

    if (!Number(price.user_limit_end)) {
      userQuantityAmount = userQuantity > totalDiff ? userQuantity - totalDiff : 0;
    } else if (userQuantity >= totalDiff) {
      userQuantityAmount = userQuantityDiff;
      remainingAmount -= userQuantityDiff;
    } else if (userQuantity < totalDiff && userQuantity >= totalDiffDelayed) {
      userQuantityAmount = remainingAmount;
    } else {
      userQuantityAmount = 0;
    }

    result += userQuantityAmount * priceAmount;
  });

  if (isYearly) {
    return result * 12;
  }
  return result;
};

const getBasicPriceChangeData = (
  priceList?: Contract[],
  userQuantity?: number,
): { changeLevelIndex: number; userQuantitySeparatedAmounts: number[] } => {
  if (!priceList || !userQuantity) {
    return { changeLevelIndex: 0, userQuantitySeparatedAmounts: [] };
  }

  const userQuantitySeparatedAmounts: number[] = [];

  let changeLevelIndex = 0;
  let remainingAmount = userQuantity || 0;
  let totalDiff = 0;
  let totalDiffDelayed = 0;

  sortArrayByKey(priceList, 'user_limit_start').forEach((price, index) => {
    let userQuantityAmount = userQuantity;

    let userQuantityDiff =
      Number(price.user_limit_end) -
      Number(price.user_limit_start) +
      (!!price.user_limit_start ? 1 : 0);

    //Last item
    if (!Number(price.user_limit_end)) {
      userQuantityDiff = 0;
    }

    let userQuantityOldDiff = 0;

    if (priceList?.[index - 1]) {
      userQuantityOldDiff =
        Number(priceList?.[index - 1]?.user_limit_end || 0) -
        Number(priceList?.[index - 1]?.user_limit_start || 0) +
        1;
    }

    totalDiff += userQuantityDiff;
    totalDiffDelayed += userQuantityOldDiff;

    if (!Number(price.user_limit_end)) {
      userQuantityAmount = userQuantity > totalDiff ? userQuantity - totalDiff : 0;
    } else if (userQuantity >= totalDiff) {
      userQuantityAmount = userQuantityDiff;
      remainingAmount -= userQuantityDiff;
    } else if (userQuantity < totalDiff && userQuantity >= totalDiffDelayed) {
      userQuantityAmount = remainingAmount;
    } else {
      userQuantityAmount = 0;
    }

    if (userQuantityAmount) {
      changeLevelIndex++;
      userQuantitySeparatedAmounts.push(userQuantityAmount);
    }
  });

  return { changeLevelIndex, userQuantitySeparatedAmounts };
};

const getBasicChangedBilledAmount = (
  contracts: Contract[],
  originUserQuantity: number,
  changeUserQuantity: number,
) => {
  let changedBilledAmount = 0;
  const userQuantity = changeUserQuantity || 0;
  let remainingAmount = changeUserQuantity || 0;
  let userQuantityAmount = userQuantity;
  let totalDiff = 0;
  let totalDiffDelayed = 0;
  let changedUncounted = 0;

  const sortedContracts = sortArrayByKey(contracts, 'user_limit_start');

  const { userQuantitySeparatedAmounts: originUserQuantitySeparatedAmounts } =
    getBasicPriceChangeData(sortedContracts, originUserQuantity);
  const { userQuantitySeparatedAmounts: newUserQuantitySeparatedAmounts } = getBasicPriceChangeData(
    sortedContracts,
    changeUserQuantity,
  );
  const currentPriceList = sortArrayByKey(contracts || [], 'user_limit_start');

  sortedContracts?.map((price, index) => {
    let diffIndexPoint = 0;
    let diffQuantityAmount = 0;
    const priceAmount = Number(price.price_monthly);

    originUserQuantitySeparatedAmounts.forEach((originQuantityAmount, index) => {
      if (
        newUserQuantitySeparatedAmounts?.[index] &&
        originQuantityAmount <= newUserQuantitySeparatedAmounts?.[index]
      ) {
        diffIndexPoint = index;
        diffQuantityAmount = newUserQuantitySeparatedAmounts?.[index] - originQuantityAmount;
        return false;
      }
    });

    if (diffIndexPoint < 0 || index < diffIndexPoint || changeUserQuantity <= originUserQuantity) {
      remainingAmount -= originUserQuantitySeparatedAmounts?.[index];
      changedUncounted -= originUserQuantitySeparatedAmounts?.[index];
      return false;
    }

    let userQuantityDiff =
      Number(price.user_limit_end) -
      Number(price.user_limit_start) +
      (!!price.user_limit_start ? 1 : 0);

    //Last item
    if (!Number(price.user_limit_end)) {
      userQuantityDiff = 0;
    }

    let userQuantityOldDiff = 0;
    if (currentPriceList?.[index - 1]) {
      userQuantityOldDiff =
        Number(currentPriceList?.[index - 1]?.user_limit_end || 0) -
        Number(currentPriceList?.[index - 1]?.user_limit_start || 0) +
        1;
    }

    totalDiff += userQuantityDiff;
    totalDiffDelayed += userQuantityOldDiff;

    if (!Number(price.user_limit_end) && userQuantity >= Number(price.user_limit_start)) {
      userQuantityAmount =
        userQuantity >= totalDiff ? userQuantity - totalDiff + changedUncounted : 0;

      if (userQuantityAmount >= totalDiff) {
        userQuantityAmount -= totalDiff;
      }
    } else if (userQuantity >= totalDiff) {
      userQuantityAmount = userQuantityDiff;
      remainingAmount -= userQuantityDiff;

      if (remainingAmount < 0) {
        userQuantityAmount += remainingAmount;
      }
    } else if (userQuantity < totalDiff && userQuantity >= totalDiffDelayed) {
      userQuantityAmount = remainingAmount;
    } else {
      userQuantityAmount = 0;
    }

    if (userQuantityAmount && index === diffIndexPoint) {
      userQuantityAmount = diffQuantityAmount;
    }

    if (userQuantityAmount < 0) {
      userQuantityAmount = 0;
    }

    changedBilledAmount += userQuantityAmount * priceAmount;
    return changedBilledAmount;
  });

  return changedBilledAmount;
};

const getStorageChangedBilledAmount = (
  contracts: Contract[],
  originQuantity: number,
  changeQuantity: number,
) => {
  let changedBilledAmount = 0;
  let productQuantityAmount = changeQuantity || 0;
  contracts.forEach((price) => {
    const priceAmount = Number(price.price_monthly);
    productQuantityAmount -= originQuantity;

    if (productQuantityAmount < 0) {
      productQuantityAmount = 0;
    }

    changedBilledAmount += productQuantityAmount * priceAmount;
  });

  return changedBilledAmount;
};

const getStorageBillPrice = (
  priceList?: Contract[],
  productQuantity?: number,
  isYearly?: boolean,
) => {
  if (!priceList || !productQuantity) return 0;

  let result = 0;

  sortArrayByKey(priceList, 'user_limit_start').forEach((price, index) => {
    let priceAmount = Number(price.price_monthly);

    if (isYearly) {
      priceAmount = Number(price.price_yearly);
    }

    result += productQuantity * priceAmount;
  });

  if (isYearly) {
    return result * 12;
  }
  return result;
};

const getSubscriptionFormFillPath = (
  id?: ContractSubscription['id'],
  type?: ContractSubscription['contract_type'],
  featureId?: ContractSubscription['feature_id'],
  cancelSubscription ?: number
) => {
  const paramsObj = {
    subscription_id: id || '',
    contract_type: type || '',
    feature_id: featureId || '',
    cancel_subscription: cancelSubscription || 0,
  };

  return `${PATH_CONTRACT.SUBSCRIPTION}?${qs.stringify(paramsObj)}`;
};

const getContractAdditionTypeLabel = (type?: number) => {
  switch (type) {
    case CONTRACT_SUBSCRIPTION_TYPE.SUPPORT:
      return '導入サポート';

    case CONTRACT_SUBSCRIPTION_TYPE.STORAGE:
      return '追加ストレージ 100GBあたり';

    case CONTRACT_SUBSCRIPTION_TYPE.PRODUCT:
      return '講座販売';

    default:
      return 'ー';
  }
};

const getContractFormInputValue = (
  contractSettings: SettingFormData[],
  id?: number,
  formField?: string,
): number | undefined => {
  return contractSettings.find((setting) => setting.id === id && setting.field_name === formField)
    ?.value;
};

const getContractExpiredDateByPeriod = (date?: Date, periodType?: number) => {
  const monthsToAdd = periodType === CONTRACT_SUBSCRIPTION_PERIOD.YEARLY ? 12 : 1;
  return addMonths(date || new Date(), monthsToAdd);
};

const getSubscriptionCheckoutTypeByStep = (step: string) => {
  switch (step) {
    case SUBSCRIPTION_STEP.CONFIRMATION:
      return SUBSCRIPTION_CHECKOUT_TYPE.SUBSCRIBE;
    case SUBSCRIPTION_STEP.CONFIRMATION_CHANGE:
      return SUBSCRIPTION_CHECKOUT_TYPE.SUBSCRIBE_CHANGE;
    case SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE:
      return SUBSCRIPTION_CHECKOUT_TYPE.UNSUBSCRIBE;

    default:
      return '';
  }
};

const getBilledAmountWithTax = (
  billedAmountWithoutTax?: number,
  taxPercent = SUBSCRIPTION_CONST.TAX_RATE_PERCENTAGE_DEFAULT,
) => {
  if (!billedAmountWithoutTax || typeof billedAmountWithoutTax !== 'number') return 0;

  return billedAmountWithoutTax + getTaxAmount(billedAmountWithoutTax, taxPercent);
};

const getTaxAmount = (
  billedAmountWithoutTax?: number,
  taxPercent = SUBSCRIPTION_CONST.TAX_RATE_PERCENTAGE_DEFAULT,
) => {
  if (
    !taxPercent ||
    typeof taxPercent !== 'number' ||
    !billedAmountWithoutTax ||
    typeof billedAmountWithoutTax !== 'number'
  )
    return 0;

  return Math.round(billedAmountWithoutTax * (taxPercent / 100));
};

const getSubscriptionContractLabel = (
  type?: ContractSubscription['contract_type'],
  isSubscribed = false,
) => {
  let resultLabel = '';

  if (!isSubscribed) {
    switch (type) {
      case CONTRACT_SUBSCRIPTION_TYPE.BASIC:
        resultLabel = '契約状況 ｜契約管理 ｜アカウント追加の登録';
        break;
      case CONTRACT_SUBSCRIPTION_TYPE.PRODUCT:
      case CONTRACT_SUBSCRIPTION_TYPE.STORAGE:
      case CONTRACT_SUBSCRIPTION_TYPE.SUPPORT:
        resultLabel = '契約状況 ｜契約管理 ｜オプション機能の登録';
        break;

      default:
        break;
    }

    return resultLabel;
  }

  switch (type) {
    case CONTRACT_SUBSCRIPTION_TYPE.BASIC:
      resultLabel = '契約状況 ｜契約管理 ｜アカウント追加の変更 ';
      break;

    case CONTRACT_SUBSCRIPTION_TYPE.PRODUCT:
    case CONTRACT_SUBSCRIPTION_TYPE.STORAGE:
    case CONTRACT_SUBSCRIPTION_TYPE.SUPPORT:
      resultLabel = '契約状況 ｜契約管理 ｜オプション機能の変更';
      break;

    default:
      break;
  }

  return resultLabel;
};

const getBilledAmountByUsage = (
  amount: number,
  periodStartAt: string | Date,
  usedDays: number,
) => {
  return amount - getDiscountAmountByUsage(amount, periodStartAt, usedDays);
};

const getDiscountAmountByUsage = (
  amount: number,
  periodStartAt: string | Date,
  usedDays: number,
) => {
  const daysInMonth = dayjs(periodStartAt).daysInMonth();

  if (!daysInMonth || !usedDays || usedDays < 0) {
    return 0;
  }

  return Math.round((amount / daysInMonth) * usedDays);
};

const getSubscriptionTooltip = (type: number) => {
  if (!type && type !== 0) {
    return '';
  }

  return SUBSCRIPTION_TOOLTIPS.find((tooltip) => tooltip.type === type)?.content || '';
};
const getAdditionContractInfoByType = (type?: number, feature_id?: number) => {
  switch (type) {
    case CONTRACT_SUBSCRIPTION_TYPE.STORAGE:
      return "ストレージの空きが不足した場合は100GBごとに容量を追加購入いただけます。";

    case CONTRACT_SUBSCRIPTION_TYPE.PRODUCT:
      return "オンライン講座・教材を受講者へ販売するためのEC機能のご登録はこちら。";

    case CONTRACT_SUBSCRIPTION_TYPE.SUPPORT:
      return "初回導入をオンラインでサポートいたします。1回分の料金です。定額利用サービスではございません。";

    default:
      return 'ー';
  }
};

const getContractPeopleNumberLabelByFeatureId = (feature_id?: number) => {
  switch (feature_id) {
    case PALETTE_FEATURE.MANABITE.ID:
      return "受講者数";

    case PALETTE_FEATURE.TOKITE.ID:
      return "受験者数";
    
    default:
      return "受講者数";
  }
}

export {
  getSubscriptionPeriodLabel,
  getAbsoluteUserQuantity,
  getContractNameByType,
  getExpiredBasicSubscriptionLabel,
  getBasicBilledPrice,
  getStorageBillPrice,
  getSubscriptionFormFillPath,
  getContractAdditionTypeLabel,
  getContractFormInputValue,
  getContractDescriptionByType,
  getFeatureContractNameByType,
  getContractExpiredDateByPeriod,
  getSubscriptionCheckoutTypeByStep,
  getSubscriptionPeriodShortLabel,
  getBilledAmountWithTax,
  getSubscriptionContractLabel,
  getTaxAmount,
  getBasicPriceChangeData,
  getBilledAmountByUsage,
  getBasicChangedBilledAmount,
  getStorageChangedBilledAmount,
  getSubscriptionTooltip,
  getAdditionContractInfoByType,
  getContractPeopleNumberLabelByFeatureId,
  getDiscountAmountByUsage
};
