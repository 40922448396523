import { useEffect, useState } from "react";
import FormItem from "../../../user/commons/FormItem";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { OrganizationFormType } from "../../../../types/user/organization";

const SuperAdminOrganizationDetailUserForm = () => {
    const [superAdminOzFormData, setSuperAdminOzFormData] = useState<OrganizationFormType>({
        name: "",
        subdomain: "",
        status: 0,
    });
    const { getParamValue } = useObjectRoutes();
    const organizationId = getParamValue("organization_id");

    useEffect(() => {
        if (!organizationId) return;
    
        const fetchDetail = async () => {
          await request.get(
            `${API.SUPER_ADMIN.ORGANIZATION.DETAIL}?organization_id=${organizationId}`,
            (organizationData) => {
                setSuperAdminOzFormData(organizationData || {});
            }
          );
        };
    
        fetchDetail();
      }, [organizationId]);

    return (
        <div>
            <div className="w-full p-[5px] my-[20px] flex flex-col items-center">
                <div className="w-full text-[13px] p-[10px]">
                    選択中の企業名
                </div>
                <div className="w-full bg-primary text-white text-[18px] font-normal rounded-[5px] py-[20px] pl-[10px]">
                    {`${superAdminOzFormData.name || ""}`}
                </div>
            </div>
            <div className="text-[14px] py-[10px] flex items-center">請求連絡先</div>
            <FormItem label="管理者氏名" bodyClass="flex item-center">
                <div className="text-[14px] font-normal flex items-center">
                    {`${superAdminOzFormData.last_name || ""} ${superAdminOzFormData.first_name || ""}`}
                </div>
            </FormItem>
            <FormItem label="管理者氏名（カナ）" bodyClass="flex item-center">
                <div className="text-[14px] font-normal flex items-center">
                    {`${superAdminOzFormData.last_name_kana || ""} ${superAdminOzFormData.first_name_kana || ""}`}
                </div>
            </FormItem>
            <FormItem label="電話番号" bodyClass="flex item-center">
                <div className="text-[14px] font-normal flex items-center">
                    {`${superAdminOzFormData.tel || "-"}`}
                </div>
            </FormItem>
            <FormItem label="部署名" bodyClass="flex item-center">
                <div className="text-[14px] font-normal flex items-center">
                    {`${superAdminOzFormData.department || "-"}`}
                </div>
            </FormItem>
            <FormItem label="メールアドレス" bodyClass="flex item-center">
                <div className="text-[14px] font-normal flex items-center">
                    {`${superAdminOzFormData.email || "-"}`}
                </div>
            </FormItem>
        </div>
    );
};

export default SuperAdminOrganizationDetailUserForm;