import TabCard from '../../../components/user/commons/TabCard';
import UserLayout from '../../../layouts/user';
import { useTabFinder } from '../../../hooks/useTabFinder';
import { WithChildren } from '../../../types/globals';
import { CONTRACT_TAB_DATA } from '../../../services/constants/user/pages/contract';
import { PATH_CONTRACT } from '../../../services/constants/route/router';

type ServiceType = {
  title?: string;
  isIncludeTab?: boolean;
};

const ContractBasePage = ({ title, children, isIncludeTab = true }: WithChildren<ServiceType>) => {
  const { tabIndex } = useTabFinder(Object.values(PATH_CONTRACT));

  return (
    <UserLayout>
      <TabCard tabData={isIncludeTab ? CONTRACT_TAB_DATA : []} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </UserLayout>
  );
};

export default ContractBasePage;
