import { useState, useEffect } from 'react';
import {
  CONTRACT_SUBSCRIPTION_PERIOD,
  SUBSCRIPTION_STEP,
} from '../../../../../services/constants/user/pages/contract';
import { Contract, ContractSubscription } from '../../../../../types/user/contract';
import ProductContractSubscriptionForm from './Form';
import ProductContractSubscriptionConfirmation from './Confirmation';

type ProductSubscriptionProps = {
  subscription?: ContractSubscription;
  subscribedPriceList?: Contract[];
  latestPriceList?: Contract[];
  isCancelSubscription?: number;
};

const ProductContractSubscription = ({ ...props }: ProductSubscriptionProps) => {
  const [step, setStep] = useState(SUBSCRIPTION_STEP.FORM_FILL);
  const [formData, setFormData] = useState<ContractSubscription>(props?.subscription || {});
  const [originSubscribed, setOriginSubscribed] = useState<ContractSubscription>(
    props?.subscription || {},
  );

  useEffect(() => {
    let initialFormProduct: typeof formData = {};
    if (props?.latestPriceList?.length && !props?.subscription?.id) {
      initialFormProduct = {
        billed_amount: props?.latestPriceList[0]?.price_monthly,
        period_type: CONTRACT_SUBSCRIPTION_PERIOD.MONTHLY,
      };
    }

    setOriginSubscribed(props?.subscription || {});
    setFormData({ ...props?.subscription, ...initialFormProduct });
  }, [props?.subscription, props?.latestPriceList]);

  useEffect(() => {
    if(props?.isCancelSubscription) {
      setStep(SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE)
    }
  }, [props?.isCancelSubscription])

  switch (step) {
    case SUBSCRIPTION_STEP.FORM_FILL:
      return (
        <ProductContractSubscriptionForm
          step={step}
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
          originSubscribed={originSubscribed}
          {...props}
        />
      );
    case SUBSCRIPTION_STEP.CONFIRMATION:
    case SUBSCRIPTION_STEP.CONFIRMATION_CHANGE:
    case SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE:
      return (
        <ProductContractSubscriptionConfirmation
          step={step}
          formData={
            step === SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE ? originSubscribed : formData
          }
          originSubscribed={originSubscribed}
          setStep={setStep}
          {...props}
        />
      );
    default:
      return <></>;
  }
};

export default ProductContractSubscription;
