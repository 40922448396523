import React from 'react';

const SmallButton = ({
  children,
  isDisabled = false,
  className,
  ...props
}: {
  children: React.ReactNode;
  isDisabled?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      disabled={isDisabled}
      className={`w-full max-w-[170px] text-[16px] font-[500] leading-[26px] flex items-center justif-center rounded-[12px] min-h-[40px] ${
        isDisabled ? 'bg-secondary-disabled cursor-not-allowed' : 'cursor-pointer '
      } ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default SmallButton;
