import React, { useEffect, useState } from "react";
import { ContactData } from "../../../types/user/contactData";
import FormItem from "../commons/FormItem";
import Trash from "../../commons/icons/Trash";
import { ContactErrorData } from "../../../types/Contact/ContactErrorType";

type ContactInfoFormProps = {
  handleChangeInput: Function;
  index: number;
  contactData: ContactData;
  handleDeleteContact: Function;
  isDisableDeleteButton: boolean;
  errorData?: string[] | null;
  hiddenTrash: boolean;
  errors: ContactErrorData;
  setErrors: React.Dispatch<React.SetStateAction<ContactErrorData | undefined>>;
};

const ContactInfoForm = ({
  handleChangeInput,
  index,
  contactData,
  handleDeleteContact,
  isDisableDeleteButton,
  errorData,
  hiddenTrash,
  errors,
  setErrors,
}: ContactInfoFormProps) => {
  return (
    <div className="bg-white rounded-[10px] w-full min-h-[100px] pb-[50px]">
      <div className="mx-[20px]">
        <div
          className={`${
            !errorData ? "hidden" : ""
          } w-full bg-danger-lighter border border-danger rounded-[7px] py-[10px] px-[15px] my-[7px] text-danger`}
        >
          <ul>
            {errorData &&
              errorData.map((error, key) => (
                <li key={index + Math.random()} className="mb-[3px]">
                  {error}
                </li>
              ))}
          </ul>
        </div>
        <div>
          <div
            className={`text-[14px] font-normal text-left mb-[8px] flex justify-between items-center`}
          >
            <p>請求連絡先</p>
            <button
              className={`${
                isDisableDeleteButton || hiddenTrash ? " hidden" : ""
              } p-[2px] rounded  `}
              onClick={() => {
                if (errors) {
                  const updatedObject = { ...errors };
                  //delete error of component was deleted
                  delete updatedObject[index];

                  // change position key of errors
                  Object.keys(updatedObject).forEach((key, i) => {
                    if (parseInt(key) > index) {
                      const newIndex = parseInt(key) - 1;
                      updatedObject[newIndex.toString()] = updatedObject[key];
                      delete updatedObject[key];
                    }
                  });
                  setErrors(updatedObject);
                }

                handleDeleteContact(contactData.id, index);
              }}
            >
              <Trash />
            </button>
          </div>
          <FormItem label={"管理者氏名"} withRequired>
            <div className="mb-[5px] mt-[5px]]]">
              <div className="flex items-center w-full">
                <input
                  type="text"
                  name="last_name"
                  onChange={(e) => handleChangeInput(e, index)}
                  className=" border !border-[#EFF1F0] text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block px-[22px] py-[14px] w-[50%] mr-[20px]"
                  placeholder="鈴木"
                  value={contactData.last_name ?? ""}
                />
                <input
                  type="text"
                  name="first_name"
                  onChange={(e) => handleChangeInput(e, index)}
                  className=" border !border-[#EFF1F0] text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block px-[22px] py-[14px] w-[50%]"
                  placeholder="一郎"
                  value={contactData.first_name ?? ""}
                />
              </div>
            </div>
          </FormItem>
          <FormItem label={"管理者氏名（カナ）"}>
            <div className="mb-[5px] mt-[5px]]]">
              <div className="flex items-center w-full">
                <input
                  type="text"
                  name="last_name_kana"
                  onChange={(e) => handleChangeInput(e, index)}
                  className=" border !border-[#EFF1F0] text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block px-[22px] py-[14px] w-[50%] mr-[20px]"
                  placeholder="スズキ"
                  value={contactData.last_name_kana ?? ""}
                />
                <input
                  type="text"
                  name="first_name_kana"
                  onChange={(e) => handleChangeInput(e, index)}
                  className=" border !border-[#EFF1F0] text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block px-[22px] py-[14px] w-[50%]"
                  placeholder="イチロウ"
                  value={contactData.first_name_kana ?? ""}
                />
              </div>
            </div>
          </FormItem>
          <FormItem label="電話番号">
            <div className="py-[6px]">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0]"
                name="phone_number"
                value={contactData.phone_number ?? ""}
                onChange={(e) => handleChangeInput(e, index)}
                placeholder={"0312345678"}
              />
            </div>
          </FormItem>
          <FormItem label="部署名">
            <div className="py-[6px]">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0]"
                name="position"
                value={contactData.position ?? ""}
                onChange={(e) => handleChangeInput(e, index)}
              />
            </div>
          </FormItem>
          <FormItem label="メールアドレス" withRequired>
            <div className="py-[6px]">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0]"
                name="email"
                value={contactData.email ?? ""}
                onChange={(e) => handleChangeInput(e, index)}
                placeholder={"dummyusername@mail.com"}
              />
            </div>
          </FormItem>
        </div>
      </div>
    </div>
  );
};

export default ContactInfoForm;
