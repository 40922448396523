import React from "react";

const PaletteText = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="234"
      height="20"
      viewBox="0 0 234 20"
      fill="none"
    >
      <g clipPath="url(#clip0_8344_2966)">
        <path
          d="M194.771 4.21094H190.328C190.087 4.21094 189.891 4.40747 189.891 4.64797V11.1052C189.583 11.3457 180.633 18.8528 180.633 18.8528C180.524 18.9407 180.48 19.0493 180.48 19.1812C180.48 19.2252 180.48 19.2898 180.501 19.3338C180.566 19.5096 180.721 19.6182 180.918 19.6182H187.111C187.22 19.6182 187.328 19.5743 187.395 19.5096L195.055 13.0964C195.164 13.0085 195.208 12.8998 195.208 12.768V4.65055C195.208 4.41006 195.011 4.21352 194.771 4.21352V4.21094Z"
          fill="#777676"
        />
        <path
          d="M178.559 7.58398H187.095C187.4 7.58398 187.646 7.83224 187.646 8.1348V9.03731C187.646 9.34245 187.398 9.58812 187.095 9.58812H178.559C178.253 9.58812 178.008 9.33987 178.008 9.03731V8.1348C178.008 7.82965 178.256 7.58398 178.559 7.58398Z"
          fill="#777676"
        />
        <path
          d="M139.485 17.4752H138.306V5.78395C138.306 5.53569 138.104 5.33398 137.856 5.33398L137.822 5.34174C137.799 5.33916 137.779 5.33398 137.753 5.33398H125.436C125.133 5.33398 124.885 5.58224 124.885 5.8848V6.78731C124.885 7.08987 125.133 7.33812 125.436 7.33812H133.514V17.47H124.606C124.303 17.47 124.055 17.7183 124.055 18.0208V18.9233C124.055 19.2259 124.303 19.4741 124.606 19.4741H139.483C139.785 19.4741 140.034 19.2259 140.034 18.9233V18.0208C140.034 17.7183 139.785 17.47 139.483 17.47L139.485 17.4752Z"
          fill="#777676"
        />
        <path
          d="M213.13 4.85156H199.934C199.631 4.85156 199.383 5.09982 199.383 5.40238V6.30489C199.383 6.60745 199.631 6.8557 199.934 6.8557H208.904V10.789H200.976C200.673 10.789 200.425 11.0372 200.425 11.3398V12.2423C200.425 12.5449 200.673 12.7931 200.976 12.7931H208.904V17.6134H199.934C199.631 17.6134 199.383 17.8616 199.383 18.1642V19.0667C199.383 19.3693 199.631 19.6175 199.934 19.6175H213.13C213.37 19.6175 213.567 19.421 213.567 19.1805V5.29118C213.567 5.05068 213.37 4.85415 213.13 4.85415V4.85156Z"
          fill="#777676"
        />
        <path
          d="M120.341 6.42837H103.543C103.222 6.42837 102.961 6.68955 102.961 7.01021V7.85324C102.961 8.17391 103.222 8.43509 103.543 8.43509H110.949V12.6218C110.724 12.8701 104.944 19.1669 104.944 19.1669C104.854 19.2574 104.81 19.3686 104.81 19.4824C104.81 19.5264 104.833 19.5962 104.854 19.6634C104.921 19.8212 105.079 19.9324 105.257 19.9324H110.97C111.104 19.9324 111.218 19.8651 111.306 19.7746L116.32 14.3311C116.387 14.2406 116.434 14.1294 116.434 14.0156V8.4325H120.339C120.659 8.4325 120.921 8.17132 120.921 7.85066V7.00763C120.921 6.68697 120.659 6.42578 120.339 6.42578L120.341 6.42837Z"
          fill="#777676"
        />
        <path
          d="M99.3113 17.4533H93.3687V4.76649H98.7243C99.0449 4.76649 99.3061 4.5053 99.3061 4.18464V3.34161C99.3061 3.02095 99.0449 2.75977 98.7243 2.75977H82.5489C82.2283 2.75977 81.9671 3.02095 81.9671 3.34161V4.18464C81.9671 4.5053 82.2283 4.76649 82.5489 4.76649H87.9045V17.4533H81.9619C81.6309 17.4533 81.3594 17.7249 81.3594 18.0559V18.8601C81.3594 19.1911 81.6309 19.4626 81.9619 19.4626H99.3139C99.6449 19.4626 99.9164 19.1911 99.9164 18.8601V18.0559C99.9164 17.7249 99.6449 17.4533 99.3139 17.4533H99.3113Z"
          fill="#777676"
        />
        <path
          d="M103.965 3.9182H119.033C119.336 3.9182 119.584 3.66995 119.584 3.36739V3.15533H118.979C118.457 3.15533 118.033 2.73123 118.033 2.20886V1.91406H103.965C103.662 1.91406 103.414 2.16232 103.414 2.46488V3.36739C103.414 3.66995 103.662 3.9182 103.965 3.9182Z"
          fill="#777676"
        />
        <path
          d="M120.072 0H118.979C118.824 0 118.699 0.125041 118.699 0.279286V2.18774C118.699 2.34199 118.824 2.46703 118.979 2.46703H120.072C120.227 2.46703 120.352 2.34199 120.352 2.18774V0.279286C120.352 0.125041 120.227 0 120.072 0Z"
          fill="#777676"
        />
        <path
          d="M122.412 0.0214844H121.318C121.164 0.0214844 121.039 0.146525 121.039 0.300771V2.20923C121.039 2.36347 121.164 2.48851 121.318 2.48851H122.412C122.566 2.48851 122.692 2.36347 122.692 2.20923V0.300771C122.692 0.146525 122.566 0.0214844 122.412 0.0214844Z"
          fill="#777676"
        />
        <path
          d="M233.483 4.07876H228.918C228.67 4.07876 228.468 4.28046 228.468 4.52872V11.1644C228.153 11.4126 218.955 19.1266 218.955 19.1266C218.843 19.2171 218.797 19.3283 218.797 19.4628C218.797 19.5067 218.797 19.574 218.82 19.6205C218.887 19.8015 219.045 19.9127 219.247 19.9127H226.061C226.175 19.9127 226.286 19.8895 226.353 19.8222L233.775 13.5926C233.886 13.5021 233.933 13.3909 233.933 13.2564V4.52613C233.933 4.27788 233.731 4.07617 233.483 4.07617V4.07876Z"
          fill="#777676"
        />
        <path
          d="M178.439 3.91561C178.478 3.92337 178.519 3.93113 178.561 3.93113H187.097C187.4 3.93113 187.648 3.68288 187.648 3.38032V2.47781C187.648 2.18559 187.418 1.94768 187.131 1.92958C187.092 1.92182 187.05 1.91406 187.009 1.91406H178.473C178.17 1.91406 177.922 2.16232 177.922 2.46488V3.36739C177.922 3.6596 178.152 3.89751 178.439 3.91561Z"
          fill="#777676"
        />
        <path
          d="M161.023 2.25804H147.545L147.868 1.05297C147.946 0.760754 147.77 0.455608 147.478 0.378028L146.606 0.14529C146.314 0.06771 146.009 0.243557 145.932 0.535773L142.846 12.0486C142.769 12.3408 142.945 12.6459 143.237 12.7235L144.108 12.9563C144.401 13.0338 144.706 12.858 144.783 12.5658L147.007 4.26476H153.131V11.6115C152.906 11.8598 146.136 19.235 146.136 19.235C146.069 19.3255 146.022 19.4367 146.022 19.5505C146.022 19.5945 146.022 19.6617 146.045 19.7315C146.136 19.8893 146.294 20.0005 146.472 20.0005H152.161C152.296 20.0005 152.409 19.9332 152.497 19.8427L158.502 13.3209C158.569 13.2304 158.616 13.1192 158.616 13.0054V4.26476H161.018C161.321 4.26476 161.569 4.01651 161.569 3.71395V2.81144C161.569 2.50888 161.321 2.26062 161.018 2.26062L161.023 2.25804Z"
          fill="#777676"
        />
        <path
          d="M217.047 1.91016H224.345C224.65 1.91016 224.895 2.15841 224.895 2.46097V3.36348C224.895 3.66863 224.647 3.91429 224.345 3.91429H217.047C216.742 3.91429 216.496 3.66604 216.496 3.36348V2.46097C216.496 2.15582 216.744 1.91016 217.047 1.91016Z"
          fill="#777676"
        />
        <path
          d="M163.07 8.60938H173.908C174.213 8.60938 174.459 8.85763 174.459 9.16019V10.3472C174.459 10.6523 174.211 10.898 173.908 10.898H163.07C162.765 10.898 162.52 10.6497 162.52 10.3472V9.16019C162.52 8.85504 162.768 8.60938 163.07 8.60938Z"
          fill="#777676"
        />
        <path
          d="M5.31682 2.17887C5.21079 2.02888 5.05563 1.93061 4.8772 1.89699L3.75488 1.69787C3.57645 1.66684 3.39543 1.70563 3.24544 1.81166C3.09546 1.9151 2.99719 2.07284 2.96357 2.25127L0.0103725 19.0007C-0.0206593 19.1791 0.0181305 19.3601 0.124156 19.5101C0.230181 19.6601 0.385341 19.7584 0.563774 19.792L1.68609 19.9911C1.72488 19.9989 1.76626 20.0015 1.80505 20.0015C1.94469 20.0015 2.07916 19.9601 2.19553 19.8773C2.34552 19.7739 2.44379 19.6162 2.4774 19.4377L5.4306 2.6883C5.46163 2.50987 5.42284 2.32885 5.31682 2.17887Z"
          fill="#777676"
        />
        <path
          d="M13.5846 3.32345C13.5846 2.67695 13.763 2.07183 14.0682 1.54688H9.67716C9.56079 1.54688 9.42115 1.59342 9.32805 1.70979C9.23496 1.80289 9.21168 1.94253 9.23496 2.08217L11.6348 19.2816C11.658 19.5143 11.8675 19.6772 12.1002 19.6772H16.7628C16.9024 19.6772 17.0188 19.6074 17.1119 19.5143C17.205 19.4212 17.2282 19.2816 17.2282 19.1419L15.4387 6.43697C14.3371 5.83185 13.5846 4.6604 13.5846 3.32086V3.32345Z"
          fill="#777676"
        />
        <path
          d="M17.1351 0.5C15.5809 0.5 14.3086 1.7723 14.3086 3.32648C14.3086 4.88066 15.5809 6.15296 17.1351 6.15296C18.6893 6.15296 19.9616 4.88066 19.9616 3.32648C19.9616 1.7723 18.6893 0.5 17.1351 0.5ZM17.1351 4.55224C16.4601 4.55224 15.9067 3.99884 15.9067 3.32389C15.9067 2.64895 16.4601 2.09555 17.1351 2.09555C17.81 2.09555 18.3634 2.64895 18.3634 3.32389C18.3634 3.99884 17.81 4.55224 17.1351 4.55224Z"
          fill="#777676"
        />
        <path
          d="M39.0733 3.89278H36.1201C35.7451 3.89278 35.4633 4.14103 35.4633 4.47204V13.7971C33.6944 14.5005 31.9877 15.1599 30.1853 15.8555C29.1922 16.2383 28.1837 16.6288 27.1441 17.0347V2.11621C27.1441 1.77227 26.7976 1.51367 26.4873 1.51367H23.4617C23.1074 1.51367 22.8281 1.77744 22.8281 2.11621V19.1087C22.8281 19.2871 22.9109 19.4501 23.0738 19.5923C23.0945 19.6104 23.1203 19.6259 23.1462 19.6337C23.229 19.6621 23.3324 19.6776 23.4358 19.6776C23.5393 19.6776 23.6479 19.6621 23.7358 19.6311L39.3474 13.5876C39.5957 13.4945 39.7327 13.3083 39.7327 13.0627V4.47462C39.7327 4.14362 39.4509 3.89536 39.0733 3.89536V3.89278Z"
          fill="#777676"
        />
        <path
          d="M77.9554 10.7353L69.8509 9.30524V1.94812C69.8509 1.68693 69.6544 1.51367 69.4165 1.51367H65.0048C64.7668 1.51367 64.5703 1.68693 64.5703 1.94812V19.207C64.5703 19.4449 64.7668 19.6414 65.0048 19.6414H69.4165C69.6544 19.6414 69.8509 19.4449 69.8509 19.207V11.2732L77.6192 12.6437C77.8338 12.6825 78.0433 12.5351 78.0821 12.3205L78.2812 11.1982C78.32 10.9835 78.1726 10.7741 77.958 10.7353H77.9554Z"
          fill="#777676"
        />
        <path
          d="M58.1051 7.27344H54.6347C54.4459 7.27344 54.2934 7.42601 54.2934 7.61479V13.0298C54.0529 13.2186 47.0604 19.0836 47.0604 19.0836C46.975 19.1509 46.9414 19.2362 46.9414 19.3396C46.9414 19.3733 46.9414 19.425 46.9595 19.4586C47.0112 19.5957 47.1302 19.681 47.2853 19.681H52.1237C52.2091 19.681 52.2944 19.6474 52.3461 19.5957L58.3301 14.5866C58.4154 14.5194 58.449 14.4314 58.449 14.3306V7.61479C58.449 7.42601 58.2939 7.27344 58.1077 7.27344H58.1051Z"
          fill="#777676"
        />
        <path
          d="M45.2774 7.35938H46.1799C46.485 7.35938 46.7307 7.60763 46.7307 7.91019V11.378C46.7307 11.6831 46.4825 11.9288 46.1799 11.9288H45.2774C44.9722 11.9288 44.7266 11.6806 44.7266 11.378V7.91019C44.7266 7.60504 44.9748 7.35938 45.2774 7.35938Z"
          fill="#777676"
        />
        <path
          d="M48.8397 7.79063C48.832 7.82942 48.8242 7.8708 48.8242 7.91218V11.38C48.8242 11.6825 49.0725 11.9308 49.375 11.9308H50.2775C50.5698 11.9308 50.8077 11.7006 50.8258 11.4136C50.8335 11.3748 50.8413 11.3334 50.8413 11.2921V7.82425C50.8413 7.52169 50.593 7.27344 50.2905 7.27344H49.388C49.0957 7.27344 48.8578 7.50359 48.8397 7.79063Z"
          fill="#777676"
        />
      </g>
      <defs>
        <clipPath id="clip0_8344_2966">
          <rect width="233.934" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PaletteText;
