import React, { ChangeEvent, useEffect, useState } from "react";
import { USER_STATUS } from "../../../../services/constants/user";
import { makeRequest, request } from "../../../../services/axios/axios";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { API } from "../../../../services/constants/route/api";
import OrganizationTable from "./Table";
import { swalClose, swalError, swallConfirm, swalLoading } from "../../../../services/helpers/swal";
import { DELETE_CONFIRM_CONTENT, DELETE_CONFIRM_TITLE } from "../../../../services/constants/message";
import qs from "qs";
import { ORGANIZATION_SHOW_STATUS } from "../../../../services/constants/superadmin/pages/organization";
import { Organization } from "../../../../types/user/organization";
import { Link } from "react-router-dom";
import { PATH_SUPER_ADMIN } from "../../../../services/constants/route/router";
import RoundedButton from "../../../user/commons/RoundedButton";
import { HiPlus } from "react-icons/hi";
import OrganizationFilter from "./Filter";

type FilterType = {
    query: string;
    limit: number;
    page: number;
    isAll?: number;
    sortType?: number;
    section_id?: number | string;
};

const SuperAdminOrganizationList = () => {
    const [filter, setFilter] = useState<FilterType>({
        limit: 10,
        query: "",
        isAll: ORGANIZATION_SHOW_STATUS.SHOW_ALL,
        page: 1,
        sortType: 1,
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [dataOrganizations, setDataOrganizations] = useState<Organization[]>([]);
    const [selectedRecord, setSelectedRecord] = useState<number[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [selectAll, setSelectAll] = useState(false);

    const resetTable = () => {
        setCurrentPage(1);
        setSelectAll(false);
        setSelectedRecord([]);
    };

    const handleSelectedAll = () => {
        setSelectAll(!selectAll);

        if (!selectAll) {
            setSelectedRecord(dataOrganizations?.map((item) => item.id));
        } else {
            setSelectedRecord([]);
        }
    };

    const handlePageChange = (pageNumber: number) => {
        // update the current page state
        setCurrentPage(pageNumber);
        changePaginate(pageNumber);
    };

    const handleSelectedRecord = (id: number) => {
        setSelectedRecord((prevRecords) => {
            if (prevRecords.includes(id)) {
                return prevRecords.filter((recordId) => recordId !== id)
            } else {
                return [...prevRecords, id]
            }
        })
    }

    const isRecordSelected = (id: number) => selectedRecord?.includes(id);

    useEffect(() => {
        const fetch = async () => {

            const result = await makeRequest({
                method: "get",
                url: `${API.SUPER_ADMIN.ORGANIZATION.LIST}?${paramizeObject(filter)}`,
            });

            if (!result.status) return swalError();
            const { organizations, last_page } = result.data;
            setDataOrganizations(organizations);
            setTotalPages(last_page);
            swalClose();
        };

        const timer = setTimeout(() => {
            fetch();
        }, 500);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    const changeHandle = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        if (e.target.type === "checkbox") {
            const target = e.target as HTMLInputElement;
            if (target.checked) {
                setFilter({
                    ...filter,
                    isAll: USER_STATUS.ACTIVE,
                    ["page"]: 1,
                });
                resetTable();
                return;
            } else {
                setFilter({ ...filter, isAll: USER_STATUS.CONFIRMING, page: 1 });
                resetTable();
                return;
            }
        }
        setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
        resetTable();
    };

    const handleDeleteMany = async () => {
        if (!selectedRecord) return;

        const paramObj = {
            ids: selectedRecord
        }

        const queryParam = qs.stringify(paramObj);

        swallConfirm(
            async () => {
                await request.post(
                    `${API.SUPER_ADMIN.ORGANIZATION.DELETE_MANY}?${queryParam}`,
                    () => {
                    },
                    () => {
                        setFilter({ ...filter });
                        resetTable()
                    },
                    () => {
                    },
                    { withSuccess: true }
                );
            },
            DELETE_CONFIRM_TITLE.ORGANIZATION,
            DELETE_CONFIRM_CONTENT.ORGANIZATION
        )
    }

    const changePaginate = (number: number) => {
        setFilter({ ...filter, page: number });
    };
    return (
        <div>
            <section>
                <div className="flex justify-end gap-[10px]">
                    <Link to={PATH_SUPER_ADMIN.ORGANIZATION.CREATE} className="rounded-[20px]">
                        <RoundedButton>
                            <HiPlus size={20} />
                            <div className="ml-[12px] mb-[1px]">新規企業</div>
                        </RoundedButton>
                    </Link>
                </div>
            </section>
            <OrganizationFilter changeHandle={changeHandle} handleDeleteMany={handleDeleteMany} />
            <OrganizationTable
                selectedRecord={selectedRecord}
                handleSelectedRecord={handleSelectedRecord}
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                organizations={dataOrganizations}
                totalPages={totalPages}
                selectAll={selectAll}
                handleSelectedAll={handleSelectedAll}
                isRecordSelected={isRecordSelected}
            />
        </div>
    );
};

export default SuperAdminOrganizationList;
