import React from "react";
import AuthLayout from "../../layouts/AuthLayout";

const FinishSubmitForgotPwd = () => {
  return (
    <AuthLayout>
      <div className="mb-[45px] mt-[200px] flex flex-col items-center">
        <div className="w-[550px] h-[252px] px-[25px]">
          <p className="mb-[30px] font-[500] text-[15px]">
            パスワード再設定が完了しました
          </p>

          <div className="text-[14px] font-[400] leading-[190%]">
            ご登録いただいたメールアドレス宛に <br />
            仮パスワードをご案内いたしました。
            <br />
            メールに記載されたURLから30分以内にログインし <br />
            パスワードの再設定を行なってください。
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default FinishSubmitForgotPwd;
