import { getTabObj } from "../../../helpers/parseData"
import { PATH_COMPANY_INFO } from "../../route/router"

const COMPANY_TAB_ITEM = {
    PAYMENT_SETTING: "決済方法",
    INVOICE_SETTING: "請求書設定",
    RECEIPT_SETTING: "領収書設定",
    RECEIPT_HISTORY: "領収書発行履歴"
}

const COMPANY_TAB_DATA = [
    getTabObj(COMPANY_TAB_ITEM.PAYMENT_SETTING, PATH_COMPANY_INFO.PAYMENT_SETTING),
    getTabObj(COMPANY_TAB_ITEM.INVOICE_SETTING, PATH_COMPANY_INFO.INVOICE_SETTING),
    getTabObj(COMPANY_TAB_ITEM.RECEIPT_SETTING, PATH_COMPANY_INFO.RECEIPT_SETTING),
    getTabObj(COMPANY_TAB_ITEM.RECEIPT_HISTORY, PATH_COMPANY_INFO.RECEIPT_HISTORY),
];

const PAYMENT_FORM_DATA = {
    PAYMENT_METHODS: [
        {
            name: "card",
            title: "クレジット"
        },
        {
            name: "konbini",
            title: "コンビニ"
        },
        {
            name: "invoice",
            title: "請求書"
        }
    ],
    BUTTON: {
        CANCEL: "キャンセル",
        SUBMIT: "設定を保存",
        EDIT: "変更"
    },
    ACCOUNT_TYPE: [
        "普通", "当座", "貯蓄"
    ]
}

const INVOICE_FORM_DATA = {
    BUTTON: {
        CANCEL: "キャンセル",
        PREVIEW: "プレビュー",
        SUBMIT: "設定を保存"
    },
    ACCOUNT_TYPE: [
        {
            value: 0,
            label: '普通'
        },
        {
            value: 1,
            label: '当座'
        },
        {
            value: 2,
            label: '貯蓄'
        },
    ]
}

const RECEIPT_FORM_DATA = {
    BUTTON: {
        HISTORY: "領収書発行の履歴一覧を見る",
        CANCEL: "キャンセル",
        PREVIEW: "プレビュー",
        SUBMIT: "設定を保存"
    },
}

export { COMPANY_TAB_ITEM, COMPANY_TAB_DATA, PAYMENT_FORM_DATA, INVOICE_FORM_DATA, RECEIPT_FORM_DATA }