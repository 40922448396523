import SuperAdminOrganizationBase from ".";
import SuperAdminOrganizationForm from "../../../components/SuperAdmin/Organization/Form/Form";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";

const SuperAdminOrganizationCreatePage = () => {
    const { getParamValue } = useObjectRoutes();
    const organization_id = getParamValue("organization_id");
    return (
        <SuperAdminOrganizationBase title={organization_id ? "契約企業管理｜ 契約企業編集" : "契約企業管理｜ 契約企業新規追加"}>
            <SuperAdminOrganizationForm />
        </SuperAdminOrganizationBase>
    );
};

export default SuperAdminOrganizationCreatePage;
