import React from "react";
import { WithChildren } from "../../../../types/globals";
import RequiredBox from "../../commons/RequiredBox";

type FormBlockItemProps = {
  label: string;
  withInput?: boolean;
  isEdit?: boolean;
  inputName?: string;
  value?: string;
  isRequired?: boolean;
  handleChange?: (
    e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLInputElement>
  ) => void;
};

const FormBlockItem = ({
  withInput = false,
  isEdit = false,
  isRequired = false,
  value,
  label,
  children,
  inputName,
  handleChange,
}: WithChildren<FormBlockItemProps>) => {
  return (
    <div className="flex pb-[2px]">
      <div className="flex justify-between items-center text-[14px] font-[500] leading-[100%] bg-success-lighter pl-[14px] pr-[10px] min-h-[40px] min-w-[350px] border-b border-[#EBE4D8]">
        {label}
        {!isEdit && isRequired && <RequiredBox />}
      </div>

      {withInput ? (
        <div className="w-full pl-[9px] py-[5px] flex items-center gap-[10px]">
          {isEdit ? (
            <p className="break-all text-[12px]">{value}</p>
          ) : (
            <input
              type="text"
              name={inputName}
              onChange={handleChange}
              value={value}
            />
          )}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default FormBlockItem;
