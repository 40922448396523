import { useEffect, useState } from 'react';
import { USER_SIDEBAR_DATA } from '../../services/constants/user/sidebar';
import SidebarItem from './SidebarItem';
import Hamburger from '../../components/commons/icons/SideBar/Hamburger';
import { useLayoutContext } from '../../context/LayoutContext';
import { authUtil } from '../../services/utils/auth';
import { useAuthContext } from '../../context/AuthContext';
import { SidebarItem as SidebarItemProps } from "../../types/user/main";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const { user } = useAuthContext();
  const location = useLocation();
  const storedOpenItem = localStorage.getItem('openItem');
  const [openItem, setOpenItem] = useState<number | null>(
    storedOpenItem ? Number(storedOpenItem) : null,
  );
  const { sidebarToggle, setSidebarToggle } = useLayoutContext();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('openItem', openItem?.toString() || '');
  }, [openItem]);

  const handleItemClick = (index: number | null, data: SidebarItemProps) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }

    if (sidebarToggle) {
      if (data.href.length > 0 && data.childs.length <= 0) {
        navigate(`${data.href}`);
      }
      if (data.href.length <= 0 && data.childs.length > 0) {
        navigate(`${data.childs[0]['href']}`);
      }
    }
  };

  return (
    <section
      className="w-full h-full bg-white text-secondary shadow-sm transition-all"
      style={{
        maxWidth: sidebarToggle ? '60px' : '250px',
      }}
    >
      <div className="h-0 min-h-[38px] flex justify-end items-center cursor-pointer">
        <Hamburger onClick={() => setSidebarToggle(!sidebarToggle)} isOpen={!sidebarToggle} />
      </div>
      <div className="whitespace-nowrap">
        {authUtil.getAllowedInternalRoutes(USER_SIDEBAR_DATA, authUtil.getPermissions(user?.role_id || 0)).map((data, index) => (
          <SidebarItem
            data={data}
            key={index}
            currentPath={location.pathname}
            isOpen={index === openItem}
            onItemClick={() => {
              handleItemClick(index, data);
              // setSidebarToggle(false);
            }}
            isMinimize={!!sidebarToggle}
          />
        ))}
      </div>
    </section>
  );
};

export default Sidebar;
