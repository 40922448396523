import { Link } from "react-router-dom";
import { IconType } from "react-icons";

const ItemSelectHeader = ({
  label,
  href,
  icon,
}: {
  label: string;
  href: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>> | React.FC | IconType;
}) => {
  const IconComponent = icon;
  return (
    <div className={`flex items-center justify-start gap-[10px]`}>
      <Link to={href} className={`flex items-center justify-start gap-[10px]`}>
        <IconComponent color="#fff" width={23} height={23} />
        {label}
      </Link>
    </div>
  );
};

export default ItemSelectHeader;
