import React, { ChangeEvent } from 'react';
import { AccountFormData } from '../../../../types/user/account';
import { Service } from '../../../../types/user/service';

type RolePickerProps = {
  currentRole?: number;
  setFormData: React.Dispatch<React.SetStateAction<AccountFormData>>;
  roleList: Service[];
};
const RolePicker = ({ currentRole, setFormData, roleList }: RolePickerProps) => {
  const changeHandle = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;
    setFormData((formData) => ({ ...formData, [name]: Number(value) }));
  };

  return (
    <div className="">
      <select
        name="role_id"
        id=""
        className="w-full border-2 border-success-lighter max-w-[320px] h-[30px] rounded-[5px] text-[12px] font-[400] pl-[10px]"
        value={currentRole}
        onChange={changeHandle}
      >
        {roleList?.map((role, index) => (
          <option value={role.id} key={index}>
            {role.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RolePicker;
