import TabCard from "../../../components/user/commons/TabCard";
import { PATH_SUPER_ADMIN } from "../../../services/constants/route/router";
import { useTabFinder } from "../../../hooks/useTabFinder";
import { WithChildren } from "../../../types/globals";
import { ACCOUNT_TAB_DATA } from "../../../services/constants/superadmin/pages/account";
import SuperAdminLayout from "../../../layouts/superadmin";

type AccountMainProp = {
  title?: string;
};

const SuperAdminAccountBase = ({ title, children }: WithChildren<AccountMainProp>) => {
  const { tabIndex } = useTabFinder(Object.values(PATH_SUPER_ADMIN.USER_ACCOUNT));

  return (
    <SuperAdminLayout>
      <TabCard tabData={ACCOUNT_TAB_DATA} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </SuperAdminLayout>
  );
};

export default SuperAdminAccountBase;
