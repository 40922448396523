import { RiErrorWarningLine } from "react-icons/ri";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import { Account } from "../../../../types/user/account";
import { PATH_USER_ACCOUNT } from "../../../../services/constants/route/router";
import { BiDotsVerticalRounded } from "react-icons/bi";
import LogoBox from "../../commons/LogoBox";
import { BsCheck2Circle } from "react-icons/bs";
import { sortArrayByKey } from "../../../../services/helpers/parseData";

const AccountTable = ({
  handlePageChange,
  currentPage,
  accounts,
  totalPages,
}: {
  handlePageChange: (number: number) => void;
  currentPage: number;
  accounts: Account[];
  totalPages: number;
}) => {
  return (
    <>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
            <tr className="min-h-[50px] text-[13px] font-[500]">
              <th
                scope="col"
                className="2xl:px-[110px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                氏名
              </th>
              <th
                scope="col"
                className="2xl:px-[130px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                メールアドレス
              </th>
              <th
                scope="col"
                className="2xl:px-[130px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                権限
              </th>
              <th
                scope="col"
                className="2xl:px-[35px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap w-0 min-w-[100px]"
              >
                利用可能プロダクト
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px]border-x-4 border-white whitespace-nowrap"
              >
                ステータス
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              ></th>
            </tr>
          </thead>
          <tbody>
            {accounts &&
              accounts.map((account) => (
                <tr
                  key={account.id}
                  className="bg-white border-b w-full text-[12px] font-[400] text-[#2E2E2E]"
                >
                  <th className="px-6 py-4 text-[14px] font-[500] text-primary whitespace-nowrap">
                    {`${account?.last_name} ${account?.first_name}`}
                  </th>
                  <td className="px-12 py-4 whitespace-nowrap">
                    {account?.email}
                  </td>
                  <td className="px-12 py-4 whitespace-nowrap">
                    {account?.role?.name}
                  </td>
                  <td>
                    <div className="flex items-center justify-start gap-[10px] ml-[10px]">
                      {sortArrayByKey(account?.features || []).map(
                        (feature) => (
                          <LogoBox
                            imageUrl={feature.small_logo_url}
                            key={feature?.id}
                          />
                        )
                      )}
                    </div>
                  </td>
                  <td className="py-4 text-center m-auto">
                    {account?.status === 0 && (
                      <div className="max-w-[100px] w-full px-[2px] pt-[3px] pb-[5px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                        <RiErrorWarningLine
                          className="absolute top-[2px] left-[8px]"
                          size={18}
                        />
                        <p className="pl-[20px]">確認中</p>
                      </div>
                    )}
                    {account?.status === 1 && (
                      <div className="max-w-[84px] w-full pl-[17px] pt-[3px] pb-[5px] text-[#749F91] bg-[#D7F5F2] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                        <BsCheck2Circle
                          className="absolute top-[2px] left-[8px]"
                          size={18}
                        />
                        <p className="pl-[5px]">確認済み</p>
                      </div>
                    )}
                    {account?.status === 2 && (
                      <div className="max-w-[84px] w-full px-[2px] pt-[3px] pb-[5px] text-[#7A7A7A] bg-neutral-100 rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                        <IoMdRemoveCircleOutline
                          className="absolute top-[2px] left-[8px]"
                          size={18}
                        />
                        <p className="pl-[20px]">停止中</p>
                      </div>
                    )}
                  </td>
                  <td className="">
                    <Link
                      to={`${PATH_USER_ACCOUNT.DETAIL}?account_id=${account?.id}`}
                    >
                      <div className="flex justify-center items-center">
                        <BiDotsVerticalRounded size={25} />
                      </div>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {totalPages !== 0 && (
        <Pagination
          totalItems={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default AccountTable;
