import React, { FC, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";
import Logo from "../components/Login/Logo";
import { checkIsSubdomain } from "../services/helpers/domain";
import banner_login from "../assests/images/banner_login.webp";
import AdsServiceList from "../components/user/Services/AdvertiseLogin/ServiceList";
import BackgroundDefault from "../components/commons/backgroundDefault/BackgroundDefault";
interface LayoutProps {
  children?: JSX.Element;
}

const AuthLayout: FC<LayoutProps> = (props) => {
  let accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;
  const [token, setToken] = useState(accessToken);
  const isSubdomain = checkIsSubdomain();
  let navigate = useNavigate();

  // if (!isSubdomain) {
  //   return <Navigate to={ROUTE.HOME} replace={true}/>
  // }

  return (
    <>
      {token ? (
        <Navigate to={ROUTE.SERVICE} replace={true} />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 bg-secondary-morelighter w-full h-screen text-center overflow-y-auto">
          <div className="hidden md:block relative">
            {/* <img src={banner_login} alt="" className="w-full h-full object-cover" /> */}
            <BackgroundDefault />
            <div className="flex justify-center items-center w-full absolute top-0 left-0 pt-[45px]">
              <AdsServiceList />
            </div>
          </div>
          <div className="w-full">
            <div onClick={()=>navigate(`${ROUTE.LOGIN}`)} > <Logo otherStyle={"mx-auto mb-[60px] mt-[70px]"} /></div>
            {props.children}
          </div>
        </div>
      )}
    </>
  );
};

export default AuthLayout;
