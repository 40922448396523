import { getBilledAmountWithTax, getTaxAmount } from '../../../../../services/utils/contract';
import { formatMoney, sortArrayByKey } from '../../../../../services/helpers/parseData';
import { Contract, ContractSubscription } from '../../../../../types/user/contract';
import { PALETTE_FEATURE } from '../../../../../services/constants/globals';

type PriceCalculationProps = {
  subscription?: ContractSubscription;
  contracts?: Contract[];
  isNew?: boolean;
  //   isYearly?: boolean;
  billedAmount?: ContractSubscription['billed_amount'];
};

const PriceCalculation = ({
  subscription = {},
  contracts = [],
  isNew = false,
  //   isYearly = false,
  billedAmount,
}: PriceCalculationProps) => {
  let remainingAmount = subscription.user_quantity || 0;
  let totalDiff = 0;
  let totalDiffDelayed = 0;

  return (
    <div className="w-full max-w-[400px]">
      <div
        className={`text-[15px] leading-[100%] text-center py-[8px] rounded-[5px] mb-[10px] ${
          isNew ? 'bg-primary-light text-white' : 'bg-secondary-extralight'
        }`}
      >
        {`${
          subscription.feature_id === PALETTE_FEATURE.MANABITE.ID ? '受講者' : '受験者'
        }人数【変更${isNew ? '後' : '前'}】`}
      </div>
      <div className="flex justify-between items-center text-[13px] font-[500] leading-[140%] border-b border-secondary-light py-[8px]">
        <div className="">利用料金／1アカウント</div>
        <div className="pl-[5px]">利用数</div>
        <div className="pr-[30px]">金額</div>
      </div>

      <div className="">
        {contracts && contracts.length ? (
          <>
            {sortArrayByKey(contracts, 'user_limit_start')?.map((price, index) => (
              <div
                key={index}
                className="flex border-b border-secondary-light mb-[3.5px] h-0 min-h-[35px] items-center"
              >
                <div className="w-[35%]">
                  <div className={`flex items-center justify-end`}>
                    <div className="">{price.user_limit_start || ''}</div>
                    <div className="">{!!price.user_limit_start && '〜'}</div>
                    <div className="">{price.user_limit_end || ''}</div>
                  </div>
                </div>
                <div className="w-[35%] text-right pr-[10px]">
                  {/* {formatMoney(isYearly ? price.price_yearly : price.price_monthly)} */}
                  {formatMoney(price.price_monthly)}
                </div>
                {Number(subscription.user_quantity) ? (
                  <>
                    {(() => {
                      //   let priceAmount = Number(isYearly ? price.price_yearly : price.price_monthly);
                      let priceAmount = Number(price.price_monthly);
                      let userQuantity = subscription.user_quantity || 0;
                      let userQuantityAmount = userQuantity;
                      const currentPriceList = sortArrayByKey(contracts || [], 'user_limit_start');

                      let userQuantityDiff =
                        Number(price.user_limit_end) -
                        Number(price.user_limit_start) +
                        (!!price.user_limit_start ? 1 : 0);

                      //Last item
                      if (!Number(price.user_limit_end)) {
                        userQuantityDiff = 0;
                      }

                      let userQuantityOldDiff = 0;
                      if (currentPriceList?.[index - 1]) {
                        userQuantityOldDiff =
                          Number(currentPriceList?.[index - 1]?.user_limit_end || 0) -
                          Number(currentPriceList?.[index - 1]?.user_limit_start || 0) +
                          1;
                      }

                      totalDiff += userQuantityDiff;
                      totalDiffDelayed += userQuantityOldDiff;

                      if (!Number(price.user_limit_end)) {
                        userQuantityAmount =
                          userQuantity >= totalDiff ? userQuantity - totalDiff : 0;
                      } else if (userQuantity >= totalDiff) {
                        userQuantityAmount = userQuantityDiff;
                        remainingAmount -= userQuantityDiff;
                      } else if (userQuantity < totalDiff && userQuantity >= totalDiffDelayed) {
                        userQuantityAmount = remainingAmount;
                      } else {
                        userQuantityAmount = 0;
                      }

                      return (
                        <>
                          <div className="w-[43%] text-right h-[100%]">
                            {userQuantityAmount ? (
                              <div className="bg-danger-smooth pr-[10px] pt-[7%] h-[100%]">
                                {userQuantityAmount}人
                              </div>
                            ) : (
                              <div className="pr-[10px] pt-[7%] h-[100%]">ー</div>
                            )}
                          </div>

                          <div className="w-[43%] text-right h-[100%]">
                            {userQuantityAmount ? (
                              <div className="bg-danger-smooth pr-[10px] pt-[7%] h-[100%]">
                                {formatMoney(userQuantityAmount * priceAmount)}
                              </div>
                            ) : (
                              <div className="pr-[10px] pt-[7%] h-[100%]">ー</div>
                            )}
                          </div>
                        </>
                      );
                    })()}
                  </>
                ) : (
                  <>
                    <div className="w-[43%] text-right h-[100%]">
                      <div className="pr-[10px] pt-[7%] h-[100%]">ー</div>
                    </div>

                    <div className="w-[43%] text-right h-[100%]">
                      <div className="pr-[10px] pt-[7%] h-[100%]">ー</div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </>
        ) : (
          <div className="skeleton w-full h-[150px] bg-secondary-lighter mb-[10px]"></div>
        )}
      </div>
      <div className="flex border-b border-secondary py-[5px]">
        <div className="w-[60%] text-right">アカウント数合計</div>
        <div className="w-[40%] text-right pr-[10px] text-[18px] font-[500] leading-[100%]">
          {subscription.user_quantity || 0}
        </div>
      </div>

      <div className="flex py-[5px]">
        <div className="w-[60%] text-right">小計</div>
        <div className="w-[40%] text-right pr-[10px] text-[18px] font-[500] leading-[100%] h-[30px] smaller-scroll-bar whitespace-nowrap overflow-x-auto">
          {formatMoney(billedAmount)}
        </div>
      </div>

      <div className="flex py-[5px]">
        <div className="w-[60%] text-right text-[10px] font-[400] leading-[100%]">
          消費税額（10％対象）
        </div>
        <div className="w-[40%] text-right pr-[10px] text-[12px] font-[500] leading-[100%] h-[30px] smaller-scroll-bar whitespace-nowrap overflow-x-auto">
          {formatMoney(getTaxAmount(billedAmount))}
        </div>
      </div>

      <div className="flex py-[5px]">
        <div className="w-[60%] text-right">アカウント料金合計</div>
        <div className="w-[40%] text-right pr-[10px] text-[18px] font-[500] leading-[100%] h-[30px] smaller-scroll-bar whitespace-nowrap overflow-x-auto">
          {formatMoney(getBilledAmountWithTax(billedAmount))}
        </div>
      </div>
    </div>
  );
};

export default PriceCalculation;
