import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../services/constants/route/api";
import { makeRequest } from "../../services/axios/axios";
import { swalError, swalSuccess } from "../../services/helpers/swal";
import { PATH_SUPER_ADMIN } from "../../services/constants/route/router";

const SuperAdminLogout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      let result = await makeRequest({
        method: "post",
        url: API.SUPER_ADMIN.AUTH.LOGOUT,
      });
      if (!result.status) {
        navigate(PATH_SUPER_ADMIN.USER_ACCOUNT.DEFAULT);
        return swalError();
      }

      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      swalSuccess();
      navigate(PATH_SUPER_ADMIN.LOGIN);
    };

    handleLogout();
  }, [navigate]);

  return <div></div>;
};

export default SuperAdminLogout;