import React from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import Pagination from "./Pagination";
import { Link } from "react-router-dom";
import { SuperAdminAccount } from "../../../../types/user/account";
import { PATH_SUPER_ADMIN } from "../../../../services/constants/route/router";
import { BsCheck2Circle } from "react-icons/bs";
import { HiOutlineUserCircle } from "react-icons/hi";
import SemiSquareButton from "../../../user/commons/SemiSquareButton";

const AccountTable = ({
  handlePageChange,
  currentPage,
  accounts,
  totalPages,
  selectAll,
  handleSelectedAll,
  handleSelectedRecord,
  selectedRecord,
  isRecordSelected,
}: {
  handlePageChange: (number: number) => void;
  currentPage: number;
  accounts: SuperAdminAccount[];
  totalPages: number;
  selectAll: boolean;
  handleSelectedAll: () => void;
  handleSelectedRecord: (id: number) => void;
  selectedRecord: number[];
  isRecordSelected: (id: number) => boolean;
}) => {
  return (
    <>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
            <tr className="min-h-[50px] text-[13px] font-[500]">
              <th
                scope="col"
                className="px-[10px] py-[2px] border-x-4 border-white whitespace-nowrap "
              >
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectedAll}
                />
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              ></th>
              <th
                scope="col"
                className="2xl:px-[110px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                氏名
              </th>
              <th
                scope="col"
                className="2xl:px-[130px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                メールアドレス
              </th>
              <th
                scope="col"
                className="2xl:px-[130px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                ログインID
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px]border-x-4 border-white whitespace-nowrap"
              >
                ステータス
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                最終ログイン
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap min-w-[50px]"
              ></th>
            </tr>
          </thead>
          <tbody>
            {accounts &&
              accounts.map((account) => (
                <tr
                  key={account.id}
                  className="border-b w-full text-[12px] font-[400] text-[#2E2E2E] hover:bg-secondary-extralight bg-white"
                >
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={isRecordSelected(account.id)}
                      onChange={() => handleSelectedRecord(account.id)}
                    />
                  </td>
                  <td>
                    {account?.avatar_url === null ? (
                      <div className="flex justify-center items-center">
                        <HiOutlineUserCircle color="#BEBEBE" size={40} />
                      </div>
                    ) : (
                      <div className="flex justify-center items-center">
                        <img
                          className="object-cover w-[40px] h-[40px]"
                          src={account?.avatar_url}
                          width={40}
                          alt={account?.first_name}
                        />
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 font-medium text-[14px] text-primary whitespace-nowrap">
                    {`${account?.last_name} ${account?.first_name}`}
                  </td>
                  <td className="px-12 py-4 whitespace-nowrap">
                    {account?.email}
                  </td>
                  <td className="px-12 py-4 text-center whitespace-nowrap">
                    {account?.username}
                  </td>
                  <td className="py-4 text-center m-auto">
                    {account?.status === 0 && (
                      <div className="max-w-[100px] w-full pl-[5px] px-[2px] pt-[3px] pb-[5px] pr-[10px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                        <RiErrorWarningLine
                          className="absolute top-[2px] left-[8px]"
                          size={18}
                        />
                        <p className="pl-[20px]">確認中 </p>
                      </div>
                    )}
                    {account?.status === 1 && (
                      <div className="max-w-[100px] w-full pl-[17px] pt-[3px] pb-[5px] text-[#749F91] bg-[#D7F5F2] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                        <BsCheck2Circle
                          className="absolute top-[2px] left-[8px]"
                          size={18}
                        />
                        <p className="pl-[5px]">確認済み </p>
                      </div>
                    )}
                    {account?.status === 2 && (
                      <div className="max-w-[84px] w-full px-[2px] pt-[3px] pb-[5px] text-[#7A7A7A] bg-neutral-100 rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                        <IoMdRemoveCircleOutline
                          className="absolute top-[2px] left-[8px]"
                          size={18}
                        />
                        <p className="pl-[20px]">停止中</p>
                      </div>
                    )}
                  </td>
                  <td className="px-12 py-4 text-center whitespace-nowrap">
                    {account?.last_login_at}
                  </td>
                  <td className="">
                    <Link
                      to={`${PATH_SUPER_ADMIN.USER_ACCOUNT.DETAIL}?account_id=${account?.id}`}
                    >
                      <div className="flex justify-center items-center">
                        <SemiSquareButton className="!py-[5px] !px-[10px] !rounded-[5px]">
                          <div className="font-500 font-normal text-[12px]">
                            編集
                          </div>
                        </SemiSquareButton>
                      </div>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {totalPages !== 0 && (
        <Pagination
          totalItems={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default AccountTable;
