import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ErrorData } from "../../../../types/globals";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { RiErrorWarningLine } from "react-icons/ri";

import { PATH_SUPER_ADMIN } from "../../../../services/constants/route/router";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import ErrorBox from "../../../commons/form/ErrorBox";
import { TbTrash } from "react-icons/tb";

import { BsCheck2Circle } from "react-icons/bs";
import {
  swalClose,
  swalError,
  swallConfirm,
  swalLoading,
} from "../../../../services/helpers/swal";
import {
  DELETE_CONFIRM_CONTENT,
  DELETE_CONFIRM_TITLE,
} from "../../../../services/constants/message";
import FormItem from "../../../user/commons/FormItem";
import SemiSquareButton from "../../../user/commons/SemiSquareButton";
import { OrganizationFormType } from "../../../../types/user/organization";
import { ORGANIZATION_STATUS } from "../../../../services/constants/superadmin/pages/organization";
import Button from "../../../Login/Button";
import { JapanPrefectures } from "../../../../services/constants/superadmin/countries";
import postal_code from "japan-postal-code";
import ImageInternalButton from "../../../commons/buttons/ImageInternalButton";
import Spinner from "../../../commons/icons/Animations/Spinner";

const SuperAdminOrganizationEditForm = () => {
  const navigate = useNavigate();
  const [superAdminOrganizationFormData, setSuperAdminOrganizationFormData] =
    useState<OrganizationFormType>({
      status: ORGANIZATION_STATUS.IN_ACTIVE,
    });
  const inputFileRef = useRef<HTMLInputElement>(null);
  const inputFileAvatarRef = useRef<HTMLInputElement>(null);
  const [fileError, setFileError] = useState<string>("");
  const [errors, setErrors] = useState<ErrorData>({});
  const { getParamValue } = useObjectRoutes();
  const organizationId = getParamValue("organization_id");
  const [province, setProvince] = useState<string>(
    Object.values(JapanPrefectures)[0]
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  useEffect(() => {
    if (!organizationId) return;

    const fetchDetail = async () => {
      await request.get(
        `${API.SUPER_ADMIN.ORGANIZATION.DETAIL}?organization_id=${organizationId}`,
        (organizationData) => {
          setSuperAdminOrganizationFormData(organizationData || {});
          setProvince(organizationData.province);
        }
      );
      swalClose();
    };

    fetchDetail();
  }, [organizationId]);

  const syncingZipCode = () => {
    postal_code.get(
      superAdminOrganizationFormData.postal_code as string,
      (object) => {
        setProvince(object.prefecture);
        setSuperAdminOrganizationFormData({
          ...superAdminOrganizationFormData,
          province: object.prefecture,
          ward: object.city,
          address1: object.area,
        });
      }
    );
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "province") {
      setProvince(value);
    }
    const file = (e.target as HTMLInputElement).files?.[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Size in MB

      // Check file type
      if (!fileType.startsWith("image/")) {
        setFileError("無効なファイルタイプです。画像を選択してください。");
        return;
      }

      // Check file size
      if (fileSize > 2) {
        setFileError("ファイルサイズが2MBの最大制限を超えています。");
        return;
      }

      setFileError("");
      setSuperAdminOrganizationFormData({
        ...superAdminOrganizationFormData,
        [name]: file,
      });
      return;
    }

    setSuperAdminOrganizationFormData({
      ...superAdminOrganizationFormData,
      [name]: value,
    });
  };

  const handleDelete = async () => {
    if (!organizationId) return;

    swallConfirm(
      async () => {
        setIsDeleting(true);
        await request.delete(
          `${API.SUPER_ADMIN.ORGANIZATION.DELETE}?organization_id=${organizationId}`,
          () => {},
          () => {
            setIsDeleting(false);
            navigate(PATH_SUPER_ADMIN.ORGANIZATION.DEFAULT)
          },
          (errors) => {
            setErrors(errors);
            setIsDeleting(false);
          },
          { withSuccess: true }
        );
      },
      DELETE_CONFIRM_TITLE.USER,
      DELETE_CONFIRM_CONTENT.USER
    );
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let submitAction = request.post;
    let submitUrl = API.SUPER_ADMIN.ORGANIZATION.CREATE;
    let submitData = {
      ...superAdminOrganizationFormData,
    };

    if (organizationId) {
      submitUrl = API.SUPER_ADMIN.ORGANIZATION.UPDATE;
      submitData = { ...submitData, organization_id: organizationId };
    }
    await submitAction(
      submitUrl,
      submitData,
      () => {
        navigate(PATH_SUPER_ADMIN.ORGANIZATION.DEFAULT)
        setIsSubmitting(false);
      },
      (errors) => {
        setErrors(errors);
        swalError();
        setIsSubmitting(false)
      },
      { withSuccess: true, withLoading: true, hasFileRequest: true }
    );
  };

  const removeOZLogo = () => {
    const newData: OrganizationFormType = {
      ...superAdminOrganizationFormData,
      logoInput: undefined,
      logo_url: undefined,
      delete_oz_logo: 1,
    };
    setSuperAdminOrganizationFormData(newData);
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  const removeOzAdminAvatar = () => {
    const newData: OrganizationFormType = {
      ...superAdminOrganizationFormData,
      avatarInput: undefined,
      avatar_url: undefined,
      delete_oz_admin_avatar: 1,
    };
    setSuperAdminOrganizationFormData(newData);
    if (inputFileAvatarRef.current) {
      inputFileAvatarRef.current.value = "";
    }
  };

  return (
    <div>
      {/* errors */}
      {errors && !!Object.keys(errors).length && (
        <div className="mb-[20px]">
          <ErrorBox errors={errors} />
        </div>
      )}
      {/* Organization form */}
      <div className="text-[16px] font-normal py-[5px]">企業基本情報</div>
      <FormItem label="企業名" withRequired>
        <input
          className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
          name="name"
          value={superAdminOrganizationFormData.name || ""}
          onChange={handleChange}
          placeholder="dummy_company_name"
        />
      </FormItem>
      <FormItem label="企業ID（サブドメイン）" withRequired>
        <input
          className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
          name="subdomain"
          value={superAdminOrganizationFormData.subdomain || ""}
          onChange={handleChange}
        />
        <ul className="list-disc text-[8px] text-left text-[#5A5A5A] ml-[14px] py-[20px]">
          <li>
            半角英数字および「-」（ハイフン）、「.」（ピリオド／ドット）の記号にて、3字以上10字まで設定することができます。 ※サブドメインに「..」や「--」「.-」を入力しないでください。「スペース（空白）」は使用できません。
          </li>
          <li>
            英字を入力する場合、大文字小文字の区別はありません。すべて小文字で表示されます。
          </li>
          <li>先頭文字と後尾文字は英文字にしてください。</li>
        </ul>
      </FormItem>
      <FormItem label="ステータス" withRequired>
        <label className="inline-flex items-center mr-[15px]">
          <input
            type="radio"
            className="form-radio text-primary-light border border-success-extralight"
            name="status"
            value={ORGANIZATION_STATUS.ACTIVE}
            checked={
              Number(superAdminOrganizationFormData.status) ===
              ORGANIZATION_STATUS.ACTIVE
            }
            onChange={handleChange}
            disabled={
              superAdminOrganizationFormData.status ===
                ORGANIZATION_STATUS.IN_ACTIVE ||
              !superAdminOrganizationFormData.status
            }
          />
          <span
            className={`ml-[8px] text-[12px] leading-[100%] ${
              superAdminOrganizationFormData.status ===
                ORGANIZATION_STATUS.IN_ACTIVE ||
              !superAdminOrganizationFormData.status
                ? "text-secondary-light"
                : "text-secondary-dark"
            }`}
          >
            有効
          </span>
        </label>
        <label className="inline-flex items-center mr-[15px]">
          <input
            type="radio"
            className="form-radio text-primary-light border border-success-extralight"
            name="status"
            value={ORGANIZATION_STATUS.DE_ACTIVE}
            checked={
              Number(superAdminOrganizationFormData.status) ===
              ORGANIZATION_STATUS.DE_ACTIVE
            }
            onChange={handleChange}
            disabled={
              superAdminOrganizationFormData.status ===
                ORGANIZATION_STATUS.IN_ACTIVE ||
              !superAdminOrganizationFormData.status
            }
          />
          <span
            className={`ml-[8px] text-[12px] leading-[100%] ${
              superAdminOrganizationFormData.status ===
                ORGANIZATION_STATUS.IN_ACTIVE ||
              !superAdminOrganizationFormData.status
                ? "text-secondary-light"
                : "text-secondary-dark"
            }`}
          >
            停止中
          </span>
        </label>
      </FormItem>
      <FormItem label="郵便番号">
        <div className="flex items-center">
          <input
            type="text"
            name="postal_code"
            value={superAdminOrganizationFormData.postal_code || ""}
            onChange={handleChange}
            className="min-h-[30px] max-w-[330px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full px-[22px] py-[14px]"
            placeholder="123-4567"
          />
          <Button
            content={"自動入力"}
            otherstyle={
              "bg-secondary text-white text-[12px] font-thin !w-[60px] !h-[30px] !ml-[20px] !rounded-md"
            }
            onClick={syncingZipCode}
            type={"button"}
          />
        </div>
      </FormItem>
      <FormItem label="住所" labelClass="!items-start !pt-[12px]">
        <select
          name="province"
          id=""
          className="min-h-[30px] w-[50%] pr-[33px] pl-[7px] appearance-none text-secondary-dark text-[13px] leading-[100%] mb-[10px] focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent"
          onChange={handleChange}
          value={province || Object.values(JapanPrefectures)[0]}
        >
          {Object.values(JapanPrefectures).map((item, index) => (
            <option
              value={item}
              className="!h-[50px]"
              key={`prefecture-${index}`}
            >
              {item}
            </option>
          ))}
        </select>
        <input
          className="mb-[10px] text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
          name="ward"
          value={superAdminOrganizationFormData.ward || ""}
          onChange={handleChange}
          placeholder="例）港区"
        />
        <input
          className="mb-[10px] text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
          name="address1"
          value={superAdminOrganizationFormData.address1 || ""}
          onChange={handleChange}
          placeholder="例）赤坂1-2-34"
        />
        <input
          className="mb-[5px] text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
          name="address2"
          value={superAdminOrganizationFormData.address2 || ""}
          onChange={handleChange}
          placeholder="例）パレットビル3階"
        />
      </FormItem>
      <FormItem label="企業ロゴ">
        <div className="flex items-center">
          {superAdminOrganizationFormData.logoInput ||
          superAdminOrganizationFormData.logo_url ? (
            <div className="relative w-[330px] object-cover mr-4 border-gray-200 my-[5px]">
              <ImageInternalButton
                removeImage={removeOZLogo}
                editImage={() => inputFileRef.current?.click()}
              />
              <img
                src={
                  superAdminOrganizationFormData.logoInput
                    ? URL.createObjectURL(
                        superAdminOrganizationFormData.logoInput
                      )
                    : superAdminOrganizationFormData.logo_url
                }
                alt="Organization Logo"
              />
            </div>
          ) : (
            <div
              className="w-full max-w-[330px] h-[30px] flex items-center justify-center mr-4  border-[#EFF1F0] border-[1px] my-[5px]"
              onClick={() => inputFileRef.current?.click()}
            ></div>
          )}
          <label
            htmlFor="logoInput"
            className="flex items-center justify-center w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-secondary-light cursor-pointer"
          >
            ファイルを選択
          </label>
          <input
            type="file"
            id="logoInput"
            name="logoInput"
            accept="image/*"
            className="hidden"
            ref={inputFileRef}
            onChange={handleChange}
            placeholder="ファイルを選択"
          />
          {!superAdminOrganizationFormData.logoInput && (
            <div className="ml-[10px] text-[10px] font-[400] leading-[19px]">
              選択されていません
            </div>
          )}
        </div>
        {fileError && <div className="text-red-500 text-xs">{fileError}</div>}
      </FormItem>

      {/* Account form */}
      <div className="text-[16px] font-normal py-[5px]">請求連絡先</div>
      <FormItem label="ログインID（半角英数のみ８〜20）   " withRequired>
        <input
          className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
          name="username"
          value={superAdminOrganizationFormData.username || ""}
          onChange={handleChange}
          placeholder="dummy_user_name"
        />
      </FormItem>
      {!organizationId && (
        <FormItem
          label="パスワード"
          labelClass="!items-start !pt-[12px]"
          withRequired
        >
          <div className="flex flex-col gap-[9px] w-full">
            <div className="text-secondary-dark w-full flex items-center">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] max-w-[330px] border-[#E5E7EB] rounded-[5px]"
                type="password"
                name="password"
                value={superAdminOrganizationFormData.password || ""}
                onChange={handleChange}
                autoComplete="new-password"
              />
              <span className="text-[10px] flex items-center ml-[10px]">
                半角英数小文字8〜20文字（記号可）
              </span>
            </div>
            <div className="text-secondary-dark w-full flex items-center">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] max-w-[330px] border-[#E5E7EB] rounded-[5px]"
                type="password"
                name="password_confirmation"
                value={
                  superAdminOrganizationFormData.password_confirmation || ""
                }
                onChange={handleChange}
                autoComplete="new-password"
              />
              <span className="text-[10px] flex items-center ml-[10px]">
                ※確認のためもう一度入力してください
              </span>
            </div>
          </div>
        </FormItem>
      )}
      <FormItem label="メールアドレス" withRequired>
        <div className="flex items-center">
          <input
            className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] w-full max-w-[330px] placeholder-secondary-light"
            name="email"
            value={superAdminOrganizationFormData.email || ""}
            onChange={handleChange}
            placeholder="dummyusername@mail.com"
          />

          {Number(superAdminOrganizationFormData.status) ===
            ORGANIZATION_STATUS.IN_ACTIVE ||
          !superAdminOrganizationFormData.status ? (
            <div className="min-w-[90px] ml-[16px] pl-[12px] pt-[3px] pb-[5px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
              <RiErrorWarningLine
                className="absolute top-[2px] left-[4px]"
                size={18}
              />
              <p className="pl-[12px]">確認中</p>
            </div>
          ) : (
            <div className="min-w-[90px] ml-[5px] pl-[17px] pt-[3px] pb-[5px] text-[#749F91] bg-[#D7F5F2] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
              <BsCheck2Circle
                className="absolute top-[2px] left-[4px]"
                size={18}
              />
              <p className="pl-[12px]">確認済み</p>
            </div>
          )}
        </div>
      </FormItem>
      <FormItem label="管理者氏名" withRequired>
        <div className="flex gap-[9px]  w-full">
          <div className="text-secondary-dark w-full flex items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
              name="last_name"
              value={superAdminOrganizationFormData.last_name || ""}
              onChange={handleChange}
              placeholder="鈴木"
            />
          </div>
          <div className="text-secondary-dark w-full flex items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
              name="first_name"
              value={superAdminOrganizationFormData.first_name || ""}
              onChange={handleChange}
              placeholder="一郎"
            />
          </div>
        </div>
      </FormItem>
      <FormItem label="管理者氏名（カナ）">
        <div className="flex gap-[9px] w-full">
          <div className="text-secondary-dark w-full flex items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
              name="last_name_kana"
              value={superAdminOrganizationFormData.last_name_kana || ""}
              onChange={handleChange}
              placeholder="スズキ"
            />
          </div>
          <div className="text-secondary-dark] w-full flex items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
              name="first_name_kana"
              value={superAdminOrganizationFormData.first_name_kana || ""}
              onChange={handleChange}
              placeholder="イチロウ"
            />
          </div>
        </div>
      </FormItem>
      <FormItem label="電話番号">
        <input
          className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
          name="tel"
          value={superAdminOrganizationFormData.tel || ""}
          onChange={handleChange}
          placeholder="03-1234-5678"
        />
      </FormItem>
      <FormItem label="部署名">
        <input
          className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
          name="department"
          value={superAdminOrganizationFormData.department || ""}
          onChange={handleChange}
          placeholder="営業部"
        />
      </FormItem>
      <FormItem label="プロフィール画像のアップロード">
        <div className="flex items-center">
          {superAdminOrganizationFormData.avatarInput ||
          superAdminOrganizationFormData.avatar_url ? (
            <div className="relative w-[330px] object-cover mr-4 border-gray-200 my-[5px]">
              <ImageInternalButton
                removeImage={removeOzAdminAvatar}
                editImage={() => inputFileAvatarRef.current?.click()}
              />
              <img
                src={
                  superAdminOrganizationFormData.avatarInput
                    ? URL.createObjectURL(
                        superAdminOrganizationFormData.avatarInput
                      )
                    : superAdminOrganizationFormData.avatar_url
                }
                alt="Profile Avatar"
              />
            </div>
          ) : (
            <div
              className="w-full max-w-[330px] h-[30px] flex items-center justify-center mr-4  border-[#EFF1F0] border-[1px] my-[5px]"
              onClick={() => inputFileAvatarRef.current?.click()}
            ></div>
          )}
          <label
            htmlFor="avatarInput"
            className="flex items-center justify-center w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-secondary-light cursor-pointer"
          >
            ファイルを選択
          </label>
          <input
            type="file"
            id="avatarInput"
            name="avatarInput"
            accept="image/*"
            className="hidden"
            ref={inputFileAvatarRef}
            onChange={handleChange}
            placeholder="ファイルを選択"
          />
          {!superAdminOrganizationFormData.avatarInput && (
            <div className="ml-[10px] text-[10px] font-[400] leading-[19px]">
              選択されていません
            </div>
          )}
        </div>
        {fileError && <div className="text-red-500 text-xs">{fileError}</div>}
      </FormItem>

      {/* Submit Buttons */}
      <div className="w-full flex justify-center items-center mb-[58px] mt-[66px] gap-[15px]">
        <SemiSquareButton
          className="bg-secondary-light max-w-[212px]"
          onClick={() => navigate(PATH_SUPER_ADMIN.ORGANIZATION.DEFAULT)}
        >
          一覧画面へ戻る
        </SemiSquareButton>
        {organizationId ? (
          <SemiSquareButton 
            className={`${isSubmitting ? "opacity-70 pointer-events-none" : ""} max-w-[244px]`} 
            onClick={handleSubmit}
          >
            {isSubmitting ? <Spinner width={17} height={17} /> : "入力内容で更新する"}
          </SemiSquareButton>
        ) : (
          <SemiSquareButton 
            className={`${isSubmitting ? "opacity-70 pointer-events-none" : ""} max-w-[244px]`} 
            onClick={handleSubmit}
          >
            {isSubmitting ? <Spinner width={17} height={17} /> : "入力内容で登録する"}
          </SemiSquareButton>
        )}
        {organizationId && (
          <SemiSquareButton
            onClick={handleDelete}
            className="max-w-[300px] bg-secondary text-white flex items-center max-h-[36px]"
          >
            {isDeleting ? 
              <Spinner width={17} height={17} /> :
              <>
                <span className="text-white whitespace-nowrap">
                  この契約企業を削除
                </span>
                <TbTrash className="ml-[15px] text-white" size={30} /> 
              </>            
            }
          </SemiSquareButton>
        )}
      </div>
    </div>
  );
};

export default SuperAdminOrganizationEditForm;
