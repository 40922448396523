type OrganizationSupportStatusLabelProps = {
  status: boolean;
};
const OrganizationSupportStatusLabel = ({ status }: OrganizationSupportStatusLabelProps) => {
  let themeClasses = '';
  let text = '';
  if (status) {
    themeClasses =
      'text-danger text-[12px] font-[500] leading-[100%] border border-danger py-[4px] px-[6px] rounded-[5px]';
    text = 'あり';
  } else {
    themeClasses = 'bg-success-lighter text-secondary text-[10px] font-[500] tracking-[0.2px] px-[7px] rounded-[5px]';
    text = '無し';
  }

  return <div className={themeClasses}>{text}</div>;
};

export default OrganizationSupportStatusLabel;
