import React from 'react';
import { SUBSCRIPTION_COMPLETE_TYPE } from '../../../../services/constants/user/pages/contract';
import { PATH_CONTRACT } from '../../../../services/constants/route/router';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';

const SubscriptionComplete = () => {
  const { navigate, getParamValue } = useObjectRoutes();
  const type = getParamValue('type');

  let completeTitle = 'プラン登録完了';
  let completeDescription = '契約プランの登録が完了しました。';

  if (type === SUBSCRIPTION_COMPLETE_TYPE.CHANGE_COMPLETE) {
    completeTitle = 'プラン変更完了';
    completeDescription = '契約プランの変更が完了しました。';
  }

  if (type === SUBSCRIPTION_COMPLETE_TYPE.UNSUBSCRIBE_COMPLETE) {
    completeTitle = 'プラン解約完了';
    completeDescription = 'プラン解約が完了しました。';
  }

  return (
    <div className="text-secondary-dark py-[20px]">
      <div className="text-[16px] font-[500] leading-[100%] mb-[20px]">{completeTitle}</div>
      <div className="text-[14px] font-[400] leading-[170%]">{completeDescription}</div>
      <div className="flex items-center justify-center">
        <button
          className="bg-primary rounded-[10px] px-[30px] py-[9.13px] text-[14px] font-[700] leading-[100%] text-white mt-[60px] mb-[20px]"
          onClick={() => navigate(PATH_CONTRACT.OVERVIEW)}
        >
          ダッシュボードへ戻る
        </button>
      </div>
    </div>
  );
};

export default SubscriptionComplete;
