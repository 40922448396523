import TabCard from "../../../components/user/commons/TabCard";
import { PATH_SUPER_ADMIN } from "../../../services/constants/route/router";
import { useTabFinder } from "../../../hooks/useTabFinder";
import { WithChildren } from "../../../types/globals";
import SuperAdminLayout from "../../../layouts/superadmin";
import { ORGANIZATION_TAB_DATA } from "../../../services/constants/superadmin/pages/organization";

type AccountMainProp = {
    title?: string;
};

const SuperAdminOrganizationBase = ({ title, children }: WithChildren<AccountMainProp>) => {
    const { tabIndex } = useTabFinder(Object.values(PATH_SUPER_ADMIN.ORGANIZATION));

    return (
        <SuperAdminLayout>
            <TabCard tabData={ORGANIZATION_TAB_DATA} tabIndex={tabIndex} title={title}>
                {children}
            </TabCard>
        </SuperAdminLayout>
    );
};

export default SuperAdminOrganizationBase;
