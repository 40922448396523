import AccountBase from ".";
import AccountForm from "../../../components/user/Account/Form/Form";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";

const AccountCreatePage = () => {
  const { getParamValue } = useObjectRoutes();
  const accountId = getParamValue("account_id");
  return (
    <AccountBase title={accountId ? "アカウント管理 ｜アカウント情報の編集・削除" : "アカウント管理 ｜アカウント新規追加"}>
      <AccountForm/>
    </AccountBase>
  );
};

export default AccountCreatePage;
