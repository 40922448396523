import React from "react";
import { Service } from "../../../../types/user/service";
import SmallButton from "../../commons/SmallButton";
import TVIcon from "../../../commons/icons/Service/TVIcon";

const AdsServiceListItem = ({ serviceInfo }: { serviceInfo: Service }) => {
  return (
    <div className="w-full max-w-[265px] max-[1350px]:!max-w-none flex flex-col justify-center items-center border-solid border-[1px] rounded-[5px] py-[15px] bg-white">
      <div className="flex flex-col justify-center items-center text-[12px] text-[#2E2E2E] font-[400] mb-[15px]">
        {serviceInfo.description}
        <img src={serviceInfo.logo_url} alt="" className="h-[30px] mt-[10px]" />
      </div>
      <div className="flex justify-center items-center w-[230px] max-w-full h-[40px]">       
        <div>
            <a href={serviceInfo.landing_page_url} target="_blank" className="rounded-[10px]">
            <SmallButton className="border border-primary text-primary w-[170px] max-w-full px-[14px]">
                <TVIcon />
                <span className="ml-[2px]">サービスサイト</span>
            </SmallButton>
            </a>
        </div>
      </div>
    </div>
  );
};

export default AdsServiceListItem;
