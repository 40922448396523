import React from "react";
import RequiredBox from "../commons/RequiredBox";
import '../../../assests/styles/formBlockItem.css';

type FormBlockItemProps = {
  label: string;
  withInput?: boolean;
  isEdit?: boolean;
  inputName?: string;
  value?: string;
  isRequired?: boolean;
  placeholder?: string;
  children?: React.ReactNode;
  handleChange?: (
    e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLInputElement>
  ) => void;
  titleTop?: boolean;
  longInput?: boolean;
  input_style?: string;
  isNumberInput?: boolean
};

const FormBlockItem = ({
  withInput = false,
  isEdit = false,
  isRequired = false,
  value,
  label,
  children,
  inputName,
  handleChange,
  placeholder,
  titleTop = false,
  longInput = true,
  input_style,
  isNumberInput = false
}: FormBlockItemProps) => {
  return (
    <div className="flex mb-[2px] border-b-[1.5px] border-[#E1E3E2]" id="form-block-item">
      <div className={`flex justify-between ${titleTop ? 'items-start pt-[10px]' : 'items-center'} text-[14px] font-[500] leading-[100%] bg-success-lighter pl-[14px] pr-[10px] min-h-[40px] min-w-[350px]`}>
        {label}
        {!isEdit && isRequired && <RequiredBox />}
      </div>

      {withInput ? (
        <div className={`${longInput ? 'mr-[50px] w-full' : 'w-[40%]'} pl-[9px] py-[5px] flex items-center gap-[10px]`}>
          {isEdit ? (
            <p className="break-all text-[12px]">{value}</p>
          ) : (
            <input
              type={isNumberInput ? "number" : "text"}
              name={inputName}
              onChange={handleChange}
              value={value}
              placeholder={placeholder}
              className={input_style}
            />
          )}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default FormBlockItem;
