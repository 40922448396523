import React, { useEffect, useRef, useState } from "react";
import Button from "../../Login/Button";
import { useStepProvider } from "../../../context/StepsContext";
import { JapanPrefectures } from "../../../services/constants/superadmin/countries";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { ErrorData } from "../../../types/globals";
import ErrorBox from "../../commons/form/ErrorBox";
import postal_code from "japan-postal-code";
import { OrganizationRegisterDataType } from "../../../types/register/organizationRegister";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { useJwt } from "react-jwt";
import { swalMessage } from "../../../services/helpers/swal";
import { transformZenkakuToNormalNumber } from "../../../services/helpers/parseData";
import LogoDefault from "../../../assests/images/default_avatar.webp"
import { AiOutlineUpload } from "react-icons/ai";
import ImageInternalButton from "../../commons/buttons/ImageInternalButton";

const CreateOrganizationForm = () => {
    const { getParamValue } = useObjectRoutes();
    let token = getParamValue("token");
    const { decodedToken } = useJwt(token as string);
    const [dataRegister, setDataRegister] = useState<OrganizationRegisterDataType>({
        name: '',
        subdomain: '',
        ozId: localStorage.getItem('ozId') ? Number(localStorage.getItem('ozId')) : undefined,
        postal_code: ''
    });

    useEffect(() => {
        // @ts-ignore
        if (decodedToken && decodedToken.subdomain && decodedToken.oz_name) {
            // @ts-ignore
            setDataRegister({ ...dataRegister, subdomain: decodedToken.subdomain, name: decodedToken.oz_name });
        }
    }, [decodedToken]);

    const [errors, setErrors] = useState<ErrorData>({});
    const { nextStep, setDataStep, data } = useStepProvider();
    const [province, setProvince] = useState<string>(Object.values(JapanPrefectures)[0]);
    const [isSyncingPostal, setIsSyncingPostal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const numberRegex = new RegExp(/[^0-9]/, 'g');

    const syncingZipCode = () => {
        setIsSyncingPostal(true);
        let postalData = {}
        postal_code.get(dataRegister.postal_code as string, (object) => {
            setIsSyncingPostal(false);
            setProvince(object.prefecture)
            setDataRegister({
                ...dataRegister,
                province: object.prefecture,
                ward: object.city,
                address1: object.area,
            })
            postalData = object;
        }
        );

        setTimeout(() => {
            if (!Object.keys(postalData).length) {
                let messageError = "その郵便番号は存在しません";
                if(dataRegister.postal_code?.length !== 7 || dataRegister.postal_code.match(numberRegex)) {
                    messageError = "正しい郵便番号を入力してください";
                }
                swalMessage('エラー', messageError, 'error', {
                    timer: 2000,
                    showConfirmButton: false,
                });
                setIsSyncingPostal(false);
            }
        }, 1500)
    };
    
    const handleChangeInput = (
        e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
        | React.ChangeEvent<HTMLSelectElement>
        ) => {
            const { name, value } = e.target;
            if (name === 'province') {
                setProvince(value)
            }
            if (name === 'logo' && !value) return;
            const file =
            (e.target as HTMLInputElement).files &&
            (e.target as HTMLInputElement).files?.[0];
            if (file) {
                const fileType = file.type;
                const fileSize = file.size / 1024 / 1024; // Size in MB
                const mimes = ['png', 'jpg', 'jpeg'];
                
                // Check file type
                if (!fileType.startsWith("image/")) {
                    setErrors({ image: ["無効なファイルタイプです。画像を選択してください。"]});
                    return;
                }
                
                if(!mimes.includes(fileType.split('image/')[1])) {
                setErrors({ image: ["会社ロゴにはjpeg, png, jpgタイプのファイルを指定してください。"]});
                return;
            }
            
            // Check file size
            
            if (fileSize > 2) {
                setErrors({image: ['ファイルサイズが2MBの最大制限を超えています。']})
                return;
            }
      
            setErrors({});
            setDataRegister({ ...dataRegister, [name]: file });
            return;
        }
        if (name === 'postal_code') {
            let newPostalCodeValue = transformZenkakuToNormalNumber(value);
            setDataRegister({ ...dataRegister, [name]: newPostalCodeValue });
            return;
        }

        setDataRegister({ ...dataRegister, [name]: value });
    };

    const handleSubmitOrganization = async () => {
        setIsSubmitting(true);
        const submitData = { ...data.dataRegister, ...dataRegister };
        let dataToValidate: object = dataRegister;
        // @ts-ignore
        if (decodedToken && decodedToken.subdomain && decodedToken.subdomain.length > 0) {
            dataToValidate = { ...dataRegister, isAdminCreate: true } ;
        }

        const result = await makeRequest({
            method: "post",
            url: API.ADMIN_REGISTER.VALIDATE_REGISTER_ORGANIZATION_DATA,
            data: dataToValidate,
            hasFileRequest: true
        });

        if (!result.status) {
            setIsSubmitting(false);
            setErrors(result.error as ErrorData);
            return;
        }

        setIsSubmitting(false)
        setDataStep((curr) => ({
            ...curr,
            dataRegister: submitData,
        }));
        nextStep();
    };

    const removeImage = () => {
        setDataRegister({ ...dataRegister, logo: undefined });
        if (inputFileRef.current) {
            inputFileRef.current.value = '';
        }
    }  

    return (
        <div className="mb-[45px] mt-[30px] flex flex-col items-center max-h-[730px] overflow-auto">
            <p className="mb-[20px] text-[20px] font-normal">
                新規企業作成（無料）
            </p>
            {errors && Object.keys(errors).length > 0 && (<ErrorBox errors={errors} />)}
            <div className="min-[1200px]:w-[550px] min-[1100px]:w-[450px] w-[370px] mb-[25px] px-[25px] py-[30px] bg-secondary-morelighter  overflow-auto max-h-[500px] rounded-[10px]">
                <div className="mb-[15px] mt-[10px]">
                    <p className="text-[14px] font-normal text-left mb-[8px]">
                        企業名<span className="text-danger">*</span>（必須）
                    </p>
                    <input
                        type="text"
                        name="name"
                        onChange={handleChangeInput}
                        value={dataRegister.name}
                        className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
                        placeholder="例：パレットエデュケーション株式会社"
                        required
                    />
                </div>
                <div className="mb-[15px] mt-[10px]">
                    <p className="text-[14px] font-normal text-left">
                        企業ID<span className="text-danger">*</span>（サブドメイン)
                    </p>
                    <ul className="list-disc text-[8px] text-left text-[#5A5A5A] ml-[14px] py-[10px]">
                        <li>半角英数字および「-」（ハイフン）、「.」（ピリオド／ドット）の記号にて、3字以上10字まで設定することができます。 ※サブドメインに「..」や「--」「.-」を入力しないでください。「スペース（空白）」は使用できません。</li>
                        <li>英字を入力する場合、大文字小文字の区別はありません。すべて小文字で表示されます。</li>
                        <li>先頭文字と後尾文字は英文字にしてください。</li>
                    </ul>
                    <input
                        type="text"
                        name="subdomain"
                        onChange={handleChangeInput}
                        value={dataRegister.subdomain}
                        className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
                        placeholder="例：palette"
                        required
                    />
                </div>
                <div className="mb-[15px] mt-[10px]">
                    <p className="text-[14px] font-normal text-left mb-[8px]">会社ロゴ（推奨サイズ　幅50 x 高さ50px）</p>
                    <div className="flex justify-start bg-white items-center object-cover border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 w-full pr-[22px] pl-[10px] py-[5px]">
                        <div className="bg-[#F4F4F4] flex justify-center items-center w-[40px] h-[40px] max-w-[40px] max-h-[40px]">
                            <input
                                type="file"
                                id="image"
                                name="logo"
                                accept="image/*"
                                className="hidden"
                                onChange={handleChangeInput}
                                placeholder="ファイルを選択"
                                ref={inputFileRef}
                            />
                            {dataRegister.logo?
                                <img 
                                    src={dataRegister.logo? URL.createObjectURL(dataRegister.logo) : LogoDefault} 
                                    className="object-cover w-full h-full" 
                                /> :
                                <div className="w-full h-full text-[#7A7A7A] leading-[100%] mt-[10px]">
                                    <p>企業</p>
                                    <p>ロゴ</p>
                                </div>
                            }
                        </div>
                        {dataRegister.logo ?
                            <>
                                <div className="relative ml-[60px] mr-[15px]">
                                    <ImageInternalButton 
                                        removeImage={removeImage}
                                        editImage={() => inputFileRef.current?.click()}
                                        editButtonOtherStyle="!top-[-10px] !right-[25px]"
                                        removeButtonOtherStyle="!top-[-10px] !right-[0px]"
                                    />
                                </div>
                                <p className="max-w-[200px] truncate text-[12px]">{dataRegister.logo.name}</p>
                            </> : 
                            <>
                                <button 
                                className="bg-[#7E869E] text-white rounded-full mx-[15px]"
                                onClick={() => inputFileRef.current?.click()}
                                >
                                    <AiOutlineUpload size={20} className="px-1 py-1"/>
                                </button>
                                <p className="text-[10px]">選択されていません</p>
                            </>
                        }
                    </div>
                </div>
                <div className="mb-[15px] mt-[10px]">
                    <p className="text-[14px] font-normal text-left mb-[8px]">郵便番号</p>
                    <div className="flex items-center">
                        <input
                            type="text"
                            name="postal_code"
                            onChange={handleChangeInput}
                            value={dataRegister.postal_code}
                            className="min-h-[50px] max-w-[320px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
                            placeholder="1234567"
                        />
                        <Button
                            content={"自動入力"}
                            otherstyle={
                                "bg-secondary text-white text-[12px] font-thin !w-[60px] !h-[30px] !ml-[20px] !rounded-md"
                            }
                            isSubmitting={isSyncingPostal}
                            onClick={syncingZipCode}
                            type={"button"}
                        />
                    </div>
                </div>
                <div className="mb-[15px] mt-[10px]">
                    <p className="text-[14px] font-normal text-left mb-[10px]">住所</p>
                    <select
                        name="province"
                        id=""
                        className="min-h-[50px]  w-full pr-[33px] pl-[7px] appearance-none text-secondary-dark text-[11px] leading-[100%] mb-[10px]"
                        onChange={handleChangeInput}
                        value={province}
                    >
                        {Object.values(JapanPrefectures).map((item, index) => (
                            <option value={item} className="!h-[50px]" key={`prefecture-${index}`}>
                                {item}
                            </option>
                        ))}
                    </select>
                    <input
                        type="text"
                        name="ward"
                        onChange={handleChangeInput}
                        className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px] mb-[10px]"
                        placeholder="例）港区"
                        value={dataRegister.ward}
                        required
                    />
                    <input
                        type="text"
                        name="address1"
                        onChange={handleChangeInput}
                        className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px] mb-[10px]"
                        placeholder="例）赤坂1-2-34"
                        value={dataRegister.address1}
                        required
                    />
                    <input
                        type="text"
                        name="address2"
                        onChange={handleChangeInput}
                        className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px] mb-[10px]"
                        placeholder="例）パレットビル3階"
                        required
                    />
                </div>
            </div>
            <Button
                content={"次に進む"}
                otherstyle={"bg-primary text-white"}
                type={"button"}
                onClick={handleSubmitOrganization}
                isSubmitting={isSubmitting}
            />
        </div>
    );
};

export default CreateOrganizationForm;
