import { getTabObj } from "../../../helpers/parseData";
import { PATH_USER_ACCOUNT } from "../../route/router";

const ACCOUNT_TAB_ITEM = {
  ACCOUNT_LIST: "アカウント一覧",
  ACCOUNT_CREATE: "アカウント新規追加",
};

const ACCOUNT_TAB_DATA = [
  getTabObj(ACCOUNT_TAB_ITEM.ACCOUNT_LIST, PATH_USER_ACCOUNT.DEFAULT),
  getTabObj(ACCOUNT_TAB_ITEM.ACCOUNT_CREATE, PATH_USER_ACCOUNT.CREATE),
];

export { ACCOUNT_TAB_ITEM, ACCOUNT_TAB_DATA };
