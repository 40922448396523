import { ChangeEvent, useState } from 'react';
import Button from '../../components/Login/Button';
import HelpCircleIcon from '../../components/Login/HelpCircleIcon';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { PATH_SUPER_ADMIN } from '../../services/constants/route/router';
import { makeRequest } from '../../services/axios/axios';
import { API } from '../../services/constants/route/api';
import { swalClose, swalError } from '../../services/helpers/swal';
import SuperAdminAuthLayout from '../../layouts/SuperAdminAuthLayout';
import { getSubdomainFromUrl } from '../../services/helpers/domain';
import ErrorBox from '../../components/commons/form/ErrorBox';
import { ErrorData } from '../../types/globals';

const SuperAdminLogin = () => {
  const [dataLogin, setDataLogin] = useState({
    username: '',
    password: '',
    remember_me: 0,
  });
  let navigate = useNavigate();
  const [errors, setErrors] = useState<ErrorData>({});
  // check token for remember or has been login before (session)
  let accessToken = localStorage.getItem('accessToken')
    ? localStorage.getItem('accessToken')
    : null;
  const [token, setToken] = useState(accessToken);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'remember_me') {
      setDataLogin({ ...dataLogin, [name]: Number(e.target.checked) });
      return;
    }
    setDataLogin({ ...dataLogin, [name]: value });
  };

  const handleLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setIsSubmitting(true);
    let result = await makeRequest({
      method: 'post',
      url: API.SUPER_ADMIN.AUTH.LOGIN,
      data: {
        ...dataLogin,
        subdomain: getSubdomainFromUrl(),
      },
    });

    if (result.status && result.data.token) {
      // if (dataLogin.remember_me === 'on') {
      localStorage.setItem('accessToken', result.data.token);
      localStorage.setItem('user', JSON.stringify(result.data.user));
      navigate(PATH_SUPER_ADMIN.USER_ACCOUNT.DEFAULT);
      return;
    }

    setIsSubmitting(false);
    let error = result.error;
    setErrors(error || {});
    swalError();
  };

  return (
    <>
      {token ? (
        <Navigate to={PATH_SUPER_ADMIN.USER_ACCOUNT.DEFAULT} replace={true} />
      ) : (
        <SuperAdminAuthLayout>
          <>
            <form className="mb-[45px] flex flex-col items-center" onSubmit={handleLogin}>
              <div className="md:w-[500px] sm:w-[450px] w-[370px] min-h-[249px] py-[30px] px-[25px] mb-[30px] bg-[#F8F7F6]">
                <p className="mb-[20px] text-[15px]">ログイン</p>
                {errors && !!Object.keys(errors).length && (
                  <div className="mb-[20px] text-left">
                    <ErrorBox errors={errors} />
                  </div>
                )}
                <div className="mb-[20px]">
                  <input
                    type="text"
                    name="username"
                    className="min-h-[50px] border border-warning-light text-gray-900 text-[16px] placeholder:text-[16px] rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
                    placeholder="メールアドレス　または　ログインID"
                    required
                    onChange={handleChangeInput}
                  />
                </div>
                <div className="mb-[15px]">
                  <input
                    type="password"
                    placeholder="パスワード"
                    onChange={handleChangeInput}
                    name="password"
                    className="min-h-[50px] border border-warning-light text-gray-900 text-[16px] placeholder:text-[16px] rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
                    required
                  />
                </div>
                <div className="flex items-center text-[12px]">
                  <input
                    id="remember"
                    type="checkbox"
                    name={'remember_me'}
                    onChange={handleChangeInput}
                    className="w-[15px] h-[15px] border border-warning-light rounded"
                  />
                  <label htmlFor="remember" className="ml-2 text-[#5A5A5A] dark:text-gray-300">
                    ログイン状態を保持
                  </label>
                </div>
              </div>

              <Button
                isSubmitting={isSubmitting}
                content={'ログイン'}
                otherstyle={'bg-primary text-white'}
                type="submit"
              />

              <Link to={PATH_SUPER_ADMIN.FORGOT_PASSWORD}>
                <div className="flex justify-center items-center mt-[16px]">
                  <HelpCircleIcon />
                  <p className="ml-[10px] text-[14px] align-middle">パスワードをお忘れの方</p>
                </div>
              </Link>
            </form>
          </>
        </SuperAdminAuthLayout>
      )}
    </>
  );
};

export default SuperAdminLogin;
