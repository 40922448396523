const getSubdomainFromUrl = () => {
  let currentUrl = window.location.hostname;
  let positionFirstDot = currentUrl.indexOf('.');

  if (positionFirstDot === -1) {
    return '';
  }

  return currentUrl.slice(0, positionFirstDot);
};

const checkIsSubdomain = () => {
  const domain = `${window.location.host}`;
  const mainDomain = `${process.env.REACT_APP_MAIN_DOMAIN}`;

  return domain !== mainDomain;
};

const extractSubdomainByUrl = (url?: string) => {
  if (!url || !url.trim()) return '';

  return url.split('/')[2]?.split('.')[0] || '';
};

const extractDomainByUrl = (url?: string) => {
  if (!url || !url.trim()) return '';

  return url.split('/')[2] || '';
};

export { getSubdomainFromUrl, checkIsSubdomain, extractSubdomainByUrl, extractDomainByUrl };
