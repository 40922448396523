import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorData } from "../../../types/globals";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { swalError, swalSuccess } from "../../../services/helpers/swal";
import {
  PATH_SUPER_ADMIN,
  ROUTE,
} from "../../../services/constants/route/router";
import SuperAdminAuthLayout from "../../../layouts/SuperAdminAuthLayout";
import Button from "../../../components/Login/Button";
import ErrorBox from "../../../components/commons/form/ErrorBox";

const SuperAdminVerifyEmailCreateOz = () => {
  let navigate = useNavigate();
  const [errors, setErrors] = useState<ErrorData>({});
  // check token for remember or has been login before (session)
  const { getParamValue } = useObjectRoutes();
  let accessToken = getParamValue("token");
  const [token, setToken] = useState(accessToken);
  const [verificationCode, setVerificationCode] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isSubmittingCode, setIsSubmittingCode] = useState<boolean>(false);
  const handleSendCodeClick = () => {
    setIsSendingCode(true);
    // send verification code to the user's email
  };

  const handleVerificationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const code = event.target.value;
    setVerificationCode(code);
    if (code.length > 0) {
      setIsSendingCode(false);
      setIsSubmittingCode(true);
    } else {
      setIsSendingCode(true);
      setIsSubmittingCode(false);
    }
  };

  const handleSubmitCodeClick = async () => {
    // swalLoading();
    setIsSubmittingCode(true);
    const result = await makeRequest({
      method: "post",
      url: API.SUPER_ADMIN.ORGANIZATION.VERIFY_EMAIL_CREATE,
      data: { code: verificationCode, token: token },
    });
    if (!result.status) {
      setErrors(result.error as ErrorData);
      return swalError("確認コードが間違っています。");
    }

    swalSuccess();
    navigate(ROUTE.LOGIN);
  };

  return (
    <>
      <SuperAdminAuthLayout>
        <>
          <div className="mb-[45px] flex flex-col items-center">
            <div className="md:w-[500px] sm:w-[450px] w-[370px] min-h-[249px] mb-[30px] px-[25px]">
              <p className="mt-[30px] mb-[20px] text-[15px]">メール確認</p>
              <p className="mt-[30px] mb-[4px] text-[12px]">
                メールを確認してください。
              </p>
              <p className="text-[12px] px-[60px] leading-[22.8px] mb-[20px]">
                メール受信箱を確認し、下記に確認コードを入力してあなたのメールアドレスを確認してください。
              </p>
              <div className="mb-[40px]">
                <input
                  type="text"
                  placeholder="6桁のコードを入力"
                  onChange={handleVerificationCodeChange}
                  value={verificationCode}
                  name="password"
                  className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
                  required
                />
              </div>
              <div className="mb-[15px]">
                {!isSubmittingCode ? (
                  <Button
                    content={"確認"}
                    otherstyle={"bg-secondary-light text-white mb-[10px]"}
                    type={"button"}
                    disabled={!isSubmittingCode}
                  />
                ) : (
                  <Button
                    content={"確認"}
                    otherstyle={"bg-primary text-white mb-[10px]"}
                    type={"button"}
                    disabled={!isSubmittingCode}
                    onClick={handleSubmitCodeClick}
                  />
                )}
                <Button
                  content={"コードを再信"}
                  otherstyle={"bg-primary text-white mb-[10px]"}
                  type={"button"}
                  onClick={() =>
                    navigate(
                      PATH_SUPER_ADMIN.ORGANIZATION.RESEND_VERIFY_EMAIL_CREATE
                    )
                  }
                />
                {errors && Object.keys(errors).length > 0 && (
                  <ErrorBox errors={errors} />
                )}
              </div>
            </div>
          </div>
        </>
      </SuperAdminAuthLayout>
    </>
  );
};

export default SuperAdminVerifyEmailCreateOz;
