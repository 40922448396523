import React, { ChangeEvent, useState } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import Pagination from "./Pagination";
import { BsCheck2Circle } from "react-icons/bs";
import InvoicePreview from "../../../Export/InvoicePreview";
import SemiSquareButton from "../../commons/SemiSquareButton";
import LinkButton from "../../../SuperAdmin/Organization/List/LinkButton";
import { FaRegFileAlt } from "react-icons/fa";
import { formatMoney } from "../../../../services/helpers/parseData";
import BaseModal from "../../../commons/modal/BaseModal";
import { INVOICE_STATUS } from "../../../../services/constants/user/pages/revenue";
import { swallConfirm } from "../../../../services/helpers/swal";
import { CONFIRM_INVOICE_PAYMENT_TITLE } from "../../../../services/constants/message";
import { InvoiceSetting, PaymentInvoice } from "../../../../types/user/invoice";

const InvoiceTable = ({
                          handlePageChange,
                          currentPage,
                          invoices,
                          totalPages,
                          handleUpdateInvoiceStatus,
                          invoiceSetting
                      }: {
    handlePageChange: (number: number) => void;
    currentPage: number;
    invoices: any[];
    totalPages: number;
    handleUpdateInvoiceStatus: (invoiceId: number, status: number) => void;
    invoiceSetting?: InvoiceSetting;
}) => {
    const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
    const [isShowModal, setIsShowModal] = useState<boolean>(false)
    const [invoiceStatus, setInvoiceStatus] = useState<number>(1)
    const [currentInvoiceId, setCurrentInvoiceId] = useState<number>(1)
    const [currentInvoice, setCurrentInvoice] = useState<PaymentInvoice>()
    const [currentInvoiceStatus, setCurrentInvoiceStatus] = useState<number>(1)

    const renderStatus = (status: number) => {
        switch (status) {
            case INVOICE_STATUS.NOT_PAID.value:
                return (
                    <div
                        className="max-w-[90px] w-full px-[2px] pt-[3px] pb-[5px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                        <RiErrorWarningLine
                            className="absolute top-[2px] left-[12px]"
                            size={18}
                        />
                        <p className="pl-[10px]">{INVOICE_STATUS.NOT_PAID.label}</p>
                    </div>
                );
            case INVOICE_STATUS.PAID.value:
                return (
                    <div
                        className="max-w-[90px] w-full pl-[17px] pt-[3px] pb-[5px] text-[#7A7A7A] bg-[#EFF1F0] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                        <BsCheck2Circle
                            className="absolute top-[2px] left-[12px]"
                            size={18}
                        />
                        <p className="pl-[10px]">{INVOICE_STATUS.PAID.label}</p>
                    </div>
                );
            default:
                return <></>
        }
    }

    const modalClick = () => {
        switch (currentInvoiceStatus) {
            case INVOICE_STATUS.NOT_PAID.value: {
                swallConfirm(
                    async () => {
                        handleUpdateInvoiceStatus(currentInvoiceId, invoiceStatus)
                    },
                    CONFIRM_INVOICE_PAYMENT_TITLE, '', {
                        confirmButtonText: '変更する',
                    }
                );
                break;
            }
            case INVOICE_STATUS.PAID.value: {
                handleUpdateInvoiceStatus(currentInvoiceId, invoiceStatus)
                break;
            }
        }
        setIsShowModal(false)
    }

    const handleChangeInvoiceStatus = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target.name === "invoice_status") {
            setInvoiceStatus(Number(e.target.value));
            return;
        }
    }

    return (
        <>
            {isShowPreview &&
                <InvoicePreview handleClose={() => setIsShowPreview(false)}
                                invoiceSettingData={currentInvoice?.invoice_setting_when_public ? currentInvoice?.invoice_setting_when_public : invoiceSetting} invoiceData={currentInvoice}/>
            }
            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
                    <tr className="min-h-[50px] text-[13px] font-[500]">
                        <th
                            scope="col"
                            className="2xl:px-[20px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                        >
                            注文番号
                        </th>
                        <th
                            scope="col"
                            className="2xl:px-[80px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                        >
                            名前
                        </th>
                        <th
                            scope="col"
                            className="2xl:px-[90px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap w-0 min-w-[100px]"
                        >
                            メールアドレス
                        </th>
                        <th
                            scope="col"
                            className="2xl:px-[55px] xl:px-[5px] lg:px-0 px-0 py-[5px]border-x-4 border-white whitespace-nowrap"
                        >
                            料金（税抜）
                        </th>
                        <th
                            scope="col"
                            className="2xl:px-[50px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                        >
                            振込確認
                        </th>
                        <th
                            scope="col"
                            className="2xl:px-[50px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                        >
                            プレビュー
                        </th>
                        <th
                            scope="col"
                            className="2xl:px-[50px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                        >
                            設定
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {invoices &&
                        invoices.map((invoice) => (
                            <tr
                                key={invoice.id}
                                className="bg-white border-b w-full text-[12px] font-[400] text-[#2E2E2E]"
                            >
                                <td className="pl-1 whitespace-nowrap">
                                    {invoice?.code}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {invoice.buyer_data.buyer_fullname}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {invoice.buyer_data.buyer_email}
                                </td>
                                <td className="px-6 py-4 float-right whitespace-nowrap">
                                    {formatMoney(invoice.total)}
                                </td>
                                <td className="py-4 text-center m-auto">
                                    {renderStatus(invoice.status)}
                                </td>
                                <td className="">
                                    <div className="flex justify-center items-center">
                                        <LinkButton className="!py-[5px] !px-[10px] !rounded-[5px]"
                                                    onClick={() => {
                                                        setIsShowPreview(true)
                                                        setCurrentInvoice(invoice)
                                                    }}>
                                            <FaRegFileAlt
                                                className=""
                                                size={18}
                                            />
                                            <div className="font-500 font-normal text-[12px]">プレビュー</div>
                                        </LinkButton>
                                    </div>
                                </td>
                                <td className="">
                                    {isShowModal &&
                                        <BaseModal
                                            setIsOpen={setIsShowModal}
                                            justifyDirection="end"
                                            onClick={modalClick}
                                            submitLabel="保存する"
                                        >
                                            <div className="text-center pt-[222px] pb-[167px]">
                                                <div className="flex justify-center">
                                                    <div
                                                        className="w-full max-w-[372px] font-[500] text-[14px] leading-[24px] mb-[5px] text-left">
                                                        振込ステータスを選択してください
                                                    </div>
                                                </div>

                                                <div className="mb-[5px]">
                                                    <select
                                                        name="invoice_status"
                                                        id=""
                                                        className="min-h-[50px] w-full max-w-[380px] text-[14px] leading-[16px] text-secondary pl-[17px]"
                                                        onChange={handleChangeInvoiceStatus}
                                                        value={invoiceStatus}
                                                    >
                                                        <option
                                                            value={INVOICE_STATUS.NOT_PAID.value}>{INVOICE_STATUS.NOT_PAID.label}</option>
                                                        <option
                                                            value={INVOICE_STATUS.PAID.value}>{INVOICE_STATUS.PAID.label}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </BaseModal>
                                    }
                                    <div className="flex justify-center items-center">
                                        <SemiSquareButton
                                            className="!py-[5px] !px-[10px] !rounded-[5px]"
                                            onClick={() => {
                                                setIsShowModal(true)
                                                setCurrentInvoiceId(invoice.id)
                                                setInvoiceStatus(invoice.status)
                                                setCurrentInvoiceStatus(invoice.status)
                                            }}
                                        >
                                            <div className="font-500 font-normal text-[12px]">編集</div>
                                        </SemiSquareButton>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {totalPages !== 0 && (
                <Pagination
                    totalItems={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                />
            )}
        </>
    );
};

export default InvoiceTable;
