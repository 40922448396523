import StepProvider, { useStepProvider } from '../../../context/StepsContext'
import CreatePasswordForm from '../CreatePassword/CreatePasswordForm'
import CreateOrganizationForm from '../CreateOrganization/CreateOrganizationForm'
import CreateAdminForm from '../CreateAdmin/CreateAdminForm'

function AdminRegisterSteps () {
    return (
        <StepProvider defaultStep={1} steps={[
            {
                component: <CreatePasswordForm />,
                name: 'password_step'
            },
            {
                component: <CreateOrganizationForm />,
                name: 'organization_step'
            },
            {
                component: <CreateAdminForm />,
                name: 'admin_step'
            },
        ]}>
            <AdminRegisterStep/>
        </StepProvider>
    )
}

function AdminRegisterStep () {
    const {steps, current} = useStepProvider()
    return (
        <>
            {
                steps.find((item,index) => current === index + 1)?.component
            }
        </>
    )
}

export default AdminRegisterSteps