import SuperAdminOrganizationDetailBase from ".";
import SuperAdminOrganizationDetailUserForm from "../../../../components/SuperAdmin/Organization/Form/FormDetailUser";

const SuperAdminOrganizationDetailUser = () => {
    return (
        <SuperAdminOrganizationDetailBase title={"契約企業管理｜ 請求連絡先閲覧"}>
            <SuperAdminOrganizationDetailUserForm/>
        </SuperAdminOrganizationDetailBase>
    );
};

export default SuperAdminOrganizationDetailUser;
