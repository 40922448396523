import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SuperAdminAuthLayout from "../../layouts/SuperAdminAuthLayout";
import { ROUTE } from "../../services/constants/route/router";
import { API } from "../../services/constants/route/api";
import Button from "../../components/Login/Button";
import { swalMessage, swalSuccess } from "../../services/helpers/swal";
import { makeRequest, request } from "../../services/axios/axios";
import { LinkAddress } from "../../types/user/linkAddress";

const SendEmailRegisterAdmin = () => {
  let navigate = useNavigate();

  const [dataRegister, setDataRegister] = useState({
    email: "",
  });
  const [linkAddress, setLinkAdress] = useState<LinkAddress>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value = e.target.value;

    setDataRegister({ ...dataRegister, [name]: value });
  };

  const handleSubmitEmail = async () => {
    setIsSubmitting(true);
    let result = await makeRequest({
      method: "post",
      url: API.ADMIN_REGISTER.SEND_REGISTER_EMAIL,
      data: dataRegister,
    });

    if (result.status) {
      swalSuccess();
      let token = result.data.token;
      setIsSubmitting(false);
      navigate(`/${ROUTE.ADMIN_REGISTER.DEFAULT}/${ROUTE.ADMIN_REGISTER.VERIFY_REGISTER_EMAIL}?token=${token}&email=${dataRegister.email}`);
      return;
    }

    setIsSubmitting(false);
    swalMessage("エラー", result.message, "error");
    return;
  };

  useEffect(() => {
    const fetchLinkAddressDetail = async () => {
        await request.get(
            API.SUPER_ADMIN.LINK_ADDRESS.DETAIL_FOR_REGISTER,
            (data: LinkAddress) => setLinkAdress(data),
        )
    }

    fetchLinkAddressDetail();
  }, []);

  return (
    <SuperAdminAuthLayout>
      <div className="mb-[45px] mt-[30px] flex flex-col items-center">
        <div className="md:w-[580px] sm:w-[460px] w-[400px] mb-[25px] px-[25px] py-[30px] bg-secondary-morelighter">
          <p className="mb-[20px] text-[20px] font-normal">パレットIDに登録</p>
          <p className="text-[14px] font-normal">
            パレットID<Link target="_blank" to={linkAddress.term_of_use_url || "#"} className="text-primary">利用規約</Link>、<Link target="_blank" to={linkAddress.privacy_policy_url || "#"} className="text-primary">プライバシーポリシー</Link>について同意の上、ご登録ください。
          </p>
          <div className="mb-[20px] mt-[30px]">
            <p className="text-[14px] font-normal text-left">メールアドレス</p>
            <input
              type="text"
              name="email"
              onChange={handleChangeInput}
              className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
              placeholder="example@palette"
              required
            />
          </div>

          <Button
            content={"上記に同意して登録"}
            otherstyle={"bg-primary text-white"}
            type={"button"}
            onClick={handleSubmitEmail}
            isSubmitting={isSubmitting}
          />

          <Link to={ROUTE.LOGIN}>
            <p className="text-black text-[14px] font-normal mt-[10px]">
              すでにお持ちのアカウントでログインする
            </p>
          </Link>
        </div>
      </div>
    </SuperAdminAuthLayout>
  );
};

export default SendEmailRegisterAdmin;
