import React, { InputHTMLAttributes } from 'react';
import { SetStateAction } from '../../../../../types/globals';
import {
  SUBSCRIPTION_STEP,
  CONTRACT_SUBSCRIPTION_PERIOD,
  CONTRACT_SUBSCRIPTION_STATUS,
  SUBSCRIPTION_CONST,
} from '../../../../../services/constants/user/pages/contract';
import { Contract, ContractSubscription } from '../../../../../types/user/contract';
import ChangeSubscriptionPolygon from '../icons/ChangeSubscriptionPolygon';
import { isNumber, preventFloatInput } from '../../../../../services/helpers/etc';
import {
  getBasicBilledPrice,
  getBasicChangedBilledAmount,
} from '../../../../../services/utils/contract';
import SubmitButton from '../SubmitButton';
import { useObjectRoutes } from '../../../../../hooks/useObjectRoutes';
import { PATH_CONTRACT } from '../../../../../services/constants/route/router';
import { swalError } from '../../../../../services/helpers/swal';
import { MdArrowRightAlt } from 'react-icons/md';
import { useStorage } from '../../../../../hooks/useStorage';
import {
  PALETTE_FEATURE,
  STORAGE_KEY_PATH_HISTORY,
} from '../../../../../services/constants/globals';
import { RenderContractLabelFormStatus } from '../RenderUtils';
import FormLine from './FormLine';
import PriceCalculation from './PriceCalculation';
import PriceChangeCalculation from './PriceChangeCalculation';

type BasicSubscriptionFormProps = {
  subscription?: ContractSubscription;
  subscribedPriceList?: Contract[];
  latestPriceList?: Contract[];
  setStep?: SetStateAction<string>;
  formData?: ContractSubscription;
  setFormData?: SetStateAction<ContractSubscription>;
  originSubscribed?: ContractSubscription;
};

const BasicSubscriptionForm = ({
  subscription,
  subscribedPriceList,
  latestPriceList,
  formData,
  setStep,
  setFormData,
  originSubscribed,
}: BasicSubscriptionFormProps) => {
  const { navigate } = useObjectRoutes();
  const { getItem } = useStorage();
  const pathHistory = getItem(STORAGE_KEY_PATH_HISTORY);

  const changeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (!isNumber(Number(value)) || Number(value) < 0) return;

    if (name === 'user_quantity' && Number(value) > SUBSCRIPTION_CONST.LIMIT_QUANTITY) {
      return;
    }

    let additionData: ContractSubscription = {};
    if (name === 'user_quantity') {
      const finalPrice = getBasicBilledPrice(latestPriceList, Number(value));
      const changedBilledAmount = getBasicChangedBilledAmount(
        latestPriceList || [],
        Number(originSubscribed?.user_quantity),
        Number(value),
      );

      additionData = {
        ...additionData,
        billed_amount: finalPrice,
        changed_billed_amount: changedBilledAmount,
      };
    }

    if (name === 'period_type') {
      const finalPrice = getBasicBilledPrice(
        latestPriceList,
        formData?.user_quantity,
        Number(value) === CONTRACT_SUBSCRIPTION_PERIOD.YEARLY,
      );

      additionData = {
        ...additionData,
        billed_amount: finalPrice,
      };
    }

    setFormData?.({ ...formData, [name]: Number(value), ...additionData });
  };

  const submitHandle = () => {
    if (!formData?.billed_amount) {
      swalError();
      return;
    }

    if (Number(formData?.user_quantity) < SUBSCRIPTION_CONST.REQUIRED_ACCOUNT_QUANTITY) {
      swalError(`${SUBSCRIPTION_CONST.REQUIRED_ACCOUNT_QUANTITY}アカウントから入力してください`);
      return;
    }

    setStep?.(
      originSubscribed?.id ? SUBSCRIPTION_STEP.CONFIRMATION_CHANGE : SUBSCRIPTION_STEP.CONFIRMATION,
    );
  };

  const returnHandle = () => {
    if (pathHistory && pathHistory.trim()) {
      return navigate(`${PATH_CONTRACT.OVERVIEW}?${pathHistory.trim()}`);
    }

    return navigate(PATH_CONTRACT.OVERVIEW);
  };

  // temporary remove by customer feedbacks
  // const unsubscribeHandle = () => {
  //   if (!originSubscribed?.billed_amount) {
  //     swalError();
  //     return;
  //   }
  //   setStep?.(SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE);
  // };

  return (
    <div>
      <div className="mb-[7px]">契約詳細</div>
      <div className="">
        {originSubscribed && originSubscribed?.id && (
          <FormLine label="サブスク状態">
            <div className="text-danger text-[12px] font-[400] leading-[100%] ml-[18px]">
              <RenderContractLabelFormStatus
                formData={formData}
                originSubscribed={originSubscribed}
              />
            </div>
          </FormLine>
        )}

        <FormLine
          label={`${
            subscription?.feature_id === PALETTE_FEATURE.MANABITE.ID ? '受講者' : '受験者'
          }アカウント数`}
        >
          {originSubscribed && originSubscribed.id && (
            <>
              <div className="flex items-center leading-[100%]">
                <div
                  className={`w-[10%] flex items-center gap-[7px] ml-[20px] ${
                    !latestPriceList?.length ? 'pointer-events-none' : ''
                  }`}
                >
                  <input
                    type="number"
                    name="user_quantity"
                    id=""
                    className="w-[80px] h-[30px] text-right text-[16px] bg-[#F7F6F6] pointer-events-none"
                    placeholder="0"
                    defaultValue={originSubscribed?.user_quantity || ''}
                    onWheel={(e) => (e?.target as HTMLInputElement)?.blur()}
                  />
                  <span className="text-secondary-light">人</span>
                </div>
              </div>

              <MdArrowRightAlt size={24} className="ml-[15px] text-secondary-light" />
            </>
          )}

          <div className="flex items-center leading-[100%]">
            <div
              className={`w-[10%] flex items-center gap-[7px] ml-[15px] ${
                !latestPriceList?.length ? 'pointer-events-none' : ''
              }`}
            >
              <input
                type="number"
                name="user_quantity"
                id=""
                className="w-[80px] h-[30px] text-right text-[16px]"
                placeholder="0"
                value={formData?.user_quantity || ''}
                onChange={changeHandle}
                onKeyDown={preventFloatInput}
                disabled={!latestPriceList?.length}
                onWheel={(e) => (e?.target as HTMLInputElement)?.blur()}
              />
              <span className="text-secondary-light">人</span>
            </div>
          </div>

          <div className="ml-[15px] text-danger text-[12px] font-[400] leading-[100%]">
            最低ユーザー数　{SUBSCRIPTION_CONST.REQUIRED_ACCOUNT_QUANTITY}IDから
          </div>
        </FormLine>

        <FormLine label={`アカウント${originSubscribed?.id ? '変更' : '追加'}`}>
          <div className="w-full flex items-center ml-[35px] py-[25px] gap-[20px]">
            <PriceCalculation
              contracts={latestPriceList}
              subscription={subscription}
              billedAmount={subscription?.billed_amount || 0}
            />
            <ChangeSubscriptionPolygon className="ml-[10px]" />
            <PriceCalculation
              isNew
              contracts={latestPriceList}
              subscription={formData}
              billedAmount={formData?.billed_amount || 0}
            />
          </div>
        </FormLine>

        {originSubscribed?.id && (
          <FormLine label="決済金額">
            <div className="w-full flex items-center justify-center max-w-[1000px] py-[25px] gap-[20px]">
              <PriceChangeCalculation
                contracts={latestPriceList}
                subscription={formData}
                originSubscribed={originSubscribed}
              />
            </div>
          </FormLine>
        )}

        <div className="flex justify-center gap-[15px] mt-[40px]">
          <SubmitButton type="return" onClick={returnHandle}>
            キャンセル
          </SubmitButton>
          <SubmitButton
            onClick={submitHandle}
            disabled={
              !Number(formData?.user_quantity) ||
              Number(formData?.user_quantity) < SUBSCRIPTION_CONST.REQUIRED_ACCOUNT_QUANTITY ||
              (originSubscribed?.status !== CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD &&
                formData?.period_type === originSubscribed?.period_type &&
                formData?.user_quantity === originSubscribed?.user_quantity)
            }
          >
            プラン登録を確認する
          </SubmitButton>

          {/* {originSubscribed?.id ? (
            <SubmitButton
              type="unsubscribe"
              onClick={unsubscribeHandle}
              disabled={
                originSubscribed?.status === CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD &&
                !originSubscribed?.in_scheduled_subscribed?.id
              }
            >
              サブスクを解約する
            </SubmitButton>
          ) : (
            <></>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default BasicSubscriptionForm;
