import { Contract, ContractSubscription } from '../../../../types/user/contract';
import { CONTRACT_SUBSCRIPTION_STATUS, CONTRACT_SUBSCRIPTION_TYPE } from '../../../../services/constants/user/pages/contract';
import {
  getContractNameByType,
  getExpiredBasicSubscriptionLabel,
  getSubscriptionFormFillPath,
  getSubscriptionTooltip,
} from '../../../../services/utils/contract';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import { formatMoney, sortArrayByKey } from '../../../../services/helpers/parseData';
import { formatDateTime } from '../../../../services/helpers/formatTime';
import { renderContractStatus } from './StatusContractOverviewTables';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useState } from 'react';
import TooltipDisplay from './TooltipDisplay';
import { PALETTE_FEATURE } from '../../../../services/constants/globals';

type StatusContractOverviewMainTableProps = {
  subscribed: ContractSubscription;
  priceListSubscriptions: Contract[];
  featureId: ContractSubscription['feature_id'];
  tableTitle: string;
  type?: number;
  disabled?: boolean;
};

const StatusContractOverviewMainTable = ({
  subscribed = {},
  priceListSubscriptions,
  featureId,
  tableTitle,
  type,
  disabled,
}: StatusContractOverviewMainTableProps) => {
  const { navigate } = useObjectRoutes();
  const [isDisplayTooltip, setIsDisplayTooltip] = useState(false);

  let remainingAmount = subscribed.user_quantity || 0;
  let totalDiff = 0;
  let totalDiffDelayed = 0;
  const basicHeaderClasses =
    'text-[13px] font-[600] leading-[120%] border border-white bg-success-extralight flex items-center justify-center';

  const basicBodyClasses = 'min-h-[50px] text-[14px] font-[500] leading-[120%]';

  return (
    <div className="mb-[50px] overflow-x-auto">
      {/* Basic contract table */}
      <div className="mb-[10px] text-[14px] font-[500] leading-[100%]">{tableTitle}</div>
      <div className="text-secondary-dark border-b border-success-extralight max-xl:w-[1050px] 2xl:w-full">
        {/* Basic contract header table */}
        <div className="flex h-[73px] w-full">
          <div className={`${basicHeaderClasses} w-[28%]`}>サービス</div>
          <div className={`${basicHeaderClasses} w-[10%]`}>{featureId === PALETTE_FEATURE.MANABITE.ID ? '受講者' : '受験者'}アカウント数</div>
          <div className={`${basicHeaderClasses} w-[9%] text-center`}>
            利用料金
            <br />
            1アカウント／月
            <br />
            （継続契約）
          </div>

          <div className={`w-[4%]`}>
            <div className={`${basicHeaderClasses} min-h-[34px]`}>利用数</div>
            <div className="bg-[#FBFDFC] flex items-center justify-center w-full h-[40px] !text-[12px]">
              {subscribed.user_quantity || 'ー'}
            </div>
          </div>
          <div className={`${basicHeaderClasses} w-[9%]`}>ステータス</div>
          <div className={`${basicHeaderClasses} w-[9%]`}>契約開始日</div>
          <div className={`${basicHeaderClasses} w-[11%]`}>終了日</div>
          <div className={`${basicHeaderClasses} w-[11%]`}>確認/変更</div>
          <div className={`${basicHeaderClasses} w-[10%]`}>有料契約の解除</div>
        </div>

        {/* Basic contract body table */}
        <div className="flex w-full h-full bg-[#FBFDFC] items-center py-[10px] relative">
          <div className={`${basicBodyClasses} w-[28%] px-[1%] flex items-center justify-between`}>
            <div className="text-primary">
              {getContractNameByType(priceListSubscriptions?.[0]?.type, featureId)}
            </div>
            {isDisplayTooltip && (
              <div className="">
                <TooltipDisplay
                  content={getSubscriptionTooltip(Number(priceListSubscriptions?.[0]?.type))}
                  className="absolute top-[25px] left-[227px]"
                />
              </div>
            )}
            <IoMdInformationCircleOutline
              size={22}
              className="cursor-pointer text-secondary"
              onMouseEnter={() => setIsDisplayTooltip(true)}
              onMouseLeave={() => setIsDisplayTooltip(false)}
            />
          </div>
          <div className={`w-[10%] pr-[2%]`}>
            {priceListSubscriptions?.map((price, index) => (
              <div key={index} className={`${basicBodyClasses} flex items-center justify-end`}>
                <div className="">{price.user_limit_start || ''}</div>
                <div className="">{!!price.user_limit_start && '〜'}</div>
                <div className="">{price.user_limit_end || ''}</div>
              </div>
            ))}
          </div>
          <div className={`w-[9%]`}>
            <div className="flex">
              <div className="w-full">
                {priceListSubscriptions?.map((price, index) => (
                  <div
                    key={index}
                    className={`${basicBodyClasses} flex items-center justify-center`}
                  >
                    <div className="">{formatMoney(price.price_monthly)}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={`w-[4%]`}>
            {sortArrayByKey(priceListSubscriptions, 'user_limit_start')?.map((price, index) => (
              <div
                key={index}
                className={`${basicBodyClasses} !text-[12px] flex items-center justify-center`}
              >
                <div className="">
                  {Number(subscribed.user_quantity) ? (
                    <div className="">
                      {(() => {
                        let userQuantity = subscribed.user_quantity || 0;
                        let userQuantityAmount = userQuantity;
                        const currentPriceList = sortArrayByKey(
                          priceListSubscriptions || [],
                          'user_limit_start',
                        );

                        let userQuantityDiff =
                          Number(price.user_limit_end) -
                          Number(price.user_limit_start) +
                          (!!price.user_limit_start ? 1 : 0);

                        //Last item
                        if (!Number(price.user_limit_end)) {
                          userQuantityDiff = 0;
                        }

                        let userQuantityOldDiff = 0;
                        if (currentPriceList?.[index - 1]) {
                          userQuantityOldDiff =
                            Number(currentPriceList?.[index - 1]?.user_limit_end || 0) -
                            Number(currentPriceList?.[index - 1]?.user_limit_start || 0) +
                            1;
                        }

                        totalDiff += userQuantityDiff;
                        totalDiffDelayed += userQuantityOldDiff;

                        if (!Number(price.user_limit_end)) {
                          userQuantityAmount =
                            userQuantity > totalDiff ? userQuantity - totalDiff : 0;
                        } else if (userQuantity >= totalDiff) {
                          userQuantityAmount = userQuantityDiff;
                          remainingAmount -= userQuantityDiff;
                        } else if (userQuantity < totalDiff && userQuantity >= totalDiffDelayed) {
                          userQuantityAmount = remainingAmount;
                        } else {
                          userQuantityAmount = 0;
                        }

                        return (
                          <div className="">{userQuantityAmount ? userQuantityAmount : 'ー'}</div>
                        );
                      })()}
                    </div>
                  ) : (
                    <>ー</>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className={`${basicBodyClasses} w-[9%] flex items-center justify-center`}>
            {renderContractStatus(subscribed.status)}
          </div>
          <div className={`${basicBodyClasses} w-[9%] flex items-center justify-center`}>
            {formatDateTime(subscribed.available_at)}
          </div>
          <div
            className={`${basicBodyClasses} w-[11%] flex items-center justify-center break-keep text-center`}
          >
            {subscribed.period_end_at
              ? `${formatDateTime(subscribed.period_end_at)}\n${getExpiredBasicSubscriptionLabel(
                  subscribed,
                )}`
              : 'ー'}
          </div>
          <div className={`${basicBodyClasses} w-[11%] flex items-center justify-center`}>
            <button
              className={`cursor-pointer w-fit ${
                disabled ? 'bg-secondary-light pointer-events-none' : 'bg-danger'
              } text-white rounded-[5px] px-[10px] pt-[4px] pb-[5px]`}
              onClick={() => {
                navigate(
                  getSubscriptionFormFillPath(
                    subscribed.id,
                    type,
                    subscribed.feature_id || featureId,
                  ),
                );
              }}
              disabled={disabled}
            >
              {type === CONTRACT_SUBSCRIPTION_TYPE.BASIC ? (
                <>{subscribed.id ? '利用人数変更' : '利用人数登録'}</>
              ) : (
                <>{subscribed.id ? 'プラン変更' : 'プラン登録'}</>
              )}
            </button>
          </div>
          <div className={`${basicBodyClasses} w-[10%] flex items-center justify-center !text-[10px]`}>
            { ( !subscribed.id || 
                ( subscribed.status === CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD && 
                  ( !subscribed.in_scheduled_subscribed || 
                    subscribed.in_scheduled_subscribed.status === CONTRACT_SUBSCRIPTION_STATUS.CANCELLED
                  )
                )
              ) ? <>ー</> :
                <button
                  className={`cursor-pointer w-[80%] ${
                    'bg-white'
                  } text-gray-500 rounded-[5px] px-[10px] pt-[4px] pb-[5px] border-[2px]`}
                  onClick={() => {
                    navigate(
                      getSubscriptionFormFillPath(
                        subscribed.id,
                        type,
                        subscribed.feature_id || featureId,
                        1
                      ),
                    );
                  }}
                  disabled={
                    ( !subscribed.id || 
                      ( subscribed.status === CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD && 
                        ( !subscribed.in_scheduled_subscribed || 
                          subscribed.in_scheduled_subscribed.status === CONTRACT_SUBSCRIPTION_STATUS.CANCELLED
                        )
                      )
                    ) ? 
                    true : false
                  }
                >
                  自動更新を解除する
                </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusContractOverviewMainTable;
