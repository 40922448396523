import Pagination from "./Pagination";
import { PaymentReceipt, ReceiptSetting } from "../../../../types/user/receipt";
import LinkButton from "../../../SuperAdmin/Organization/List/LinkButton";
import { FaRegFileAlt } from "react-icons/fa";
import { useState } from "react";
import BaseModal from "../../../commons/modal/BaseModal";
import ReceiptPreview from "../../../Export/ReceiptPreview";
import { formatMoney } from "../../../../services/helpers/parseData";
import { formatDateTime } from "../../../../services/helpers/formatTime";
import { TYPE_DATE_FORMAT } from "../../../../services/constants/globals";
import { TRANSACTION_STATUS } from "../../../../services/constants/user/payment";
import { getBilledAmountWithTax } from "../../../../services/utils/contract";

const ReceiptHistoryTable = ({
  handlePageChange,
  currentPage,
  receipts,
  totalPages,
  receiptSetting
}: {
  handlePageChange: (number: number) => void;
  currentPage: number;
  receipts: PaymentReceipt[];
  totalPages: number;
  receiptSetting: ReceiptSetting;
}) => {
    const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
    const [isShowModal, setIsShowModal] = useState<boolean>(false)
    const [currentReceipt, setCurrentReceipt] = useState<PaymentReceipt>()

    const handlePDFDownload = () => {
        const input = document.getElementById('receipt-preview'); 
        // Specify the id of the element you want to convert to PDF
        const printWindow = window.open();
        const styles = Array.from(document.querySelectorAll('style'))
                            .filter(style => style.innerText.includes('tailwind'))
                            .map(style => style.outerHTML)
                            .join('\n');
        if(input && printWindow) {
            try {
                printWindow.document.open();
                printWindow.document.write('<html><head><title>receipt</title>' + styles + '</head><body>' + input.innerHTML + '</body></html>');
                printWindow.document.close();
                window.close();
                printWindow.print();
                printWindow.close();
            } catch (error) {
                console.error('Error generating PDF:', error);
            }
        }
    }

    return (
        <>
            {isShowPreview &&
                <BaseModal 
                    setIsOpen={setIsShowPreview}
                    justifyDirection="end"
                    submitLabel="PDFダウンロード"
                    onClick={handlePDFDownload}
                >
                    <div className="mb-[0px] mt-[20px] flex justify-start w-[80%] m-auto">
                        <div className="w-fit pr-[20px] text-lg text-primary self-center">領収書の確認</div>
                        <div className="flex-1 bg-danger-lighter rounded-[2px] py-[10px] px-[15px] my-[7px] text-xs">
                            <p>実際のPDFとは文字のサイズなどが異なる場合がございます。</p>
                            <p>下の「PDFダウンロード」ボタンからダウンロードしてご確認ください</p>
                        </div>
                    </div>
                    <ReceiptPreview 
                        receiptSettingData={receiptSetting}
                        receiptData={currentReceipt}
                        otherStyle="w-[70%] mx-auto"    
                    />
                </BaseModal>
            }
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
                    <tr className="min-h-[50px] text-[13px] font-[500]">
                    <th
                        scope="col"
                        className="2xl:px-[50px] xl:px-[40px] lg:px-[20px] px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                    >
                        発行日時
                    </th>
                    <th
                        scope="col"
                        className="2xl:px-[60px] xl:px-[50px] lg:px-[30px] px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
                    >
                        名前
                    </th>
                    <th
                        scope="col"
                        className="2xl:px-[150px] xl:px-[120px] lg:px-[80px] px-0 py-[5px] border-x-4 border-white whitespace-nowrap w-0 min-w-[100px]"
                    >
                        注文番号
                    </th>
                    <th
                        scope="col"
                        className="2xl:px-[20px] xl:px-[15px] lg:px-[10px] px-0 py-[5px]border-x-4 border-white whitespace-nowrap"
                    >
                        金額（税込）
                    </th>
                    <th
                        scope="col"
                        className="2xl:px-[25px] xl:px-[20px] lg:px-[10px] px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                    >
                        プレビュー
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {receipts &&
                        receipts.filter((receipt) => receipt.status === TRANSACTION_STATUS.PAID).map((receipt) => (
                        <tr
                            key={receipt.id}
                            className="bg-white border-b w-full text-[12px] font-[400] text-[#2E2E2E]"
                        >
                            <td className="px-6 py-4 whitespace-nowrap">
                                {formatDateTime(receipt?.publish_date, TYPE_DATE_FORMAT.REGULAR_GLOBAL)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                {receipt.buyer_data.buyer_fullname}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                                {receipt.code}
                            </td>
                            <td className="px-6 py-4 float-right whitespace-nowrap">
                                {formatMoney(getBilledAmountWithTax(receipt.total))}
                            </td>
                            <td className="py-4 text-center m-auto">
                                <div className="flex justify-center items-center">
                                        <LinkButton className="!py-[5px] !px-[10px] !rounded-[5px]"
                                        onClick={() => {
                                            setIsShowPreview(true)
                                            setCurrentReceipt(receipt)
                                        }}>
                                        <FaRegFileAlt
                                            className=""
                                            size={18}
                                        />
                                        <div className="font-500 font-normal text-[12px]">プレビュー</div>
                                    </LinkButton>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
                </table>
            </div>
            {totalPages !== 0 && (
                <Pagination
                totalItems={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                />
            )}
        </>
    );
};

export default ReceiptHistoryTable;
