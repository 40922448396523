import SuperAdminAccountBase from ".";
import SuperAdminAccountList from "../../../components/SuperAdmin/Account/List/List";

const SuperAdminAccountListPage = () => {
  return (
    <SuperAdminAccountBase title="パレット管理者管理｜パレット管理者一覧">
      <SuperAdminAccountList />
    </SuperAdminAccountBase>
  );
};

export default SuperAdminAccountListPage;
