import React, { useEffect, useState } from "react";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { Service } from "../../../../types/user/service";
import { sortArrayByKey } from "../../../../services/helpers/parseData";
import AdsServiceListItem from "./ServiceListItem";
import PaletteText from "../../../Login/PaletteText";

const AdsServiceList = () => {
  const [services, setServices] = useState<Service[]>([]);
  const [serviceList, setServiceList] = useState<Service[]>([])
  useEffect(() => {
    const fetchServices = async () => {
      await request.get(API.SERVICE.LIST_ADS, (serviceList) => {
        setServices(serviceList || []);
      });
    };
    fetchServices();
  }, []);

  useEffect(() => {
    setServiceList(sortArrayByKey(services, "type").reverse())
  }, [services])
  
  return (
    <>
      {services && services.length > 0 && (
        <div className="flex flex-col justify-center items-center w-[640px] max-w-[calc(100%-40px)] bg-[#F8F7F6] rounded-[10px] p-[40px]">
          <div className="w-[550px] max-w-full">
            <div className="flex items-center flex-wrap text-[#7A7A7A] border-solid pb-1.5 border-b-[1px]">
              <div className="mr-[20px]">
                <PaletteText />
              </div>
              <div>サービスのご案内</div>
            </div>
            <div className="w-full mt-[40px] gap-y-[40px] flex gap-x-[15px] items-center justify-center flex-wrap">
              {serviceList.map((service, index) => (
                <AdsServiceListItem serviceInfo={service} key={index} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdsServiceList;
