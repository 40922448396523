import SuperAdminLinkAddressBase from ".";
import LinkAddressForm from "../../../components/SuperAdmin/LinkAddress/Form/Form";

const LinkAddressPage = () => {
  return (
    <SuperAdminLinkAddressBase title="リンク先 ｜ URL一覧">
      <LinkAddressForm />
    </SuperAdminLinkAddressBase>
  );
};

export default LinkAddressPage;
