import React from "react";
import ModuleButtonGroup from "./ModuleButtonGroup";
import { Service } from "../../../../types/user/service";
import { SERVICE_TYPES } from "../../../../services/constants/user/pages/service";
import SystemButtonGroup from "./SystemButtonGroup";

const ServiceListItem = ({ serviceInfo }: { serviceInfo: Service }) => {
  return (
    <div className="px-[20px] border border-secondary-light w-full h-[200px] py-[20px] rounded-[10px] justify-self-center">
      <div className="flex justify-center items-center">
        <img src={serviceInfo.logo_url} alt="" className="h-[50px] mb-[10px]" />
      </div>
      <hr className="mb-[15px]" />
      <div className="text-[14px] font-[400] mb-[20px]">
        {serviceInfo.description}
      </div>
      {serviceInfo.type === SERVICE_TYPES.SYSTEM && (
        <SystemButtonGroup
          landingPageUrl={serviceInfo.landing_page_url || ""}
          domainUrl={serviceInfo.domain || ""}
        />
      )}
      {serviceInfo.type === SERVICE_TYPES.MODULE && (
        <ModuleButtonGroup
          landingPageUrl={serviceInfo.landing_page_url || ""}
          domainContactUrl={serviceInfo.contact_domain || ""}
        />
      )}
    </div>
  );
};

export default ServiceListItem;
