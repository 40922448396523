import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorData } from "../../types/globals";
import { makeRequest } from "../../services/axios/axios";
import { swalError, swalSuccess } from "../../services/helpers/swal";
import Button from "../../components/Login/Button";
import SuperAdminAuthLayout from "../../layouts/SuperAdminAuthLayout";
import { ROUTE } from "../../services/constants/route/router";
import { API } from "../../services/constants/route/api";

const ResendEmailRegisterAdmin = () => {
  let navigate = useNavigate();
  const [errors, setErrors] = useState<ErrorData>({});
  const [email, setEmail] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  const handleResendCode = async () => {
    // swalLoading();
    const result = await makeRequest({
      method: "post",
      url: API.ADMIN_REGISTER.RESEND_REGISTER_EMAIL,
      data: { ["email"]: email },
    });
    if (!result.status) {
      setErrors(result.error as ErrorData);
      return swalError();
    }
    swalSuccess();
    let token = result.data.token;
    navigate(
      `/${ROUTE.ADMIN_REGISTER.DEFAULT}/${ROUTE.ADMIN_REGISTER.VERIFY_REGISTER_EMAIL}?token=${token}&email=${email}`
    );
    return;
  };

  return (
    <>
      <SuperAdminAuthLayout>
        <div className="mb-[45px] mt-[30px] flex flex-col items-center">
          <div className="md:w-[550px] sm:w-[450px] w-[370px] mb-[25px] px-[25px] py-[30px] bg-secondary-morelighter">
            <p className="mb-[20px] text-[20px] font-normal">コード再信</p>
            <p className="text-[14px] font-normal">
              ご登録メールアドレスに確認コードをお届けします。
              確認コードを受け取るメールアドレスを入力してください。
            </p>
            <div className="mb-[20px] mt-[30px]">
              <p className="text-[14px] font-normal text-left">
                メールアドレス
              </p>
              <input
                type="text"
                name="email"
                onChange={handleEmailChange}
                className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
                placeholder="example@palette"
                required
              />
            </div>

            <Button
              content={"コードを再信"}
              otherstyle={"bg-primary text-white"}
              type={"button"}
              onClick={handleResendCode}
            />

            <Button
              content={"キャンセル"}
              otherstyle={"bg-secondary-light text-white mt-[10px]"}
              type={"button"}
              onClick={handleGoBack}
            />
          </div>
        </div>
      </SuperAdminAuthLayout>
    </>
  );
};

export default ResendEmailRegisterAdmin;
