import { FeatureContract } from '../../../../types/user/contract';
import { SERVICE_TYPES } from '../../../../services/constants/user/pages/service';
import { formatMoney } from '../../../../services/helpers/parseData';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import { PATH_CONTRACT } from '../../../../services/constants/route/router';
import {
  CONTRACT_OVERVIEW_MODE,
  CONTRACT_SUBSCRIPTION_STATUS,
} from '../../../../services/constants/user/pages/contract';
import {
  getFeatureContractNameByType,
  getSubscriptionPeriodLabel,
} from '../../../../services/utils/contract';

type FeatureContractOverviewItemProps = {
  header?: boolean;
  featureContract?: FeatureContract;
};

const FeatureContractOverviewItem = ({
  header = false,
  featureContract,
}: FeatureContractOverviewItemProps) => {
  const { navigate } = useObjectRoutes();

  if (!!header && !!featureContract?.id) {
    return <></>;
  }

  const headerBaseClasses =
    'text-secondary-dark min-h-[73px] bg-success-lighter flex items-center justify-center text-center border border-white';

  const bodyBaseClasses =
    'text-secondary-dark min-h-[73px] flex items-center justify-start text-center';

  return (
    <>
      {!!header ? (
        <div className="w-full flex items-center">
          <div className={`w-[40%] ${headerBaseClasses}`}>サービス</div>
          <div className={`w-[23%] ${headerBaseClasses}`}>プラン</div>
          <div className={`w-[10%] ${headerBaseClasses}`}>契約形態</div>
          <div className={`w-[10%] ${headerBaseClasses}`}>費用</div>
          <div className={`w-[17%] ${headerBaseClasses}`}>確認／変更</div>
        </div>
      ) : (
        <div
          className={`w-full flex items-center border-b border-success-extralight hover:bg-[#FBFDFC]
        ${featureContract?.contract_subscriptions?.length && 'py-[10px]'}
        `}
        >
          <div className={`w-[40%] pl-[5%] ${bodyBaseClasses}`}>
            <div className="py-[30px]">
              <img src={featureContract?.logo_url} className="mb-[20px] h-[45px]" alt="" />
              <div className="">{featureContract?.description}</div>
            </div>
          </div>
          <div className={`w-[23%] pl-[30px] ${bodyBaseClasses}`}>
            <div className="w-full flex flex-col items-start gap-[20px]">
              {!!featureContract?.contract_subscriptions?.length ? (
                <>
                  {featureContract?.contract_subscriptions?.map((subscription, index) => (
                    <div key={index}>
                      {getFeatureContractNameByType(subscription.contract_type)}
                    </div>
                  ))}
                </>
              ) : (
                <>ー</>
              )}
            </div>
          </div>
          <div className={`w-[10%] ${bodyBaseClasses}`}>
            <div className="w-full flex flex-col items-center gap-[20px]">
              {!!featureContract?.contract_subscriptions?.length ? (
                <>
                  {featureContract?.contract_subscriptions?.map((subscription, index) => (
                    <div key={index}>
                      {subscription.status === CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD
                        ? 'ー'
                        : getSubscriptionPeriodLabel(subscription.period_type)}
                    </div>
                  ))}
                </>
              ) : (
                <>ー</>
              )}
            </div>
          </div>
          <div className={`w-[10%] ${bodyBaseClasses}`}>
            <div className="w-full flex flex-col items-end gap-[20px]">
              {!!featureContract?.contract_subscriptions?.length ? (
                <>
                  {featureContract?.contract_subscriptions?.map((subscription, index) => (
                    <div key={index} className="mr-[10%]">
                      {formatMoney(subscription.charge_amount)}
                    </div>
                  ))}
                </>
              ) : (
                <div className="mr-[10%]">ー</div>
              )}
            </div>
          </div>
          <div className={`w-[17%] ${bodyBaseClasses}`}>
            <div
              className="w-full flex items-center justify-center"
              onClick={() => {
                if (featureContract?.type === SERVICE_TYPES.MODULE) {
                  window.open(String(featureContract?.landing_page_url));
                  return;
                }

                navigate(
                  `${PATH_CONTRACT.OVERVIEW}?view_mode=${CONTRACT_OVERVIEW_MODE.STATUS_DETAIL}&feature_id=${featureContract?.id}`,
                );
              }}
            >
              {featureContract?.type === SERVICE_TYPES.MODULE ? (
                <button className="cursor-pointer border border-primary text-primary rounded-[5px] w-full max-w-[140px] py-[1px] text-center">
                  サービスサイト
                </button>
              ) : (
                <button className="cursor-pointer bg-danger text-white rounded-[5px] w-full max-w-[140px] py-[1px] text-center">
                  {!!featureContract?.contract_subscriptions?.length ? 'プラン変更' : 'プラン登録'}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FeatureContractOverviewItem;
