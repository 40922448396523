import { getTabObj } from "../../../helpers/parseData";
import { PATH_BASIC_INFO } from "../../route/router";

const BASIC_INFO_TAB_ITEM = {
  BASIC_INFO: "企業情報",
  CONTACT_LIST: "請求連絡先",
};

const BASIC_INFO_TAB_DATA = [
  getTabObj(BASIC_INFO_TAB_ITEM.BASIC_INFO, PATH_BASIC_INFO.BASIC_INFO),
  getTabObj(BASIC_INFO_TAB_ITEM.CONTACT_LIST, PATH_BASIC_INFO.CONTACT_LIST),
];

export { BASIC_INFO_TAB_ITEM, BASIC_INFO_TAB_DATA };
