import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { PATH_REGISTER_ADMIN } from "../../services/constants/route/router";
import SuperAdminAuthLayout from "../../layouts/SuperAdminAuthLayout";
import AdminRegisterSteps from "../../components/AdminRegister/AdminRegisterSteps/AdminRegisterSteps";
import { useObjectRoutes } from "../../hooks/useObjectRoutes";

const RegisterAdminOrganization = () => {
  // Link: /admin-organization-register/:token
  // Check token expired
  const { getParamValue } = useObjectRoutes();
  let accessToken = getParamValue("token");
  const [token, setToken] = useState(accessToken);
  if (!token) {
    return <Navigate to={PATH_REGISTER_ADMIN.SEND_REGISTER_EMAIL} replace={true} />;
  }

  return (
    <SuperAdminAuthLayout>
      <AdminRegisterSteps />
    </SuperAdminAuthLayout>
  );
};

export default RegisterAdminOrganization;
