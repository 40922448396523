import { getTabObj } from '../../../helpers/parseData';
import { ROUTE } from '../../route/router';

const SERVICE_TAB_ITEM = {
  SERVICE_LIST: 'サービス一覧',
};

const SERVICE_TAB_DATA = [getTabObj(SERVICE_TAB_ITEM.SERVICE_LIST, ROUTE.SERVICE)];

const SERVICE_TYPES = {
  SYSTEM: 2,
  MODULE: 1,
};

const AVAILABLE_SERVICES = [
  {
    id: 1,
    description: 'パレットLMS動画学習システム（LMSシステム）',
    type: SERVICE_TYPES.SYSTEM,
    logo: '/#',
  },
  {
    id: 2,
    description: 'サービスの説明テキストダミーサービスの説明テキストダミーMAX1行',
    type: SERVICE_TYPES.SYSTEM,
    logo: '/#',
  },
  {
    id: 3,
    description: 'プロナレーターによる宅録ナレーション録音サービス',
    type: SERVICE_TYPES.MODULE,
    logo: '/#',
  },
  {
    id: 4,
    description: '著作権利用許諾申請の処理代行サービス',
    type: SERVICE_TYPES.MODULE,
    logo: '/#',
  },
];

export { SERVICE_TAB_DATA, SERVICE_TAB_ITEM, AVAILABLE_SERVICES, SERVICE_TYPES };
