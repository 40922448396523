import React from "react";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { ORGANIZATION_STATUS } from "../../../services/constants/superadmin/pages/organization";
import { RiErrorWarningLine } from "react-icons/ri";

type OrganizationLabelStatusProps = {
  type: number;
};
const OrganizationLabelStatus = ({ type }: OrganizationLabelStatusProps) => {
  const data = {
    [ORGANIZATION_STATUS.ACTIVE]: {
      text: "有効",
      class:
        "px-[2px] pt-[3px] pb-[5px] text-danger bg-white border-[1px] border-red-500 min-w-[50px]",
      icon: null,
      textClass: "",
    },
    [ORGANIZATION_STATUS.DE_ACTIVE]: {
      text: "停止中",
      class:
        "px-[2px] pt-[3px] pb-[5px] text-[#7A7A7A] bg-neutral-100 min-w-[100px]",
      icon: (
        <IoMdRemoveCircleOutline
          className="absolute top-[3px] left-[9px]"
          size={18}
        />
      ),
      textClass: "pl-[20px]",
    },
    [ORGANIZATION_STATUS.IN_ACTIVE]: {
      text: "確認中",
      class:
        "px-[2px] pt-[3px] pb-[5px] text-danger bg-white border-[1px] border-red-500 min-w-[120px]",
      icon: (
        <RiErrorWarningLine
          className="absolute top-[3px] left-[9px]"
          size={18}
        />
      ),
      textClass: "pl-[20px]",
    },
  };

  let className = `whitespace-nowrap relative text-xs align-middle inline-block rounded-[5px] ${data[type].class}`;

  return (
    <div className={className}>
      {data[type].icon}
      <p className={data[type].textClass}>{data[type].text}</p>
    </div>
  );
};

export default OrganizationLabelStatus;
