import React from "react";
import CompanyBase from ".";
import PaymentForm from "../../../components/user/Company/Payment/Form";

const PaymentSettingPage = () => {
  return (
    <CompanyBase title="販売決済方法 ｜決済方法">
      <PaymentForm />
    </CompanyBase>
  );
};

export default PaymentSettingPage;
