import { ErrorData } from '../../../types/globals';
import { replaceJSX } from '../../../services/helpers/etc';

const ErrorBox = ({ errors }: { errors: ErrorData }) => {
  return (
    <div className="w-full bg-danger-lighter border border-danger rounded-[7px] py-[10px] px-[15px] my-[7px] text-danger">
      <ul>
        {Object.keys(errors).map((errorKey, index) => (
          <li key={index} className="mb-[3px]">
            {replaceJSX(
              errors[errorKey][0],
              '退会画面',
              <span
                className="underline underline-offset-[3px] cursor-pointer"
                onClick={() => {
                  alert('Coming soon...');
                  return;
                }}
              >
                退会画面
              </span>,
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorBox;
