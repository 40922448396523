import {
  getBasicPriceChangeData,
  getBilledAmountByUsage,
  getBilledAmountWithTax,
  getDiscountAmountByUsage,
  getTaxAmount,
} from '../../../../../services/utils/contract';
import { formatMoney, sortArrayByKey } from '../../../../../services/helpers/parseData';
import { Contract, ContractSubscription } from '../../../../../types/user/contract';
import dayjs from '../../../../../services/dayjs';
import React from 'react';

type PriceChangeCalculationProps = {
  subscription?: ContractSubscription;
  contracts?: Contract[];
  originSubscribed?: ContractSubscription;
};

const PriceChangeCalculation = ({
  subscription = {},
  contracts = [],
  originSubscribed = {},
}: PriceChangeCalculationProps) => {
  let remainingAmount = subscription.user_quantity || 0;
  let totalDiff = 0;
  let totalDiffDelayed = 0;
  let changedBilledAmount = 0;
  const userQuantity = subscription.user_quantity || 0;
  let userQuantityAmount = userQuantity;
  let changedUncounted = 0;
  const now = originSubscribed?.test_clock ? dayjs.unix(originSubscribed?.test_clock) : dayjs();
  const usedDays = dayjs(now).diff(originSubscribed?.period_start_at, 'd') + 1;
  const sortedContracts = sortArrayByKey(contracts, 'user_limit_start');

  const { userQuantitySeparatedAmounts: originUserQuantitySeparatedAmounts } =
    getBasicPriceChangeData(sortedContracts, originSubscribed.user_quantity);

  const { userQuantitySeparatedAmounts: newUserQuantitySeparatedAmounts } = getBasicPriceChangeData(
    sortedContracts,
    subscription.user_quantity,
  );
  const currentPriceList = sortArrayByKey(contracts || [], 'user_limit_start');

  return (
    <div className="w-full max-w-[400px]">
      <div className="text-[15px] leading-[100%] text-center py-[8px] rounded-[5px] mb-[10px] bg-[#5A5A5A] text-white">
        今回のご決済
      </div>

      <div className="mb-[10px]">
        {contracts &&
          contracts.length &&
          Number(subscription?.user_quantity) > Number(originSubscribed?.user_quantity) && (
            <div className="text-primary text-[14px] font-[400] leading-[100%] mb-[10px]">
              追加アカウント
            </div>
          )}
        {contracts && contracts.length && subscription?.user_quantity ? (
          <>
            {sortedContracts?.map((price, index) => (
              <React.Fragment key={index}>
                {subscription.user_quantity && originSubscribed.user_quantity ? (
                  <>
                    {(() => {
                      let diffIndexPoint = 0;
                      let diffQuantityAmount = 0;
                      const priceAmount = Number(price.price_monthly);

                      originUserQuantitySeparatedAmounts.forEach((originQuantityAmount, index) => {
                        if (
                          newUserQuantitySeparatedAmounts?.[index] &&
                          originQuantityAmount <= newUserQuantitySeparatedAmounts?.[index]
                        ) {
                          diffIndexPoint = index;
                          diffQuantityAmount =
                            newUserQuantitySeparatedAmounts?.[index] - originQuantityAmount;
                          return false;
                        }
                      });

                      if (
                        diffIndexPoint < 0 ||
                        index < diffIndexPoint ||
                        subscription.user_quantity <= originSubscribed.user_quantity
                      ) {
                        remainingAmount -= originUserQuantitySeparatedAmounts?.[index];
                        changedUncounted -= originUserQuantitySeparatedAmounts?.[index];
                        return <></>;
                      }

                      let userQuantityDiff =
                        Number(price.user_limit_end) -
                        Number(price.user_limit_start) +
                        (!!price.user_limit_start ? 1 : 0);

                      //Last item
                      if (!Number(price.user_limit_end)) {
                        userQuantityDiff = 0;
                      }

                      let userQuantityOldDiff = 0;
                      if (currentPriceList?.[index - 1]) {
                        userQuantityOldDiff =
                          Number(currentPriceList?.[index - 1]?.user_limit_end || 0) -
                          Number(currentPriceList?.[index - 1]?.user_limit_start || 0) +
                          1;
                      }

                      totalDiff += userQuantityDiff;
                      totalDiffDelayed += userQuantityOldDiff;

                      if (
                        !Number(price.user_limit_end) &&
                        userQuantity >= Number(price.user_limit_start)
                      ) {
                        userQuantityAmount =
                          userQuantity >= totalDiff
                            ? userQuantity - totalDiff + changedUncounted
                            : 0;

                        if (userQuantityAmount >= totalDiff) {
                          userQuantityAmount -= totalDiff;
                        }
                      } else if (userQuantity >= totalDiff) {
                        userQuantityAmount = userQuantityDiff;
                        remainingAmount -= userQuantityDiff;

                        if (remainingAmount < 0) {
                          userQuantityAmount += remainingAmount;
                        }
                      } else if (userQuantity < totalDiff && userQuantity >= totalDiffDelayed) {
                        userQuantityAmount = remainingAmount;
                      } else {
                        userQuantityAmount = 0;
                      }

                      if (userQuantityAmount && index === diffIndexPoint) {
                        userQuantityAmount = diffQuantityAmount;
                      }

                      if (userQuantityAmount < 0) {
                        return <></>;
                      }

                      changedBilledAmount += userQuantityAmount * priceAmount;

                      return (
                        <>
                          {userQuantityAmount ? (
                            <div className="flex border-b border-secondary-light mb-[3.5px] h-0 min-h-[35px] items-center">
                              <div className="w-[35%]">
                                <div className={`flex items-center justify-end`}>
                                  <div className="">{price.user_limit_start || ''}</div>
                                  <div className="">{!!price.user_limit_start && '〜'}</div>
                                  <div className="">{price.user_limit_end || ''}</div>
                                </div>
                              </div>
                              <div className="w-[35%] text-right pr-[10px]">
                                {formatMoney(price.price_monthly)}
                              </div>
                              <div className="w-[43%] text-right h-[100%]">
                                <div className="pr-[10px] pt-[7%] h-[100%]">
                                  {userQuantityAmount}人
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })()}
                  </>
                ) : (
                  <>
                    <div className="w-[43%] text-right h-[100%]">
                      <div className="pr-[10px] pt-[7%] h-[100%]">ー</div>
                    </div>

                    <div className="w-[43%] text-right h-[100%]">
                      <div className="pr-[10px] pt-[7%] h-[100%]">ー</div>
                    </div>
                  </>
                )}
              </React.Fragment>
            ))}
          </>
        ) : (
          <>
            {subscription?.user_quantity ? (
              <div className="skeleton w-full h-[100px] bg-secondary-lighter mb-[10px]"></div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <div className="text-primary text-[14px] font-[400] leading-[100%] mb-[10px]">
        契約初月利用日数
      </div>
      <div className="flex border-b border-secondary-light mb-[3.5px] h-0 min-h-[35px] items-center">
        <div className="w-[62%] text-right">契約初月（日割計算）</div>
        <div className="w-[38%] text-right pr-[10px]">{dayjs(originSubscribed?.period_start_at).daysInMonth() - usedDays}日</div>
      </div>

      <div className="flex py-[5px]">
        <div className="w-[60%] text-right">月額通常料金</div>
        <div className="w-[40%] text-right pr-[10px] text-[18px] font-[500] leading-[100%] h-[30px] smaller-scroll-bar whitespace-nowrap overflow-x-auto">
          {formatMoney(changedBilledAmount)}
        </div>
      </div>

      <div className="flex py-[5px]">
        <div className="w-[60%] text-right">初月（日割）割引額</div>
        <div className="w-[40%] text-right pr-[10px] text-[18px] font-[500] leading-[100%] h-[30px] smaller-scroll-bar whitespace-nowrap overflow-x-auto">
          -{formatMoney(
            getDiscountAmountByUsage(
              changedBilledAmount,
              String(originSubscribed?.period_start_at),
              usedDays,
            ),
          )}
        </div>
      </div>

      <div className="flex py-[5px] border-b border-secondary-light">
        <div className="w-[60%] text-right">初月（日割）支払い合計</div>
        <div className="w-[40%] text-right pr-[10px] text-[18px] font-[500] leading-[100%] h-[30px] smaller-scroll-bar whitespace-nowrap overflow-x-auto">
          {formatMoney(
            getBilledAmountByUsage(
              changedBilledAmount,
              String(originSubscribed?.period_start_at),
              usedDays,
            ),
          )}
        </div>
      </div>

      <div className="flex py-[5px]">
        <div className="w-[60%] text-right text-[10px] font-[400] leading-[100%]">
          消費税額（10％対象）
        </div>
        <div className="w-[40%] text-right pr-[10px] text-[12px] font-[500] leading-[100%] h-[30px] smaller-scroll-bar whitespace-nowrap overflow-x-auto">
          {formatMoney(
            getTaxAmount(
              getBilledAmountByUsage(
                changedBilledAmount,
                String(originSubscribed?.period_start_at),
                usedDays,
              ),
            ),
          )}
        </div>
      </div>

      <div className="flex py-[5px]">
        <div className="w-[60%] text-[20px] text-danger text-right">今回のお支払い合計</div>
        <div className="w-[40%] text-right pr-[10px] text-[18px] font-[500] leading-[100%] h-[30px] smaller-scroll-bar whitespace-nowrap overflow-x-auto">
          {formatMoney(
            getBilledAmountWithTax(
              getBilledAmountByUsage(
                changedBilledAmount,
                String(originSubscribed?.period_start_at),
                usedDays,
              ),
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceChangeCalculation;
