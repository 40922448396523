import ContractBasePage from '.';
import ContractPurchaseHistory from '../../../components/user/Contract/PurschaseHistory/ContractPurchaseHistory';

const ContractPurchaseHistoryPage = () => {
  return (
    <ContractBasePage title="サービス一覧">
      <ContractPurchaseHistory />
    </ContractBasePage>
  );
};

export default ContractPurchaseHistoryPage;
