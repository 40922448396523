import { Navigate, useNavigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext";

type RequiredAuthProps = {
  children: JSX.Element | JSX.Element[]
}

const RequiredAuth = ({ children }: RequiredAuthProps) => {
  let [tokenValid, setTokenValid] = useState(true);
  let { setUser } = useAuthContext();
  let token = localStorage.getItem('accessToken');
  // let storedUser = localStorage.getItem('user')
  // let user = storedUser ? JSON.parse(storedUser) : {};
  let navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      let result = await makeRequest({
        method: "get",
        url: API.USER.INFO
      })

      if (result.code === 401) {
        navigate(ROUTE.LOGIN);
        return;
      }
      setUser(result.data);
    }

    if (token) {
      verifyToken();
    } else {
      navigate(ROUTE.LOGIN);
    }
  }, []);


  return (
    <>
      {
        tokenValid ? children : <Navigate to={ROUTE.LOGIN} replace={true}/>
      }
    </>
  )
}

export default RequiredAuth;
