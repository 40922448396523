import React, { useState } from "react";
import Button from "../components/Login/Button";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { swalError, swalLoading, swalSuccess } from "../services/helpers/swal";
import AuthLayout from "../layouts/AuthLayout";
import { ErrorData } from "../types/globals";
import ErrorBox from "../components/commons/form/ErrorBox";

const ResendVerification = () => {
  let navigate = useNavigate();
  const [errors, setErrors] = useState<ErrorData>({});
  const [email, setEmail] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  const handleResendCode = async () => {
    // swalLoading();
    setIsSendingCode(true);
    const result = await makeRequest({
      method: "post",
      url: API.AUTH.RESEND_VERIFY_EMAIL,
      data: { email: email },
    });
    if (!result.status) {
      setIsSendingCode(false);
      setErrors(result.error as ErrorData);
      return swalError();
    }

    swalSuccess();
    setIsSendingCode(false);
    navigate(ROUTE.LOGIN);
  };

  return (
    <>
      <AuthLayout>
        <>
          <form className="mb-[45px] flex flex-col items-center">
            <div className="md:w-[500px] sm:w-[450px] w-[370px] min-h-[249px] mb-[30px] px-[25px]">
              <p className="mt-[30px] mb-[20px] text-[15px]">コード再信</p>
              <p className="text-[12px] px-[60px] leading-[22.8px] mb-[20px]">
                ご登録メールアドレスに確認コードをお届けします。<br></br>
                確認コードを受け取るメールアドレスを入力してください。
              </p>
              <div className="mb-[40px]">
                <input
                  type="text"
                  placeholder="メールアドレスを入力"
                  onChange={handleEmailChange}
                  value={email}
                  name="email"
                  className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
                  required
                />
              </div>
              <div className="mb-[15px]">
                <Button
                  isSubmitting={isSendingCode}
                  content={"コードを再信"}
                  otherstyle={"bg-primary text-white mb-[10px]"}
                  type={"button"}
                  onClick={handleResendCode}
                />
                <Button
                  disabled={isSendingCode}
                  content={"キャンセル"}
                  otherstyle={"bg-secondary-light text-white mb-[10px]"}
                  type={"button"}
                  onClick={handleGoBack}
                />
                {errors && Object.keys(errors).length > 0 && (
                  <ErrorBox errors={errors} />
                )}
              </div>
              <p className="text-[12px] align-middle font-[400] mt-[7px] py-[14px] text-secondary-dark">
                管理者が登録したメールアドレスを入力してください
              </p>
            </div>
          </form>
        </>
      </AuthLayout>
    </>
  );
};

export default ResendVerification;
