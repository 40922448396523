import { PATH_SUPER_ADMIN } from '../services/constants/route/router';
import { RouteObject } from 'react-router';
import SuperAdminLogin from '../pages/superadmin/Login';
import SuperAdminForgotPassword from '../pages/superadmin/ForgotPassword';
import SuperAdminUpdateNewPwd from '../pages/superadmin/UpdateNewPassword';
import SuperAdminFinishSubmitForgotPwd from '../pages/superadmin/FinishSubmitPassword';
import SuperAdminAccountCreatePage from '../pages/superadmin/Account/Create';
import SuperAdminVerifyEmail from '../pages/superadmin/VerifyEmail';
import SuperAdminResendVerification from '../pages/superadmin/ResendVerification';
import NotFoundPage from '../pages/404';
import SuperAdminAccountListPage from '../pages/superadmin/Account/List';
import SuperAdminLogout from '../pages/superadmin/Logout';
import SuperAdminOrganizationListPage from '../pages/superadmin/Organization/List';
import SuperAdminOrganizationCreatePage from '../pages/superadmin/Organization/Create';
import SuperAdminOrganizationDetailUser from '../pages/superadmin/Organization/Detail/DetailUser';
import SuperAdminContractSettingPage from '../pages/superadmin/contract-setting/setting';
import SuperAdminOrganizationEditPage from '../pages/superadmin/Organization/Edit';
import SuperAdminOrganizationDetailInfo from '../pages/superadmin/Organization/Detail/DetailInfo';
import SuperAdminVerifyEmailCreateOz from '../pages/superadmin/Organization/VerifyEmailCreate';
import SuperAdminResendVerifyEmailCreateOz from '../pages/superadmin/Organization/ResendVerifyEmailCreate';
import LinkAddressPage from '../pages/superadmin/LinkAddress/List';
import { PALETTE_FEATURE } from '../services/constants/globals';
import PwdResetComplete from '../pages/auth/PwdResetComplete';
import SuperAdminProfile from '../pages/superadmin/Account/SuperAdminProfile';

type CustomRouterObject = {};

const superAdminRouter: Array<RouteObject & CustomRouterObject> = [
  {
    path: PATH_SUPER_ADMIN.DEFAULT,
    children: [
      {
        path: PATH_SUPER_ADMIN.LOGIN,
        element: <SuperAdminLogin />,
      },
      {
        path: PATH_SUPER_ADMIN.PROFILE,
        element: <SuperAdminProfile />,
      },
      {
        path: PATH_SUPER_ADMIN.LOGOUT,
        element: <SuperAdminLogout />,
      },
      {
        path: PATH_SUPER_ADMIN.FORGOT_PASSWORD,
        element: <SuperAdminForgotPassword />,
      },
      {
        path: `${PATH_SUPER_ADMIN.UPDATE_NEW_PWD}/:token`,
        element: <SuperAdminUpdateNewPwd />,
      },
      {
        path: PATH_SUPER_ADMIN.SUBMIT_FORGOT_PASSWORD_DONE,
        element: <SuperAdminFinishSubmitForgotPwd />,
      },
      {
        path: PATH_SUPER_ADMIN.VERIFY_EMAIL,
        element: <SuperAdminVerifyEmail />,
      },
      {
        path: PATH_SUPER_ADMIN.RESEND_VERIFY,
        element: <SuperAdminResendVerification />,
      },
      {
        path: PATH_SUPER_ADMIN.PWD_RESET_COMPLETE,
        element: <PwdResetComplete />,
      },
      {
        path: PATH_SUPER_ADMIN.USER_ACCOUNT.DEFAULT,
        children: [
          { index: true, element: <SuperAdminAccountListPage /> },
          {
            path: PATH_SUPER_ADMIN.USER_ACCOUNT.CREATE,
            element: <SuperAdminAccountCreatePage />,
          },
          {
            path: PATH_SUPER_ADMIN.USER_ACCOUNT.DETAIL,
            element: <SuperAdminAccountCreatePage />,
          },
        ],
      },
      {
        path: PATH_SUPER_ADMIN.ORGANIZATION.DEFAULT,
        children: [
          { index: true, element: <SuperAdminOrganizationListPage /> },
          {
            path: PATH_SUPER_ADMIN.ORGANIZATION.CREATE,
            element: <SuperAdminOrganizationCreatePage />,
          },
          {
            path: PATH_SUPER_ADMIN.ORGANIZATION.DETAIL,
            element: <SuperAdminOrganizationEditPage />,
          },
          {
            path: PATH_SUPER_ADMIN.ORGANIZATION.DETAIL_INFO,
            element: <SuperAdminOrganizationDetailInfo />,
          },
          {
            path: PATH_SUPER_ADMIN.ORGANIZATION.DETAIL_CONTACT,
            element: <SuperAdminOrganizationDetailUser />,
          },
          {
            path: PATH_SUPER_ADMIN.ORGANIZATION.VERIFY_EMAIL_CREATE,
            element: <SuperAdminVerifyEmailCreateOz />,
          },
          {
            path: PATH_SUPER_ADMIN.ORGANIZATION.RESEND_VERIFY_EMAIL_CREATE,
            element: <SuperAdminResendVerifyEmailCreateOz />,
          },
        ],
      },
      {
        path: PATH_SUPER_ADMIN.CONTRACT_PRICE_SETTING.DEFAULT,
        children: [
          { index: true, element: <SuperAdminContractSettingPage /> },
          {
            path: PATH_SUPER_ADMIN.CONTRACT_PRICE_SETTING.MANABITE,
            element: <SuperAdminContractSettingPage featureId={PALETTE_FEATURE.MANABITE.ID}/>,
          },
          {
            path: PATH_SUPER_ADMIN.CONTRACT_PRICE_SETTING.TOKITE,
            element: <SuperAdminContractSettingPage featureId={PALETTE_FEATURE.TOKITE.ID}/>,
          },
        ],
      },
      {
        path: PATH_SUPER_ADMIN.LINK_ADDRESS.LIST,
        element: <LinkAddressPage />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export default superAdminRouter;
