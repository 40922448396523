import React, { FC, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { PATH_SUPER_ADMIN, ROUTE } from "../services/constants/route/router";
import Logo from "../components/Login/Logo";
import YellowBlock from "../components/Login/YellowBlock";
import BeigeBlock from "../components/Login/BeigeBlock";
import OrangeBlock from "../components/Login/OrangeBlock";
import BlueBlock from "../components/Login/BlueBlock";
import GreenBlock from "../components/Login/GreenBlock";
import PurpleBlock from "../components/Login/PurpleBlock";

interface LayoutProps {
  children?: JSX.Element;
}

const SuperAdminAuthLayout: FC<LayoutProps> = (props) => {
  let accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;
  const [token, setToken] = useState(accessToken);
  const navigate= useNavigate();

  return (
    <>
      {token ? (
        <Navigate to={PATH_SUPER_ADMIN.USER_ACCOUNT.DEFAULT} replace={true} />
      ) : (
        <div className="flex justify-center items-center bg-white w-full text-center overflow-y-auto">
          <div className="w-full centered z-[1000] flex items-center justify-center">
            <div className="w-[600px] bg-white" >
              <div onClick={()=>navigate(`${PATH_SUPER_ADMIN.LOGIN}`)} > <Logo otherStyle={"mx-auto mb-[30px] mt-[90px]"} /></div>
              {props.children}
            </div>
          </div>
          <div className="fixed top-0 left-0 z-10">
            <YellowBlock />
          </div>
          <div className="fixed top-[91px] left-[146px]">
            <BeigeBlock />
          </div>
          <div className="fixed bottom-0 left-0">
            <OrangeBlock />
          </div>
          <div className="fixed bottom-[-13px] right-[133px]">
            <BlueBlock />
          </div>
          <div className="fixed bottom-[38%] right-0">
            <GreenBlock />
          </div>
          <div className="fixed bottom-[27%] right-0">
            <PurpleBlock />
          </div>
        </div>
      )}
    </>
  );
};

export default SuperAdminAuthLayout;
