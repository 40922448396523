import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { USER_STATUS } from "../../../services/constants/user";
import { SuperAdminAccountFormData } from "../../../types/user/account";
import { ErrorData } from "../../../types/globals";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { PATH_SUPER_ADMIN } from "../../../services/constants/route/router";
import ErrorBox from "../../../components/commons/form/ErrorBox";
import FormItem from "../../../components/user/commons/FormItem";
import { BsCheck2Circle } from "react-icons/bs";
import ImageInternalButton from "../../../components/commons/buttons/ImageInternalButton";
import SemiSquareButton from "../../../components/user/commons/SemiSquareButton";
import FormButton from "../../../components/commons/form/FormButton";
import SuperAdminLayout from "../../../layouts/superadmin";

const SuperAdminAccountForm = () => {
  const navigate = useNavigate();
  const [superAdminAccountFormData, setSuperAdminAccountFormData] =
    useState<SuperAdminAccountFormData>({
      status: USER_STATUS.CONFIRMING,
    });
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [fileError, setFileError] = useState<string>("");
  const [errors, setErrors] = useState<ErrorData>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [id, setId] = useState<number | null>(null);

  useEffect(() => {
    const fetchDetail = async () => {
      await request.get(`${API.SUPER_ADMIN.AUTH.USER_INFO}`, (accountData) => {
        setSuperAdminAccountFormData(accountData || {});
        setId(accountData.id);
      });
    };

    fetchDetail();
  }, []);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const file = (e.target as HTMLInputElement).files?.[0];
    if (name === "image" && !value) return;
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Size in MB

      // Check file type
      if (!fileType.startsWith("image/")) {
        setFileError("無効なファイルタイプです。画像を選択してください。");
        return;
      }

      // Check file size
      if (fileSize > 2) {
        setFileError("ファイルサイズが2MBの最大制限を超えています。");
        return;
      }

      setFileError("");
      setSuperAdminAccountFormData({
        ...superAdminAccountFormData,
        [name]: file,
      });
      return;
    }

    setSuperAdminAccountFormData({
      ...superAdminAccountFormData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (!id) return;

    setIsSubmitting(true);
    let submitAction = request.post;
    let submitUrl = API.SUPER_ADMIN.ACCOUNT.CREATE;
    let submitData = {
      ...superAdminAccountFormData,
    };
    submitUrl = API.SUPER_ADMIN.ACCOUNT.UPDATE;
    submitData = { ...submitData, user_id: id };
    await submitAction(
      submitUrl,
      submitData,
      (user) => {
        setIsSubmitting(false);
        localStorage.setItem("user", JSON.stringify(user));
        navigate(PATH_SUPER_ADMIN.USER_ACCOUNT.DEFAULT);
      },
      (errors) => {
        setIsSubmitting(false);
        setErrors(errors);
      },
      { withSuccess: true, withLoading: true, hasFileRequest: true }
    );
  };

  const removeImage = () => {
    const newData: SuperAdminAccountFormData = {
      ...superAdminAccountFormData,
      image: undefined,
      avatar_url: undefined,
      delete_avatar: 1,
    };
    setSuperAdminAccountFormData(newData);
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  return (
    <SuperAdminLayout>
      <section className="w-full">
        <div className="bg-white rounded-[10px] w-full min-h-[100px] pb-[50px]">
          <div className="pt-[30px] px-[28px] mb-[20px]">
            <div className="border-b border-secondary-light text-secondary-dark pb-[14px] font-[500] text-[16px] leading-[100%]">
              アカウント管理 ｜アカウント情報の変更
            </div>
          </div>

          <div className="mx-[43px]">
            <div>
              {/* errors */}
              {errors && !!Object.keys(errors).length && (
                <div className="mb-[20px]">
                  <ErrorBox errors={errors} />
                </div>
              )}
              {/* Account form */}
              <FormItem label="ログインID（半角英数のみ８〜20）" withRequired>
                <input
                  className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
                  name="username"
                  value={superAdminAccountFormData.username}
                  onChange={handleChange}
                  placeholder="dummy_user_name"
                />
              </FormItem>
              <FormItem label="メールアドレス" withRequired>
                <div className="flex items-center">
                  <div className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] w-full max-w-[330px] placeholder-secondary-light">
                    {superAdminAccountFormData?.email || ""}
                  </div>
                  <div className="min-w-[90px] ml-[5px] pl-[17px] pt-[3px] pb-[5px] text-[#749F91] bg-[#D7F5F2] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                    <BsCheck2Circle
                      className="absolute top-[2px] left-[4px]"
                      size={18}
                    />
                    <p className="pl-[12px]">確認済み</p>
                  </div>
                </div>
              </FormItem>
              <FormItem label="パレット管理者氏名" withRequired>
                <div className="flex gap-[9px]  w-full">
                  <div className="text-secondary-dark w-full flex items-center">
                    <input
                      className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
                      name="last_name"
                      value={superAdminAccountFormData.last_name}
                      onChange={handleChange}
                      placeholder="鈴木"
                    />
                  </div>
                  <div className="text-secondary-dark w-full flex items-center">
                    <input
                      className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
                      name="first_name"
                      value={superAdminAccountFormData.first_name}
                      onChange={handleChange}
                      placeholder="一郎"
                    />
                  </div>
                </div>
              </FormItem>
              <FormItem label="パレット管理者氏名（カナ）">
                <div className="flex gap-[9px] w-full">
                  <div className="text-secondary-dark w-full flex items-center">
                    <input
                      className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
                      name="last_name_kana"
                      value={superAdminAccountFormData.last_name_kana}
                      onChange={handleChange}
                      placeholder="スズキ"
                    />
                  </div>
                  <div className="text-secondary-dark] w-full flex items-center">
                    <input
                      className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
                      name="first_name_kana"
                      value={superAdminAccountFormData.first_name_kana}
                      onChange={handleChange}
                      placeholder="イチロウ"
                    />
                  </div>
                </div>
              </FormItem>
              <FormItem label="プロフィール画像のアップロード">
                <div className="flex items-center">
                  {superAdminAccountFormData.image ||
                  superAdminAccountFormData.avatar_url ? (
                    <div className="relative w-[330px] object-cover mr-4 border-gray-200 my-[5px]">
                      <ImageInternalButton
                        removeImage={removeImage}
                        editImage={() => inputFileRef.current?.click()}
                      />
                      <img
                        src={
                          superAdminAccountFormData.image
                            ? URL.createObjectURL(
                                superAdminAccountFormData.image
                              )
                            : superAdminAccountFormData.avatar_url
                        }
                        alt="Profile Avatar"
                      />
                    </div>
                  ) : (
                    <div
                      className="w-full max-w-[330px] h-[30px] flex items-center justify-center mr-4  border-[#EFF1F0] border-[1px] my-[5px]"
                      onClick={() => inputFileRef.current?.click()}
                    ></div>
                  )}
                  <label
                    htmlFor="image"
                    className="flex items-center justify-center w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-secondary-light cursor-pointer"
                  >
                    ファイルを選択
                  </label>
                  <input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    className="hidden"
                    ref={inputFileRef}
                    onChange={handleChange}
                    placeholder="ファイルを選択"
                  />
                  {!superAdminAccountFormData.image && (
                    <div className="ml-[10px] text-[10px] font-[400] leading-[19px]">
                      選択されていません
                    </div>
                  )}
                </div>
                {fileError && (
                  <div className="text-red-500 text-xs">{fileError}</div>
                )}
              </FormItem>

              {/* Submit Buttons */}
              <div className="w-full flex justify-center items-center mb-[58px] mt-[66px] gap-[15px]">
                <SemiSquareButton
                  className="bg-secondary-light max-w-[212px]"
                  onClick={() =>
                    navigate(PATH_SUPER_ADMIN.USER_ACCOUNT.DEFAULT)
                  }
                >
                  一覧画面へ戻る
                </SemiSquareButton>
                <FormButton
                  className="bg-primary px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[16px] leading-[100%] max-w-[244px] h-[36px]"
                  onClick={handleSubmit}
                  isSubmitting={isSubmitting}
                  label="内容を変更する"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </SuperAdminLayout>
  );
};

export default SuperAdminAccountForm;
