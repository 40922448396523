/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  CONTRACT_SUBSCRIPTION_TYPE,
  SUBSCRIPTION_CONST,
} from '../../../../services/constants/user/pages/contract';
import StorageContractSubscription from './Storage/StorageContractSubscription';
import ProductContractSubscription from './Product/ProductContractSubscription';
import BasicContractSubscription from './Basic/BasicContractSubscription';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import { request } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import { Contract, ContractSubscription } from '../../../../types/user/contract';
import { paramizeObject, sortArrayByKey } from '../../../../services/helpers/parseData';
import SupportContractSubscription from './Support/SupportContractSubscription';
import { SetStateAction } from '../../../../types/globals';
import { getSubscriptionContractLabel } from '../../../../services/utils/contract';
import { useStorage } from '../../../../hooks/useStorage';

type SubscriptionDetail = {
  subscribed?: ContractSubscription;
  subscribedPriceList?: Contract[];
  latestPriceList?: Contract[];
};

type Props = {
  setTitle: SetStateAction<string>;
};

const ContractSubscriptionUpsert = ({ setTitle }: Props) => {
  const { getParamValue, navigate } = useObjectRoutes();
  const subscriptionType = Number(getParamValue('contract_type') || 0);
  const subscriptionId = Number(getParamValue('subscription_id') || 0);
  const featureId = Number(getParamValue('feature_id') || 0);
  const isCancelSubscription = Number(getParamValue('cancel_subscription')) || 0;
  //   const subscriptionId = basicDecrypted(String(subscriptionEncryptedId));
  const [subscriptionDetail, setSubscriptionDetail] = useState<SubscriptionDetail>({});
  const [isFetching, setIsFetching] = useState(false);
  const { setItem } = useStorage();

  useEffect(() => {
    const fetcher = async () => {
      setIsFetching(true);
      const currentLabel = getSubscriptionContractLabel(subscriptionType, !!subscriptionId);
      setItem(SUBSCRIPTION_CONST.SUBSCRIPTION_LABEL_STORAGE_KEY, currentLabel);
      setTitle(currentLabel);

      const paramObj: ContractSubscription = {
        id: subscriptionId,
        contract_type: subscriptionType,
        feature_id: featureId,
      };

      await request.get(
        `${API.CONTRACT_SUBSCRIPTION.DETAIL}?${paramizeObject(paramObj)}`,
        (res) => {
          setSubscriptionDetail({
            subscribed: res?.subscribed?.id
              ? {
                  ...res.subscribed,
                  feature_id: featureId,
                }
              : {
                  contract_type: subscriptionType,
                  feature_id: featureId,
                },
            subscribedPriceList: res?.subscribed_price_list || [],
            latestPriceList: res?.latest_price_list || [],
          });
        },
        () => {
          navigate(-1);
        },
      );

      if (subscriptionType === CONTRACT_SUBSCRIPTION_TYPE.STORAGE) {
        await request.get(
          `${API.CONTRACT_SUBSCRIPTION.FREE_SETTING_DETAIL}?feature_id=${featureId}`,
          (res) => {
            setItem(
              'free-setting-capacity',
              res?.free_setting[0]?.storage_capacity?.toString() || '""',
            );
          },
        );
      }
      setIsFetching(false);
    };

    if (!Number(subscriptionType)) {
      return;
    }
    fetcher();
  }, [subscriptionId, subscriptionType]);

  switch (subscriptionType) {
    case CONTRACT_SUBSCRIPTION_TYPE.BASIC:
      return (
        <>
          {isFetching && !!subscriptionId ? (
            <div className="flex gap-[10px] h-[450px] pt-[30px]">
              <div className="skeleton w-[450px] bg-secondary-lighter"></div>
              <div className="skeleton w-full bg-secondary-lighter"></div>
            </div>
          ) : (
            <BasicContractSubscription
              subscription={subscriptionDetail.subscribed}
              subscribedPriceList={subscriptionDetail.subscribedPriceList}
              latestPriceList={sortArrayByKey(
                subscriptionDetail.latestPriceList || [],
                'user_limit_start',
              )}
              isCancelSubscription={isCancelSubscription}
            />
          )}
        </>
      );

    case CONTRACT_SUBSCRIPTION_TYPE.SUPPORT:
      return (
        <SupportContractSubscription
          subscription={subscriptionDetail.subscribed}
          subscribedPriceList={subscriptionDetail.subscribedPriceList}
          latestPriceList={subscriptionDetail.latestPriceList || []}
          isCancelSubscription={isCancelSubscription}
        />
      );
    case CONTRACT_SUBSCRIPTION_TYPE.STORAGE:
      return (
        <StorageContractSubscription
          subscription={subscriptionDetail.subscribed}
          subscribedPriceList={subscriptionDetail.subscribedPriceList}
          latestPriceList={subscriptionDetail.latestPriceList || []}
          setTitle={setTitle}
          isCancelSubscription={isCancelSubscription}
        />
      );

    case CONTRACT_SUBSCRIPTION_TYPE.PRODUCT:
      return (
        <ProductContractSubscription 
          subscription={subscriptionDetail.subscribed}
          subscribedPriceList={subscriptionDetail.subscribedPriceList}
          latestPriceList={subscriptionDetail.latestPriceList || []}
          isCancelSubscription={isCancelSubscription}
        />
      );

    default:
      return <div className="">Coming soon...</div>;
  }
};

export default ContractSubscriptionUpsert;
