import { ChildrenWithProps } from '../../../../../types/globals';

type FormLineProps = {
  label: string;
};

const FormLine = ({ label, children }: ChildrenWithProps<FormLineProps>) => {
  return (
    <div className="flex border-b border-success-extralight text-secondary-dark text-[14px] mt-[3px]">
      <div className="w-full max-w-[350px] bg-success-lighter">
        <div className="flex items-center py-[12px] px-[14px] leading-[100%] font-bold">
          {label}
        </div>
      </div>
      <div className="w-full flex items-center ">{children}</div>
    </div>
  );
};

export default FormLine;
