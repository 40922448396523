import SuperAdminOrganizationDetailBase from ".";
import SuperAdminOrganizationDetailInfoForm from "../../../../components/SuperAdmin/Organization/Form/FormDetailInfo";

const SuperAdminOrganizationDetailInfo = () => {
    return (
        <SuperAdminOrganizationDetailBase title={"契約企業管理｜ 契約企業閲覧"}>
            <SuperAdminOrganizationDetailInfoForm/>
        </SuperAdminOrganizationDetailBase>
    );
};

export default SuperAdminOrganizationDetailInfo;
