import React, { useEffect, useState } from "react";
import { AccountFormData } from "../../../../types/user/account";
import { IoIosArrowDown } from "react-icons/io";
import { Service } from "../../../../types/user/service";

type ServicePickerProps = {
  formData: AccountFormData
  setFormData: React.Dispatch<React.SetStateAction<AccountFormData>>;
  serviceList: Service[];
};
const ServicePicker = ({ formData, setFormData, serviceList }: ServicePickerProps) => {
  const [selectedIds, setSelectedIds] = useState<(string | number)[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const toggleMenu = () => setIsOpenMenu(!isOpenMenu);

  useEffect(() => {
    setSelectedIds(formData.features || []);
    checkSelectAll(serviceList);
  }, [isOpenMenu]);

  const checkSelectAll = (serviceList: Service[]) => {
    let allServiceIds = serviceList?.map((s) => s?.id);
    let allServiceSelected = allServiceIds.every((id) =>
      selectedIds.includes(id)
    );
    if (allServiceSelected) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedIds(serviceList?.map((s) => s.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleSelectService = (id: number | string) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((sId) => sId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleAddFeatures = () => {
    setFormData((prev) => {
      return { ...prev, features: selectedIds };
    })
    toggleMenu();
  }
  const isServiceSelected = (id: number | string) => selectedIds.includes(id);
  return (
    <div className="relative mb-[10px]">
      <div
        className="relative w-full border-2 border-success-lighter max-w-[320px] h-[30px] rounded-[5px] text-[12px] font-[400]"
        onClick={toggleMenu}
      >
        <div className="absolute left-[13px] top-[5px]">選択してください</div>
        <IoIosArrowDown className="absolute top-[7px] right-[9.26px] text-secondary-light" size={15} />
      </div>
      {isOpenMenu && (
        <div className="absolute top-8 left-0 z-[1000] min-h-[182px] overflow-y-auto w-full max-w-[320px] font-[400] text-[12px] leading-[100%] bg-white shadow-2xl rounded-[5px] text-left p-[15px]">
          <div className="flex items-center gap-[4px] mb-[10px]">
            <input
              type="checkbox"
              name="select-all"
              className="rounded-[3px] border border-success-lighter"
              onChange={toggleSelectAll}
              checked={selectAll}
            />
            <label htmlFor={"select-all"}>すべて選択</label>
          </div>
          <hr className="w-full mb-[10px]"></hr>
          {serviceList?.map((service, index) => (
            <div
              className="flex items-center gap-[4px] mb-[8px]"
              key={index}
            >
              <input
                type="checkbox"
                name="features"
                className="rounded-[3px] border border-success-lighter"
                onChange={() => handleSelectService(service?.id)}
                checked={isServiceSelected(service?.id)}
              />
              <img src={service?.small_logo_url} alt={`service?-logo${service?.id}`} width={33} />
              <label htmlFor={"select-all"}>{service?.name}</label>
            </div>
          ))}
          <div className="w-full flex justify-center items-center mt-[15px] gap-[8px]">
            <button onClick={toggleMenu} className="h-[30px] w-full max-w-[95px] px-[15px] py-[8.5px] text-white font-[700] text-[13px] leading-[100%] rounded-[10px] bg-secondary-light">キャンセル</button>
            <button onClick={handleAddFeatures} className="h-[30px] w-full max-w-[95px] px-[15px] py-[8.5px] text-white font-[700] text-[13px] leading-[100%] rounded-[10px] bg-primary">保存</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServicePicker;
