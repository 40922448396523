import React, { useState } from 'react';
import { HiOutlineUserCircle } from 'react-icons/hi';
import Logo from '../../components/commons/icons/Logo';
import { AiFillCaretDown } from 'react-icons/ai';
import Menu from './Menu';
import { useAuthContext } from '../../context/AuthContext';
import DefaultAvatar from '../../../src/assests/images/default_avatar.webp'

const Header = () => {
  const user = JSON.parse(localStorage.getItem('user') || '""')
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

  return (
    <section className="flex justify-between min-h-[60px] items-center pr-[50px]">
      <div className="flex items-center justify-center w-full max-w-[250px] cursor-pointer">
        <Logo otherStyle="w-[150px] h-[32.17px]" />
      </div>
      <div className="flex items-center cursor-pointer" onClick={() => setIsOpenMenu(!isOpenMenu)}>
        <div className="mr-[11px] bg-success-lighter w-[30px] h-[30px] flex justify-center items-center">
          {user?.avatar_url ? (
            <img
              src={user.avatar_url|| DefaultAvatar}
              width={26.67}
              height={26.67}
              alt="avatar"
              className="object-cover w-[26.67px] h-[26.67px]"
            />
          ) : (
            <HiOutlineUserCircle color="#BEBEBE" size={26.67} />
          )}
        </div>
        <div className="text-secondary flex items-end">
          <div className="text-[13px] font-[500] leading-[19px] tracking-[0.02em] mr-[3px]">
            {(user?.last_name || '') + (user?.first_name || '')}
          </div>
          <div className="text-[10px] leading-[14px] mr-[10px]">さん</div>
          <AiFillCaretDown />
          <Menu
            className={`absolute top-[60px] right-[10px] ${!isOpenMenu && 'hidden'} z-[1000]`}
          />
        </div>
      </div>
      {isOpenMenu && (
        <div className="fixed top-0 left-0 w-full h-full" onClick={() => setIsOpenMenu(false)} />
      )}
    </section>
  );
};

export default Header;
