import { InvoiceSetting, PaymentInvoice, Recipient } from "../../types/user/invoice";
import dayjs from "dayjs";
import { INVOICE_FORM_DATA } from "../../services/constants/user/pages/company";
import { formatMoneyNoUnit } from "../../services/helpers/parseData";

type InvoicePreviewProps = {
    handleClose: any,
    invoiceSettingData?: InvoiceSetting,
    invoiceData?: PaymentInvoice,
    recipientData?: Recipient,
}

const InvoicePreview = (props: InvoicePreviewProps) => {
    let { invoiceData, handleClose, invoiceSettingData, recipientData } = props;

    const formatZipcode = (zipcode: string | null) => {
        if (!zipcode) return 'xxx-xxxx';
        const matches = zipcode.match(/^(\d{3})(\d{4})$/);
        if (matches) {
            return `${matches[1]}-${matches[2]}`;
        }

        return zipcode;
    }
    let zipcode = '';
    if (invoiceSettingData && invoiceSettingData.oz_postal_code) {
        zipcode = formatZipcode(invoiceSettingData.oz_postal_code)
    }

    const accountType: {
        [key: string]: any
    } = {}

    INVOICE_FORM_DATA.ACCOUNT_TYPE.map((type) => {
        accountType[type.value] = type.label;
    });

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-6 mx-auto min-w-[210mm]">
                    <div
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div
                            className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                            <h3 className="text-3xl font-semibold">
                                請求書プレビュー
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black opacity-60 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={handleClose}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div className="relative p-6 flex-auto overflow-x-hidden overflow-y-auto h-[80vh]">
                            <div className={'text-[#F2F2F2] bg-[#3863E8] text-center text-xl py-2'}>請求書</div>
                            <br/>
                            <div className={'grid grid-cols-6 grid-rows-2 place-items-center'}>
                                <div className={'col-start-5 col-end-5'}>発行日</div>
                                <div
                                    className={'col-start-6 col-end-6'}>{dayjs(invoiceData?.publish_date).format('YYYY/MM/DD') || dayjs().format('YYYY/MM/DD')}</div>
                                <div className={'col-start-5 col-end-5'}>請求番号</div>
                                <div className={'col-start-6 col-end-6'}>{invoiceData?.code || '21313212'}</div>
                            </div>
                            <div className={'grid grid-cols-2'}>
                                <div className={'customer-info'}>
                                    <div
                                        className={'text-left'}>{invoiceData?.buyer_data && invoiceData?.buyer_data.buyer_fullname ? invoiceData?.buyer_data.buyer_fullname : "○○○○"}様
                                        御中
                                    </div>
                                    <div
                                        className={'text-left'}>〒{invoiceData?.buyer_data ? formatZipcode(invoiceData.buyer_data.zipcode) : 'xxx-xxxx'}</div>
                                    <div
                                        className={'text-left'}>{invoiceData?.buyer_data && invoiceData?.buyer_data.address ? invoiceData?.buyer_data.address : '東京都千代区0-1-2 ○○ビル 3F'}</div>
                                    <br/>
                                    <div className={'text-center'}>下記の通り、ご請求申し上げます。</div>
                                    <div className={'grid grid-cols-1 grid-rows-3'}>
                                        <div
                                            className={'text-center text-[#F2F2F2] bg-[#3863E8] border py-[2px]'}>クーポン利用
                                        </div>
                                        <div
                                            className={'text-right text-xl border border-t-0 row-span-2 flex justify-end items-center pr-[3px]'}>￥{invoiceData?.amount_discount ? formatMoneyNoUnit(invoiceData?.amount_discount as string) : '0'}
                                        </div>
                                    </div>
                                    <br/>
                                    <div className={'grid grid-cols-1 grid-rows-3'}>
                                        <div
                                            className={'text-center text-[#F2F2F2] bg-[#3863E8] border py-[2px]'}>ご請求金額（税込）
                                        </div>
                                        <div
                                            className={'text-right text-xl border border-t-0 row-span-2 flex justify-end items-center pr-[3px]'}>￥{invoiceData?.total ? formatMoneyNoUnit(invoiceData.total) : formatMoneyNoUnit(100000)}
                                        </div>
                                    </div>
                                    <br/>
                                    <div className={'grid grid-cols-5 grid-rows-3'}>
                                        <div
                                            className={'text-[#F2F2F2] bg-[#3863E8] row-span-2 flex justify-center items-center'}>振込先
                                        </div>
                                        <div className={'border border-b-[0px] pl-1 col-span-4 max-w-[338px]'}>
                                            <span className={'mr-[5px]'}>{invoiceSettingData?.payee_bank_name} </span>
                                            <span
                                                className={'mr-[5px]'}>{invoiceSettingData?.payee_bank_branch_name} </span>
                                            <span
                                                className={'mr-[5px]'}>{accountType[invoiceSettingData?.payee_bank_account_type ?? 0]}</span>
                                            <span>{invoiceSettingData?.payee_bank_account_number}</span>
                                        </div>
                                        <div
                                            className={'border pl-1 col-span-4 max-w-[338px]'}>{invoiceSettingData?.payee_bank_account_name}</div>
                                    </div>
                                    <div>
                                        振込手数料は御社のご負担にお願いいたします。
                                    </div>
                                </div>
                                <div className={'company-info mt-[90px] px-[20px]'}>
                                    <table
                                        className=" border-collapse border-[2px] border-slate-300 w-full">
                                        <tbody>
                                        <tr>
                                            <td colSpan={2}
                                                className="border-[2px] border-slate-300 text-center">{invoiceSettingData?.oz_name || '組織名'}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-[2px] border-slate-300 text-center">登録番号</td>
                                            <td className="border-[2px] border-slate-300 max-w-[210px] break-all p-[3px]">{invoiceSettingData?.oz_register_number || ""}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-[2px] border-slate-300 text-center">住所</td>
                                            <td className="border-[2px] border-slate-300 text-left max-w-[210px] break-all p-[3px]">{zipcode ? `〒${zipcode}` : '〒xxx-xxx'}
                                                <br/>{`${invoiceSettingData?.oz_province || ''} ${invoiceSettingData?.oz_ward || 'XX'} ${invoiceSettingData?.oz_address || 'XX1-2-34'} ${invoiceSettingData?.oz_building || 'XX3XX'}`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="border-[2px] border-slate-300 text-center">担当者</td>
                                            <td className="border-[2px] border-slate-300 max-w-[210px] break-all p-[3px]">{invoiceSettingData?.oz_responsible_person ?? ''}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-[2px] border-slate-300 text-center">電話</td>
                                            <td className="border-[2px] border-slate-300 p-[3px]">{invoiceSettingData?.oz_tel ?? ''}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-[2px] border-slate-300 text-center">FAX</td>
                                            <td className="border-[2px] border-slate-300 max-w-[210px] break-all p-[3px]">{invoiceSettingData?.oz_fax ?? ''}</td>
                                        </tr>
                                        <tr>
                                            <td className="border-[2px] border-slate-300 text-center">メールアドレス</td>
                                            <td className="border-[2px] border-slate-300 max-w-[210px] break-all p-[3px]">{invoiceSettingData?.oz_email ?? ''}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            {/*Product table info*/}
                            <table
                                className="w-full mt-[20px] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border">
                                <thead
                                    className="text-xs text-[#F2F2F2] bg-[#3863E8] uppercase">
                                <tr>
                                    <th scope="col" className="px-6 py-3 border-2 border-l-[#3863E8] border-t-[#3863E8]">
                                        日付
                                    </th>
                                    <th scope="col" className="px-6 py-3 border-2 border-l-[#3863E8] border-t-[#3863E8]">
                                        内容
                                    </th>
                                    <th scope="col" className="px-6 py-3 border-2 border-l-[#3863E8] border-t-[#3863E8]">
                                        数量
                                    </th>
                                    <th scope="col" className="px-6 py-3 border-2 border-l-[#3863E8] border-t-[#3863E8]">
                                        単価（税込）
                                    </th>
                                    <th scope="col" className="px-6 py-3 border-2 border-r-[#3863E8] border-t-[#3863E8]">
                                        金額（税込）
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    invoiceData?.product_data ? invoiceData.product_data.map((product, index) => {
                                            return (
                                                <tr key={`productData-${index}`} className="bg-white border text-black ">
                                                    <th scope="row"
                                                        className="px-6 py-4 font-medium  whitespace-nowrap  border-2">
                                                        {dayjs(invoiceData?.publish_date).format('YYYY/MM/DD')}
                                                    </th>
                                                    <td className="px-6 py-4 border-2">
                                                        {product.product_name}
                                                    </td>
                                                    <td className="px-6 py-4 border-2">
                                                        {product.quantity}
                                                    </td>
                                                    <td className="px-6 py-4 border-2">
                                                        ￥{formatMoneyNoUnit(product.price)}
                                                    </td>
                                                    <td className="px-6 py-4 border-2">
                                                        ￥{formatMoneyNoUnit(product.price * product.quantity)}
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                        (<tr className="bg-white border dark:bg-gray-800 text-black dark:border-gray-700">
                                            <th scope="row"
                                                className="px-6 py-4 font-medium whitespace-nowrap dark:text-white border-2">
                                                12/01/2022
                                            </th>
                                            <td className="px-6 py-4 border-2">
                                                ○○○○
                                            </td>
                                            <td className="px-6 py-4 border-2">
                                                1
                                            </td>
                                            <td className="px-6 py-4 border-2">
                                                ￥5.000
                                            </td>
                                            <td className="px-6 py-4 border-2">
                                                ￥5.000
                                            </td>
                                        </tr>)
                                }

                                </tbody>
                            </table>
                        </div>
                        {/*footer*/}
                        <div
                            className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                            <button
                                className="bg-primary text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={handleClose}
                            >
                                閉じる
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default InvoicePreview