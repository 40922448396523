import SuperAdminOrganizationBase from ".";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import SuperAdminOrganizationEditForm from "../../../components/SuperAdmin/Organization/Form/FormEdit";

const SuperAdminOrganizationEditPage = () => {
    const { getParamValue } = useObjectRoutes();
    const organization_id = getParamValue("organization_id");
    return (
        <SuperAdminOrganizationBase title={organization_id ? "契約企業管理｜ 契約企業編集" : "契約企業管理｜ 契約企業新規追加"}>
            <SuperAdminOrganizationEditForm />
        </SuperAdminOrganizationBase>
    );
};

export default SuperAdminOrganizationEditPage;
