import React from "react";
import SmallButton from "../../commons/SmallButton";
import VerifiedUserIcon from "../../../commons/icons/Service/VerifiedUserIcon";
import UserIcon from "../../../commons/icons/Service/UserIcon";
import TVIcon from "../../../commons/icons/Service/TVIcon";

const SystemButtonGroup = ({
  landingPageUrl,
  domainUrl,
}: {
  landingPageUrl: string;
  domainUrl: string;
}) => {
  return (
    <div className="flex gap-[20px] w-full justify-center">
      {/* Verified User Button hidden for future phases */}
      <a href={landingPageUrl} target="_blank" className="rounded-[10px]">
        <SmallButton className="border border-primary text-primary px-[14px]">
          <TVIcon />
          <span className="ml-[2px]">サービスサイト</span>
        </SmallButton>
      </a>
      <a href={domainUrl} target="_blank" className="rounded-[10px]">
        <SmallButton className="bg-primary text-white px-[35px]">
          <UserIcon />
          <span className="ml-[8px]">ログイン</span>
        </SmallButton>
      </a>
    </div>
  );
};

export default SystemButtonGroup;
