import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import { swalError, swalSuccess } from "../services/helpers/swal";
import { ROUTE } from "../services/constants/route/router";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = async () => {
      let result = await makeRequest({
        method: "post",
        url: API.AUTH.LOGOUT,
      });
      if (!result.status) {
        navigate(ROUTE.SERVICE);
        return swalError();
      }

      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      swalSuccess();
      navigate(ROUTE.LOGIN);
    };

    handleLogout();
  }, [navigate]);

  return <div></div>;
};

export default Logout;