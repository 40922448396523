import { useState, useEffect } from 'react';
import {
  CONTRACT_SUBSCRIPTION_PERIOD,
  SUBSCRIPTION_STEP,
} from '../../../../../services/constants/user/pages/contract';
import { Contract, ContractSubscription } from '../../../../../types/user/contract';
import SupportContractSubscriptionForm from './Form';
import SupportContractSubscriptionConfirmation from './Confirmation';

type SupportSubscriptionProps = {
  subscription?: ContractSubscription;
  subscribedPriceList?: Contract[];
  latestPriceList?: Contract[];
  isCancelSubscription?: number;
};

const SupportContractSubscription = ({ ...props }: SupportSubscriptionProps) => {
  const [step, setStep] = useState(SUBSCRIPTION_STEP.FORM_FILL);
  const [formData, setFormData] = useState<ContractSubscription>(props?.subscription || {});
  const [originSubscribed, setOriginSubscribed] = useState<ContractSubscription>(
    props?.subscription || {},
  );

  useEffect(() => {
    let initialFormSupport: typeof formData = {};
    if (props?.latestPriceList?.length && !props?.subscription?.id) {
      initialFormSupport = {
        billed_amount: props?.latestPriceList[0]?.price_monthly,
        period_type: CONTRACT_SUBSCRIPTION_PERIOD.MONTHLY,
      };
    }

    setOriginSubscribed(props?.subscription || {});
    setFormData({ ...props?.subscription, ...initialFormSupport });
  }, [props?.subscription, props?.latestPriceList]);

  useEffect(() => {
    if(props?.isCancelSubscription) {
      setStep(SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE)
    }
  }, [props?.isCancelSubscription])

  switch (step) {
    case SUBSCRIPTION_STEP.FORM_FILL:
      return (
        <SupportContractSubscriptionForm
          step={step}
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
          originSubscribed={originSubscribed}
          {...props}
        />
      );
    case SUBSCRIPTION_STEP.CONFIRMATION:
    case SUBSCRIPTION_STEP.CONFIRMATION_CHANGE:
    case SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE:
      return (
        <SupportContractSubscriptionConfirmation
          step={step}
          formData={
            step === SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE ? originSubscribed : formData
          }
          originSubscribed={originSubscribed}
          setStep={setStep}
          {...props}
        />
      );
    default:
      return <></>;
  }
};

export default SupportContractSubscription;
