import { useEffect, useState } from 'react';
import FeatureContractOverviewItem from './FeatureContractOverviewItem';
import { request } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import { FeatureContract } from '../../../../types/user/contract';
import { sortArrayByKey } from '../../../../services/helpers/parseData';

const FeatureContractOverview = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [featureList, setFeatureList] = useState<FeatureContract[]>([]);

  useEffect(() => {
    const fetcher = async () => {
      setIsFetching(true);
      await request.get(API.CONTRACT_SUBSCRIPTION.FEATURE_LIST, (res) => {
        setFeatureList(res?.features || []);
        setIsFetching(false);
      });
    };

    fetcher();
  }, []);

  return (
    <div className="">
      <div className="text-secondary-dark font-[500] leading-[100%] mb-[10px]">
        ご利用プロダクト一覧
      </div>
      <FeatureContractOverviewItem header />
      {!isFetching ? (
        <>
          {sortArrayByKey(featureList, 'type')
            .reverse()
            .map((feature) => (
              <FeatureContractOverviewItem featureContract={feature} key={feature.id} />
            ))}
        </>
      ) : (
        <>
          <div className="skeleton h-[150px] mt-[5px]"></div>
          <div className="skeleton h-[150px] mt-[5px]"></div>
          <div className="skeleton h-[150px] mt-[5px]"></div>
        </>
      )}
    </div>
  );
};

export default FeatureContractOverview;
