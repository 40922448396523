/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { PATH_CONTRACT } from "../../../../services/constants/route/router";
import { CONTRACT_OVERVIEW_MODE } from "../../../../services/constants/user/pages/contract";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import {
  Contract,
  ContractSubscription,
} from "../../../../types/user/contract";
import StatusContractOverviewTables from "./StatusContractOverviewTables";
import { useStorage } from "../../../../hooks/useStorage";
import { STORAGE_KEY_PATH_HISTORY } from "../../../../services/constants/globals";
import { Service } from "../../../../types/user/service";

const StatusContractOverview = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [subscribeds, setSubscribeds] = useState<ContractSubscription[]>([]);
  const [priceListSubscriptions, setPriceListSubscriptions] = useState<
    Contract[]
  >([]);
  const { getParamValue, searchString } = useObjectRoutes();
  const { setItem } = useStorage();
  const feature_id = getParamValue("feature_id");
  const { navigate } = useObjectRoutes();
  const [service, setService] = useState<Service | null>(null);

  useEffect(() => {
    if (!feature_id) return;

    const fetcher = async () => {
      setIsFetching(true);
      // get free setting
      await request.get(
        `${API.CONTRACT_SUBSCRIPTION.FEATURE_DETAIL}?feature_id=${feature_id}`,
        (res) => {
          setSubscribeds(res?.subscribeds || []);
          setPriceListSubscriptions(res?.price_list_subscriptions || []);
          setIsFetching(false);
          setItem(STORAGE_KEY_PATH_HISTORY, String(searchString));
          setService(res?.service_infor);
        }
      );
    };

    fetcher();
  }, [feature_id]);

  return (
    <div>
      <div className="flex justify-between items-center mb-[20px] mt-[-40px]">
        <div className="max-w-[200px]">
          {service?.logo_url && <img src={service?.logo_url} />}
        </div>
        <button
          className="bg-white text-primary border border-primary p-[10px] rounded-[5px] w-fit font-[500] leading-[100%]"
          onClick={() =>
            navigate(
              `${PATH_CONTRACT.OVERVIEW}?view_mode=${CONTRACT_OVERVIEW_MODE.FEATURE_BASIC}`
            )
          }
        >
          プロダクト一覧へ戻る
        </button>
      </div>
      <div className="overflow-x-auto">
        {!isFetching ? (
          <StatusContractOverviewTables
            priceListSubscriptions={priceListSubscriptions}
            subscribeds={subscribeds}
            featureId={Number(feature_id)}
          />
        ) : (
          <div className="">
            <div className="skeleton w-full min-h-[335px] bg-secondary-lighter mb-[50px]"></div>
            <div className="skeleton w-full min-h-[150px] bg-secondary-lighter mb-[20px]"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusContractOverview;
