import AuthLayout from "../../layouts/AuthLayout";
import { useNavigate } from "react-router-dom";
import { PATH_SUPER_ADMIN, ROUTE } from "../../services/constants/route/router";
import Button from "../../components/Login/Button";

const PwdResetComplete = () => {
  let navigate = useNavigate();
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const pathArr = url.pathname.split("/");

  let route = ROUTE.LOGIN;
  if (pathArr[1] === "superadmin") {
    route = PATH_SUPER_ADMIN.LOGIN;
  }

  return (
    <AuthLayout>
      <div className="mb-[45px] mt-[200px] flex flex-col items-center">
        <div className="w-[550px] h-[252px] px-[25px]">
          <p className="mb-[30px] font-[500] text-[15px]">パスワード変更完了</p>

          <div className="text-[14px] font-[400] leading-[190%]">
            パスワードの変更が完了しました <br />
            引き続きサービスをご利用いただく場合は
            <br />
            ログイン画面へお進みください。 <br />
          </div>
        </div>
        <Button
          content={"ログイン画面へ "}
          otherstyle={"bg-primary text-white w-[430px] h-[50px]"}
          type={"button"}
          onClick={() => navigate(route)}
        />
      </div>
    </AuthLayout>
  );
};

export default PwdResetComplete;
