import React from "react";
import CompanyBase from ".";
import ReceiptForm from "../../../components/user/Company/Receipt/Form";

const ReceiptSettingPage = () => {
  return (
    <CompanyBase title="販売決済方法 ｜領収書設定">
      <ReceiptForm />
    </CompanyBase>
  );
};

export default ReceiptSettingPage;
