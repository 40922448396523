import React, { ChangeEvent, useEffect, useState } from "react";
import { makeRequest, request } from "../../../../services/axios/axios";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { API } from "../../../../services/constants/route/api";
import InvoiceTable from "./Table";
import InvoiceFilter from "./Filter";
import { swalMessage } from "../../../../services/helpers/swal";
import { CONFIRM_INVOICE_PAYMENT_SUCCESS, MESSAGE_ERROR } from "../../../../services/constants/message";
import { InvoiceSetting, PaymentInvoice } from "../../../../types/user/invoice";

type FilterType = {
    query: string;
    limit: number;
    page: number;
    sortType?: number;
    section_id?: number | string;
    payment_type?: string
};

const InvoiceList = () => {
    const initFilter = {
        limit: 10,
        query: "",
        page: 1,
        sortType: 2,
        payment_type: 'invoice'
    };
    const [filter, setFilter] = useState<FilterType>(initFilter);
    const [currentPage, setCurrentPage] = useState(1);
    const [invoices, setInvoices] = useState<PaymentInvoice[]>([]);
    const [invoiceSetting, setInvoiceSetting] = useState<InvoiceSetting>();
    const [totalPages, setTotalPages] = useState<number>(0);

    const resetTable = () => {
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber: number) => {
        // update the current page state
        setCurrentPage(pageNumber);
        changePaginate(pageNumber);
    };

    const handleUpdateInvoiceStatus = async (invoiceId: number, invoiceStatus: number) => {
        const result = await makeRequest({
            method: "post",
            url: `${API.REVENUE.CONFIRM}`,
            data: {
                transaction_id: invoiceId,
                status: invoiceStatus
            }
        })

        if (!result.status) {
            swalMessage('エラー', MESSAGE_ERROR.PAID_INVOICE_CANNOT_CHANGE_STATUS, 'error', {
                timer: 2000,
                showConfirmButton: false
            });
            return;
        }

        let _invoice = invoices.map((invoice, index) => {
            if (invoice.id == invoiceId) {
                invoice.status = invoiceStatus;
            }

            return invoice;
        })

        swalMessage('成功', CONFIRM_INVOICE_PAYMENT_SUCCESS, 'success', {
            timer: 2000,
            showConfirmButton: false
        })
        setInvoices(_invoice);
    }

    const fetchInvoice = async () => {
        await request.get(
            `${API.REVENUE.LIST}?${paramizeObject(filter)}`,
            (data) => {
                setInvoices(data.transactions);
                setTotalPages(data.last_page);
                setInvoiceSetting(data.invoice_setting)
            }
        );
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchInvoice();
        }, 500);
        return () => clearTimeout(timer);
    }, [filter]);

    const changeHandle = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        if (e.target.type === "checkbox") {
            const target = e.target as HTMLInputElement;
            if (target.checked) {
                setFilter({
                    ...filter,
                    ["page"]: 1,
                });
                resetTable();
                return;
            } else {
                setFilter({ ...filter, page: 1 });
                resetTable();
                return;
            }
        }
        setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
        resetTable();
    };

    const changePaginate = (number: number) => {
        setFilter({ ...filter, page: number });
    };

    return (
        <div>
            <InvoiceFilter changeHandle={changeHandle}/>
            <InvoiceTable
                handlePageChange={handlePageChange}
                currentPage={currentPage}
                invoices={invoices}
                totalPages={totalPages}
                handleUpdateInvoiceStatus={handleUpdateInvoiceStatus}
                invoiceSetting={invoiceSetting}
            />

        </div>
    );
};

export default InvoiceList;
