/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import ScrollToTop from '../../components/commons/etcs/ScrollToTop';
import { ChildrenWithProps } from '../../types/globals';
import Header from './Header';
import Sidebar from './Sidebar';
import RequiredAuth from '../../middleware/auth';
import { useEffect } from 'react';
import { request } from '../../services/axios/axios';
import { API } from '../../services/constants/route/api';
import { useAuthContext } from '../../context/AuthContext';
import { IoMdRefresh } from 'react-icons/io';
import Spinner from '../../components/commons/icons/Animations/Spinner';
import { useStorage } from '../../hooks/useStorage';
import { CONTRACT_STORAGE_KEY } from '../../services/constants/user/pages/contract';
import { useObjectRoutes } from '../../hooks/useObjectRoutes';
import { ContractLimitStatus, StripePastDueInvoice } from '../../types/user/contract';
import { PALETTE_FEATURE } from '../../services/constants/globals';
import { swalError } from '../../services/helpers/swal';
import { Link } from 'react-router-dom';
import dayjs from '../../services/dayjs';
import { formatDateTime } from '../../services/helpers/formatTime';

const UserLayout = ({ children, gap = false, ...props }: { gap?: boolean } & ChildrenWithProps) => {
  const { getItem, setItem } = useStorage();
  const contractLimitExceedMsg =
    'ストレージの容量もしくはアカウント数の上限に達しています。追加契約してください。';
  const { user } = useAuthContext();
  const [contractLimitMsg, setContractLimitMsg] = useState(
    !!getItem(CONTRACT_STORAGE_KEY.LIMIT_EXCEED_KEY) ? contractLimitExceedMsg : '',
  );

  const [pastDueInvoice, setPastDueInvoice] = useState<StripePastDueInvoice>(
    !!getItem(CONTRACT_STORAGE_KEY.PAST_DUE_PAYMENT)
      ? JSON.parse(getItem(CONTRACT_STORAGE_KEY.PAST_DUE_PAYMENT) || JSON.stringify(''))
      : {},
  );

  const delayAllowed = 7;
  const pastDueInvoiceMsg = `決済方法はエラーが発生しました。${formatDateTime(
    dayjs(pastDueInvoice?.period_end_at).add(delayAllowed, 'd').format(),
  )}まで利用できます。`;

  const [isVerifying, setIsVerifying] = useState(true);
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const { getParamValue } = useObjectRoutes();
  const featureId = getParamValue('feature_id');

  useEffect(() => {
    if (!refetchTrigger && !user?.id) return;
    setIsVerifying(true);
    const verifyRequest = {
      username: user.username,
    };

    request.post(
      `${API.SERVICE.VERIFY_CONTRACT_USED_AMOUNT}`,
      verifyRequest,
      (res) => {
        setIsVerifying(false);
        let limitMessage = contractLimitExceedMsg;
        let featureErrors = [];
        const contractLimitStatus = res.contract_limit_status as ContractLimitStatus;
        if (!contractLimitStatus) {
          swalError();
          return;
        }

        if (
          !contractLimitStatus[`${PALETTE_FEATURE.MANABITE.ID}`]?.is_satisfied ||
          !contractLimitStatus[`${PALETTE_FEATURE.TOKITE.ID}`]?.is_satisfied
        ) {
          setItem(CONTRACT_STORAGE_KEY.LIMIT_EXCEED_KEY, CONTRACT_STORAGE_KEY.LIMIT_EXCEED_KEY);

          if (!contractLimitStatus[`${PALETTE_FEATURE.MANABITE.ID}`]?.is_satisfied) {
            featureErrors.push(PALETTE_FEATURE.MANABITE.LABEL);
          }

          if (!contractLimitStatus[`${PALETTE_FEATURE.TOKITE.ID}`]?.is_satisfied) {
            featureErrors.push(PALETTE_FEATURE.TOKITE.LABEL);
          }

          limitMessage = `【${featureErrors.join('、')}】${contractLimitExceedMsg}`;
          setContractLimitMsg(limitMessage);
          return;
        }

        setItem(CONTRACT_STORAGE_KEY.LIMIT_EXCEED_KEY, '');
        setContractLimitMsg('');
      },
      () => {
        setIsVerifying(false);
      },
    );

    request.get(
      `${API.CONTRACT_SUBSCRIPTION.GET_PAST_DUE_PAYMENT}?organization_id=${user?.organization_id}`,
      (res) => {
        if (res) {
          setPastDueInvoice(res);
          setItem(CONTRACT_STORAGE_KEY.PAST_DUE_PAYMENT, JSON.stringify(res));
          return;
        }

        setItem(CONTRACT_STORAGE_KEY.PAST_DUE_PAYMENT, JSON.stringify(''));
      },
    );
  }, [user?.id, refetchTrigger, featureId]);

  return (
    <>
      <ScrollToTop />
      <RequiredAuth>
        <section className="min-h-screen" {...props}>
          <Header />
          <div className="w-full min-h-screen flex bg-success-lighter">
            <div className="w-full flex justify-between">
              <div className="flex w-full items-start">
                <Sidebar />
                <div className="w-full px-[32px] pt-[30px] overflow-x-hidden">
                  {!!contractLimitMsg ? (
                    <div className="break-keep text-[14px] text-danger border border-danger rounded-[10px] px-[15px] py-[7px] mb-[20px] bg-danger-lighter flex item-center justify-between">
                      <div className="flex items-center gap-[10px] mt-[1px]">
                        {!!isVerifying ? (
                          <Spinner className="pt-[3px] mr-[7px]" color="#FA646A" />
                        ) : (
                          <button
                            className="rounded-[6px] border border-primary w-[27px] h-[27px] flex items-center justify-center bg-white text-primary"
                            onClick={() => setRefetchTrigger(new Date().getTime())}
                          >
                            <IoMdRefresh size={20} />
                          </button>
                        )}
                        {contractLimitMsg}
                      </div>
                    </div>
                  ) : (
                    <div className=""></div>
                  )}

                  {pastDueInvoice && pastDueInvoice.hosted_invoice_url ? (
                    <div className="break-keep text-[14px] text-danger border border-danger rounded-[10px] px-[15px] py-[7px] mb-[20px] bg-danger-lighter flex item-center justify-start">
                      {pastDueInvoiceMsg}
                      <Link
                        to={pastDueInvoice.hosted_invoice_url || ''}
                        className="ml-[5px] underline underline-offset-[3px] hover:bg-none"
                      >
                        ここで決済してください。
                      </Link>
                    </div>
                  ) : (
                    <div className=""></div>
                  )}
                  {children}
                </div>
              </div>
            </div>
          </div>
        </section>
      </RequiredAuth>
    </>
  );
};

export default UserLayout;
