import { BiSearchAlt2 } from "react-icons/bi";
import { FILTER } from "../../../../services/constants/user/main";
import { ChangeEvent, useRef } from "react";
import SemiSquareButton from "../../../user/commons/SemiSquareButton";

const OrganizationFilter = ({
                                changeHandle,
                                handleDeleteMany
                            }: {
    changeHandle: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    handleDeleteMany: () => void;
}) => {
    const selectRef = useRef<HTMLSelectElement | null>(null);

    return (
        <section className="mt-[40px] pb-[40px] border-secondary-light">
            <div className="flex h-[30px] gap-[8px] relative">
                <div className="relative">
                    <input
                        type="text"
                        name="query"
                        id=""
                        className="h-full max-w-[190px] pl-[12px] pr-[35px] placeholder-[#BEBEBE] text-[11px] leading-[100%] border-[#E1E3E2] rounded-[5px]"
                        placeholder="契約企業の検索"
                        onChange={changeHandle}
                    />
                    <BiSearchAlt2
                        className="absolute top-[5px] right-[9.26px] text-secondary-light"
                        size={22}
                    />
                </div>
                <div className="relative">
                    <select
                        name="limit"
                        id=""
                        className="h-full pr-[33px] pl-[7px] appearance-none text-secondary-dark text-[11px] leading-[100%] w-[90px]"
                        onChange={changeHandle}
                        ref={selectRef}
                    >
                        {FILTER.OPTION.RECORD_LIMIT.map((item, index) => (
                            <option value={item.value} className="" key={index}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="relative flex items-center">
                    <label className="ml-[3px] inline-flex items-center ">
                        <input
                            type="checkbox"
                            onChange={changeHandle}
                            className="form-checkbox text-primary border-[1px] border-[#EBE4D8] rounded-[3px]"
                        />
                        <span className="ml-2 mr-[5px] font-400 text-[12px] whitespace-nowrap">
                            停止中アカウントを表示
                        </span>
                    </label>
                </div>
                <div className="flex items-center absolute top-[-3px] right-0">
          <span className="ml-2 mr-[5px] font-400 text-[13px] whitespace-nowrap">
            チェックした企業を
          </span>
                    <SemiSquareButton className="!bg-danger !px-[15px] !rounded-[5px]" onClick={handleDeleteMany}>
                        <div className="font-500 font-normal text-[14px]">削除</div>
                    </SemiSquareButton>
                </div>
            </div>
        </section>
    );
};

export default OrganizationFilter;
