import React from 'react';

type ChangeSubscriptionPolygonProps = {} & React.SVGProps<SVGSVGElement>;

const ChangeSubscriptionPolygon = ({ ...props }: ChangeSubscriptionPolygonProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="52"
      viewBox="0 0 20 52"
      fill="none"
      {...props}
    >
      <path d="M19.5 26L0.749998 51.9808L0.75 0.0192367L19.5 26Z" fill="#BEBEBE" />
    </svg>
  );
};

export default ChangeSubscriptionPolygon;
