import { getTabObj } from "../../../helpers/parseData";
import { PATH_SUPER_ADMIN } from "../../route/router";

const LINK_ADDRESS_ITEM = {
    LIST: "URL一覧",
};

const LINK_ADDRESS_TAB_DATA = [
    getTabObj(LINK_ADDRESS_ITEM.LIST, PATH_SUPER_ADMIN.LINK_ADDRESS.LIST),
];

const LINK_ADDRESSES = {
  URL_LIST: {
    TERM_OF_USE: {
      TITLE: "利用規約　URL",
      NAME: "term_of_use_url",
    },
    PRIVACY_POLICY: {
      TITLE: "プライバシーポリシー　URL",
      NAME: "privacy_policy_url",
    },
    COMPANY_INFO_URL: {
      TITLE: "会社概要　URL",
      NAME: "company_info_url",
    },
    DESC_COMMERCAIL_LAW: {
      TITLE: "特定商取引法に基づく表記　URL",
      NAME: "desc_commercial_law_url",
    },
  },
  HELP_URL_LIST: {
    FREQUENTLY_QUESTION: {
      TITLE: "よくある質問　URL",
      NAME: "frequently_asked_questions_url",
    },
    MANUAL_TEMPLATE: {
      TITLE: "各種マニュアル・テンプレート　URL",
      NAME: "manual_template_url",
    },
    CONTACT: {
      TITLE: "お問い合わせ　URL",
      NAME: "contact_url",
    },
  },
};

const LINK_ADDRESS_TITLE = {
  URL_LIST: "URL一覧",
  HELP_URL_LIST: "ヘルプURL一覧",
};

export { LINK_ADDRESS_ITEM, LINK_ADDRESS_TAB_DATA, LINK_ADDRESSES, LINK_ADDRESS_TITLE };