import TabCard from "../../../components/user/commons/TabCard";
import UserLayout from "../../../layouts/user";
import { PATH_REVENUE, ROUTE } from "../../../services/constants/route/router";
import { useTabFinder } from "../../../hooks/useTabFinder";
import { WithChildren } from "../../../types/globals";
import { REVENUE_TAB_DATA } from "../../../services/constants/user/pages/revenue";

type InvoiceProps = {
  title?: string;
};

const InvoiceBase = ({ title, children }: WithChildren<InvoiceProps>) => {
  const { tabIndex } = useTabFinder(
    Object.values(PATH_REVENUE)
  );

  return (
    <UserLayout>
      <TabCard tabData={REVENUE_TAB_DATA} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </UserLayout>
  );
};

export default InvoiceBase;
