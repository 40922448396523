import ContractSettingForm from './Form/ContractSettingForm';
import { Contract } from '../../../types/user/contract';

type ContractSettingProps = {
  settingData: Contract[];
  featureId: number;
};

const ContractSetting = ({ settingData, featureId }: ContractSettingProps) => {
  return <ContractSettingForm settingData={settingData} featureId={featureId} />;
};

export default ContractSetting;
