import { CiWarning } from "react-icons/ci";

const DeleteOzWarningBox = () => {
    return (
        <div className="2xl:w-[60%] xl:w-[70%] lg:w-[70%] w-[70%] bg-[#FFEBEB] border border-red-500 rounded-[10px] min-h-[100px]">
            <div className="mx-[25px] my-[30px]">
                <div className="flex justify-center items-center">
                    <div className="w-full gap-x-[10px] gap-y-[20px] flex justify-start">
                        <CiWarning 
                            className="text-red-500"
                            size={"30px"}
                        />
                        <div className="text-[14px] w-full">
                            <div className="w-full">削除されるデータには、電子帳簿保存法などの法令により一定期間の保存義務が定められているものが含まれている場合があります。</div>
                            <div className="text-red-500 pb-[20px]">退会手続きを行わず、【無料プラン】で引き続き継続されることを推奨いたします。</div>
                            <div className="">無料プランへの変更手続きは</div>
                            <div>「契約管理」メニューから「自動更新を解除する」ボタンを押して手続きいただけます。</div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    )
}

export default DeleteOzWarningBox