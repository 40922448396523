import { WithChildren } from "../../../../types/globals";
import RequiredBox from "../../commons/RequiredBox";

type FormBlockProps = {
  title: string;
  className?: string;
};

const FormBlock = ({
  title,
  children,
}: WithChildren<FormBlockProps>) => {
  return (
    <div className="text-[14px] font-[500] pb-[20px]">
      <p>{title}</p>
      {children}
    </div>
  );
};

export default FormBlock;
