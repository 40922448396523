import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ErrorData } from "../../types/globals";
import { useObjectRoutes } from "../../hooks/useObjectRoutes";
import { makeRequest } from "../../services/axios/axios";
import { swalMessage, swalSuccess } from "../../services/helpers/swal";
import { PATH_REGISTER_ADMIN } from "../../services/constants/route/router";
import Button from "../../components/Login/Button";
import ErrorBox from "../../components/commons/form/ErrorBox";
import SuperAdminAuthLayout from "../../layouts/SuperAdminAuthLayout";
import { API } from "../../services/constants/route/api";

const VerifyRegisterEmail = () => {
  let navigate = useNavigate();
  const [errors, setErrors] = useState<ErrorData>({});
  // check token for remember or has been login before (session)
  const { getParamValue } = useObjectRoutes();
  let accessToken = getParamValue("token");
  let email = getParamValue("email");
  const token = accessToken;
  const [verificationCode, setVerificationCode] = useState("");
  const [isSubmittingCode, setIsSubmittingCode] = useState<boolean>(false);

  if (!token) {
    return <Navigate to={PATH_REGISTER_ADMIN.SEND_REGISTER_EMAIL} replace={true} />;
  }

  const handleVerificationCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const code = event.target.value;
    setVerificationCode(code);
  };

  const handleSubmitCodeClick = async () => {
    if(!verificationCode) return;

    setIsSubmittingCode(true);
    const result = await makeRequest({
      method: "post",
      url: API.ADMIN_REGISTER.VERIFY_REGISTER_EMAIL,
      data: { code: verificationCode, token: token },
    });
    if (!result.status) {
      setIsSubmittingCode(false);
      if (result.error) {
        setErrors(result.error as ErrorData);
      } else {
        swalMessage('エラー', result.message, 'error')
      }
      return;
    }
    if (result.data && result.data.ozId) {
      localStorage.setItem('ozId', result.data.ozId)
    }
    swalSuccess();
    setIsSubmittingCode(false);
    navigate(`${PATH_REGISTER_ADMIN.REGISTER_ADMIN_ORGANIZATION}?token=${token}&email=${email}`);
  };

  return (
    <SuperAdminAuthLayout>
      <div className="mb-[45px] mt-[30px] flex flex-col items-center">
        <div className="md:w-[500px] sm:w-[450px] w-[370px] mb-[25px] px-[25px] py-[30px] bg-secondary-morelighter">
          <p className="mb-[20px] text-[20px] font-normal">確認コードを入力してください</p>
          {errors && Object.keys(errors).length > 0 && (
              <ErrorBox errors={errors} />
          )}
          <p className="mt-[20px] mb-[20px] text-[14px] font-normal">
              {(email as string).replace(' ', '+')}に確認コードを記載したメールを送信しました。記載されている確認コードを入力してください。
          </p>
          <div className="mb-[20px] mt-[30px]">
            <p className="text-[14px] font-normal text-left">
              確認コード
            </p>
            <input
              type="text"
              placeholder="000000"
              onChange={handleVerificationCodeChange}
              value={verificationCode}
              name="code"
              className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
              required
            />
          </div>
          <div className="mb-[15px] w-full">
            <Button
              content={"次へ"}
              otherstyle={`${!verificationCode ? 'bg-secondary-light pointer-events-none' : 'bg-primary'} text-white mb-[10px]`}
              type={"button"}
              disabled={!verificationCode}
              isSubmitting={isSubmittingCode}
              onClick={handleSubmitCodeClick}
            />
            <p className="flex justify-center items-center text-[14px] font-normal mt-[10px]">
              確認コードが届かない場合や誤って削除した場合は、
            </p>
            <p className="flex justify-center items-center text-[14px] font-normal mt-[10px] mb-[10px]">
              以下より再度送信してください。
            </p>
            <Link to={PATH_REGISTER_ADMIN.RESEND_REGISTER_EMAIL}>
              <p className="text-primary text-[15px] font-normal">再送信する</p>
            </Link>
          </div>
        </div>
      </div>
    </SuperAdminAuthLayout>
  );
};

export default VerifyRegisterEmail;
