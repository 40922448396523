import TabCard from "../../../components/user/commons/TabCard";
import { PATH_SUPER_ADMIN } from "../../../services/constants/route/router";
import { useTabFinder } from "../../../hooks/useTabFinder";
import { WithChildren } from "../../../types/globals";
import SuperAdminLayout from "../../../layouts/superadmin";
import { LINK_ADDRESS_TAB_DATA } from "../../../services/constants/superadmin/pages/linkAddress";

type LinkAddressProps = {
  title?: string;
} & WithChildren;

const SuperAdminLinkAddressBase = ({ title, children }: LinkAddressProps) => {
  const { tabIndex } = useTabFinder(Object.values(PATH_SUPER_ADMIN.LINK_ADDRESS));

  return (
    <SuperAdminLayout>
      <TabCard tabData={LINK_ADDRESS_TAB_DATA} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </SuperAdminLayout>
  );
};

export default SuperAdminLinkAddressBase;
