import React, { ChangeEvent, useEffect } from "react";
import { useState } from "react";
import { request } from "../../../../services/axios/axios";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { API } from "../../../../services/constants/route/api";
import ReceiptHistoryFilter from "./Filter";
import ReceiptHistoryTable from "./Table";
import { PaymentReceipt, ReceiptSetting } from "../../../../types/user/receipt";
import { swalClose, swalLoading } from "../../../../services/helpers/swal";

type FilterType = {
  query: string;
  limit: number;
  page: number;
  isAll?: number;
  sortType?: number;
  section_id?: number | string;
};

const SORT_TYPE = {
  DATE_ASC: 1,
  DATE_DESC: 2,
};

const ReceiptHistoryList = () => {
  const [filter, setFilter] = useState<FilterType>({
    limit: 10,
    query: "",
    page: 1,
    sortType: SORT_TYPE.DATE_DESC,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [receipts, setReceipts] = useState<PaymentReceipt[]>([]);
  const [receiptSetting, setReceiptSetting] = useState<ReceiptSetting>({});
  const [totalPages, setTotalPages] = useState<number>(0);

  const resetTable = () => {
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    // update the current page state
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };

  useEffect(() => {
    const fetch = async () => {
      swalLoading();
      await request.get(
        `${API.RECEIPT_HISTORY.LIST}?${paramizeObject(filter)}`,
        (receiptData) => {
          setReceipts(receiptData.transactions);
          setTotalPages(receiptData.last_page);
          setReceiptSetting(receiptData.receipt_setting);
          swalClose();
        }
      );
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const changeHandle = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
    resetTable();
  };
  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };
  return (
    <div>
      <ReceiptHistoryFilter changeHandle={changeHandle} />
      <ReceiptHistoryTable
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        receipts={receipts}
        totalPages={totalPages}
        receiptSetting={receiptSetting}
      />
    </div>
  );
};

export default ReceiptHistoryList;
