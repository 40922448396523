import TabCard from '../../../components/user/commons/TabCard';
import UserLayout from '../../../layouts/user';
import { useTabFinder } from '../../../hooks/useTabFinder';
import { WithChildren } from '../../../types/globals';
import { PATH_BASIC_INFO } from '../../../services/constants/route/router';
import { BASIC_INFO_TAB_DATA } from "../../../services/constants/user/pages/basicInfo";

type ServiceType = {
  title?: string;
  isIncludeTab?: boolean;
};

const BasicInfoBasePage = ({ title, children, isIncludeTab = true }: WithChildren<ServiceType>) => {
  const { tabIndex } = useTabFinder(Object.values(PATH_BASIC_INFO));

  return (
    <UserLayout>
      <TabCard tabData={isIncludeTab ? BASIC_INFO_TAB_DATA : []} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </UserLayout>
  );
};

export default BasicInfoBasePage;
