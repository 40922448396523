import React, { useEffect, useRef, useState } from "react";
import FormBlock from "../../commons/FormBlock";
import FormBlockItem from "../../commons/FormBlockItem";
import { RECEIPT_FORM_DATA } from "../../../../services/constants/user/pages/company";
import { makeRequest } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { swalError, swallConfirm, swalMessage, swalSuccess, } from "../../../../services/helpers/swal";
import ErrorBox from "../../../commons/form/ErrorBox";
import { ErrorData } from "../../../../types/globals";
import { Organization } from "../../../../types/user/organization";
import postal_code from "japan-postal-code";
import { FaRegClock } from "react-icons/fa";
import { ReceiptSetting } from "../../../../types/user/receipt";
import ReceiptPreview from "../../../Export/ReceiptPreview";
import BaseModal from "../../../commons/modal/BaseModal";
import { Link } from "react-router-dom";
import { PATH_COMPANY_INFO } from "../../../../services/constants/route/router";
import FormButton from "../../../commons/form/FormButton";
import { useStorage } from "../../../../hooks/useStorage";
import { User } from "../../../../types/user";
import "../../../../assests/styles/receiptPrint.css"

const ReceiptForm = () => {
    const [ozData, setOzData] = useState<Organization>();
    const [receiptData, setReceiptData] = useState<ReceiptSetting>({
        oz_name: ozData?.name || ''
    })
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorData>({});
    const [zipCode, setZipCode] = useState<string>("");
    const [zipCodePrefix, setZipCodePrefix] = useState<string>("");
    const inputPrefixRef = useRef<HTMLInputElement>(null);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [fileError, setFileError] = useState<string>("");
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const { getItem } = useStorage();
    const user: User = JSON.parse(getItem("user") || "");

    const fetchReceiptData = async () => {
        setIsLoading(true)
        const result = await makeRequest({
            method: "get",
            url: API.RECEIPT_SETTING.DETAIL + '?organization_id=' + user.organization_id
        });

        if (!result.status) {
            swalError();
            return;
        }
        if (result.data.receipt_setting_data.oz_name) {
            setIsEdit(true);
        }
        setIsLoading(false)
        setReceiptData({ ...receiptData, ...result.data.receipt_setting_data })
        let postalCode = result.data.receipt_setting_data?.oz_postal_code
        if (postalCode && postalCode.length === 7) {
            setZipCode(postalCode.substring(0, 3));
            setZipCodePrefix(postalCode.substring(3));
        }
        setOzData(result.data.oz_data)
        if (!receiptData.oz_name && result.data.oz_data.name) {
            setReceiptData({ ...receiptData, oz_name: result.data.oz_data.name })
        }
    }

    useEffect(() => {
        fetchReceiptData();
    }, []);

    const handleChangeForm = (
        e: 
            React.ChangeEvent<HTMLInputElement> | 
            React.ChangeEvent<HTMLSelectElement> |
            React.FormEvent<HTMLInputElement>
    ) => {
        const elementCurrent = e.currentTarget;
        let receiptFormData;
        // handle file upload
        const file = (e.target as HTMLInputElement).files?.[0];
        if (file) {
            const fileType = file.type;
            const fileSize = file.size / 1024 / 1024; // Size in MB

            // Check file type
            if (!fileType.startsWith("image/")) {
                setFileError("無効なファイルタイプです。画像を選択してください。");
                return;
            }

            // Check file size
            if (fileSize > 2) {
                setFileError("ファイルサイズが2MBの最大制限を超えています。");
                return;
            }

            setFileError("");
            const fileData: ReceiptSetting = {
                image: file
            }
            setReceiptData({ ...receiptData, ...fileData });
            return;
        }

        switch (elementCurrent.type) {
            case "radio":
                receiptFormData = {
                    ...receiptData,
                    [elementCurrent.name]: Number(elementCurrent.value),
                };
                break;
            default:
                receiptFormData = {
                    ...receiptData,
                    [elementCurrent.name]: elementCurrent.value,
                };
                break;
        }
        setReceiptData(receiptFormData);
    };


    const handleSubmit = async () => {
        let zipcode = `${zipCode}${zipCodePrefix}`;
        swallConfirm(async () => {
            setIsSubmitting(true);
            let url = API.RECEIPT_SETTING.CREATE;

            const result = await makeRequest({
                method: "post",
                url: url,
                data: {
                    ...receiptData,
                    oz_postal_code: zipcode
                },
                hasFileRequest: true
            });

            if (!result.status) {
                setErrors(result.error as ErrorData);
                setIsSubmitting(false);
                return swalError();
            }

            swalSuccess();
            setErrors({});
            setIsEdit(true);
            setIsSubmitting(false);

        }, "請求書テンプレートは変更されます。よろしいでしょうか？", '', {
            confirmButtonText: '変更する',
        });
    };

    const handleUpdate = async () => {
        let zipcode = `${zipCode}${zipCodePrefix}`;
        swallConfirm(async () => {
            setIsSubmitting(true);
            let url = API.RECEIPT_SETTING.UPDATE;

            const result = await makeRequest({
                method: "post",
                url: url,
                data: {
                    ...receiptData,
                    oz_postal_code: zipcode
                },
                hasFileRequest: true
            });

            if (!result.status) {
                setErrors(result.error as ErrorData);
                setIsSubmitting(false);
                return swalError();
            }

            swalSuccess();
            setErrors({});
            setIsSubmitting(false);

        }, "請求書テンプレートは変更されます。よろしいでしょうか？", '', {
            confirmButtonText: '変更する',
        });
    };

    const syncingZipCode = () => {
        let fullZipCode = `${zipCode}${zipCodePrefix}`;
        if (!fullZipCode) {
            swalMessage('エラー', '郵便番号を入力してください。', 'error')
        }

        postal_code.get(fullZipCode, (object) => {
            setReceiptData({
                ...receiptData,
                oz_province: object.prefecture,
                oz_ward: object.city,
                oz_address: object.area,
                oz_building: object.street,
            })
            }
        );
    };

    const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        if (/^\d{0,3}$/.test(input)) {
            setZipCode(input);
            setReceiptData({
                ...receiptData,
                oz_postal_code: `${input}${zipCodePrefix}`,
            })
            if (input.length === 3 && inputPrefixRef.current) {
                inputPrefixRef.current.focus();
            }
        }
    };
    const handleZipCodePrefixChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const input = e.target.value;
        if (/^\d{0,4}$/.test(input)) {
            setZipCodePrefix(input);
            setReceiptData({
                ...receiptData,
                oz_postal_code: `${zipCode}${input}`,
            })
        }
    };
    const handleZipCodePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedData = e.clipboardData.getData("text/plain");
        const matches = pastedData.match(/^(\d{3})-(\d{4})$/);
        if (matches) {
            setZipCode(matches[1]);
            setZipCodePrefix(matches[2]);
            e.preventDefault();
        }
    };

    const showPreviewReceiptModal = () => {
        setIsShowPreview(true)
    }

    const handlePDFDownload = async () => {
        window.print()
    };

    return (
        <>
            <Link to={PATH_COMPANY_INFO.RECEIPT_HISTORY}>
                <button
                    className="mx-[-15px] mb-[20px] px-[20px] py-[10px] bg-primary-light rounded-[10px] text-white flex justify-center"
                >
                    <FaRegClock className="mr-[5px] mt-[4px]"/>
                    {RECEIPT_FORM_DATA.BUTTON.HISTORY}
                </button>
            </Link>
            {
                isLoading ? <></> :
                    <>
                        <div className="text-secondary-dark mx-[-15px]">
                            {errors && !!Object.keys(errors).length && (
                                <div className="mb-[20px]">
                                    <ErrorBox errors={errors}/>
                                </div>
                            )}
                            {
                                isShowPreview ? 
                                    <BaseModal 
                                        setIsOpen={setIsShowPreview}
                                        justifyDirection="end"
                                        submitLabel="PDFダウンロード"
                                        onClick={handlePDFDownload}
                                    >
                                        <div className="mb-[0px] mt-[20px] flex justify-start w-[80%] m-auto">
                                            <div className="w-fit pr-[20px] text-lg text-primary self-center">領収書の確認</div>
                                            <div className="flex-1 bg-danger-lighter rounded-[2px] py-[10px] px-[15px] my-[7px] text-xs">
                                                <p>実際のPDFとは文字のサイズなどが異なる場合がございます。</p>
                                                <p>下の「PDFダウンロード」ボタンからダウンロードしてご確認ください</p>
                                            </div>
                                        </div>
                                        <ReceiptPreview 
                                            receiptSettingData={receiptData}
                                            otherStyle="w-[70%] mx-auto max-h-[80%] overflow-y-auto"    
                                        />
                                    </BaseModal>
                                : 
                                    <></>
                            }
                            <div>
                                <form>
                                    <FormBlock title="領収書設定">
                                        <FormBlockItem
                                            label="但し書き"
                                            withInput={true}
                                            value={receiptData?.oz_proviso || ""}
                                            inputName="oz_proviso"
                                            handleChange={handleChangeForm}
                                            isRequired={true}
                                            placeholder={''}
                                        />
                                        <FormBlockItem
                                            label="会社名"
                                            withInput={true}
                                            value={receiptData?.oz_name || ""}
                                            inputName="oz_name"
                                            handleChange={handleChangeForm}
                                            isRequired={true}
                                        />
                                        {/*Postal code*/}
                                        <FormBlockItem
                                            label={'郵便番号'}
                                            isRequired={true}
                                            withInput={false}>
                                            <div
                                                className="text-secondary-dark ml-[13px] w-[40%] flex gap-[7px] items-center">
                                                <input
                                                    className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0] max-w-[80px]"
                                                    name="zip_code"
                                                    onChange={handleZipCodeChange}
                                                    onPaste={handleZipCodePaste}
                                                    value={zipCode}
                                                />
                                                <div className="w-[12px] bg-secondary-light h-[1px]"></div>
                                                <input
                                                    className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0] max-w-[80px]"
                                                    name="zip_code_prefix"
                                                    ref={inputPrefixRef}
                                                    onChange={handleZipCodePrefixChange}
                                                    onPaste={handleZipCodePaste}
                                                    value={zipCodePrefix}
                                                />
                                                <div
                                                    onClick={syncingZipCode}
                                                    className={`px-[3.5px] ${
                                                        zipCode || zipCodePrefix ? "bg-primary" : "bg-[#BEBEBE]"
                                                    } text-white text-[9px] font-[500] leading-[100%] w-[52px] h-[22px] flex items-center justify-center rounded-[5px] cursor-pointer ml-[5px]`}
                                                >
                                                    自動入力
                                                </div>
                                            </div>
                                        </FormBlockItem>
                                        {/*Address data*/}
                                        <FormBlockItem
                                            label="住所"

                                            isRequired={true}
                                            titleTop={true}
                                        >
                                            <div
                                                className="text-secondary-dark ml-[13px] items-center text-[13px] leading-[100%] py-[5px] w-[85%] mr-[50px]">
                                                <div className="relative w-[300px]">
                                                    <input
                                                        name="oz_province"
                                                        className="text-[13px] h-[35px] pr-[33px] pl-[15.5px] mb-[6px] appearance-none text-secondary-dark leading-[100%] min-w-[300px] placeholder-[#BEBEBE]"
                                                        onChange={handleChangeForm}
                                                        value={receiptData?.oz_province || ''}
                                                        placeholder="都道府県を入力"
                                                    />
                                                </div>
                                                <input
                                                    type="text"
                                                    name="oz_ward"
                                                    onChange={handleChangeForm}
                                                    value={receiptData?.oz_ward || ''}
                                                    className="text-[13px] leading-[100%] flex items-center pl-[15.5px] mb-[6px] h-[35px] placeholder-[#BEBEBE]"
                                                    placeholder="例）港区"
                                                />
                                                <input
                                                    type="text"
                                                    name="oz_address"
                                                    onChange={handleChangeForm}
                                                    value={receiptData?.oz_address || ''}
                                                    className="text-[13px] leading-[100%] flex items-center pl-[15.5px] mb-[6px] h-[35px] placeholder-[#BEBEBE]"
                                                    placeholder="例）赤坂1-2-34"
                                                />
                                                <input
                                                    type="text"
                                                    name="oz_building"
                                                    onChange={handleChangeForm}
                                                    value={receiptData?.oz_building || ''}
                                                    className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[35px] placeholder-[#BEBEBE]"
                                                    placeholder="例）パレットビル3階)"
                                                />
                                            </div>
                                        </FormBlockItem>

                                        <FormBlockItem
                                            label="電話番号"
                                            withInput={true}
                                            value={receiptData?.oz_tel || ""}
                                            inputName="oz_tel"
                                            handleChange={handleChangeForm}
                                            isRequired={true}
                                        />

                                        <FormBlockItem
                                            label="印影（png形式推奨）"
                                            isRequired={true}
                                        >
                                            <div className="flex items-center w-full">
                                                {/* show image */}
                                                {receiptData.oz_seal_url || receiptData.image ? (
                                                    <img
                                                    className="w-[330px] object-cover mr-4 border-gray-200 my-[5px]"
                                                    src={
                                                        receiptData.image
                                                            ? URL.createObjectURL(receiptData.image)
                                                            : receiptData?.oz_seal_url
                                                    }
                                                    alt="Image"
                                                    />
                                                ) : (
                                                    <div
                                                        className="w-full max-w-[330px] h-[30px] flex items-center justify-center mr-4  border-[#EFF1F0] border-[1px] my-[5px]"
                                                        onClick={() => inputFileRef.current?.click()}
                                                    ></div>
                                                )}
                                                <label
                                                    htmlFor="image"
                                                    className="flex items-center justify-center w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-secondary-light cursor-pointer"
                                                >
                                                    ファイルを選択
                                                </label>
                                                <input
                                                    type="file"
                                                    id="image"
                                                    name="image"
                                                    accept="image/*"
                                                    className="hidden"
                                                    ref={inputFileRef}
                                                    onChange={handleChangeForm}
                                                    placeholder="ファイルを選択"
                                                />
                                                {!receiptData.image && !receiptData.oz_seal && (
                                                    <div className="ml-[10px] text-[10px] font-[400] leading-[19px]">
                                                    選択されていません
                                                    </div>
                                                )}
                                                </div>
                                                {/* show Error */}
                                                {fileError && <div className="text-red-500 text-xs">{fileError}</div>}
                                        </FormBlockItem>
                                    </FormBlock>
                                </form>
                                <div
                                    className="flex items-center justify-center gap-[19px] text-white text-[16px] font-[700] mt-[26px]">
                                    <button
                                        className="px-[50px] py-[10px] bg-primary rounded-[10px]"
                                        onClick={showPreviewReceiptModal}
                                    >
                                        {RECEIPT_FORM_DATA.BUTTON.PREVIEW}
                                    </button>
                                    {/* <button
                                        className="px-[50px] py-[10px] bg-primary rounded-[10px]"
                                        onClick={isEdit ? handleUpdate : handleSubmit}
                                    >
                                        {RECEIPT_FORM_DATA.BUTTON.SUBMIT}
                                    </button> */}
                                    <FormButton 
                                        isSubmitting={isSubmitting}
                                        className="px-[50px] py-[10px] bg-primary rounded-[10px]"
                                        onClick={isEdit ? handleUpdate : handleSubmit}
                                        label={RECEIPT_FORM_DATA.BUTTON.SUBMIT}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    );
};

export default ReceiptForm;
