import ContractBasePage from '.';
import ContractOverview from '../../../components/user/Contract/Overview/ContractOverview';

const ContractOverviewPage = () => {
  return (
    <ContractBasePage title="契約状況 ｜契約管理">
      <ContractOverview />
    </ContractBasePage>
  );
};

export default ContractOverviewPage;
