import { useState } from 'react';
import { request } from '../../../../../services/axios/axios';
import { API } from '../../../../../services/constants/route/api';
import { PATH_CONTRACT } from '../../../../../services/constants/route/router';
import {
  CONFIRM_UNSUBSCRIBE_CHECKED,
  CONTRACT_SUBSCRIPTION_PERIOD,
  CONTRACT_SUBSCRIPTION_TYPE_NAME,
  SUBSCRIPTION_COMPLETE_TYPE,
  SUBSCRIPTION_STEP,
} from '../../../../../services/constants/user/pages/contract';
import { PAYMENT_METHOD_TYPE } from '../../../../../services/constants/user/payment';
import { ErrorData, SetStateAction } from '../../../../../types/globals';
import {
  Contract,
  ContractSubscription,
  SubscriptionCheckoutData,
  UnsubscribeSubscriptionAdditionalData,
} from '../../../../../types/user/contract';
import SubmitButton from '../SubmitButton';
import FormLine from '../Basic/FormLine';
import {
  getSubscriptionPeriodLabel,
  getSubscriptionCheckoutTypeByStep,
  getTaxAmount,
  getBilledAmountWithTax,
} from '../../../../../services/utils/contract';
import { formatMoney } from '../../../../../services/helpers/parseData';
import Spinner from '../../../../commons/icons/Animations/Spinner';
import { RenderConfirmPassWordUnSubscribeSubscription, RenderContractLabelConfirm, RenderUnSubscribeSubscriptionAnnouncement } from '../RenderUtils';
import { swalMessage } from '../../../../../services/helpers/swal';
import { useNavigate } from 'react-router-dom';
import { useStorage } from '../../../../../hooks/useStorage';
import { STORAGE_KEY_PATH_HISTORY } from '../../../../../services/constants/globals';

type ProductSubscriptionConfirmationProps = {
  setStep?: SetStateAction<string>;
  formData?: ContractSubscription;
  latestPriceList?: Contract[];
  step?: string;
  originSubscribed?: ContractSubscription;
};

const ProductContractSubscriptionConfirmation = ({
  latestPriceList,
  formData,
  setStep,
  step = '',
  originSubscribed,
}: ProductSubscriptionConfirmationProps) => {
  const navigate = useNavigate();
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [verifyData, setVerifyData] = useState<UnsubscribeSubscriptionAdditionalData>({})
  const isCancel = (step === SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE) ? true : false;
  const submitText = isCancel ? '契約を解約する' : '決済画面へ進む';
  const { getItem } = useStorage();
  const pathHistory = getItem(STORAGE_KEY_PATH_HISTORY);

  const handleChangeAdditionData = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    let additionFormData;
    if (e.target.type === 'checkbox') {
        const target = e.target as HTMLInputElement;
        if (target.checked) {
            additionFormData = {
              ...verifyData,
              confirmation: CONFIRM_UNSUBSCRIBE_CHECKED,
            };
        } else {
            additionFormData = {
              password: verifyData.password
            };
        }
    } else {
        additionFormData = {
          ...verifyData,
          [name]: value
        }
    }
    setVerifyData(additionFormData);
  };

  const submitHandle = async () => {
    setIsCheckingOut(true);
    let isVerified = true;
    let errorMessage = '';

    // handle check verify user when unsubscribe
    if(isCancel) {
      await request.post(
        `${API.AUTH.VERIFY_ADMIN}`, 
        verifyData, 
        () => {}, 
        (err: ErrorData) => {
          {Object.keys(err).map((errorKey, index) => (
            errorMessage += err[errorKey][0] + '<br/>'
          ))}
          swalMessage(
            'エラー',
            '',
            'error',
            { timer: 2000, html: errorMessage },
          );
          isVerified = false;
          return;
        }
      )
      if(!isVerified) {
        setIsCheckingOut(false)
        return;
      }
    }

    let additionParamString = '';
    switch(step) {
      case SUBSCRIPTION_STEP.CONFIRMATION_CHANGE:
        additionParamString = `?type=${SUBSCRIPTION_COMPLETE_TYPE.CHANGE_COMPLETE}`;
        break;
      case SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE:
        additionParamString = `?type=${SUBSCRIPTION_COMPLETE_TYPE.UNSUBSCRIBE_COMPLETE}`;
        break;
      default:
        additionParamString = '';
        break;
    }

    const submitData: SubscriptionCheckoutData = {
      payment_method_type: PAYMENT_METHOD_TYPE.CARD.VALUE,
      success_url: `${window.location.origin}${PATH_CONTRACT.SUBSCRIPTION_COMPLETE}${additionParamString}`,
      cancel_url: window.location.origin + PATH_CONTRACT.OVERVIEW,
      subscription: formData,
      subscription_checkout_type: getSubscriptionCheckoutTypeByStep(step),
    };

    await request.post(
      API.CONTRACT_SUBSCRIPTION.GET_URL_CHECKOUT,
      submitData,
      (res) => {
        window.location.href = res;
        return;
      },
      () => {
        setIsCheckingOut(false);
      },
    );
  };

  const returnHandle = () => {
    if(isCancel) {
      if (pathHistory && pathHistory.trim()) {
        return navigate(`${PATH_CONTRACT.OVERVIEW}?${pathHistory.trim()}`);
      }
      
      return navigate(PATH_CONTRACT.OVERVIEW);
    }
    setStep?.(SUBSCRIPTION_STEP.FORM_FILL);
  };

  return (
    <div className="text-secondary-dark">
      <div className="mb-[7px]">契約詳細</div>
      <div className="">
        <FormLine label="追加機能">
          <div className="flex items-center gap-[7px] ml-[16px]">
            {CONTRACT_SUBSCRIPTION_TYPE_NAME.PRODUCT_NAME}
          </div>
        </FormLine>

        <FormLine label="契約">
          <div className="ml-[16px] text-[12px] font-[400] leading-[100%]">
            {step === SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE
              ? '無し'
              : getSubscriptionPeriodLabel(formData?.period_type)}
            <span className="text-danger text-[12px] font-[400] leading-[100%]">
              <RenderContractLabelConfirm
                step={step || ''}
                formData={formData}
                originSubscribed={originSubscribed}
              />
            </span>
          </div>
        </FormLine>

        <FormLine label="金額（税抜）">
          <div className="ml-[16px]">
            {latestPriceList?.length && (
              <>
                {formatMoney(
                  formData?.period_type === CONTRACT_SUBSCRIPTION_PERIOD.YEARLY
                    ? (latestPriceList[0]?.price_yearly || 0) * 12
                    : latestPriceList[0]?.price_monthly,
                )}
              </>
            )}
          </div>
        </FormLine>
        {!isCancel ? (
            <>
              <div className="flex justify-center items-center border-t border-secondary-light mt-[20px] py-[12px]">
                <div className="w-[290px] flex items-center ml-[-70px]">
                  <div className="text-[16px] font-[500] leading-[100%] w-full text-right">小計</div>
                  <div className="text-[18px] font-[500] leading-[100%] w-full text-right">
                    {formatMoney(formData?.billed_amount)}
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center mt-[20px] py-[5px]">
                <div className="w-[290px] flex items-center ml-[-70px]">
                  <div className="text-[10px] font-[500] leading-[100%] w-full text-right">
                    消費税額（10％対象）
                  </div>
                  <div className="text-[12px] font-[500] leading-[100%] w-full text-right">
                    {formatMoney(getTaxAmount(formData?.billed_amount))}
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center mt-[20px] py-[12px]">
                <div className="w-[290px] flex items-center ml-[-70px]">
                  <div className="text-primary text-[16px] font-[500] leading-[100%] w-full text-right">
                    料金合計
                  </div>
                  <div className="text-[18px] font-[500] leading-[100%] w-full text-right">
                    {formatMoney(getBilledAmountWithTax(formData?.billed_amount))}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <FormLine label="注意事項">
                <div className="w-full flex items-center ml-[16px] py-[15 px] gap-[20px] pb-[15px]">
                  <RenderUnSubscribeSubscriptionAnnouncement />
                </div>
              </FormLine>
              <div className='2xl:w-[43%] w-[46%] mx-auto pb-[10px] pt-[30px]'>
                <RenderConfirmPassWordUnSubscribeSubscription 
                  handleChange={handleChangeAdditionData}
                />
              </div>
            </>
          )

        }

        <div className="flex justify-center gap-[15px] mt-[30px] mb-[20px]">
          <SubmitButton type="return" onClick={returnHandle} disabled={isCheckingOut}>
            前の画面へ戻る
          </SubmitButton>
          <SubmitButton 
            type="unsubscribe" 
            onClick={submitHandle} 
            disabled={isCheckingOut || (isCancel && verifyData.confirmation !== CONFIRM_UNSUBSCRIBE_CHECKED)}
          >
            {isCheckingOut ? <Spinner /> : submitText}
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default ProductContractSubscriptionConfirmation;
