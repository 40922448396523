import React, { ChangeEvent, useState } from "react";
import Button from "../components/Login/Button";

import { Link, useNavigate } from "react-router-dom";
import { ROUTE } from "../services/constants/route/router";
import { swalMessage } from "../services/helpers/swal";
import { makeRequest } from "../services/axios/axios";
import { API } from "../services/constants/route/api";
import AuthLayout from "../layouts/AuthLayout";
import { getSubdomainFromUrl } from "../services/helpers/domain";

const ForgotPassword = () => {
  let navigate = useNavigate();
  const [disabledBtn, setDisabledBtn] = useState<boolean>(false);
  const [dataForgotPassword, setDataForgotPassword] = useState({
    username: "",
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value = e.target.value;

    setDataForgotPassword({ ...dataForgotPassword, [name]: value });
  };

  const handleSubmitEmail = async () => {
    let result = await makeRequest({
      method: "post",
      url: API.AUTH.SEND_EMAIL_FORGOT_PASSWORD,
      data: {
        ...dataForgotPassword,
        subdomain: getSubdomainFromUrl(),
      },
    });

    if (result.status) {
      navigate(ROUTE.SUBMIT_FORGOT_PASSWORD_DONE);
      return;
    }
    setDisabledBtn(false);
    swalMessage("エラー", result.message, "error");
    return;
  };

  return (
    <AuthLayout>
      <div className="mb-[45px] mt-[30px] flex flex-col items-center">
        <div className="md:w-[550px] sm:w-[450px] w-[370px] h-[437px] mb-[30px] px-[25px]">
          <p className="mt-[30px] mb-[20px] text-[0.94rem] font-medium">
            パスワード再設定
          </p>
          <div className="text-[0.75rem] font-light leading-[190%]">
            ご登録いただいたメールアドレスを入力してください。
            <br />
            パスワードを再発行いたします。
            <br />
            ※再設定のコードの有効期限は30分で、時間内は何度でも使用可能です。
          </div>
          <div className="mb-[30px] mt-[30px]">
            <input
              type="text"
              name="username"
              onChange={handleChangeInput}
              className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
              placeholder="メールアドレス　または　ログインID"
              required
            />
          </div>
          <Button
            content={"送信する"}
            otherstyle={"bg-primary text-white"}
            type={"button"}
            onClick={() => {
              setDisabledBtn(true);
              handleSubmitEmail();
            }}
            isSubmitting={disabledBtn}
          />
          <Link to={ROUTE.LOGIN}>
            <Button
              content={"ログイン画面へ戻る "}
              otherstyle={"bg-secondary-light text-white mt-[10px]"}
              type={"button"}
            />
          </Link>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
