import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { ValueSelectType } from '../../../types/globals';

const SelectMacBook = (
   { className, valueArr, valueChoice, onChange }:
      {
         className?: string,
         valueArr: ValueSelectType[],
         valueChoice?: number | string,
         onChange: any,
      }
) => {
   const [isOpenOptionList, setIsOpenOptionList] = useState<boolean>(false);
   const [chosenValue, setChosenValue] = useState<string | number>(valueChoice || '');
   const selectDiv = useRef<HTMLDivElement>(null);

   useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
         document.removeEventListener('mousedown', handleClickOutside);
      };
   }, []);

   const handleClickOutside = (event: MouseEvent) => {
      if (selectDiv.current && !selectDiv.current.contains(event.target as Node)) {
         setIsOpenOptionList(false);
      }
   };

   const handleOpenOptionList = () => {
      setIsOpenOptionList(isOpenOptionList ? false : true);
   }

   const handleChoiceValue = (value: string | number) => {
      onChange(value);
      setChosenValue(value);
      setIsOpenOptionList(false); 
   }

   return (
      <div
         ref={selectDiv}
         className={`${className} border-[1px] min-h-[30px] min-w-[70px] max-w-[300px] border-[#BEBEBE] rounded-[5px] relative`}>
         <div
            className={`h-full w-full flex justify-between items-center gap-[10px] hover:cursor-pointer`}
            onClick={handleOpenOptionList}
         >
            <div className='pl-[15px]'>{chosenValue ? chosenValue : valueChoice}</div>
            <div className='pr-[5px]'>
               {!isOpenOptionList ? <IoIosArrowDown /> : <IoIosArrowUp />}
            </div>
         </div>
         <div className={`${!isOpenOptionList ? "hidden" : ""} rounded-[5px] absolute top-[30px] overflow-y-auto max-h-[200px] w-full border-secondary-disabled border-[1px] bg-white`}>
            {valueArr?.map((value, index) => {
               return (
                  <div
                     className={`${value?.value === chosenValue ? 'bg-primary-synch text-white' : ''}
                      hover:bg-primary-synch hover:text-white pl-[15px] py-[2px] text-left border-y-white border-[1px]`}
                     key={index}
                     onClick={() => handleChoiceValue(value.value)}
                  >{value?.label}</div>
               )
            })}
         </div>
      </div>
   );
};

export default SelectMacBook;

