import { RouterProvider } from "react-router-dom";
import "./App.css";
import "./assests/styles/globals.css";
import AuthProvider from "./context/AuthContext";
import { routes } from "./routes";
import LayoutContextProvider from "./context/LayoutContext";

function App() {
  return (
    <AuthProvider>
      <LayoutContextProvider>
        <RouterProvider router={routes} />
      </LayoutContextProvider>
    </AuthProvider>
  );
}

export default App;
