import React from 'react';
import { ElementProps, PropsWithChild } from '../../../../types/globals';

type SubmitButtonProps = PropsWithChild<
  {
    type?: 'return' | 'submit' | 'unsubscribe';
    disabled?: boolean;
  } & ElementProps<HTMLButtonElement>
>;

const SubmitButton = ({
  type = 'submit',
  disabled = false,
  children,
  ...props
}: SubmitButtonProps) => {
  let bgScheme = ''; // 'submit' as default

  switch (type) {
    case 'return':
      bgScheme = 'bg-secondary-light';
      break;
    case 'unsubscribe':
      bgScheme = 'bg-danger';
      break;

    default:
      bgScheme = 'bg-primary-dark';
      break;
  }

  return (
    <button
      className={`w-full max-w-[200px] min-h-[34px] text-[14px] font-[600] leading-[100%] flex justify-center items-center text-white rounded-[10px] ${bgScheme} ${
        disabled ? 'opacity-50 pointer-events-none' : ''
      }`}
      disabled={disabled}
      {...props}
    >
      {children || 'Submit'}
    </button>
  );
};

export default SubmitButton;
