import { getTabObj } from '../../../helpers/parseData';
import { PATH_SUPER_ADMIN } from '../../route/router';

const CONTRACT_SETTING_TAB_ITEM = {
  MANABITE_FORM_LABEL: '契約料金設定 ｜ パレットLMS',
  MANABITE_LABEL: 'パレットLMS契約料金',
  TOKITE_FORM_LABEL: '契約料金設定 ｜ パレットCBT',
  TOKITE_LABEL: 'パレットCBT契約料金',
};

const CONTRACT_SETTING_TAB_DATA = [
  {
    ...getTabObj(
      CONTRACT_SETTING_TAB_ITEM.MANABITE_LABEL,
      PATH_SUPER_ADMIN.CONTRACT_PRICE_SETTING.MANABITE,
    ),
    formLabel: CONTRACT_SETTING_TAB_ITEM.MANABITE_FORM_LABEL,
  },
  {
    ...getTabObj(
      CONTRACT_SETTING_TAB_ITEM.TOKITE_LABEL,
      PATH_SUPER_ADMIN.CONTRACT_PRICE_SETTING.TOKITE,
    ),
    formLabel: CONTRACT_SETTING_TAB_ITEM.TOKITE_FORM_LABEL,
  },
];

export { CONTRACT_SETTING_TAB_DATA };
