import React, { useEffect, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import FormBlock from "./FormBlock";
import FormBlockItem from "./FormBlockItem";
import { PAYMENT_FORM_DATA } from "../../../../services/constants/user/pages/company";
import { Payment } from "../../../../types/user/payment";
import { makeRequest, request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import {
  swalError,
  swallConfirm,
} from "../../../../services/helpers/swal";
import ErrorBox from "../../../commons/form/ErrorBox";
import { ErrorData } from "../../../../types/globals";
import { PALETTE_FEATURE } from "../../../../services/constants/globals";
import FormButton from "../../../commons/form/FormButton";

const PaymentForm = () => {
  const [paymentData, setPaymentData] = useState<Payment>({
    services: [],
    payment_methods: [],
    jcb_enable: false,
  });
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [selectedServices, setSelectedServices] = useState<number[]>([]);
  const [editSCKey, setEditSCKey] = useState<boolean>(false);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<
    string[]
  >([]);
  const [errors, setErrors] = useState<ErrorData>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleChangeForm = (
    e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLInputElement>
  ) => {
    const elementCurrent = e.currentTarget;
    let paymentFormData;

    switch (elementCurrent.type) {
      case "radio":
        paymentFormData = {
          ...paymentData,
          [elementCurrent.name]: Number(elementCurrent.value),
        };
        break;
      default:
        paymentFormData = {
          ...paymentData,
          [elementCurrent.name]: elementCurrent.value,
        };
        break;
    }

    setPaymentData(paymentFormData);
  };

  const handleChecked = (
    e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLInputElement>
  ) => {
    const elementCurrent = e.currentTarget;
    const value = elementCurrent.value;
    let selected;

    switch (elementCurrent.name) {
      case "services":
        if (selectedServices.includes(Number(value))) {
          selected = selectedServices.filter(
            (serviceId) => serviceId !== Number(value)
          );
        } else {
          selected = [...selectedServices, Number(value)];
        }

        setSelectedServices(selected);
        break;
      case "payment_methods":
        if (selectedPaymentMethods.includes(value)) {
          selected = selectedPaymentMethods.filter(
            (method) => method !== value
          );
        } else {
          selected = [...selectedPaymentMethods, value];
        }

        setSelectedPaymentMethods(selected);
        break;
      case "jcb_enable":
        selected = elementCurrent.checked;
        break;
      default:
        break;
    }

    setPaymentData({ ...paymentData, [elementCurrent.name]: selected });
  };

  const handleSubmit = async () => {
    swallConfirm(async () => {
      setIsSubmitting(true);
      let url =
        paymentData?.id !== undefined
          ? API.COMPANY_INFO.PAYMENT_SETTING_UPDATE
          : API.COMPANY_INFO.PAYMENT_SETTING;

      if (paymentData.id) {
        await request.patch(
          url,
          paymentData,
          (data) => {
            setPaymentData(data);
            setIsEdit(true);
            setErrors({});
            setEditSCKey(false);
            setIsSubmitting(false);
          },
          (errors) => {
            setErrors(errors);
            swalError();
            setIsSubmitting(false);
          }
        );
      } else {
        await request.post(
          url,
          paymentData,
          (data) => {
            setPaymentData(data);
            setIsEdit(true);
            setErrors({});
            setEditSCKey(false);
            setIsSubmitting(false);
          },
          (errors) => {
            setErrors(errors);
            swalError();
            setIsSubmitting(false);
          }
        );
      }
    }, "Stripeアカウントを変更すると、登録済みのクーポンは利用できなくなります。設定を保存しますか？", '', null, '設定を保存');
  };

  const handleCancel = async () => {
    // swalLoading();
    // const result = await makeRequest({
    //   method: "get",
    //   url: API.COMPANY_INFO.PAYMENT_SETTING_DETAIL,
    // });

    // if (result.status && result.data !== null) {
    //   const paymentSetting: Payment = result.data;
    //   setPaymentData(paymentSetting);
    //   setIsEdit(true);
    //   setErrors({});
    //   setEditSCKey(false);
    // }
    setIsEdit(true);
    setErrors({});
    setEditSCKey(false);
  };

  const handleMaskedString = (value: string) => {
    const passwordChar = "●";

    const firstPart = value.slice(0, 8);
    const lastPart = value.slice(-4);
    const middlePart = passwordChar.repeat(6);
    const maskedString = firstPart + middlePart + lastPart;

    return maskedString;
  };

  const handleEdit = () => {
    setIsEdit(false);
  };

  const handleEditSCKey = () => {
    setEditSCKey(true);
    setPaymentData({ ...paymentData, stripe_secret_key: "" });
  };

  const fetchPaymentSetting = async () => {
    // swalLoading();

    const result = await makeRequest({
      method: "get",
      url: API.COMPANY_INFO.PAYMENT_SETTING_DETAIL,
    });

    if (result.status && result.data !== null) {
      const paymentSetting: Payment = result.data;
      setPaymentData(paymentSetting);
      setIsEdit(true);

      if (paymentSetting.services && paymentSetting.services.length > 0) {
        setSelectedServices(paymentSetting.services);
      }

      if (
        paymentSetting.payment_methods &&
        paymentSetting.payment_methods.length > 0
      ) {
        setSelectedPaymentMethods(paymentSetting.payment_methods);
      }
    }
  };

  // const fetchServiceUsers = async () => {
  //   const result = await makeRequest({
  //     method: "get",
  //     url: API.SERVICE.LIST_SERVICE_OF_USER,
  //   });

  //   setServices(result.data);
  // };

  useEffect(() => {
    fetchPaymentSetting();
    // fetchServiceUsers();
  }, []);
  
  return (
    <div className="text-secondary-dark mx-[-15px]">
      <p className="text-[12px] font-[400] leading-[190%] pb-[5px]">
        ユーザーに商品ページを公開する場合は、公開設定で非公開から公開に切り替えてください。
      </p>
      <p className="text-[12px] font-[400] leading-[190%] pb-[25px]">
        商品の一覧はカテゴリごとに表示されるので、カテゴリが登録されていない商品は閲覧および購入ができません。
      </p>
      <div className="text-[12px] font-[400] leading-[190%] pb-[20px]">
        <p>商品販売を公開するにはStripeアカウントが必要になります。</p>
      </div>
      <div className="pb-[20px]">
        <button 
          className="flex items-center justify-center gap-[10px] text-white text-[14px] text-center bg-primary pl-[30px] pr-[20px] py-[5px] rounded-[10px]"
          onClick={() => {
            window.open("https://palette-education.com/support/")
          }}
        >
          Stripe連携について <BiChevronRight size={24} />
        </button>
      </div>
      {errors && !!Object.keys(errors).length && (
        <div className="mb-[20px]">
          <ErrorBox errors={errors} />
        </div>
      )}
      <div>
        <form>
          <FormBlock title="プロダクト選択">
            <FormBlockItem
              label="利用プロダクトの選択"
              isRequired={true}
              isEdit={isEdit}
            >
              <div
                className={`w-full py-[5px] pl-[9px] pr-[32px] flex items-center gap-[20px]`}
              >
                {/* {services &&
                  services.length > 0 &&
                  services.map((service) => (
                    <div className="flex items-center gap-1" key={service.id}>
                      <input
                        type="checkbox"
                        name="services"
                        id={service.name}
                        value={service.id}
                        className={
                          isEdit
                            ? "bg-secondary-extralight pointer-events-none"
                            : "cursor-pointer"
                        }
                        onChange={handleChecked}
                        checked={paymentData?.services?.includes(
                          Number(service.id)
                        )}
                        disabled={isEdit}
                      />
                      <label
                        htmlFor={service.name}
                        className={
                          isEdit ? "pointer-events-none" : "cursor-pointer"
                        }
                      >
                        {service.name}
                      </label>
                    </div>
                  ))} */}
                <div className="flex items-center gap-1">
                  <input
                    type="checkbox"
                    name="services"
                    id="manabite"
                    value={PALETTE_FEATURE.MANABITE.ID}
                    className={
                      isEdit
                        ? "bg-secondary-extralight pointer-events-none"
                        : "cursor-pointer"
                    }
                    onChange={handleChecked}
                    checked={paymentData?.services?.includes(PALETTE_FEATURE.MANABITE.ID)}
                    disabled={isEdit}
                  />
                  <label
                    htmlFor="manabite"
                    className={
                      isEdit ? "pointer-events-none" : "cursor-pointer"
                    }
                  >
                    パレットLMS
                  </label>
                </div>
                <div className="flex items-center gap-1">
                  <input
                    type="checkbox"
                    name="services"
                    id="tokite"
                    value={PALETTE_FEATURE.TOKITE.ID}
                    className={
                      isEdit
                        ? "bg-secondary-extralight pointer-events-none"
                        : "cursor-pointer"
                    }
                    onChange={handleChecked}
                    checked={paymentData?.services?.includes(PALETTE_FEATURE.TOKITE.ID)}
                    disabled={isEdit}
                  />
                  <label
                    htmlFor="tokite"
                    className={
                      isEdit ? "pointer-events-none" : "cursor-pointer"
                    }
                  >
                    パレットCBT
                  </label>
                </div>
              </div>
            </FormBlockItem>
          </FormBlock>
          <FormBlock title="決済方法">
            <FormBlockItem label="決済方法" isRequired={true} isEdit={isEdit}>
              <div className={`w-full py-[10px] pl-[9px] pr-[32px]`}>
                <div className="flex items-center gap-[20px] mb-[10px]">
                  {PAYMENT_FORM_DATA.PAYMENT_METHODS.map(
                    (paymentMethod, index) => (
                      <div className="flex items-center gap-1" key={index}>
                        <input
                          type="checkbox"
                          name="payment_methods"
                          id={paymentMethod.name}
                          value={paymentMethod.name}
                          onChange={handleChecked}
                          checked={paymentData.payment_methods?.includes(
                            paymentMethod.name
                          )}
                          disabled={isEdit}
                          className={
                            isEdit
                              ? "pointer-events-none bg-secondary-extralight"
                              : "cursor-pointer"
                          }
                        />
                        <label
                          htmlFor={paymentMethod.name}
                          className={
                            isEdit ? "pointer-events-none" : "cursor-pointer"
                          }
                        >
                          {paymentMethod.title}
                        </label>
                      </div>
                    )
                  )}
                </div>
                {isEdit ? "" : <span>必要な決済方法をお選びください。</span>}
              </div>
            </FormBlockItem>
             <p className="text-[12px] font-[400] leading-[190%] text-[#FE6D73]">
              【ご注意ください】コンビニ決済のご利用は、Stripe管理画面での利用申請手続きと事前の審査（約30日間）が必要です。審査完了までコンビニ決済は受付られません。
            </p>
          </FormBlock>
          <FormBlock title="Stripe APIキー（キーを確認）">
            <FormBlockItem
              label="公開可能キー"
              isRequired={true}
              isEdit={isEdit}
            >
              <div className={`w-full py-[10px] pl-[9px]`}>
                <div className="flex flex-col">
                  {isEdit ? (
                    <p className="break-all text-[12px]">
                      {paymentData?.stripe_public_key ? paymentData?.stripe_public_key : "未設定"}
                    </p>
                  ) : (
                    <>
                      <input
                        type="text"
                        name="stripe_public_key"
                        onChange={handleChangeForm}
                        value={paymentData?.stripe_public_key}
                        className="border-none"
                      />
                      <p className="text-[12px]">
                        Stripe連携についてはこちらのマニュアルをご確認ください。
                      </p>
                      <div className="text-[12px] text-primary">
                        <a 
                          className="hover:cursor-pointer"
                          onClick={() => {
                            window.open("https://palette-education.com/support/")
                          }}
                        >
                          Stripeアカウントの連携
                        </a>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </FormBlockItem>
            <FormBlockItem
              label="シークレットキー"
              isRequired={true}
              isEdit={isEdit}
            >
              <div className="w-full pl-[9px]">
                {paymentData.id ? (
                  isEdit ? (
                    <p className="break-all text-[12px]">
                      {paymentData.stripe_secret_key
                        ? handleMaskedString(paymentData.stripe_secret_key)
                        : "未設定"}
                    </p>
                  ) : editSCKey ? (
                    <input
                      type="text"
                      name="stripe_secret_key"
                      onChange={handleChangeForm}
                      value={paymentData.stripe_secret_key}
                      className="flex-1"
                    />
                  ) : (
                    <div className="flex gap-[20px] items-center">
                      <p className="break-all text-[12px]">
                        {paymentData.stripe_secret_key
                          ? handleMaskedString(paymentData.stripe_secret_key)
                          : ""}
                      </p>
                      <button
                        className="text-[9px] text-center text-white font-[500] px-[13.5px] pt-[6px] pb-[7px] bg-secondary-light"
                        onClick={handleEditSCKey}
                      >
                        変更
                      </button>
                    </div>
                  )
                ) : isEdit ? (
                  <div className="flex flex-col py-[10px]">
                    <p className="break-all text-[12px]">
                      未設定
                    </p>
                  </div>
                ) : (
                  <input
                    type="text"
                    name="stripe_secret_key"
                    onChange={handleChangeForm}
                    value={paymentData.stripe_secret_key}
                  />
                )}
              </div>
            </FormBlockItem>
          </FormBlock>
          {/* {!isEdit && 
            <FormBlock title="基本情報 ">
              <FormBlockItem
                label="銀行名"
                withInput={true}
                isEdit={isEdit}
                value={paymentData.bank_name || ""}
                inputName="bank_name"
                handleChange={handleChangeForm}
                isRequired={true}
              />
              <FormBlockItem
                label="銀行コード"
                withInput={true}
                isEdit={isEdit}
                value={paymentData.bank_code || ""}
                inputName="bank_code"
                handleChange={handleChangeForm}
                isRequired={true}
              />
              <FormBlockItem
                label="支店名"
                withInput={true}
                isEdit={isEdit}
                value={paymentData.branch_name || ""}
                inputName="branch_name"
                handleChange={handleChangeForm}
                isRequired={true}
              />
              <FormBlockItem
                label="支店コード"
                withInput={true}
                isEdit={isEdit}
                value={paymentData.branch_code || ""}
                inputName="branch_code"
                handleChange={handleChangeForm}
                isRequired={true}
              />
              <FormBlockItem label="口座区分" isRequired={true} isEdit={isEdit}>
                <div className="w-full pl-[9px] py-[10px] flex items-center gap-[20px]">
                  {isEdit ? (
                    <p>
                      {
                        PAYMENT_FORM_DATA.ACCOUNT_TYPE[
                          paymentData.account_type || 0
                        ]
                      }
                    </p>
                  ) : (
                    <>
                      {PAYMENT_FORM_DATA.ACCOUNT_TYPE.map((value, index) => (
                        <div className="flex items-center gap-[5px]" key={index}>
                          <input
                            type="radio"
                            name="account_type"
                            id={`account_type_${index}`}
                            value={index}
                            onChange={handleChangeForm}
                            checked={paymentData.account_type === Number(index)}
                            disabled={isEdit}
                            className={isEdit ? "pointer-events-none" : ""}
                          />
                          <label
                            htmlFor={`account_type_${index}`}
                            className={isEdit ? "" : "cursor-pointer"}
                          >
                            {value}
                          </label>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </FormBlockItem>
              <FormBlockItem
                label="口座番号"
                withInput={true}
                isEdit={isEdit}
                value={paymentData.account_number || ""}
                inputName="account_number"
                isRequired={true}
                handleChange={handleChangeForm}
              />
              <FormBlockItem
                label="口座名義"
                withInput={true}
                isEdit={isEdit}
                value={paymentData.account_holder || ""}
                inputName="account_holder"
                isRequired={true}
                handleChange={handleChangeForm}
              />
            </FormBlock>
          } */}
        </form>
        <div className="flex items-center justify-center gap-[19px] text-white text-[16px] font-[700] mt-[26px]">
          {isEdit ? (
            <button
              className="px-[50px] py-[10px] bg-primary rounded-[10px]"
              onClick={handleEdit}
            >
              {PAYMENT_FORM_DATA.BUTTON.EDIT}
            </button>
          ) : (
            <>
              <button
                className="px-[50px] py-[10px] bg-secondary-light rounded-[10px]"
                onClick={handleCancel}
              >
                {PAYMENT_FORM_DATA.BUTTON.CANCEL}
              </button>
              {/* <button
                className="px-[50px] py-[10px] bg-primary rounded-[10px]"
                onClick={handleSubmit}
              >
                {PAYMENT_FORM_DATA.BUTTON.SUBMIT}
              </button> */}
              <FormButton 
                isSubmitting={isSubmitting}
                className="px-[50px] py-[10px] bg-primary rounded-[10px]"
                onClick={handleSubmit}
                label={PAYMENT_FORM_DATA.BUTTON.SUBMIT}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
