import ContractBasePage from '.';
import SubscriptionComplete from '../../../components/user/Contract/Subscription/SubscriptionComplete';
import { SUBSCRIPTION_CONST } from '../../../services/constants/user/pages/contract';
import { useStorage } from '../../../hooks/useStorage';

const SubscriptionCompletePage = () => {
  const { getItem } = useStorage();

  return (
    <ContractBasePage
      isIncludeTab={false}
      title={getItem(SUBSCRIPTION_CONST.SUBSCRIPTION_LABEL_STORAGE_KEY) || ''}
    >
      <SubscriptionComplete />
    </ContractBasePage>
  );
};

export default SubscriptionCompletePage;
