import React, { useState } from 'react';
import { Contract } from '../../../../types/user/contract';
import { ElementProps, PropsWithChild } from '../../../../types/globals';
import RequiredBox from '../../../user/commons/RequiredBox';
import {
  CONTRACT_SUBSCRIPTION_TYPE,
  SUBSCRIPTION_ADDITION_TYPES,
  SUBSCRIPTION_CONST,
} from '../../../../services/constants/user/pages/contract';
import { request } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import {
  formatNumberWithComma,
  formatThousandByComma,
} from '../../../../services/helpers/parseData';
import {
  getContractAdditionTypeLabel,
  getContractFormInputValue,
} from '../../../../services/utils/contract';
import FormButton from '../../../commons/form/FormButton';
import { PALETTE_FEATURE } from '../../../../services/constants/globals';

type ContractSettingProps = {
  settingData: Contract[];
  featureId: number;
};

type ContractSettingFormLineProps = PropsWithChild<
  {
    label?: string;
    isRequired?: boolean;
    isTransparent?: boolean;
    noBorder?: boolean;
    noGap?: boolean;
    noCenterAlgin?: boolean;
    isFetched?: boolean;
  } & ElementProps<HTMLDivElement>
>;

export type SettingFormData = {
  id: number;
  field_name: string;
  value: number;
};

const ContractSettingFormLine = ({
  label,
  children,
  isRequired = true,
  isTransparent = false,
  noBorder = false,
  noGap = false,
  noCenterAlgin = false,
  isFetched = false,
  ...props
}: ContractSettingFormLineProps) => {
  return (
    <div {...props}>
      <div
        className={`flex ${!noGap || !isFetched ? 'gap-[10px]' : ''} text-secondary-dark ${
          !noCenterAlgin ? 'items-center' : ''
        } ${!noBorder ? 'border-b' : ''} border-success-extralight`}
      >
        <div
          className={`pl-[14px] pr-[10px] w-[33%] h-[40px] ${
            !isTransparent ? 'bg-success-lighter' : ''
          } flex justify-between items-center`}
        >
          <div className="text-[14px] font-[500] leading-[100%]">{label}</div>
          {isRequired && !isTransparent && (
            <div className="">
              <RequiredBox />
            </div>
          )}
        </div>
        <div className="w-[67%] text-[12px]">
          {isFetched ? <>{children}</> : <div className="skeleton h-[35px] mb-[2px] w-[99%]"></div>}
        </div>
      </div>
    </div>
  );
};

const ContractSettingForm = ({ settingData, featureId }: ContractSettingProps) => {
  const [formData, setFormData] = useState<SettingFormData[]>([]);
  const [isLoading, setLoading] = useState(false);

  const priceInputStyles = 'h-[30px] text-[12px] w-[80px]';

  const freeSetting =
    settingData.find((data) => data.type === CONTRACT_SUBSCRIPTION_TYPE.FREE_SETTING) || {};
  const basicPriceList =
    settingData.filter((data) => data.type === CONTRACT_SUBSCRIPTION_TYPE.BASIC) || [];
  const additionPriceList =
    settingData.filter((data) => SUBSCRIPTION_ADDITION_TYPES.includes(data.type || 0)) || [];

  const changeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, id } = e.currentTarget;
    if (Number(value) > SUBSCRIPTION_CONST.LIMIT_QUANTITY) return;

    const parsedValue = formatNumberWithComma(value);

    let newFormData: typeof formData = formData;
    newFormData = newFormData.filter(
      (data) => !(data.id === Number(id) && data.field_name === name),
    );

    const formItem: SettingFormData = {
      id: Number(id),
      field_name: name,
      value: parsedValue,
    };

    setFormData([...newFormData, formItem]);
  };

  const submitHandle = async () => {
    if (!formData.length) return;

    setLoading(true);
    await request.patch(
      API.SUPER_ADMIN.CONTRACT_SETTING.DETAIL,
      formData,
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      },
      { withSuccess: true },
    );
  };

  const getFinalInputValue = (number?: number, initialValue?: number) => {
    return Number(number !== undefined ? number : initialValue);
  };

  return (
    <div>
      <div className="mb-[42px]">
        <div className="mb-[10px] text-[14px]">無料プラン</div>
        <ContractSettingFormLine
          label={`${featureId === PALETTE_FEATURE.MANABITE.ID ? '受講者' : '受験者'}アカウント数`}
          className="mb-[3px]"
          isFetched={!!settingData?.length}
        >
          <input
            type="text"
            name="user_limit_end"
            id={String(freeSetting?.id)}
            className={`${priceInputStyles} !w-full`}
            placeholder="0"
            value={getFinalInputValue(
              getContractFormInputValue(formData, freeSetting.id, 'user_limit_end'),
              freeSetting?.user_limit_end,
            )}
            onChange={changeHandle}
          />
        </ContractSettingFormLine>
        <ContractSettingFormLine label="無料ディクス内容(GB)" isFetched={!!settingData?.length}>
          <input
            type="text"
            name="storage_capacity"
            id={String(freeSetting?.id)}
            className={`${priceInputStyles} !w-full`}
            placeholder="0"
            value={getFinalInputValue(
              getContractFormInputValue(formData, freeSetting.id, 'storage_capacity'),
              freeSetting?.storage_capacity,
            )}
            onChange={changeHandle}
          />
        </ContractSettingFormLine>
      </div>

      <div className="mb-[42px]">
        <div className="mb-[10px] text-[14px]">アカウント追加契約</div>

        <ContractSettingFormLine isTransparent className="mb-[3px]" noBorder isFetched>
          <div className="flex">
            <div className="w-[15%]"></div>
            <div className="flex w-[85%] gap-[28px]">
              <div className="ml-[-10px]">月額払い（税別）</div>
            </div>
          </div>
        </ContractSettingFormLine>

        <ContractSettingFormLine
          label="アカウント料金（利用料金／1アカウント／月）"
          className="mb-[3px]"
          noBorder
          noGap
          noCenterAlgin
          isFetched={!!settingData?.length}
        >
          {basicPriceList.map((price, index) => (
            <div
              className={`flex ${
                index === basicPriceList.length - 1 ? 'border-y' : 'border-t'
              } border-success-extralight mb-[3px] py-[5px]`}
              key={price.id}
            >
              <div className="w-[15%] flex items-center">
                <div className={`flex items-center justify-start pl-[20px]`}>
                  <div className="">{price.user_limit_start || ''}</div>
                  <div className="">{!!price.user_limit_start ? '〜' : ''}</div>
                  <div className="">{price.user_limit_end || ''}</div>
                </div>
              </div>
              <div className="flex w-[85%] gap-[28px]">
                <div className="flex items-center gap-[11px]">
                  <input
                    type="text"
                    name="price_monthly"
                    id={String(price.id)}
                    className={priceInputStyles}
                    placeholder="0"
                    value={formatThousandByComma(
                      getFinalInputValue(
                        getContractFormInputValue(formData, price.id, 'price_monthly'),
                        price.price_monthly,
                      ),
                    )}
                    onChange={changeHandle}
                  />
                  <div className="">円</div>
                </div>
              </div>
            </div>
          ))}
        </ContractSettingFormLine>
      </div>

      <div className="mb-[50px]">
        <div className="mb-[10px] text-[14px]">オプション機能</div>

        <ContractSettingFormLine isTransparent className="mb-[3px]" isFetched>
          <div className="flex">
            <div className="w-[15%]"></div>
            <div className="flex w-[85%] gap-[28px]">
              <div className="ml-[-10px]">月額払い（税別）</div>
            </div>
          </div>
        </ContractSettingFormLine>

        {additionPriceList.map((additionPrice) => (
          <ContractSettingFormLine
            label={getContractAdditionTypeLabel(additionPrice.type)}
            className="mb-[3px]"
            noGap
            isFetched={!!settingData?.length}
            key={additionPrice.id}
          >
            <div className="flex">
              <div className="w-[15%]"></div>
              <div className="flex w-[85%] gap-[28px]">
                <div className="flex items-center gap-[11px]">
                  <input
                    type="text"
                    name="price_monthly"
                    id={String(additionPrice?.id)}
                    className={priceInputStyles}
                    placeholder="0"
                    value={formatThousandByComma(
                      getFinalInputValue(
                        getContractFormInputValue(formData, additionPrice.id, 'price_monthly'),
                        additionPrice?.price_monthly,
                      ),
                    )}
                    onChange={changeHandle}
                  />
                  <div className="">円</div>
                </div>
              </div>
            </div>
          </ContractSettingFormLine>
        ))}
      </div>

      <div className="flex justify-center">
        <FormButton
          label="保存する"
          className="py-[10px] w-[200px] rounded-[10px] bg-primary text-white text-[16px] font-[700] leading-[100%] flex justify-center items-center"
          isSubmitting={isLoading}
          disabled={!formData.length}
          onClick={submitHandle}
        />
      </div>
    </div>
  );
};

export default ContractSettingForm;
