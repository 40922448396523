import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { DIRECTION } from '../../services/constants/globals';

const SuperAdminSidebarDirection = ({
  onDirection,
}: {
  onDirection: 'up' | 'down' | 'right' | 'left';
}) => {
  switch (onDirection) {
    case DIRECTION.UP:
      return <IoIosArrowUp className="text-white" />;

    default:
      return <IoIosArrowDown className="text-white" />;
  }
};

export default SuperAdminSidebarDirection;
