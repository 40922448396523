import BasicInfoBasePage from "./index";
import ErrorBox from "../../../components/commons/form/ErrorBox";
import FormItem from "../../../components/user/commons/FormItem";
import ImageInternalButton from "../../../components/commons/buttons/ImageInternalButton";
import { PATH_BASIC_INFO } from "../../../services/constants/route/router";
import FormButton from "../../../components/commons/form/FormButton";
import React, { useEffect, useRef, useState } from "react";
import { ErrorData } from "../../../types/globals";
import postal_code from "japan-postal-code";
import {
  swalError,
  swalLoading,
  swalMessage,
} from "../../../services/helpers/swal";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import {
  JapanPrefectures,
  JapanPrefecturesWithDefault,
} from "../../../services/constants/superadmin/countries";
import { makeRequest, request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import LogoDefault from "../../../assests/images/default_avatar.webp";
import { AiOutlineUpload } from "react-icons/ai";
import Button from "../../../components/Login/Button";
import { OrganizationFormBasicInfoType } from "../../../types/user/organization";
import { useNavigate } from "react-router-dom";
import Trash from "../../../components/commons/icons/Trash";

const BasicInfoDetailPage = () => {
  const { getParamValue } = useObjectRoutes();
  const navigate = useNavigate();
  let token = getParamValue("token");
  const [dataBasicInfo, setDataBasicInfo] =
    useState<OrganizationFormBasicInfoType>({
      name: "",
      postal_code: "",
    });
  const [errors, setErrors] = useState<ErrorData>({});
  const [zipCode, setZipCode] = useState<string>("");
  const [zipCodePrefix, setZipCodePrefix] = useState<string>("");
  const [province, setProvince] = useState<string>(
    Object.values(JapanPrefecturesWithDefault)[0]
  );
  const [isSyncingPostal, setIsSyncingPostal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const numberRegex = new RegExp(/[^0-9]/, "g");
  const inputPrefixRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const fetchBasicInfoDetail = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.BASIC_INFO.BASIC_INFO}`,
      });

      if (!result.data) return swalError();
      for (let key in result.data) {
        if (result.data[key] === null) {
          delete result.data[key];
        }
      }
      if (result.data["province"]) {
        setProvince(result.data["province"]);
      }

      if (
        result.data["postal_code"] &&
        result.data["postal_code"].length === 7
      ) {
        setZipCode(result.data["postal_code"].substring(0, 3));
        setZipCodePrefix(result.data["postal_code"].substring(3));
      }

      setDataBasicInfo(result.data);
    };

    fetchBasicInfoDetail();
  }, []);

  const syncingZipCode = () => {
    setIsSyncingPostal(true);
    let postalData = {};
    postal_code.get((zipCode + zipCodePrefix) as string, (object) => {
      setIsSyncingPostal(false);
      setProvince(object.prefecture);
      setDataBasicInfo({
        ...dataBasicInfo,
        province: object.prefecture,
        ward: object.city,
        address1: object.area,
      });
      postalData = object;
    });

    setTimeout(() => {
      if (!Object.keys(postalData).length) {
        let messageError = "その郵便番号は存在しません";
        if (
          dataBasicInfo.postal_code?.length !== 7 ||
          dataBasicInfo.postal_code.match(numberRegex)
        ) {
          messageError = "正しい郵便番号を入力してください";
        }
        swalMessage("エラー", messageError, "error", {
          timer: 5000,
          showConfirmButton: false,
        });
        setIsSyncingPostal(false);
      }
    }, 1500);
  };

  const handleZipCodePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedData = e.clipboardData.getData("text/plain");
    const matches = pastedData.match(/^(\d{3})-(\d{4})$/);
    if (matches) {
      setZipCode(matches[1]);
      setZipCodePrefix(matches[2]);
      setDataBasicInfo({
        ...dataBasicInfo,
        postal_code: `${matches[1]}${matches[2]}`,
      });
      e.preventDefault();
    }
  };

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    if (/^\d{0,3}$/.test(input)) {
      setZipCode(input);
      if (input.length === 3 && inputPrefixRef.current) {
        inputPrefixRef.current.focus();
      }
    }
  };

  const handleZipCodePrefixChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = e.target.value;
    if (/^\d{0,4}$/.test(input)) {
      setZipCodePrefix(input);
    }
  };

  const handleChangeInput = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "province") {
      setProvince(value);
    }
    if (name === "logoInput" && !value) return;
    const file =
      (e.target as HTMLInputElement).files &&
      (e.target as HTMLInputElement).files?.[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Size in MB
      const mimes = ["png", "jpg", "jpeg"];

      // Check file type
      if (!fileType.startsWith("image/")) {
        setErrors({
          image: ["無効なファイルタイプです。画像を選択してください。"],
        });
        return;
      }

      if (!mimes.includes(fileType.split("image/")[1])) {
        setErrors({
          image: [
            "会社ロゴにはjpeg, png, jpgタイプのファイルを指定してください。",
          ],
        });
        return;
      }

      // Check file size
      if (fileSize > 2) {
        setErrors({ image: ["ファイルサイズが2MBの最大制限を超えています。"] });
        return;
      }

      setErrors({});
      setDataBasicInfo({ ...dataBasicInfo, [name]: file, logo: "" });
      return;
    }
    if (name === "zip_code" || name === "zip_code_prefix") {
      let postal_code_data = `${zipCode}${zipCodePrefix}`;
      setDataBasicInfo({ ...dataBasicInfo, postal_code: postal_code_data });
      return;
    }

    setDataBasicInfo({ ...dataBasicInfo, [name]: value });
  };

  const handleSubmitBasicInfo = async () => {
    setIsSubmitting(true)
    let submitAction = request.post;
    let submitUrl = API.BASIC_INFO.EDIT_BASIC_INFO;
    let submitData = {
      ...dataBasicInfo,
      postal_code: zipCode + zipCodePrefix,
      _method: "PATCH",
    };

    let result = await submitAction(
      submitUrl,
      submitData,
      () => navigate(PATH_BASIC_INFO.DEFAULT),
      setErrors,
      { withSuccess: true, withLoading: true, hasFileRequest: true }
    );

    if (result) {
      setErrors({});
      setIsSubmitting(false)
    }
    setIsSubmitting(false)
  };

  const removeImage = () => {
    setDataBasicInfo({
      ...dataBasicInfo,
      logoInput: undefined,
      logo: undefined,
      delete_oz_logo: 1,
    });
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  return (
    <BasicInfoBasePage title="企業情報 ｜企業情報">
      <section className="w-full">
        <div className="text-[14px] font-normal text-left mb-[8px] flex justify-between items-center">
          <p>基本情報</p>
        </div>
        <div className="bg-white rounded-[10px] w-full min-h-[100px] pb-[50px]">
          <div>
            <div>
              {/* errors */}
              {errors && !!Object.keys(errors).length && (
                <div className="mb-[20px]">
                  <ErrorBox errors={errors} />
                </div>
              )}
              <FormItem label="企業名" withRequired>
                <div className="py-[6px]">
                  <input
                    className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0]"
                    name="name"
                    value={dataBasicInfo.name ?? ""}
                    onChange={handleChangeInput}
                    placeholder={"例：パレットエデュケーション株式会社"}
                  />
                </div>
              </FormItem>
              <FormItem label="企業ID*（サブドメイン）" withRequired>
                <div className="py-[6px]">
                  <input
                    className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0]"
                    name="subdomain"
                    value={dataBasicInfo.subdomain ?? ""}
                    onChange={handleChangeInput}
                    placeholder={"例：palette-education"}
                  />
                </div>
                <div className="ml-[2px] px-[10px] py-[20px]">
                  <ul className="text-[8px] text-[#5A5A5A] list-disc">
                    <li>
                      半角英数字および「-」（ハイフン）、「.」（ピリオド／ドット）の記号にて、3字以上10字まで設定することができます。 ※サブドメインに「..」や「--」「.-」を入力しないでください。「スペース（空白）」は使用できません。
                    </li>
                    <li>
                      英字を入力する場合、大文字小文字の区別はありません。すべて小文字で表示されます。
                    </li>
                    <li>先頭文字と後尾文字は英文字にしてください。</li>
                  </ul>
                </div>
              </FormItem>
              <FormItem label="会社ロゴ（推奨サイズ　幅50 x 高さ50px）">
                <div className="flex justify-start bg-white items-center object-cover text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 w-full pr-[22px] pl-[10px] mb-[2px]">
                  <div 
                    className={`${(dataBasicInfo.logo || dataBasicInfo.logoInput) ? "" : "bg-[#F4F4F4]"} flex justify-center items-center w-[40px] h-[40px] max-w-[40px] max-h-[40px]`}
                  >
                    <input
                      type="file"
                      id="image"
                      name="logoInput"
                      accept="image/*"
                      className="hidden"
                      onChange={handleChangeInput}
                      placeholder="ファイルを選択"
                      ref={inputFileRef}
                    />
                    {dataBasicInfo.logo ? (
                      <img
                        src={dataBasicInfo.logo_url}
                        className="object-cover w-full h-full"
                      />
                    ) : dataBasicInfo.logoInput ? (
                      <img
                        src={
                          dataBasicInfo.logoInput
                            ? URL.createObjectURL(dataBasicInfo.logoInput)
                            : LogoDefault
                        }
                        className="object-cover w-full h-full"
                      />
                    ) : (
                      <div className="w-full h-full text-[#7A7A7A] leading-[100%] mt-[10px] pl-[5px]">
                        <p>企業</p>
                        <p>ロゴ</p>
                      </div>
                    )}
                  </div>
                  {dataBasicInfo.logo ? (
                    <>
                      <div className="relative ml-[60px] mr-[15px]">
                        <ImageInternalButton
                          removeImage={removeImage}
                          editImage={() => inputFileRef.current?.click()}
                          editButtonOtherStyle="!top-[-10px] !right-[25px]"
                          removeButtonOtherStyle="!top-[-10px] !right-[0px]"
                        />
                      </div>
                      <p className="max-w-[200px] truncate text-[12px]"></p>
                    </>
                  ) : dataBasicInfo.logoInput ? (
                    <>
                      <div className="relative ml-[60px] mr-[15px]">
                        <ImageInternalButton
                          removeImage={removeImage}
                          editImage={() => inputFileRef.current?.click()}
                          editButtonOtherStyle="!top-[-10px] !right-[25px]"
                          removeButtonOtherStyle="!top-[-10px] !right-[0px]"
                        />
                      </div>
                      <p className="max-w-[200px] truncate text-[12px]">
                        {dataBasicInfo.logoInput.name}
                      </p>
                    </>
                  ) : (
                    <>
                      <button
                        className="bg-[#7E869E] text-white rounded-full mx-[15px]"
                        onClick={() => inputFileRef.current?.click()}
                      >
                        <AiOutlineUpload size={20} className="px-1 py-1" />
                      </button>
                      <p className="text-[10px]">選択されていません</p>
                    </>
                  )}
                </div>
              </FormItem>
              <FormItem label={"郵便番号"}>
                <div className="text-secondary-dark ml-[13px] w-[40%] flex gap-[7px] items-center">
                  <input
                    className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0] max-w-[80px]"
                    name="zip_code"
                    value={zipCode}
                    onChange={handleZipCodeChange}
                    onPaste={handleZipCodePaste}
                  />
                  <div className="w-[12px] bg-secondary-light h-[1px]"></div>
                  <input
                    className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0] max-w-[80px]"
                    name="zip_code_prefix"
                    value={zipCodePrefix}
                    onChange={handleZipCodePrefixChange}
                    onPaste={handleZipCodePaste}
                    ref={inputPrefixRef}
                  />
                  <Button
                    content={"自動入力"}
                    otherstyle={
                      "bg-secondary text-white text-[9px] font-thin !w-[52px] !h-[22px] !ml-[20px] !rounded-md !bg-[#BEBEBE]"
                    }
                    isSubmitting={isSyncingPostal}
                    onClick={syncingZipCode}
                    type={"button"}
                  />
                </div>
              </FormItem>
              <FormItem label={"住所"} labelClass="!items-start !pt-[17px]">
                <div className="mb-[15px] mt-[10px]">
                  <select
                    name="province"
                    id=""
                    className="min-h-[30px] w-[250px] pr-[33px] pl-[7px] !border-[#EFF1F0] appearance-none text-secondary-dark text-[11px] leading-[100%] mb-[10px]"
                    onChange={handleChangeInput}
                    value={province}
                  >
                    {Object.values(JapanPrefectures).map((item, index) => {
                      return (
                        <option
                          value={item}
                          className="!h-[50px]"
                          key={`prefecture-${index}`}
                        >
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  <input
                    type="text"
                    name="ward"
                    onChange={handleChangeInput}
                    className="min-h-[30px] border !border-[#EFF1F0] text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px] mb-[10px]"
                    placeholder="例）港区"
                    value={dataBasicInfo.ward ?? ""}
                    required
                  />
                  <input
                    type="text"
                    name="address1"
                    onChange={handleChangeInput}
                    className="min-h-[30px] border !border-[#EFF1F0] text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px] mb-[10px]"
                    placeholder="例）赤坂1-2-34"
                    value={dataBasicInfo.address1 ?? ""}
                    required
                  />
                  <input
                    type="text"
                    name="address2"
                    onChange={handleChangeInput}
                    className="min-h-[30px] border !border-[#EFF1F0] text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px] mb-[10px]"
                    placeholder="パレットLMSビル3階"
                    value={dataBasicInfo.address2 ?? ""}
                    required
                  />
                </div>
              </FormItem>

              {/* Submit Buttons */}
              <div className="w-full flex justify-center items-center mb-[58px] mt-[66px] gap-[15px]">
                <FormButton
                  className="bg-primary px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[16px] leading-[100%] w-[220px] h-[34px]"
                  onClick={handleSubmitBasicInfo}
                  isSubmitting={isSubmitting}
                  label="内容を保存する"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </BasicInfoBasePage>
  );
};

export default BasicInfoDetailPage;
