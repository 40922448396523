const MESSAGE_ERROR = {
  SOMETHINGS_WENT_WRONG: "エラーが発生しました。",
  PAID_INVOICE_CANNOT_CHANGE_STATUS:
    " 支払済みのステータスになりましたので、振込ステータスを変更できません。",
};

const MESSAGE_COMMON = {
  LOADING: "ロード中",
  INVALID_FILE_TYPE: 'ファイルの拡張子が指定と異なります',
};

const DELETE_CONFIRM_TITLE = {
  COURSE: "このコースを削除してよろしいですか？",
  LECTURE: "この講義を削除してよろしいですか？",
  CHAPTER: "この章を削除してよろしいですか？",
  UNIT: "この教材を削除してよろしいですか？",
  USER: "このアカウントを削除してもよろしいですか？",
  ORGANIZATION: "この企業を削除してもよろしいですか？",
  USER_SELF: "このアカウントを削除しますか？",
};
const DELETE_CONFIRM_CONTENT = {
  COURSE: "コースに含まれる「講義」「単元」「教材」も含めて削除されます",
  LECTURE: "講義に含まれる「単元」「教材」も含めて削除されます",
  CHAPTER: "章に含まれる「教材」も含めて削除されます",
  USER: "削除したアカウントは復元できません",
  ORGANIZATION: "削除した企業は復元できません",
  USER_SELF: "削除されたアカウントは復旧できません。",
};

const REGISTER_ADMIN = {
  TOKEN_REGISTER_EXPIRED:
    "トークンの有効期限が切れています。 再度登録手順を行ってください。", //Token has expired. Please follow the registration steps again.
};

const CONFIRM_INVOICE_PAYMENT_TITLE =
  " 振り込みステータスを変更すると元に戻せません。よろしいですか？";
const CONFIRM_INVOICE_PAYMENT_SUCCESS = "振込ステータスを更新しました。";

export {
  MESSAGE_ERROR,
  DELETE_CONFIRM_TITLE,
  DELETE_CONFIRM_CONTENT,
  MESSAGE_COMMON,
  REGISTER_ADMIN,
  CONFIRM_INVOICE_PAYMENT_TITLE,
  CONFIRM_INVOICE_PAYMENT_SUCCESS,
};
