import React from "react";

const Monitor = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 2.6C1.25 1.85441 1.85442 1.25 2.6 1.25H21.4C22.1456 1.25 22.75 1.85442 22.75 2.6V17.4C22.75 18.1456 22.1456 18.75 21.4 18.75H14.25L14.25 21.25H15C15.4142 21.25 15.75 21.5858 15.75 22C15.75 22.4142 15.4142 22.75 15 22.75H9C8.58579 22.75 8.25 22.4142 8.25 22C8.25 21.5858 8.58579 21.25 9 21.25H9.75V18.75H2.6C1.85442 18.75 1.25 18.1456 1.25 17.4V2.6ZM11.25 18.75V21.25H12.75L12.75 18.75H11.25ZM13.4988 17.25H2.75V16.25H21.25V17.25H13.5012C13.5008 17.25 13.5004 17.25 13.5 17.25C13.4996 17.25 13.4992 17.25 13.4988 17.25ZM21.25 14.75V2.75H2.75V14.75H21.25Z"
        fill={props.color || "#BEBEBE"}
      />
    </svg>
  );
};

export default Monitor;
