import React from "react";
import CompanyBase from ".";
import InvoiceForm from "../../../components/user/Company/Invoice/Form";

const InvoiceSettingPage = () => {
    return (
        <CompanyBase title="販売決済方法 ｜請求書設定">
            <InvoiceForm/>
        </CompanyBase>
    );
};

export default InvoiceSettingPage;
