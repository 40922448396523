import TabCard from "../../../components/user/commons/TabCard";
import UserLayout from "../../../layouts/user";
import { ROUTE } from "../../../services/constants/route/router";
import { useTabFinder } from "../../../hooks/useTabFinder";
import { SERVICE_TAB_DATA } from "../../../services/constants/user/pages/service";
import { WithChildren } from "../../../types/globals";

type ServiceMainProp = {
  title?: string;
};

const ServiceBase = ({ title, children }: WithChildren<ServiceMainProp>) => {
  const { tabIndex } = useTabFinder(
    Object.values({ SERVICE_LIST: ROUTE.SERVICE })
  );

  return (
    <UserLayout>
      <TabCard tabData={SERVICE_TAB_DATA} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </UserLayout>
  );
};

export default ServiceBase;
