import ScrollToTop from '../../components/commons/etcs/ScrollToTop';
import { ChildrenWithProps } from '../../types/globals';
import SuperAdminHeader from './Header';
import SuperAdminSidebar from './Sidebar';
import RequiredAuthSuperAdmin from '../../middleware/authSuperAdmin';

const SuperAdminLayout = ({
  children,
  gap = false,
  ...props
}: { gap?: boolean } & ChildrenWithProps) => {
  return (
    <>
      <ScrollToTop />
      <RequiredAuthSuperAdmin>
        <section className="min-h-screen" {...props}>
          <SuperAdminHeader />
          <div className="w-full min-h-screen flex bg-warning-extralight">
            <div className="w-full flex justify-between">
              <div className="flex w-full items-start">
                <SuperAdminSidebar />
                <div className="w-full px-[32px] pt-[30px] overflow-x-hidden">{children}</div>
              </div>
            </div>
          </div>
        </section>
      </RequiredAuthSuperAdmin>
    </>
  );
};

export default SuperAdminLayout;
