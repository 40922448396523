import React from "react";
import {
  USER_MENU_BORDER_INDEX,
  USER_MENU_DATA,
} from "../../services/constants/user/menu";
import ItemSelectHeader from "../../components/Header/ItemSelectHeader";

const Menu = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
) => {
  return (
    <div
      {...props}
      className={`w-[220px] bg-primary text-white px-[10px] py-[33px] rounded-[5px] ${props.className}`}
    >
      {USER_MENU_DATA.map((item, index) => (
        <div
          key={index}
          className={`mb-[15px] cursor-pointer ${
            USER_MENU_BORDER_INDEX.includes(index) &&
            "pb-[15px] border-b border-white"
          }`}
        >
          <ItemSelectHeader
            key={index}
            label={item.label}
            href={item.href}
            icon={item.icon}
          />
        </div>
      ))}
    </div>
  );
};

export default Menu;
