const PAYMENT_METHOD_TYPE = {
  CARD: {
    VALUE: 'card',
    LABEL: 'クレジットカード',
  },
  KONBINI: {
    VALUE: 'konbini',
    LABEL: 'コンビニ',
  },
};

const CART_COMMON = {
  CART_TITLE: 'カート',
  CART_BUY_LATER: 0,
  CART_IN_SHOP: 1,
};

const TRANSACTION_STATUS = {
  UNPAID: 0, 
  PAID: 1,
}

export { PAYMENT_METHOD_TYPE, CART_COMMON, TRANSACTION_STATUS };
