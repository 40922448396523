import React, { useEffect, useState } from 'react';
import ServiceListItem from './ListItem';
import { request } from '../../../../services/axios/axios';
import { API } from '../../../../services/constants/route/api';
import { sortArrayByKey } from '../../../../services/helpers/parseData';
import { Service } from '../../../../types/user/service';

const ServiceList = () => {
  const [services, setServices] = useState<Service[]>([])

  useEffect(() => {
    const fetchServices = async () => {
      await request.get(API.SERVICE.LIST, (serviceList) => {
        setServices(serviceList || []);
      });
    };
    fetchServices();
  }, []);

  return (
    <div className="flex justify-center items-center">
      <div className="grid grid-cols-2 w-full gap-x-[30px] gap-y-[20px]">
        {services.length ? (
          sortArrayByKey(services, 'type')
            .reverse()
            .map((service, index) => <ServiceListItem serviceInfo={service} key={index} />)
        ) : (
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <div className="skeleton w-full rounded-[10px] h-[200px] mt-[5px]" key={index}></div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ServiceList;
