import React, { FC } from 'react';
import { Toaster } from "react-hot-toast";
import CheckSubDomain from "../middleware/checkSubDomain";

interface LayoutProps {
  children?: JSX.Element;
}

const LayoutLandingPage: FC<LayoutProps> = (props) => {
  return (
    <CheckSubDomain>
      <div className="w-full h-full bg-[#FEFCF9]">
        <Toaster position="top-right"
                 reverseOrder={false}
                 gutter={8}
        />
        <div className="h-[5px] w-full bg-primary"></div>
        {props.children}
      </div>
    </CheckSubDomain>
  );
}

export default LayoutLandingPage;
