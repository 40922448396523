const AUTH_CONSTANT = {
  // Start action const
  ROLE: {
    SUPER_ADMIN: 1,
    ADMIN: 2,
    STAFF: 3,
    STUDENT: 4,
  },
};

export { AUTH_CONSTANT };
