import React, { useEffect, useRef, useState } from "react";

import Button from "../../Login/Button";
import { useStepProvider } from "../../../context/StepsContext";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { swalMessage, swalSuccess } from "../../../services/helpers/swal";
import { ROUTE } from "../../../services/constants/route/router";
import { useNavigate } from "react-router-dom";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";
import { ErrorData } from "../../../types/globals";
import ErrorBox from "../../commons/form/ErrorBox";
import { REGISTER_ADMIN } from "../../../services/constants/message";
import { useJwt } from "react-jwt";
import { OrganizationRegisterDataType } from "../../../types/register/organizationRegister";
import ImageInternalButton from "../../commons/buttons/ImageInternalButton";
import LogoDefault from "../../../assests/images/default_avatar.webp"
import { AiOutlineUpload } from "react-icons/ai";

const CreateAdminForm = () => {
    const { getParamValue } = useObjectRoutes();
    let accessToken = getParamValue("token");
    let email = getParamValue("email");
    const { decodedToken } = useJwt(accessToken as string);
    const [dataRegister, setDataRegister] = useState<OrganizationRegisterDataType>({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const inputFileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        // @ts-ignore
        if (decodedToken && decodedToken.subdomain && decodedToken.oz_name) {
            // @ts-ignore
            setDataRegister({ ...dataRegister, subdomain: decodedToken.subdomain, name: decodedToken.oz_name });
        }
    }, [decodedToken]);
    const [errors, setErrors] = useState<ErrorData>({});
    let navigate = useNavigate();
    const { data } = useStepProvider();

    const handleChangeInput = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
            | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        if(name === 'avatar' && !value) return;
        const file =
            (e.target as HTMLInputElement).files &&
            (e.target as HTMLInputElement).files?.[0];
        if (file) {
            const fileType = file.type;
            const fileSize = file.size / 1024 / 1024; // Size in MB
            const mimes = ['png', 'jpg', 'jpeg'];
            
            // Check file type
            if (!fileType.startsWith("image/")) {
                setErrors({ image: ["無効なファイルタイプです。画像を選択してください。"]});
                return;
            }

            if(!mimes.includes(fileType.split('image/')[1])) {
                setErrors({ image: ["会社ロゴにはjpeg, png, jpgタイプのファイルを指定してください。"]});
                return;
            }
      
            // Check file size
            if (fileSize > 2) {
                setErrors({ image: ['ファイルサイズが2MBの最大制限を超えています。']})
                return;
            }
      
            setErrors({});
            setDataRegister({ ...dataRegister, [name]: file });
            return;
        }

        setDataRegister({ ...dataRegister, [name]: value });
    };

    const handleSubmitPassword = async () => {
        setIsSubmitting(true);
        const dataOz = { ...data.dataRegister, ...dataRegister };
        let finalData = dataOz;

        // @ts-ignore
        if (decodedToken && decodedToken.subdomain && decodedToken.subdomain.length > 0) {
            finalData = { ...dataOz, isAdminCreate: true };
        }

        const result = await makeRequest({
            method: "post",
            url: API.ADMIN_REGISTER.REGISTER_ADMIN_ORGANIZATION,
            data: { "token": accessToken, ...finalData },
            hasFileRequest: true,
        });
        if (!result.status) {
            setIsSubmitting(false);
            if (result.message === REGISTER_ADMIN.TOKEN_REGISTER_EXPIRED) {
                swalMessage('エラー', result.message, 'error', {
                    timer: 2000
                })
                navigate(`/${ROUTE.ADMIN_REGISTER.DEFAULT}/${ROUTE.ADMIN_REGISTER.SEND_REGISTER_EMAIL}`, { replace: true })
            }
            setErrors(result.error as ErrorData);
            return;
        }
        setIsSubmitting(false);
        swalSuccess();
        navigate(`${ROUTE.LOGIN}`);
    };

    const removeImage = () => {
        setDataRegister({ ...dataRegister, avatar: undefined });
        if (inputFileRef.current) {
            inputFileRef.current.value = '';
        }
    }

    return (
        <div className="mb-[45px] mt-[30px] flex flex-col items-center overflow-auto max-h-[730px]">
            <p className="mb-[20px] text-[20px] font-normal">
                新規管理者作成（無料）
            </p>
            {errors && Object.keys(errors).length > 0 && (<ErrorBox errors={errors} />)}
            <div className="md:w-[550px] sm:w-[450px] w-[370px] mb-[25px] px-[25px] py-[30px] bg-secondary-morelighter overflow-auto max-h-[500px] rounded-[10px]">
                <div className="mb-[20px] mt-[10px]">
                    <p className="text-[14px] font-normal text-left mb-[8px]">
                        メールアドレス
                    </p>
                    <label>{(email as string).replace(' ', '+')}</label>
                </div>
                <div className="mb-[20px] mt-[10px]">
                    <p className="text-[14px] font-normal text-left mb-[8px]">
                        ログインID<span className="text-danger">*</span>
                        （半角英数のみ８〜20）
                    </p>
                    <input
                        type="text"
                        name="username"
                        onChange={handleChangeInput}
                        className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
                        placeholder="loginname"
                        required
                    />
                </div>
                <div className="mb-[10px] mt-[10px]">
                    <p className="text-[14px] font-normal text-left mb-[8px]">
                        プロフィール画像（推奨サイズ　幅25 x 高さ25px）
                    </p>
                    <div className="flex justify-start bg-white items-center object-cover border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 w-full pr-[22px] pl-[10px] py-[5px]">
                        <div className="bg-[#F4F4F4] flex justify-center items-center max-w-[40px] max-h-[40px]">
                            <input
                                type="file"
                                id="image"
                                name="avatar"
                                accept="image/*"
                                className="hidden"
                                onChange={handleChangeInput}
                                placeholder="選択されていません"
                                ref={inputFileRef}
                            />
                            <img 
                                src={dataRegister.avatar? URL.createObjectURL(dataRegister.avatar) : LogoDefault} 
                                className="object-cover w-full h-full"
                            />
                        </div>
                        {dataRegister.avatar ?
                            <>
                                <div className="relative ml-[60px] mr-[15px]">
                                    <ImageInternalButton 
                                        removeImage={removeImage}
                                        editImage={() => inputFileRef.current?.click()}
                                        editButtonOtherStyle="!top-[-10px] !right-[25px]"
                                        removeButtonOtherStyle="!top-[-10px] !right-[0px]"
                                    />
                                </div>
                                <p className="max-w-[200px] truncate text-[12px]">{dataRegister.avatar.name}</p>
                            </> : 
                            <>
                                <button 
                                className="bg-[#7E869E] text-white rounded-full mx-[15px]"
                                onClick={() => inputFileRef.current?.click()}
                                >
                                    <AiOutlineUpload size={20} className="px-1 py-1"/>
                                </button>
                                <p className="text-[10px]">選択されていません</p>
                            </>
                        }
                    </div>
                </div>
                <div className="mb-[10px] mt-[10px]">
                    <p className="text-[14px] font-normal text-left mb-[8px]">
                        氏名<span className="text-danger">*</span>
                    </p>
                    <div className="flex items-center w-full">
                        <input
                            type="text"
                            name="last_name"
                            onChange={handleChangeInput}
                            className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block px-[22px] py-[14px] w-[250px] mr-[20px]"
                            placeholder="鈴木"
                        />
                        <input
                            type="text"
                            name="first_name"
                            onChange={handleChangeInput}
                            className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block px-[22px] py-[14px] w-[250px]"
                            placeholder="一郎"
                        />
                    </div>
                </div>
                <div className="mb-[10px] mt-[10px]">
                    <p className="text-[14px] font-normal text-left mb-[8px]">
                        氏名（カナ）
                    </p>
                    <div className="flex items-center">
                        <input
                            type="text"
                            name="last_name_kana"
                            onChange={handleChangeInput}
                            className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block px-[22px] py-[14px] w-[250px] mr-[20px]"
                            placeholder="スズキ"
                        />
                        <input
                            type="text"
                            name="first_name_kana"
                            onChange={handleChangeInput}
                            className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block px-[22px] py-[14px] w-[250px]"
                            placeholder="イチロウ"
                        />
                    </div>
                </div>
                <div className="mb-[10px] mt-[10px]">
                    <p className="text-[14px] font-normal text-left mb-[8px]">電話番号</p>
                    <div className="flex items-center">
                        <input
                            type="text"
                            name="tel"
                            onChange={handleChangeInput}
                            className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
                            placeholder=""
                        />
                    </div>
                </div>
                <div className="mb-[30px] mt-[10px]">
                    <p className="text-[14px] font-normal text-left mb-[8px]">部署名</p>
                    <div className="flex items-center">
                        <input
                            type="text"
                            name="department"
                            onChange={handleChangeInput}
                            className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px]"
                            placeholder=""
                        />
                    </div>
                </div>
            </div>
            <Button
                content={"完了する"}
                otherstyle={"bg-primary text-white"}
                type={"button"}
                onClick={handleSubmitPassword}
                isSubmitting={isSubmitting}
            />
        </div>
    );
};

export default CreateAdminForm;
