import { PropsWithChildren } from "react";

type FormBlockProps = {
    title: string;
    className?: string;
};

const FormBlock = ({
                       title,
                       children,
                   }: FormBlockProps & PropsWithChildren) => {
    return (
        <div className="text-[14px] font-[500] pb-[20px]">
            <p>{title}</p>
            {children}
        </div>
    );
};

export default FormBlock;
