import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import StoragePlanForm from './StoragePlanForm';
import { Contract, ContractSubscription } from '../../../../../types/user/contract';
import {
  CONTRACT_SUBSCRIPTION_PERIOD,
  SUBSCRIPTION_STEP,
} from '../../../../../services/constants/user/pages/contract';
import StorageSubscriptionConfirmation from './Confirmation';

type StorageSubscriptionProps = {
  subscription?: ContractSubscription;
  subscribedPriceList?: Contract[];
  latestPriceList?: Contract[];
  setTitle: Dispatch<SetStateAction<string>>;
  isCancelSubscription?: number;
};

const StorageContractSubscription = ({ ...props }: StorageSubscriptionProps) => {
  const [step, setStep] = useState(SUBSCRIPTION_STEP.FORM_FILL);
  const [formData, setFormData] = useState<ContractSubscription>(props?.subscription || {});
  const [originSubscribed, setOriginSubscribed] = useState<ContractSubscription>(
    props?.subscription || {},
  );

  useEffect(() => {
    let initialFormEssential: typeof formData = {};
    if (props?.latestPriceList?.length && !props?.subscription?.id) {
      initialFormEssential = {
        product_quantity: 1,
        period_type: CONTRACT_SUBSCRIPTION_PERIOD.MONTHLY,
        billed_amount: props?.latestPriceList[0]?.price_monthly,
      };
    }

    setFormData({ ...props?.subscription, ...initialFormEssential });
    setOriginSubscribed(props?.subscription || {});
  }, [props?.subscription]);

  useEffect(() => {
    if(props?.isCancelSubscription) {
      setStep(SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE)
    }
  }, [props?.isCancelSubscription])

  switch (step) {
    case SUBSCRIPTION_STEP.FORM_FILL:
      return (
        <StoragePlanForm
          setStep={setStep}
          formData={formData}
          originSubscribed={originSubscribed}
          setFormData={setFormData}
          latestPriceList={props.latestPriceList}
          {...props}
        />
      );
    case SUBSCRIPTION_STEP.CONFIRMATION:
    case SUBSCRIPTION_STEP.CONFIRMATION_CHANGE:
    case SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE:
      return (
        <StorageSubscriptionConfirmation
          formData={
            step === SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE ? originSubscribed : formData
          }
          setStep={setStep}
          originSubscribed={originSubscribed}
          step={step}
          latestPriceList={props.latestPriceList}
          {...props}
        />
      );
    default:
      return <></>;
  }
};

export default StorageContractSubscription;
