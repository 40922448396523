import AccountBase from ".";
import AccountList from "../../../components/user/Account/List/List";

const AccountListPage = () => {
  return (
    <AccountBase title="アカウント管理 ｜アカウント一覧">
      <AccountList />
    </AccountBase>
  );
};

export default AccountListPage;
