const BackgroundDefault = () => {
  return (
    <div className="w-full h-full bg-white relative overflow-hidden">
      <div 
      className="bg-[#D6812C] h-[219px] w-[219px] rounded-t-[100%] rounded-b-[0%] rounded-l-[0%] rounded-r-[100%] absolute bottom-0 opacity-90"
      style={{borderRadius: "0px 200px 0px 0px"}}
      ></div>
      <div className="bg-[#86B45F] h-[195px] w-[195px] rounded-br-[40%] absolute bottom-[20%] right-[0%] opacity-90"></div>
      <div className="bg-[#8D7DB4] h-[200px] w-[100px] rounded-tl-[20%] absolute bottom-[5%] right-[0%] opacity-70"></div>
      <div className="bg-[#D1AE37] h-[222px] w-[222px] rounded-br-[30%] left-[5%] top-[-13%] absolute opacity-90"></div>
      <div className="bg-[#EBE4D8] h-[115px] w-[115px] absolute top-[8%] opacity-90"></div>
      <div className="bg-[#6C9AD2] h-[300px] w-[167px] absolute right-0 opacity-90"></div>
      {/* <div className="w-full h-full">
        <div className="bg-red-400 w-[375px] h-[167px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
      </div> */}
    </div>
  );
};

export default BackgroundDefault;
