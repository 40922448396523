import React from 'react';
import "../../../assests/styles/modal.css"

type BaseModalProps = {
  children: React.ReactNode;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClick?: () => void;
  submitLabel?: string;
  withSubmit?: boolean;
  justifyDirection?: string;
  width?: number;
  height?: number;
};

const BaseModal = ({
  children,
  setIsOpen,
  onClick,
  submitLabel = '新規作成',
  withSubmit = true,
  justifyDirection = 'center',
  width = 1010,
  height = 616,
}: BaseModalProps) => {
  return (
    <section className='z-[1000]'>
      <div className="dark_bg z-[1000]" onClick={() => setIsOpen(false)} />
      <div className="centered z-[1000]">
        <div className="modal z-[1000]">
          <div
            className="text-secondary-dark overflow-y-auto"
            style={{ width: `${width}px`, height: `${height}px` }}
          >
            {children}
            {withSubmit && (
              <div
                className={`flex gap-[10px] justify-${justifyDirection} w-full bg-secondary-extralight items-center h-[80px] absolute bottom-0 rounded-b-[10px] px-[65px]`}
              >
                <button
                  type='button'
                  className="bg-white text-secondary text-[14px] leading-[20px] w-fit px-[24px] py-[12px] rounded-[5px]"
                  onClick={() => setIsOpen(false)}
                >
                  キャンセル
                </button>
                <button
                  type='button'
                  className="bg-danger text-white text-[14px] leading-[20px] w-full max-w-[150px] h-[44px] rounded-[5px] verify_email_btn"
                  onClick={onClick}
                >
                  {submitLabel}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BaseModal;
