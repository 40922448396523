import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { ChildrenWithProps } from "../types/globals";


interface LayoutContextType {
  sidebarToggle: boolean;
  setSidebarToggle: Dispatch<SetStateAction<boolean>>;
}

export const LayoutContext = React.createContext<LayoutContextType>({
  sidebarToggle: true,
  setSidebarToggle: () => { },
});

const LayoutContextProvider = ({ children }: ChildrenWithProps) => {
  const [sidebarToggle, setSidebarToggle] = useState<boolean>(true);

  return (
    <LayoutContext.Provider value={{ sidebarToggle, setSidebarToggle }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;

export const useLayoutContext = () => useContext(LayoutContext);
