import { Contract, ContractSubscription } from '../../../../types/user/contract';
import {
  CONTRACT_SUBSCRIPTION_STATUS,
  CONTRACT_SUBSCRIPTION_TYPE,
  SUBSCRIPTION_ADDITION_TYPES,
} from '../../../../services/constants/user/pages/contract';
import {
  getContractDescriptionByType,
  getContractNameByType,
  getExpiredBasicSubscriptionLabel,
  getSubscriptionFormFillPath,
  getSubscriptionPeriodLabel,
  getSubscriptionTooltip,
} from '../../../../services/utils/contract';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import { formatMoney } from '../../../../services/helpers/parseData';
import { formatDateTime } from '../../../../services/helpers/formatTime';
import StatusContractOverviewMainTable from './StatusContractOverviewMainTable';
import TooltipDisplay from './TooltipDisplay';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useState } from 'react';

type StatusContractOverviewTablesProps = {
  subscribeds: ContractSubscription[];
  priceListSubscriptions: Contract[];
  featureId: ContractSubscription['feature_id'];
};

type DisplayTooltipStatus = {
  type?: number;
  status?: boolean;
};

export const renderContractStatus = (status?: number) => {
  switch (status) {
    case CONTRACT_SUBSCRIPTION_STATUS.INUSE_INTERVAL:
      return (
        <div className="text-white bg-primary-light w-fit px-[16px] pt-[1px] h-[20px] rounded-[5px] text-[12px]">
          契約中
        </div>
      );

    case CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD:
      return (
        <div className="text-danger border border-danger w-fit px-[10px] pt-[2px] h-[25px] rounded-[5px] text-[12px]">
          解約予定
        </div>
      );

    default:
      return (
        <div className="text-white bg-secondary-light w-fit px-[16px] pt-[1px] h-[20px] rounded-[5px] text-[12px]">
          未契約
        </div>
      );
  }
};

const StatusContractOverviewTables = ({
  subscribeds,
  priceListSubscriptions,
  featureId,
}: StatusContractOverviewTablesProps) => {
  const { navigate } = useObjectRoutes();

  const basicSubscribed =
    subscribeds.find(
      (subscribed) => subscribed.contract_type === CONTRACT_SUBSCRIPTION_TYPE.BASIC,
    ) || {};

  const basicPriceListSubscriptions = priceListSubscriptions.filter(
    (price) => price.type === CONTRACT_SUBSCRIPTION_TYPE.BASIC,
  );

  const additionPriceListSubscriptions = priceListSubscriptions.filter(
    (price) => price.type && SUBSCRIPTION_ADDITION_TYPES.includes(price.type),
  );

  const additionHeaderClasses =
    'text-[13px] font-[600] leading-[120%] border border-white bg-success-extralight flex items-center justify-center';

  const additionBodyClasses =
    'min-h-[50px] text-[14px] font-[500] leading-[120%] flex items-center justify-center py-[5px] text-center';

  const currentAdditionSubscribed = (additionPrice?: Contract) =>
    subscribeds.find((subscribed) => subscribed.contract_type === additionPrice?.type);

  const [displayTooltipStatus, setDisplayTooltipStatus] = useState<DisplayTooltipStatus>({});

  return (
    <div>
      <StatusContractOverviewMainTable
        subscribed={basicSubscribed}
        priceListSubscriptions={basicPriceListSubscriptions}
        featureId={featureId}
        tableTitle="アカウント追加契約"
        type={CONTRACT_SUBSCRIPTION_TYPE.BASIC}
      />

      <div className="overflow-x-auto pt-[70px] mt-[-70px]">
        {/* Addition contracts table */}
        <div className="mb-[5px] text-[14px] font-[500] leading-[100%]">追加機能契約</div>
        <div className="text-secondary-dark max-xl:w-[1250px] 2xl:w-full">
          {/* Addition contract header table */}
          <div className="flex h-[30px]">
            <div className={`${additionHeaderClasses} w-[20%]`}>サービス</div>
            <div className={`${additionHeaderClasses} w-[13%]`}>内容</div>
            <div className={`${additionHeaderClasses} w-[6%]`}>月額払い</div>
            <div className={`${additionHeaderClasses} w-[4%]`}>数量</div>
            <div className={`${additionHeaderClasses} w-[9%]`}>ステータス</div>
            <div className={`${additionHeaderClasses} w-[5%]`}>契約</div>
            <div className={`${additionHeaderClasses} w-[9%]`}>契約開始日</div>
            <div className={`${additionHeaderClasses} w-[12%]`}>終了日</div>
            <div className={`${additionHeaderClasses} w-[12%]`}>確認/変更</div>
            <div className={`${additionHeaderClasses} w-[10%]`}>有料契約の解除</div>
          </div>

          {/* Addition contract body table */}
          <div className="">
            {additionPriceListSubscriptions.map((additionPrice, index) => (
              <div
                className="flex w-full h-full bg-[#FBFDFC] items-center border-b border-success-extralight"
                key={index}
              >
                <div className={`${additionBodyClasses} w-[20%] !justify-between px-[1%] relative`}>
                  <div className="text-primary">
                    {getContractNameByType(additionPrice.type, featureId)}
                  </div>
                  {displayTooltipStatus?.type === additionPrice.type &&
                    !!displayTooltipStatus?.status && (
                      <div className="">
                        <TooltipDisplay
                          content={getSubscriptionTooltip(Number(additionPrice.type))}
                          className="absolute top-[-100px] left-[138px]"
                        />
                      </div>
                    )}
                  <IoMdInformationCircleOutline
                    size={22}
                    className="cursor-pointer text-secondary"
                    onMouseEnter={() =>
                      setDisplayTooltipStatus({ type: additionPrice.type, status: true })
                    }
                    onMouseLeave={() =>
                      setDisplayTooltipStatus({ type: additionPrice.type, status: false })
                    }
                  />
                </div>
                <div className={`${additionBodyClasses} w-[13%] break-keep`}>
                  {getContractDescriptionByType(additionPrice.type)}
                </div>
                <div className={`${additionBodyClasses} w-[6%] `}>
                  {formatMoney(additionPrice.price_monthly)}
                </div>
                <div className={`${additionBodyClasses} w-[4%]`}>
                  {currentAdditionSubscribed(additionPrice)?.product_quantity || 'ー'}
                </div>
                <div className={`${additionBodyClasses} w-[9%]`}>
                  {renderContractStatus(currentAdditionSubscribed(additionPrice)?.status)}
                </div>
                <div className={`${additionBodyClasses} w-[5%]`}>
                  {getSubscriptionPeriodLabel(
                    currentAdditionSubscribed(additionPrice)?.period_type,
                  )}
                </div>
                <div className={`${additionBodyClasses} w-[9%]`}>
                  {formatDateTime(currentAdditionSubscribed(additionPrice)?.available_at)}
                </div>
                <div className={`${additionBodyClasses} w-[12%] break-keep text-center`}>
                  {currentAdditionSubscribed(additionPrice)?.period_end_at
                    ? `${formatDateTime(
                        currentAdditionSubscribed(additionPrice)?.period_end_at,
                      )}\n${getExpiredBasicSubscriptionLabel(
                        currentAdditionSubscribed(additionPrice),
                        additionPrice.storage_capacity,
                      )}`
                    : 'ー'}
                </div>
                <div className={`${additionBodyClasses} w-[12%]`}>
                  <button
                    className="cursor-pointer w-fit bg-danger text-white rounded-[5px] px-[10px] pt-[4px] pb-[5px] text-[12px]"
                    onClick={() => {
                      // For coming soon...
                      // if (currentAdditionSubscribed(additionPrice)?.id) {
                      //   alert('Coming soon...');
                      //   return;
                      // }

                      navigate(
                        getSubscriptionFormFillPath(
                          currentAdditionSubscribed(additionPrice)?.id,
                          additionPrice.type,
                          currentAdditionSubscribed(additionPrice)?.feature_id || featureId,
                        ),
                      );
                    }}
                  >
                    {currentAdditionSubscribed(additionPrice)?.id ? 'プラン変更' : 'プラン登録'}
                  </button>
                </div>
                <div className={`${additionBodyClasses} w-[10%] flex items-center justify-center !text-[10px]`}>
                  { ( !currentAdditionSubscribed(additionPrice)?.id || 
                      ( currentAdditionSubscribed(additionPrice)?.status === CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD && 
                        ( !currentAdditionSubscribed(additionPrice)?.in_scheduled_subscribed || 
                          currentAdditionSubscribed(additionPrice)?.in_scheduled_subscribed?.status === CONTRACT_SUBSCRIPTION_STATUS.CANCELLED
                        )
                      ) 
                    ) ? <>ー</> :
                      <button
                        className={`cursor-pointer w-[80%] ${
                          ( !currentAdditionSubscribed(additionPrice)?.id || 
                            ( currentAdditionSubscribed(additionPrice)?.status === CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD && 
                              ( !currentAdditionSubscribed(additionPrice)?.in_scheduled_subscribed || 
                                currentAdditionSubscribed(additionPrice)?.in_scheduled_subscribed?.status === CONTRACT_SUBSCRIPTION_STATUS.CANCELLED
                              )
                            ) 
                          ) ? 
                          'bg-secondary-light pointer-events-none text-white' : 
                          'bg-white'
                        } text-gray-500 rounded-[5px] px-[10px] pt-[4px] pb-[5px] border-[2px]`}
                        onClick={() => {
                          navigate(
                            getSubscriptionFormFillPath(
                              currentAdditionSubscribed(additionPrice)?.id,
                              additionPrice.type,
                              currentAdditionSubscribed(additionPrice)?.feature_id || featureId,
                              1
                            ),
                          );
                        }}
                        disabled={
                          ( !currentAdditionSubscribed(additionPrice)?.id || 
                            ( currentAdditionSubscribed(additionPrice)?.status === CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD && 
                              ( !currentAdditionSubscribed(additionPrice)?.in_scheduled_subscribed || 
                                currentAdditionSubscribed(additionPrice)?.in_scheduled_subscribed?.status === CONTRACT_SUBSCRIPTION_STATUS.CANCELLED
                              ) 
                            ) 
                          ) ? 
                          true : false
                        }
                      >
                        自動更新を解除する
                      </button>
                  }
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusContractOverviewTables;
