const DEFAULT_PREFIX_ROUTE = {
  USER: '/user',
  SUPER_ADMIN: '/superadmin',
  REGISTER: '/register',
  SERVICE: '/service',
  PAYMENT_SETTING: '/payment-setting',
  CONTRACT_SUBSCRIPTION: '/contract-subscription',
  STORAGE_PLAN_SUBSCRIPTION: '/storage-plan-subscription',
  PAYMENT: '/payment',
  ORGANIZATION: '/organization',
  CONTRACT_SETTING: '/contract-setting',
  INVOICE_SETTING: '/invoice-setting',
  CONTRACT_USED_AMOUNT: '/contract-used-amount',
  LINK_ADDRESS: '/link-address',
  REVENUE: '/revenue',
  BASIC_INFO: '/oz-info',
  RECEIPT_SETTING: '/receipt-setting',
  RECEIPT_HISTORY: '/receipt-history',
};

const API = {
  AUTH: {
    VERIFY_DOMAIN: '/verify-domain',
    REGISTER: '/register',
    LOGIN: '/login',
    LOGOUT: '/logout',
    SEND_EMAIL_FORGOT_PASSWORD: '/send-email-forgot-password',
    SOCIAL_LOGIN: '/social-login',
    CHANGE_PWD: '/update-pwd',
    VERIFY_EMAIL: '/verify-email',
    VERIFY_EMAIL_REGISTER: '/verify-email-after-create',
    RESEND_VERIFY_EMAIL: '/resend-verify-email',
    GET_APP_KEY: '/get-app-key',
    ROLE: '/role',
    VERIFY_ADMIN: '/verify-admin'
  },
  USER: {
    INFO: '/user',
    DETAIL_INFO: '/user-detail',
    UPDATE_PROFILE: '/update-profile'
  },

  ADMIN_REGISTER: {
    SEND_REGISTER_EMAIL: `${DEFAULT_PREFIX_ROUTE.REGISTER}/send-email-register`,
    RESEND_REGISTER_EMAIL: `${DEFAULT_PREFIX_ROUTE.REGISTER}/resend-email-register`,
    VERIFY_REGISTER_EMAIL: `${DEFAULT_PREFIX_ROUTE.REGISTER}/verify-email-after-register`,
    REGISTER_ADMIN_ORGANIZATION: `${DEFAULT_PREFIX_ROUTE.REGISTER}/register-admin-organization`,
    VALIDATE_REGISTER_ORGANIZATION_DATA: `${DEFAULT_PREFIX_ROUTE.REGISTER}/validate-data-register-organization`,
    VALIDATE_REGISTER_ORGANIZATION_PWD: `${DEFAULT_PREFIX_ROUTE.REGISTER}/validate-pwd-register-organization`,
  },

  SUPER_ADMIN: {
    AUTH: {
      LOGIN: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}/login`,
      SEND_EMAIL_FORGOT_PASSWORD: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}/send-email-forgot-password`,
      CHANGE_PWD: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}/update-pwd`,
      VERIFY_EMAIL_REGISTER: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}/verify-email-after-create`,
      RESEND_VERIFY_EMAIL: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}/resend-verify-email`,
      USER_INFO: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}/user`,
      LOGOUT: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}/logout`,
    },
    ACCOUNT: {
      LIST: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.USER}/get-list-user`,
      DELETE_MANY: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.USER}/delete-many-user`,
      CREATE: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.USER}/create-user`,
      DETAIL: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.USER}/get_user_detail`,
      UPDATE: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.USER}/update-user`,
      DELETE: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.USER}/delete-user`,
    },
    ORGANIZATION: {
      LIST: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.ORGANIZATION}/list-organization`,
      DETAIL: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.ORGANIZATION}/organization-detail`,
      CREATE: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.ORGANIZATION}/create-organization`,
      UPDATE: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.ORGANIZATION}/update-organization`,
      DELETE: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.ORGANIZATION}/delete-organization`,
      DELETE_MANY: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.ORGANIZATION}/delete-many-organizations`,
      VERIFY_EMAIL_CREATE: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.ORGANIZATION}/verify-email-create-oz`,
      RESEND_VERIFY_EMAIL_CREATE: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.ORGANIZATION}/resend-verify-email-create-oz`,
    },
    LINK_ADDRESS: {
      DETAIL_FOR_REGISTER: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.LINK_ADDRESS}/detail-register`,
      DETAIL: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.LINK_ADDRESS}/detail`,
      CREATE: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.LINK_ADDRESS}/create`,
      UPDATE: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.LINK_ADDRESS}/update`,
    },
    CONTRACT_SETTING: {
      DETAIL: `${DEFAULT_PREFIX_ROUTE.SUPER_ADMIN}${DEFAULT_PREFIX_ROUTE.CONTRACT_SETTING}/detail`,
    },
  },

  SERVICE: {
    LIST: `${DEFAULT_PREFIX_ROUTE.SERVICE}/get-list-service-type`,
    LIST_All: `${DEFAULT_PREFIX_ROUTE.SERVICE}/get-all-list-service-type`,
    LIST_SERVICE_OF_USER: `${DEFAULT_PREFIX_ROUTE.SERVICE}/get-list-service-of-user`,
    LIST_ADS: `${DEFAULT_PREFIX_ROUTE.SERVICE}/get-list-service`,
    VERIFY_CONTRACT_USED_AMOUNT: `${DEFAULT_PREFIX_ROUTE.SERVICE}/verify-contract-used-amount`,
    GET_PAST_DUE_PAYMENT_URL: `${DEFAULT_PREFIX_ROUTE.SERVICE}/get-past-due-payment-url`,
  },

  USER_ACCOUNT: {
    LIST: `${DEFAULT_PREFIX_ROUTE.USER}/get-list-user-of-organization`,
    DELETE: `${DEFAULT_PREFIX_ROUTE.USER}/delete-user`,
    CREATE: `${DEFAULT_PREFIX_ROUTE.USER}/create-user`,
    DETAIL: `${DEFAULT_PREFIX_ROUTE.USER}/get-user-detail`,
    UPDATE: `${DEFAULT_PREFIX_ROUTE.USER}/update-user`,
  },

  COMPANY_INFO: {
    PAYMENT_SETTING: `${DEFAULT_PREFIX_ROUTE.PAYMENT_SETTING}/create`,
    PAYMENT_SETTING_UPDATE: `${DEFAULT_PREFIX_ROUTE.PAYMENT_SETTING}/update`,
    PAYMENT_SETTING_DETAIL: `${DEFAULT_PREFIX_ROUTE.PAYMENT_SETTING}/detail`,
  },

  CONTRACT_SUBSCRIPTION: {
    FEATURE_LIST: `${DEFAULT_PREFIX_ROUTE.CONTRACT_SUBSCRIPTION}/feature-list`,
    FEATURE_DETAIL: `${DEFAULT_PREFIX_ROUTE.CONTRACT_SUBSCRIPTION}/feature-detail`,
    DETAIL: `${DEFAULT_PREFIX_ROUTE.CONTRACT_SUBSCRIPTION}/subscription-detail`,
    UPSERT: `${DEFAULT_PREFIX_ROUTE.CONTRACT_SUBSCRIPTION}/upsert-subscription`,
    GET_URL_CHECKOUT: `${DEFAULT_PREFIX_ROUTE.CONTRACT_SUBSCRIPTION}/get-url-checkout`,
    FREE_SETTING_DETAIL: `${DEFAULT_PREFIX_ROUTE.CONTRACT_SUBSCRIPTION}/free-setting-detail`,
    GET_PAST_DUE_PAYMENT: `${DEFAULT_PREFIX_ROUTE.CONTRACT_SUBSCRIPTION}/get-past-due-payment`,
  },

  STORAGE_PLAN_SUBSCRIPTION: {
    GET_USED_STORAGE: `${DEFAULT_PREFIX_ROUTE.STORAGE_PLAN_SUBSCRIPTION}/get-used-storage-of-oz`,
    CHECK_NEW_PLAN: `${DEFAULT_PREFIX_ROUTE.STORAGE_PLAN_SUBSCRIPTION}/check-new-storage-plan`,
  },

  INVOICE_SETTING: {
    DETAIL: `${DEFAULT_PREFIX_ROUTE.INVOICE_SETTING}/detail`,
    UPDATE: `${DEFAULT_PREFIX_ROUTE.INVOICE_SETTING}/make-invoice-setting`,
  },

  REVENUE: {
    LIST: `${DEFAULT_PREFIX_ROUTE.REVENUE}/list-revenue`,
    CONFIRM: `${DEFAULT_PREFIX_ROUTE.REVENUE}/confirm-invoice-payment`,
  },

  ORGANIZATION: {
    ADMIN_DELETE: `${DEFAULT_PREFIX_ROUTE.ORGANIZATION}/admin-delete-organizaion`,
  },

  BASIC_INFO: {
    BASIC_INFO: `${DEFAULT_PREFIX_ROUTE.BASIC_INFO}/detail`,
    EDIT_BASIC_INFO: `${DEFAULT_PREFIX_ROUTE.BASIC_INFO}/update`,
    LIST_CONTACTS: `${DEFAULT_PREFIX_ROUTE.BASIC_INFO}/list-contacts`,
    UPDATE_LIST_CONTACTS: `${DEFAULT_PREFIX_ROUTE.BASIC_INFO}/update-list-contacts`,
    DELETE_CONTACT: `${DEFAULT_PREFIX_ROUTE.BASIC_INFO}/delete-contact`,
  },

  RECEIPT_SETTING: {
    DETAIL: `${DEFAULT_PREFIX_ROUTE.RECEIPT_SETTING}/detail`,
    CREATE: `${DEFAULT_PREFIX_ROUTE.RECEIPT_SETTING}/make-receipt-setting`,
    UPDATE: `${DEFAULT_PREFIX_ROUTE.RECEIPT_SETTING}/update-receipt-setting`,
  },

  RECEIPT_HISTORY: {
    LIST: `${DEFAULT_PREFIX_ROUTE.RECEIPT_HISTORY}`,
  },
};

export { API };
