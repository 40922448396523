import React from 'react';
import type { FC } from 'react';
import classNames from 'classnames';
import Spinner from '../commons/icons/Animations/Spinner';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  otherstyle: string | null;
  children?: JSX.Element;
  content: string;
  disabled?: boolean;
  isSubmitting?: boolean;
}

const Button: FC<ButtonProps> = ({ isSubmitting, ...props }) => {
  return (
    <button
      {...props}
      className={classNames({
        'w-full sm:max-w-[430px] max-w-[230px] h-[50px] rounded-lg font-semibold text-[1.25rem]':
          true,
        [`${props.otherstyle}`]: props.otherstyle !== null,
        'pointer-events-none opacity-50': !!isSubmitting,
      })}
      disabled={isSubmitting || props.disabled}
    >
      <span className="align-middle flex justify-center items-center">
        {isSubmitting ? <Spinner /> : props.content}
      </span>
    </button>
  );
};

export default Button;
