import { Contract, ContractSubscription } from '../../../../../types/user/contract';
import { SetStateAction } from '../../../../../types/globals';
import FormLine from '../Basic/FormLine';
import { isNumber } from '../../../../../services/helpers/etc';
import RadioBox from '../../../../commons/form/RadioBox';
import {
  CONTRACT_SUBSCRIPTION_PERIOD,
  CONTRACT_SUBSCRIPTION_STATUS,
  PERIOD_RADIO_DATA,
  SUBSCRIPTION_STEP,
} from '../../../../../services/constants/user/pages/contract';
import { formatMoney } from '../../../../../services/helpers/parseData';
import SubmitButton from '../SubmitButton';
import { PATH_CONTRACT } from '../../../../../services/constants/route/router';
import { useObjectRoutes } from '../../../../../hooks/useObjectRoutes';
import { swalError } from '../../../../../services/helpers/swal';
import { RenderContractLabelFormStatus } from '../RenderUtils';
import { useStorage } from '../../../../../hooks/useStorage';
import { STORAGE_KEY_PATH_HISTORY } from '../../../../../services/constants/globals';
import { getBilledAmountWithTax, getTaxAmount } from '../../../../../services/utils/contract';

type SupportSubscriptionFormProps = {
  subscription?: ContractSubscription;
  subscribedPriceList?: Contract[];
  latestPriceList?: Contract[];
  setStep?: SetStateAction<string>;
  formData?: ContractSubscription;
  setFormData?: SetStateAction<ContractSubscription>;
  originSubscribed?: ContractSubscription;
  step?: string;
};

const SupportContractSubscriptionForm = ({
  subscription,
  subscribedPriceList,
  latestPriceList,
  formData,
  setStep,
  setFormData,
  originSubscribed,
}: SupportSubscriptionFormProps) => {
  const { navigate } = useObjectRoutes();
  const { getItem } = useStorage();
  const pathHistory = getItem(STORAGE_KEY_PATH_HISTORY);

  const changeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (!isNumber(Number(value)) || Number(value) < 0 || !latestPriceList?.length) return;

    let additionData: typeof formData = {
      billed_amount:
        Number(value) === CONTRACT_SUBSCRIPTION_PERIOD.YEARLY
          ? (latestPriceList[0]?.price_yearly || 0) * 12
          : latestPriceList[0]?.price_monthly,
    };

    setFormData?.({ ...formData, [name]: Number(value), ...additionData });
  };

  const thTableStyles = 'h-[30px] flex items-center justify-center bg-success-lighter';

  const returnHandle = () => {
    if (pathHistory && pathHistory.trim()) {
      return navigate(`${PATH_CONTRACT.OVERVIEW}?${pathHistory.trim()}`);
    }

    return navigate(PATH_CONTRACT.OVERVIEW);
  };

  const submitHandle = () => {
    if (!formData?.billed_amount) {
      swalError();
      return;
    }

    setStep?.(
      originSubscribed?.id ? SUBSCRIPTION_STEP.CONFIRMATION_CHANGE : SUBSCRIPTION_STEP.CONFIRMATION,
    );
  };

  const unsubscribeHandle = () => {
    if (!originSubscribed?.billed_amount) {
      swalError();
      return;
    }
    setStep?.(SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE);
  };

  return (
    <div className="">
      <div className="mb-[7px]">契約詳細</div>
      {originSubscribed && originSubscribed?.id && (
        <FormLine label="サブスク状態">
          <div className="text-danger text-[12px] font-[400] leading-[100%] ml-[18px]">
            <RenderContractLabelFormStatus
              formData={formData}
              originSubscribed={originSubscribed}
            />
          </div>
        </FormLine>
      )}

      {latestPriceList?.length ? (
        <div className="">
          {/* <FormLine label="契約">
            <RadioBox
              data={PERIOD_RADIO_DATA}
              value={formData?.period_type || CONTRACT_SUBSCRIPTION_PERIOD.MONTHLY}
              handleOnChange={changeHandle}
              inputName="period_type"
              className="w-[20%] flex items-center gap-[7px] ml-[18px]"
            />
          </FormLine> */}

          <FormLine label="オプション">
            <div className="w-full py-[20px] pl-[5%] px-[20%]">
              <div className="flex w-full gap-[5px]">
                <div className={`w-[55%] ${thTableStyles}`}>サービス</div>
                <div className={`w-[20%] ${thTableStyles}`}>
                  {formData?.period_type === CONTRACT_SUBSCRIPTION_PERIOD.YEARLY ? '年額' : '月額'}
                  払い
                </div>
                <div className={`w-[25%] !bg-primary-light text-white ${thTableStyles}`}>
                  ご契約金額
                </div>
              </div>
              <div className="flex py-[25px] items-center gap-[5px]">
                <div className="w-[55%] text-primary text-[14px] font-[500] leading-[100%]">
                  導入サポート　 2時間・5人まで・交通費別途
                </div>
                <div className="w-[20%] text-center text-[14px] font-[400] leading-[100%]">
                  {formatMoney(
                    formData?.period_type === CONTRACT_SUBSCRIPTION_PERIOD.YEARLY
                      ? latestPriceList[0]?.price_yearly
                      : latestPriceList[0]?.price_monthly,
                  )}
                </div>
                <div className="w-[25%] text-center text-[14px] font-[400] leading-[100%]">
                  {formatMoney(
                    formData?.period_type === CONTRACT_SUBSCRIPTION_PERIOD.YEARLY
                      ? Number(latestPriceList[0]?.price_yearly) * 12
                      : latestPriceList[0]?.price_monthly,
                  )}
                </div>
              </div>

              <div className="flex py-[20px] items-center gap-[5px] border-t border-secondary-light">
                <div className="w-[50%] text-[14px] font-[500] leading-[100%] text-center ml-[17px]">
                  小計
                </div>
                <div className="w-[50%] text-right text-[18px] font-[500] leading-[100%] pr-[10px]">
                  {formatMoney(formData?.billed_amount)}
                </div>
              </div>

              <div className="flex py-[20px] items-center gap-[5px]">
                <div className="w-[50%] text-[10px] font-[500] leading-[100%] text-center mr-[40px]">
                  消費税額（10％対象）
                </div>
                <div className="w-[50%] text-right text-[12px] font-[500] leading-[100%] pr-[10px]">
                  {formatMoney(getTaxAmount(formData?.billed_amount))}
                </div>
              </div>

              <div className="flex py-[20px] items-center gap-[5px]">
                <div className="w-[50%] text-primary text-[14px] font-[500] leading-[100%] text-center ml-[30px]">
                  合計
                </div>
                <div className="w-[50%] text-right text-[18px] font-[500] leading-[100%] pr-[10px]">
                  {formatMoney(getBilledAmountWithTax(formData?.billed_amount))}
                </div>
              </div>
            </div>
          </FormLine>
        </div>
      ) : (
        <>
          <div className="skeleton h-[150px] mt-[5px]"></div>
        </>
      )}

      <div className="flex justify-center gap-[15px] mt-[40px]">
        <SubmitButton type="return" onClick={returnHandle}>
          キャンセル
        </SubmitButton>
        <SubmitButton
          onClick={submitHandle}
          disabled={
            originSubscribed?.status !== CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD &&
            formData?.period_type === originSubscribed?.period_type
          }
        >
          プラン登録を確認する
        </SubmitButton>

        {/* {originSubscribed?.id ? (
          <SubmitButton
            type="unsubscribe"
            onClick={unsubscribeHandle}
            disabled={
              originSubscribed?.status === CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD &&
              !originSubscribed?.in_scheduled_subscribed?.id
            }
          >
            サブスクを解約する
          </SubmitButton>
        ) : (
          <></>
        )} */}
      </div>
    </div>
  );
};

export default SupportContractSubscriptionForm;
