import React from 'react';

type LogoBoxProps = {
  imageUrl?: string | null;
};

const LogoBox = ({ imageUrl }: LogoBoxProps) => {

  if (imageUrl) {
    return <div className=' flex items-center w-[30px] h-[30px] rounded-[5px] border border-success-lighter'><img src={imageUrl} alt="Image" width={30}/></div>;
  }

  return <div className={"w-[30px] h-[30px] bg-secondary-light rounded-[5px] "} />;
};

export default LogoBox ;