import React from "react";
import { IoWalletOutline } from "react-icons/io5";

const Wallet = () => {
    return (
        <IoWalletOutline 
            className="text-[#BEBEBE]"
            size={24}
        />
    );
};

export default Wallet;