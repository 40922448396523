import TabCard from "../../../components/user/commons/TabCard";
import UserLayout from "../../../layouts/user";
import { PATH_USER_ACCOUNT } from "../../../services/constants/route/router";
import { useTabFinder } from "../../../hooks/useTabFinder";
import { WithChildren } from "../../../types/globals";
import { ACCOUNT_TAB_DATA } from "../../../services/constants/user/pages/account";

type AccountMainProp = {
  title?: string;
};

const AccountBase = ({ title, children }: WithChildren<AccountMainProp>) => {
  const { tabIndex } = useTabFinder(Object.values(PATH_USER_ACCOUNT));

  return (
    <UserLayout>
      <TabCard tabData={ACCOUNT_TAB_DATA} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </UserLayout>
  );
};

export default AccountBase;
