import {
  getBilledAmountByUsage,
  getBilledAmountWithTax,
  getDiscountAmountByUsage,
  getTaxAmount,
} from '../../../../../services/utils/contract';
import { formatMoney } from '../../../../../services/helpers/parseData';
import { Contract, ContractSubscription } from '../../../../../types/user/contract';
import dayjs from '../../../../../services/dayjs';
import React from 'react';
import { STORAGE_PLUS_PLAN_PACKAGE_AMOUNT } from '../../../../../services/constants/user/pages/contract';

type PriceChangeCalculationProps = {
  subscription?: ContractSubscription;
  contracts?: Contract[];
  originSubscribed?: ContractSubscription;
};

const PriceChangeCalculation = ({
  subscription = {},
  contracts = [],
  originSubscribed = {},
}: PriceChangeCalculationProps) => {
  let changedBilledAmount = 0;
  let productQuantityAmount = subscription?.product_quantity || 0;
  const now = originSubscribed?.test_clock ? dayjs.unix(originSubscribed?.test_clock) : dayjs();
  const usedDays = dayjs(now).diff(originSubscribed?.period_start_at, 'd') + 1;

  const planCapacity =
    contracts && contracts[0]?.storage_capacity
      ? contracts[0]?.storage_capacity
      : STORAGE_PLUS_PLAN_PACKAGE_AMOUNT;

  return (
    <div className="w-full max-w-[400px]">
      <div className="text-[15px] leading-[100%] text-center py-[8px] rounded-[5px] mb-[10px] bg-[#5A5A5A] text-white">
        今回のご決済
      </div>

      <div className="">
        {contracts &&
          contracts.length &&
          Number(subscription?.product_quantity) > Number(originSubscribed?.product_quantity) && (
            <div className="text-primary text-[14px] font-[400] leading-[100%] mb-[10px]">
              追加アカウント
            </div>
          )}
        {contracts && contracts.length && subscription?.product_quantity ? (
          <>
            {contracts?.map((price, index) => (
              <React.Fragment key={index}>
                {subscription.product_quantity && originSubscribed.product_quantity ? (
                  <>
                    {(() => {
                      const priceAmount = Number(price.price_monthly);
                      productQuantityAmount -= originSubscribed?.product_quantity;
                      changedBilledAmount += productQuantityAmount * priceAmount;

                      if (productQuantityAmount < 0) {
                        productQuantityAmount = 0;
                        changedBilledAmount = 0;
                      }

                      return (
                        <>
                          {productQuantityAmount ? (
                            <div className="flex border-b border-secondary-light mb-[3.5px] h-0 min-h-[35px] items-center">
                              <div className="w-[35%]"></div>
                              <div className="w-[35%] text-right pr-[10px]">
                                {formatMoney(price.price_monthly)}
                              </div>
                              <div className="w-[43%] text-right h-[100%]">
                                <div className="pr-[10px] pt-[5%] h-[100%] text-[13px]">
                                  {productQuantityAmount * planCapacity}
                                  <span className="">GB</span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })()}
                  </>
                ) : (
                  <>
                    <div className="w-[43%] text-right h-[100%]">
                      <div className="pr-[10px] pt-[7%] h-[100%]">ー</div>
                    </div>

                    <div className="w-[43%] text-right h-[100%]">
                      <div className="pr-[10px] pt-[7%] h-[100%]">ー</div>
                    </div>
                  </>
                )}
              </React.Fragment>
            ))}
          </>
        ) : (
          <>
            {subscription?.product_quantity ? (
              <div className="skeleton w-full h-[100px] bg-secondary-lighter mb-[10px]"></div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>

      <div className="text-primary text-[14px] font-[400] leading-[100%] mb-[10px]">
        契約初月利用日数
      </div>
      <div className="flex border-b border-secondary-light mb-[3.5px] h-0 min-h-[35px] items-center">
        <div className="w-[62%] text-right">契約初月（日割計算）</div>
        <div className="w-[38%] text-right pr-[10px]">{dayjs(originSubscribed?.period_start_at).daysInMonth() - usedDays}日</div>
      </div>

      <div className="flex py-[5px]">
        <div className="w-[60%] text-right">月額通常料金</div>
        <div className="w-[40%] text-right pr-[10px] text-[18px] font-[500] leading-[100%] h-[30px] smaller-scroll-bar whitespace-nowrap overflow-x-auto">
          {formatMoney(changedBilledAmount)}
        </div>
      </div>

      <div className="flex py-[5px]">
        <div className="w-[60%] text-right">初月（日割）割引額</div>
        <div className="w-[40%] text-right pr-[10px] text-[18px] font-[500] leading-[100%] h-[30px] smaller-scroll-bar whitespace-nowrap overflow-x-auto">
          -
          {formatMoney(
            getDiscountAmountByUsage(
              changedBilledAmount,
              String(originSubscribed?.period_start_at),
              usedDays,
            ),
          )}
        </div>
      </div>

      <div className="flex py-[5px]">
        <div className="w-[60%] text-right">初月（日割）支払い合計</div>
        <div className="w-[40%] text-right pr-[10px] text-[18px] font-[500] leading-[100%] h-[30px] smaller-scroll-bar whitespace-nowrap overflow-x-auto">
          {formatMoney(
            getBilledAmountByUsage(
              changedBilledAmount,
              String(originSubscribed?.period_start_at),
              usedDays,
            ),
          )}
        </div>
      </div>

      <div className="flex py-[5px]">
        <div className="w-[60%] text-right text-[10px] font-[400] leading-[100%]">
          消費税額（10％対象）
        </div>
        <div className="w-[40%] text-right pr-[10px] text-[12px] font-[500] leading-[100%] h-[30px] smaller-scroll-bar whitespace-nowrap overflow-x-auto">
          {formatMoney(
            getTaxAmount(
              getBilledAmountByUsage(
                changedBilledAmount,
                String(originSubscribed?.period_start_at),
                usedDays,
              ),
            ),
          )}
        </div>
      </div>

      <div className="flex py-[5px]">
        <div className="w-[60%] text-[20px] text-danger text-right">今回のお支払い合計</div>
        <div className="w-[40%] text-right pr-[10px] text-[18px] font-[500] leading-[100%] h-[30px] smaller-scroll-bar whitespace-nowrap overflow-x-auto">
          {formatMoney(
            getBilledAmountWithTax(
              getBilledAmountByUsage(
                changedBilledAmount,
                String(originSubscribed?.period_start_at),
                usedDays,
              ),
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceChangeCalculation;
