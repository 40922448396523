import React from "react";
import SmallButton from "../../commons/SmallButton";
import TVIcon from "../../../commons/icons/Service/TVIcon";
import ContactIcon from "../../../commons/icons/Service/ContactIcon";

const ModuleButtonGroup = ({
  landingPageUrl,
  domainContactUrl,
}: {
  landingPageUrl: string;
  domainContactUrl: string;
}) => {
  return (
    <div className="flex gap-[20px] w-full justify-center">
      <a href={landingPageUrl} target="_blank" className="rounded-[10px]">
        <SmallButton className="border border-primary text-primary px-[14px]">
          <TVIcon/>
          <span className="ml-[2px]">サービスサイト</span>
        </SmallButton>
      </a>
      <a href={domainContactUrl} target="_blank" className="rounded-[10px]">
        <SmallButton className="border border-danger text-danger px-[20px]">
          <ContactIcon/>
          <span className="ml-[8px]">お問い合わせ</span>
        </SmallButton>
      </a>
    </div>
  );
};

export default ModuleButtonGroup;
