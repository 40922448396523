import SuperAdminAccountBase from ".";
import SuperAdminAccountForm from "../../../components/SuperAdmin/Account/Form/Form";
import { useObjectRoutes } from "../../../hooks/useObjectRoutes";


const SuperAdminAccountCreatePage = () => {
  const { getParamValue } = useObjectRoutes();
  const accountId = getParamValue("account_id");
  return (
    <SuperAdminAccountBase title={accountId ? "パレット管理者管理｜パレット管理者編集" : "パレット管理者管理｜パレット管理者新規追加"}>
      <SuperAdminAccountForm/>
    </SuperAdminAccountBase>
  );
};

export default SuperAdminAccountCreatePage;
