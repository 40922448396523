import { useState } from "react";
import RequiredBox from "../commons/RequiredBox";
import SemiSquareButton from "../commons/SemiSquareButton";
import DeleteOzWarningBox from "./DeleteOzComponent/DeleteOzWarningBox";
import SuggestBox from "./DeleteOzComponent/SuggestBox";
import { useNavigate } from "react-router-dom";
import { PATH_SERVICE, PATH_USER_ACCOUNT, ROUTE } from "../../../services/constants/route/router";
import { swallConfirm } from "../../../services/helpers/swal";
import Spinner from "../../commons/icons/Animations/Spinner";
import { request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { ErrorData } from "../../../types/globals";
import ErrorBox from "../../commons/form/ErrorBox";

type DeleteOzProps = {
  title?: string;
};

type DeleteOzFormDataType = {
    confirmation?: number;
    password?: string
}

const CONFIRM_CHECKED = 1;
const CONFIRM_UNCHECKED = 0;

const DeleteOz = ({
    title
}: DeleteOzProps ) => {
    const [formData, setFormData] = useState<DeleteOzFormDataType>({
        password: ""
    })
    const navigate = useNavigate()
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [errors, setErrors] = useState<ErrorData>({});

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
    ) => {
        const { name, value } = e.target;
        let additionFormData;
        if (e.target.type === 'checkbox') {
            const target = e.target as HTMLInputElement;
            if (target.checked) {
                additionFormData = {
                    ...formData,
                    confirmation: CONFIRM_CHECKED,
                };
            } else {
                additionFormData = {
                    password: formData.password
                };
            }
        } else {
            additionFormData = {
                ...formData,
                [name]: value
            }
        }
        setFormData(additionFormData);
    };

    const handleDeleteOz = async () => {
        // call API
        swallConfirm(
            async () => {
                // swalLoading();
                setIsDeleting(true);
                await request.delete(
                `${API.ORGANIZATION.ADMIN_DELETE}?confirmation=${formData.confirmation}&password=${formData.password}`,
                () => {},
                () => {
                    setIsDeleting(false);
                    setTimeout(() => {
                        navigate(ROUTE.LOGIN);
                    }, 1000)
                },
                (errs) => {
                    setIsDeleting(false);
                    setErrors(errs);
                },
                { withSuccess: true },
                );
            },
            '本当に退会手続き（登録データの全削除）をしてもよろしいですか？',
        );
    }

    return (
        <div className="bg-white rounded-[10px] w-full min-h-[100px] pb-[50px]">
            <div className="py-[30px] px-[28px] mb-[20px]">
                <div className="border-b border-secondary-light text-secondary-dark pb-[14px] font-[500] text-[16px] leading-[100%]">
                    {title}
                </div>
            </div>
            {/* errors */}
            {errors && !!Object.keys(errors).length && (
                <div className="mb-[20px] mx-[43px]">
                    <ErrorBox errors={errors} />
                </div>
            )}
            <div className="mx-[43px]">
                <div className="flex justify-center items-center">
                    <div className="w-full gap-x-[30px] gap-y-[20px]">
                        <div className="w-full pb-[20px]">退会手続きの前に、以下をご確認ください</div>
                        <div className="text-red-500 font-bold text-[18px]">退会手続きを行うと</div>
                        <div className="text-red-500 font-bold text-[18px] pb-[20px]">パレットエデュケーションに登録されたデータはすべて削除され、復旧できません</div>
                        <DeleteOzWarningBox />
                        <SuggestBox />
                        <div className="text-[16px] font-bold">
                            上記の内容を確認した上で、本当に退会手続き（登録データの全削除）をしてもよろしいですか？
                        </div>
                        <div className="mt-[5px] mb-[40px]">
                            <input
                                type="checkbox"
                                onChange={handleChange}
                                name="confirmation"
                                className="form-checkbox text-primary border-[1px] border-[#EBE4D8] rounded-[3px]"
                            />
                            <span className="ml-2 mr-[5px] font-400 text-[14px] text-gray-600 whitespace-nowrap">
                                上記の内容を確認し、削除に同意しました
                            </span>
                        </div>
                        <div>
                            <div className="flex justify-start text-[12px] font-[500] text-gray-600 leading-[100%] w-full max-w-[350px] mb-[10px]">
                                <div className="mr-[10px]">パスワードを入力してください</div>
                                <RequiredBox />
                            </div>
                            <div className="text-secondary-dark w-[40%] flex items-center">
                                <input
                                    className="text-[16px] leading-[100%] flex items-center pl-[15.5px] h-[40px] border border-[#ebe4d8] rounded placeholder-secondary-light"
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                    placeholder="パスワード"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center">
                <div className="">
                    <SemiSquareButton
                        className={`${(isDeleting || formData.confirmation !== CONFIRM_CHECKED) ? 'opacity-70 pointer-events-none' : ''} !bg-[#FA646A] max-w-[240px] w-full flex justify-center items-center mb-[15px] mt-[40px] gap-[15px]`}
                        onClick={handleDeleteOz}
                    >
                        {isDeleting ? <Spinner /> : "退会手続きを実行"}
                    </SemiSquareButton>
                    <SemiSquareButton
                        className="bg-secondary-light max-w-[240px] w-full flex justify-center items-center gap-[15px]"
                        onClick={() => navigate(PATH_SERVICE.DEFAULT)}
                    >
                        戻る
                    </SemiSquareButton>
                </div>
            </div>
      </div>
    )
   
}

export default DeleteOz