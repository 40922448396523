import React from "react";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import { PATH_SUPER_ADMIN } from "../../../services/constants/route/router";
import RoundedButton from "../../user/commons/RoundedButton";

const TableCommonAction = ({
  ...props
}: React.HTMLProps<HTMLElement>) => {
  return (
    <section {...props}>
      <div className="flex justify-end gap-[10px]">
        <Link to={PATH_SUPER_ADMIN.USER_ACCOUNT.CREATE} className="rounded-[20px]">
          <RoundedButton>
            <HiPlus size={20} />
            <div className="ml-[12px] mb-[1px]">新規パレット管理者登録</div>
          </RoundedButton>
        </Link>
      </div>
    </section>
  );
};

export default TableCommonAction;
