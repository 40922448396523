import React, { useState } from 'react';
import { ErrorData, SetStateAction } from '../../../../../types/globals';
import FormLine from './FormLine';
import {
  Contract,
  ContractSubscription,
  SubscriptionCheckoutData,
  UnsubscribeSubscriptionAdditionalData,
} from '../../../../../types/user/contract';
import {
  CONFIRM_UNSUBSCRIBE_CHECKED,
  CONTRACT_SUBSCRIPTION_PERIOD,
  SUBSCRIPTION_COMPLETE_TYPE,
  SUBSCRIPTION_STEP,
} from '../../../../../services/constants/user/pages/contract';
import SubmitButton from '../SubmitButton';
import { formatMoney, sortArrayByKey } from '../../../../../services/helpers/parseData';
import {
  getSubscriptionPeriodLabel,
  getSubscriptionCheckoutTypeByStep,
  getTaxAmount,
  getBilledAmountWithTax,
  getContractPeopleNumberLabelByFeatureId,
} from '../../../../../services/utils/contract';
import { PATH_CONTRACT } from '../../../../../services/constants/route/router';
import { request } from '../../../../../services/axios/axios';
import { API } from '../../../../../services/constants/route/api';
import { PAYMENT_METHOD_TYPE } from '../../../../../services/constants/user/payment';
import Spinner from '../../../../commons/icons/Animations/Spinner';
import { RenderConfirmPassWordUnSubscribeSubscription, RenderContractLabelConfirm, RenderUnSubscribeSubscriptionAnnouncement } from '../RenderUtils';
import { swalError, swalMessage } from '../../../../../services/helpers/swal';
import PriceChangeCalculation from './PriceChangeCalculation';
import { useNavigate } from 'react-router-dom';
import { useStorage } from '../../../../../hooks/useStorage';
import { STORAGE_KEY_PATH_HISTORY } from '../../../../../services/constants/globals';

type BasicSubscriptionConfirmationProps = {
  setStep?: SetStateAction<string>;
  step?: string;
  formData?: ContractSubscription;
  latestPriceList?: Contract[];
  originSubscribed?: ContractSubscription;
};

type PriceCalculationConfirmationProps = {
  contracts?: Contract[];
  subscription?: ContractSubscription;
};

const PriceCalculationConfirmation = ({
  contracts,
  subscription = {},
}: PriceCalculationConfirmationProps) => {
  let remainingAmount = subscription.user_quantity || 0;
  let totalDiff = 0;
  let totalDiffDelayed = 0;

  return (
    <div className="w-full max-w-[400px]">
      {contracts && contracts.length ? (
        <>
          {sortArrayByKey(contracts, 'user_limit_start')?.map((price, index) => (
            <div key={index} className="flex items-center gap-[5px]">
              <div className="w-[50%] border-b border-secondary-light">
                <div className={`flex items-center justify-start ml-[10px] py-[5px]`}>
                  <div className="">{getContractPeopleNumberLabelByFeatureId(subscription?.feature_id)}（{price.user_limit_start || ''}</div>
                  <div className="">{!!price.user_limit_start && '〜'}</div>
                  <div className="">{price.user_limit_end || ''}）</div>
                </div>
              </div>
              {Number(subscription?.user_quantity) ? (
                <>
                  {(() => {
                    let priceAmount = Number(
                      subscription.period_type === CONTRACT_SUBSCRIPTION_PERIOD.YEARLY
                        ? price.price_yearly
                        : price.price_monthly,
                    );

                    let userQuantity = subscription.user_quantity || 0;
                    let userQuantityAmount = userQuantity;
                    const currentPriceList = sortArrayByKey(contracts || [], 'user_limit_start');

                    let userQuantityDiff =
                      Number(price.user_limit_end) -
                      Number(price.user_limit_start) +
                      (!!price.user_limit_start ? 1 : 0);

                    //Last item
                    if (!Number(price.user_limit_end)) {
                      userQuantityDiff = 0;
                    }

                    let userQuantityOldDiff = 0;
                    if (currentPriceList?.[index - 1]) {
                      userQuantityOldDiff =
                        Number(currentPriceList?.[index - 1]?.user_limit_end || 0) -
                        Number(currentPriceList?.[index - 1]?.user_limit_start || 0) +
                        1;
                    }

                    totalDiff += userQuantityDiff;
                    totalDiffDelayed += userQuantityOldDiff;

                    if (!Number(price.user_limit_end)) {
                      userQuantityAmount = userQuantity > totalDiff ? userQuantity - totalDiff : 0;
                    } else if (userQuantity >= totalDiff) {
                      userQuantityAmount = userQuantityDiff;
                      remainingAmount -= userQuantityDiff;
                    } else if (userQuantity < totalDiff && userQuantity >= totalDiffDelayed) {
                      userQuantityAmount = remainingAmount;
                    } else {
                      userQuantityAmount = 0;
                    }

                    return (
                      <>
                        <div className="w-[25%] whitespace-nowrap text-right h-[100%] border-b border-secondary-light">
                          {userQuantityAmount ? (
                            <div className="text-center py-[5px]">
                              {userQuantityAmount}アカウント
                            </div>
                          ) : (
                            <div className="text-center py-[5px]">ー</div>
                          )}
                        </div>

                        <div className="w-[25%] whitespace-nowrap text-right h-[100%] border-b border-secondary-light">
                          {userQuantityAmount ? (
                            <div className="text-center py-[5px]">
                              {formatMoney(userQuantityAmount * priceAmount)}
                            </div>
                          ) : (
                            <div className="text-center py-[5px]">ー</div>
                          )}
                        </div>
                      </>
                    );
                  })()}
                </>
              ) : (
                <div className="text-center py-[5px]">ー</div>
              )}
            </div>
          ))}
        </>
      ) : (
        <div className="skeleton w-full h-[150px] bg-secondary-lighter mb-[10px]"></div>
      )}
    </div>
  );
};

const BasicSubscriptionConfirmation = ({
  latestPriceList,
  formData,
  setStep,
  step = '',
  originSubscribed,
}: BasicSubscriptionConfirmationProps) => {
  const navigate = useNavigate();
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [verifyData, setVerifyData] = useState<UnsubscribeSubscriptionAdditionalData>({})
  const isCancel = (step === SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE) ? true : false;
  const submitText = isCancel ? '契約を解約する' : '決済画面へ進む';
  const { getItem } = useStorage();
  const pathHistory = getItem(STORAGE_KEY_PATH_HISTORY);

  const handleChangeAdditionData = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;
    let additionFormData;
    if (e.target.type === 'checkbox') {
        const target = e.target as HTMLInputElement;
        if (target.checked) {
            additionFormData = {
              ...verifyData,
              confirmation: CONFIRM_UNSUBSCRIBE_CHECKED,
            };
        } else {
            additionFormData = {
              password: verifyData.password
            };
        }
    } else {
        additionFormData = {
          ...verifyData,
          [name]: value
        }
    }
    setVerifyData(additionFormData);
  };

  const submitHandle = async () => {
    setIsCheckingOut(true);
    let isVerified = true;
    let errorMessage = '';

    if(isCancel) {
      await request.post(
        `${API.AUTH.VERIFY_ADMIN}`, 
        verifyData, 
        () => {}, 
        (err: ErrorData) => {
          {Object.keys(err).map((errorKey, index) => (
            errorMessage += err[errorKey][0] + '<br/>'
          ))}
          swalMessage(
            'エラー',
            '',
            'error',
            { timer: 2000, html: errorMessage },
          );
          isVerified = false;
          return;
        }
      )
      if(!isVerified) {
        setIsCheckingOut(false)
        return;
      }
    }

    let additionParamString = '';
    switch(step) {
      case SUBSCRIPTION_STEP.CONFIRMATION_CHANGE:
        additionParamString = `?type=${SUBSCRIPTION_COMPLETE_TYPE.CHANGE_COMPLETE}`;
        break;
      case SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE:
        additionParamString = `?type=${SUBSCRIPTION_COMPLETE_TYPE.UNSUBSCRIBE_COMPLETE}`;
        break;
      default:
        additionParamString = '';
        break;
    }

    const submitData: SubscriptionCheckoutData = {
      payment_method_type: PAYMENT_METHOD_TYPE.CARD.VALUE,
      success_url: `${window.location.origin}${PATH_CONTRACT.SUBSCRIPTION_COMPLETE}${additionParamString}`,
      cancel_url: `${window.location.origin}${PATH_CONTRACT.OVERVIEW}`,
      subscription: formData,
      subscription_checkout_type: getSubscriptionCheckoutTypeByStep(step),
    };

    await request.post(
      API.CONTRACT_SUBSCRIPTION.GET_URL_CHECKOUT,
      submitData,
      (res) => {
        window.location.href = res;
        setIsCheckingOut(false);
        return;
      },
      (err: string) => {
        swalError(String(err || ''), 3000);
        setIsCheckingOut(false);
      },
    );
  };

  const returnHandle = () => {
    if(isCancel) {
      if (pathHistory && pathHistory.trim()) {
        return navigate(`${PATH_CONTRACT.OVERVIEW}?${pathHistory.trim()}`);
      }
      
      return navigate(PATH_CONTRACT.OVERVIEW);
    }
    setStep?.(SUBSCRIPTION_STEP.FORM_FILL);
  };

  return (
    <div className="text-secondary-dark">
      <div className="mb-[7px]">契約詳細</div>
      <div className="">
        <FormLine label={getContractPeopleNumberLabelByFeatureId(formData?.feature_id)}>
          <div className="w-[10%] flex items-center gap-[7px] ml-[16px]">
            {formData?.user_quantity}人
          </div>
        </FormLine>

        <FormLine label="契約">
          <div className="ml-[16px] text-[12px] font-[400] leading-[100%]">
            {step === SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE
              ? '無し'
              : getSubscriptionPeriodLabel()}

            <span className="text-danger text-[12px] font-[400] leading-[100%]">
              <RenderContractLabelConfirm
                step={step || ''}
                formData={formData}
                originSubscribed={originSubscribed}
              />
            </span>
          </div>
        </FormLine>

        <FormLine label="金額（税抜）">
          <div className="w-full flex items-center ml-[16px] py-[15 px] gap-[20px] pb-[15px]">
            <PriceCalculationConfirmation contracts={latestPriceList} subscription={formData} />
          </div>
        </FormLine>

        {!isCancel ? (
            <>
              <div className="flex justify-center items-center border-t border-secondary-light mt-[20px] py-[12px]">
                <div className="w-[290px] flex items-center ml-[-70px]">
                  <div className="text-[16px] font-[500] leading-[100%] w-full text-right">小計</div>
                  <div className="text-[18px] font-[500] leading-[100%] w-full text-right">
                    {formatMoney(formData?.billed_amount)}
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center mt-[20px] py-[5px]">
                <div className="w-[290px] flex items-center ml-[-70px]">
                  <div className="text-[10px] font-[500] leading-[100%] w-full text-right">
                    消費税額（10％対象）
                  </div>
                  <div className="text-[12px] font-[500] leading-[100%] w-full text-right">
                    {formatMoney(getTaxAmount(formData?.billed_amount))}
                  </div>
                </div>
              </div>

              <div className="flex justify-center items-center mt-[20px] py-[12px]">
                <div className="w-[290px] flex items-center ml-[-70px]">
                  <div className="text-primary text-[16px] font-[500] leading-[100%] w-full text-right">
                    料金合計
                  </div>
                  <div className="text-[18px] font-[500] leading-[100%] w-full text-right">
                    {formatMoney(getBilledAmountWithTax(formData?.billed_amount))}
                  </div>
                </div>
              </div>

              {originSubscribed?.id && (
                <div className="w-full flex items-center justify-center py-[25px] gap-[20px]">
                  <PriceChangeCalculation
                    contracts={latestPriceList}
                    subscription={formData}
                    originSubscribed={originSubscribed}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <FormLine label="注意事項">
                <div className="w-full flex items-center ml-[16px] py-[15 px] gap-[20px] pb-[15px]">
                  <RenderUnSubscribeSubscriptionAnnouncement />
                </div>
              </FormLine>
              <div className='2xl:w-[43%] w-[46%] mx-auto pb-[10px] pt-[30px]'>
                <RenderConfirmPassWordUnSubscribeSubscription 
                  handleChange={handleChangeAdditionData}
                />
              </div>
            </>
          )

        }

        <div className="flex justify-center gap-[15px] mt-[30px]">
          <SubmitButton type="return" onClick={returnHandle} disabled={isCheckingOut}>
            前の画面へ戻る
          </SubmitButton>
          <SubmitButton 
            type="unsubscribe" 
            onClick={submitHandle} 
            disabled={isCheckingOut || (isCancel && verifyData.confirmation !== CONFIRM_UNSUBSCRIBE_CHECKED)}
          >
            {isCheckingOut ? <Spinner /> : submitText}
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default BasicSubscriptionConfirmation;
