import ServiceBase from ".";
import ServiceList from "../../../components/user/Services/List/List";

const ServiceListPage = () => {
  return (
    <ServiceBase title="サービス一覧">
      <ServiceList />
    </ServiceBase>
  );
};

export default ServiceListPage;
