import dayjs from 'dayjs';

const getDaysInMonth = (month: number, year: number) =>
  Array.from(
    { length: new Date(year, month, 0).getDate() },
    (_, i) => new Date(year, month - 1, i + 1),
  );

const getCommonDate = (date?: Date | string) => (date ? dayjs(date).format('YYYY-MM-DD') : '');

const isToday = (dateInput?: string) => {
  if (!dateInput || !dayjs(dateInput).isValid()) return false;

  return dayjs(dateInput).isToday();
};

const isValidDate = (date?: Date | string | null): boolean => dayjs(date || null).isValid();

const getDate = (date: Date | string): string => {
  // get end date in calendar
  if (!isValidDate(date)) return new Date(new Date()).toISOString();
  return new Date(date).toISOString();
};

const addMonths = (date: Date, months: number) => {
  date.setMonth(date.getMonth() + months);

  return date;
};

export { getDaysInMonth, getCommonDate, isToday, isValidDate, getDate, addMonths };
