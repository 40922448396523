const BREADCRUMB_SEPARATOR = '〉';

const WEEKDAYS = ['月', '火', '水', '木', '金', '土', '日'];

const BASE_LOCAL = 'ja';

const DIRECTION = {
  UP: 'up',
  DOWN: 'down',
  RIGHT: 'right',
  LEFT: 'left',
  CENTER: 'center',
} as const;

const STATUS = {
  ACTIVE: '有効',
  INACTIVE: '無効',
};

const PUBLIC_STATUS = {
  PUBLIC: '公開',
  PRIVATE: '非公開',
};

const STATUS_DATA = [
  { value: 0, label: STATUS.INACTIVE },
  { value: 1, label: STATUS.ACTIVE },
];

const PUBLIC_STATUS_DATA = [
  { value: 1, label: PUBLIC_STATUS.PUBLIC },
  { value: 0, label: PUBLIC_STATUS.PRIVATE },
];

const STEP = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
};

const TYPE_DATE_FORMAT = {
  REGULAR: 1,
  YEAR_MONTH: 2,
  REGULAR_GLOBAL: 3,
  TIME_HOUR_MINUTE: 4,
  TIME_HOUR_MINUTE_24H: 5,
  MONTH_DAY: 6,
  SHORT_DEFAULT: 7,
  FULL_TO_MINUTE_DEFAULT: 8,
  REGULAR_DASH: 9,
};

const BASE_PASSPHRASE = 'BASE_PASSPHRASE';

const HTTP_CODE = {
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  EARLY_HINTS: 103,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  ALREADY_REPORTED: 208,
  IM_USED: 226,
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  MISDIRECTED_REQUEST: 421,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  TOO_EARLY: 425,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  LOOP_DETECTED: 508,
  BANDWIDTH_LIMIT_EXCEEDED: 509,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
};

const STORAGE_KEY_PATH_HISTORY = 'path-history';

const PALETTE_FEATURE = {
  MANABITE: {
    ID: 1,
    LABEL: 'LMS'
  },
  TOKITE: {
    ID: 4,
    LABEL: 'CBT'
  },
};

export {
  BREADCRUMB_SEPARATOR,
  WEEKDAYS,
  BASE_LOCAL,
  DIRECTION,
  STATUS_DATA,
  STEP,
  PUBLIC_STATUS_DATA,
  TYPE_DATE_FORMAT,
  BASE_PASSPHRASE,
  HTTP_CODE,
  STORAGE_KEY_PATH_HISTORY,
  PALETTE_FEATURE,
};
