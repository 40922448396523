import React from "react";
import { CiSettings } from "react-icons/ci";

const Setting = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <CiSettings 
            className="text-[#BEBEBE]"
            size={24}
        />
    );
};

export default Setting;

