import qs from 'qs';
import { useLocation } from 'react-router';
import { useSearchParams, useNavigate } from 'react-router-dom';

export const useObjectRoutes = () => {
  const location = useLocation();
  const searchString = location.search.substring(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const getParamValue = (field: string) => {
    return searchParams.get(field);
  };

  const searchParamsObject = qs.parse(searchString);

  const getSearchParamObject = (searchString: string) => {
    return qs.parse(searchString);
  };

  const getSearchParamsPlainObject = (searchString: string) =>
    !!searchString
      ? JSON.parse(
          '{"' + searchString.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
          (key, value) => (key === '' ? value : decodeURIComponent(value)),
        )
      : {};

  const pathname = location.pathname;

  return {
    searchParams,
    setSearchParams,
    navigate,
    getParamValue,
    getSearchParamObject,
    getSearchParamsPlainObject,
    searchParamsObject,
    pathname,
    searchString,
  };
};
