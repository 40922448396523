import { CiWarning } from "react-icons/ci";

const SuggestBox = () => {
    return (
        <div className="2xl:w-[50%] xl:w-[60%] lg:w-[60%] w-[60%] border-l-2 mt-[25px] mb-[40px] ml-[40px]">
            <p className="mx-[15px] text-[14px]">※ 退会手続きではなく「無料プラン（自動更新の解除）」をご検討の際は以下にご注意ください</p>
            <div className="w-full gap-x-[10px] gap-y-[20px] flex justify-start pl-[25px] pr-[10px] mt-[10px]">
                <CiWarning 
                    className="text-red-500"
                    size={"25px"}
                />
                <div className="text-[12px] w-full">
                    <div className="text-red-500">無料プランのアカウント数には上限があります。</div>
                    <div className="">アカウント数が超過したまま「自動更新を解除する」と全ユーザーがサービスにログインできなくなります。</div>
                    <div>自動更新を解除する前に現在のアカウント数を必ずご確認ください。</div>
                </div>
            </div>
            <div className="w-full gap-x-[10px] gap-y-[20px] flex justify-start pl-[25px] pr-[10px] mt-[15px]">
                <CiWarning 
                    className="text-red-500"
                    size={"25px"}
                />
                <div className="text-[12px] w-full">
                    <div className="text-red-500">無料プランのストレージ容量には上限があります。</div>
                    <div className="inline">
                        <div className="inline">ストレージにアップロードされたデータが無料プランの容量を超えている場合、「自動更新を解除する」とデータはすべて削除されます。</div>
                        <div className="text-red-500 inline">データの復元はできません。</div>
                        <div className="inline">自動更新を解除する前に現在のストレージ使用容量を必ずご確認ください。</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuggestBox