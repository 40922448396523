import React from 'react';
import { ElementProps } from '../../../types/globals';
import Spinner from '../icons/Animations/Spinner';

type FormButtonProps = {
  isSubmitting?: boolean;
  label?: string;
  spinner?: JSX.Element;
  disabled?: boolean;
  className?: string
} & ElementProps<HTMLButtonElement>;

const FormButton = ({
  isSubmitting,
  label,
  spinner,
  disabled = isSubmitting,
  className,
  ...props
}: FormButtonProps) => {
  return (
    <button
      className={`${disabled || isSubmitting ? 'opacity-70 pointer-events-none' : ''} ${className}`}
      disabled={disabled || isSubmitting}
      {...props}
    >
      <div className="flex items-center justify-center w-full">
        {isSubmitting ? spinner || <Spinner width={17} height={17} /> : <>{label || 'button'}</>}
      </div>
    </button>
  );
};

export default FormButton;
