import { getTabObj } from '../../../helpers/parseData';
import { PATH_CONTRACT } from '../../route/router';

const CONTRACT_TAB_ITEM = {
  OVERVIEW: '契約管理',
  PURCHASE_HISTORY: '請求履歴',
  PAYMENT_SETTING: 'お支払い方法',
};

const CONTRACT_TAB_DATA = [
  getTabObj(CONTRACT_TAB_ITEM.OVERVIEW, PATH_CONTRACT.OVERVIEW),
  // getTabObj(CONTRACT_TAB_ITEM.PURCHASE_HISTORY, PATH_CONTRACT.PURCHASE_HISTORY),
  // getTabObj(CONTRACT_TAB_ITEM.PAYMENT_SETTING, PATH_CONTRACT.PAYMENT_SETTING),
];

const CONTRACT_OVERVIEW_MODE = {
  FEATURE_BASIC: 'feature-basic',
  STATUS_DETAIL: 'status-detail',
};

const CONTRACT_SUBSCRIPTION_TYPE = {
  BASIC: 1,
  STORAGE: 2,
  PRODUCT: 3,
  FREE_SETTING: 5,
  SUPPORT: 6,
};

const SUBSCRIPTION_ADDITION_TYPES = [
  CONTRACT_SUBSCRIPTION_TYPE.PRODUCT,
  CONTRACT_SUBSCRIPTION_TYPE.STORAGE,
  CONTRACT_SUBSCRIPTION_TYPE.SUPPORT,
];

const CONTRACT_SUBSCRIPTION_TYPE_NAME = {
  BASIC_NAME: 'パレットLMS受講者アカウント料金',
  CBT_BASIC_NAME: 'パレットCBT受験者アカウント料金',
  STORAGE_NAME: '追加ストレージ',
  PRODUCT_NAME: '講座販売',
  SUPPORT_NAME: '導入サポート',
};

const CONTRACT_SUBSCRIPTION_PERIOD = {
  MONTHLY: 1,
  YEARLY: 2,
};

const PERIOD_RADIO_DATA = [
  { value: CONTRACT_SUBSCRIPTION_PERIOD.MONTHLY, label: '月額' },
  // { value: CONTRACT_SUBSCRIPTION_PERIOD.YEARLY, label: '年額' }, // remove by cr: https://educon.backlog.com/view/LMS2023-1363
];

const CONTRACT_SUBSCRIPTION_STATUS = {
  UNAVAILABLE: 0,
  SUBMITTED: 1,
  PAYMENT_PROCESSING: 2,
  PAYMENT_SUCCEEDED: 3,
  PAYMENT_FAILED: 4,
  INUSE_INTERVAL: 5,
  SUSPENDED: 6,
  CANCELLED: 7,
  INUSE_FOR_END_OF_PERIOD: 8,
  IN_SCHEDULE: 9,
};

const SUBSCRIPTION_STEP = {
  FORM_FILL: 'formfill',
  CONFIRMATION: 'confirmation',
  CONFIRMATION_UNSUBSCRIBE: 'confirmationunsubscribe',
  CONFIRMATION_CHANGE: 'confirmationchange',
};

const SUBSCRIPTION_COMPLETE_TYPE = {
  COMPLETE: 'complete',
  CHANGE_COMPLETE: 'changecomplete',
  UNSUBSCRIBE_COMPLETE: 'unsubscribecomplete',
};

const SUBSCRIPTION_CHECKOUT_PRODUCT_IMAGE =
  'https://fastly.picsum.photos/id/1/200/200.jpg?hmac=jZB9EZ0Vtzq-BZSmo7JKBBKJLW46nntxq79VMkCiBG8';

const CONTRACT_STORAGE_KEY = {
  PAST_DUE_PAYMENT: 'contractPastDuePaymentKey',
  LIMIT_EXCEED_KEY: 'contractLimitExceedKey',
};

const SUBSCRIPTION_CHECKOUT_TYPE = {
  SUBSCRIBE: 'checkoutsubscribe',
  SUBSCRIBE_CHANGE: 'checkoutsubscribechange',
  UNSUBSCRIBE: 'checkoutunsubscribe',
};

const SUBSCRIPTION_CONST = {
  REQUIRED_ACCOUNT_QUANTITY: 100,
  TAX_RATE_PERCENTAGE_DEFAULT: 10,
  LIMIT_QUANTITY: 9000000000,
  SUBSCRIPTION_LABEL_STORAGE_KEY: 'subscriptionLabelStorageKey',
};
// options from 0 to 100
const STORAGE_CONTRACT_NUMBER_OPTIONS = [...new Array(100)].map((each, index) => ({
  label: index + 1,
  value: index + 1,
}));

const STORAGE_PLUS_PLAN_PACKAGE_AMOUNT = 100;

const SUBSCRIPTION_TOOLTIPS = [
  {
    type: CONTRACT_SUBSCRIPTION_TYPE.BASIC,
    content: `受講者アカウント数に応じて１アカウントあたりの料金が変動します。
    「利用人数登録」のボタンからご契約いただけます。`,
  },
  {
    type: CONTRACT_SUBSCRIPTION_TYPE.SUPPORT,
    content:
      '初回導入をオンラインでサポートいたします。1回分の料金です。定額利用サービスではございません。',
  },
  {
    type: CONTRACT_SUBSCRIPTION_TYPE.STORAGE,
    content: 'ストレージの空きが不足した場合は100GBごとに容量を追加購入いただけます。',
  },
  {
    type: CONTRACT_SUBSCRIPTION_TYPE.PRODUCT,
    content: 'オンライン講座・教材を受講者へ販売するためのEC機能のご登録はこちら。',
  },
];

const CONFIRM_UNSUBSCRIBE_CHECKED = 1;
const CONFIRM_UNSUBSCRIBE_UNCHECKED = 0;

export {
  CONTRACT_TAB_ITEM,
  CONTRACT_TAB_DATA,
  CONTRACT_OVERVIEW_MODE,
  CONTRACT_SUBSCRIPTION_PERIOD,
  CONTRACT_SUBSCRIPTION_TYPE,
  CONTRACT_SUBSCRIPTION_TYPE_NAME,
  CONTRACT_SUBSCRIPTION_STATUS,
  SUBSCRIPTION_STEP,
  PERIOD_RADIO_DATA,
  SUBSCRIPTION_COMPLETE_TYPE,
  SUBSCRIPTION_ADDITION_TYPES,
  SUBSCRIPTION_CHECKOUT_PRODUCT_IMAGE,
  CONTRACT_STORAGE_KEY,
  STORAGE_CONTRACT_NUMBER_OPTIONS,
  SUBSCRIPTION_CHECKOUT_TYPE,
  SUBSCRIPTION_CONST,
  STORAGE_PLUS_PLAN_PACKAGE_AMOUNT,
  SUBSCRIPTION_TOOLTIPS,
  CONFIRM_UNSUBSCRIBE_CHECKED,
  CONFIRM_UNSUBSCRIBE_UNCHECKED
};
