import { PATH_SUPER_ADMIN } from "../route/router";
import { IoSettingsOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { ItemType } from "../../../types/globals";

const SUPER_ADMIN_MENU = {
  LOGOUT: "ログアウト",
  PROFILE: "プロフィール編集",
};

const SUPER_ADMIN_MENU_DATA: ItemType[] = [
  {
    label: SUPER_ADMIN_MENU.PROFILE,
    href: PATH_SUPER_ADMIN.PROFILE,
    icon: IoSettingsOutline,
  },
  {
    label: SUPER_ADMIN_MENU.LOGOUT,
    href: PATH_SUPER_ADMIN.LOGOUT,
    icon: MdLogout,
  },
];

const SUPER_ADMIN_MENU_BORDER_INDEX = [0, 3, 7, 10];

export { SUPER_ADMIN_MENU, SUPER_ADMIN_MENU_BORDER_INDEX, SUPER_ADMIN_MENU_DATA };
