import { useEffect, useState } from 'react';
import { useObjectRoutes } from '../../../../hooks/useObjectRoutes';
import { CONTRACT_OVERVIEW_MODE } from '../../../../services/constants/user/pages/contract';
import FeatureContractOverview from './FeatureContractOverview';
import StatusContractOverview from './StatusContractOverview';

const ContractOverview = () => {
  const { getParamValue } = useObjectRoutes();
  const viewModeParam = getParamValue('view_mode');
  const [viewMode, setViewMode] = useState(CONTRACT_OVERVIEW_MODE.FEATURE_BASIC);

  useEffect(() => {
    if (!viewModeParam) return;
    setViewMode(viewModeParam);
  }, [viewModeParam]);

  return (
    <div className="p-[20px]">
      <div className="mt-[20px]">
        {viewMode === CONTRACT_OVERVIEW_MODE.STATUS_DETAIL ? (
          <StatusContractOverview />
        ) : (
          <FeatureContractOverview />
        )}
      </div>
    </div>
  );
};

export default ContractOverview;
