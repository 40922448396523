import React from "react";

const ContactIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.39535 3.3472C1.39535 2.70501 1.91595 2.18441 2.55814 2.18441H17.4419C18.0841 2.18441 18.6047 2.70501 18.6047 3.3472V12.6495C18.6047 13.2917 18.0841 13.8123 17.4419 13.8123H2.55814C1.91595 13.8123 1.39535 13.2917 1.39535 12.6495V3.3472ZM2.55814 0.789062C1.14532 0.789062 0 1.93438 0 3.3472V12.6495C0 14.0624 1.14532 15.2077 2.55814 15.2077H17.4419C18.8547 15.2077 20 14.0624 20 12.6495V3.3472C20 1.93438 18.8547 0.789062 17.4419 0.789062H2.55814ZM5.74889 4.63611C5.43322 4.41515 4.9982 4.49191 4.77724 4.80758C4.55627 5.12324 4.63304 5.55826 4.94871 5.77922L9.59987 9.03504C9.84009 9.2032 10.1598 9.2032 10.4 9.03504L15.0512 5.77922C15.3669 5.55826 15.4436 5.12324 15.2227 4.80758C15.0017 4.49191 14.5667 4.41515 14.251 4.63611L9.99996 7.61186L5.74889 4.63611Z"
        fill="#FA646A"
      />
    </svg>
  );
};

export default ContactIcon;
