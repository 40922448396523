import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ErrorData } from "../../../../types/globals";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { RiErrorWarningLine } from "react-icons/ri";

import { PATH_SUPER_ADMIN } from "../../../../services/constants/route/router";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import ErrorBox from "../../../commons/form/ErrorBox";
import { TbTrash } from "react-icons/tb";

import { USER_STATUS } from "../../../../services/constants/user";
import { BsCheck2Circle } from "react-icons/bs";
import {
  swalClose,
  swalLoading,
  swallConfirm,
} from "../../../../services/helpers/swal";
import {
  DELETE_CONFIRM_CONTENT,
  DELETE_CONFIRM_TITLE,
} from "../../../../services/constants/message";
import FormItem from "../../../user/commons/FormItem";
import SemiSquareButton from "../../../user/commons/SemiSquareButton";
import { SuperAdminAccountFormData } from "../../../../types/user/account";
import ImageInternalButton from "../../../commons/buttons/ImageInternalButton";
import FormButton from "../../../commons/form/FormButton";

const SuperAdminAccountForm = () => {
  const navigate = useNavigate();
  const [superAdminAccountFormData, setSuperAdminAccountFormData] =
    useState<SuperAdminAccountFormData>({
      status: USER_STATUS.CONFIRMING,
    });
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [fileError, setFileError] = useState<string>("");
  const [errors, setErrors] = useState<ErrorData>({});
  const { getParamValue } = useObjectRoutes();
  const accountId = getParamValue("account_id");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!accountId) return;

    const fetchDetail = async () => {
      await request.get(
        `${API.SUPER_ADMIN.ACCOUNT.DETAIL}?user_id=${accountId}`,
        (accountData) => {
          setSuperAdminAccountFormData(accountData || {});
        }
      );
    };

    fetchDetail();
  }, [accountId]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const file = (e.target as HTMLInputElement).files?.[0];
    if (name === "image" && !value) return;
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Size in MB

      // Check file type
      if (!fileType.startsWith("image/")) {
        setFileError("無効なファイルタイプです。画像を選択してください。");
        return;
      }

      // Check file size
      if (fileSize > 2) {
        setFileError("ファイルサイズが2MBの最大制限を超えています。");
        return;
      }

      setFileError("");
      setSuperAdminAccountFormData({
        ...superAdminAccountFormData,
        [name]: file,
      });
      return;
    }

    setSuperAdminAccountFormData({
      ...superAdminAccountFormData,
      [name]: value,
    });
  };

  const handleDelete = async () => {
    if (!accountId) return;

    swallConfirm(
      async () => {
        // swalLoading();
        await request.delete(
          `${API.SUPER_ADMIN.ACCOUNT.DELETE}?user_id=${accountId}`,
          () => {},
          () => navigate(PATH_SUPER_ADMIN.USER_ACCOUNT.DEFAULT),
          setErrors,
          { withSuccess: true }
        );
      },
      DELETE_CONFIRM_TITLE.USER,
      DELETE_CONFIRM_CONTENT.USER
    );
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let submitAction = request.post;
    let submitUrl = API.SUPER_ADMIN.ACCOUNT.CREATE;
    let submitData = {
      ...superAdminAccountFormData,
    };

    if (accountId) {
      submitUrl = API.SUPER_ADMIN.ACCOUNT.UPDATE;
      submitData = { ...submitData, user_id: accountId };
    }
    await submitAction(
      submitUrl,
      submitData,
      () => {
        setIsSubmitting(false);
        navigate(PATH_SUPER_ADMIN.USER_ACCOUNT.DEFAULT);
      },
      (errors) => {
        setIsSubmitting(false);
        setErrors(errors);
      },
      { withSuccess: true, withLoading: true, hasFileRequest: true }
    );
  };

  const removeImage = () => {
    const newData: SuperAdminAccountFormData = {
      ...superAdminAccountFormData,
      image: undefined,
      avatar_url: undefined,
      delete_avatar: 1,
    };
    setSuperAdminAccountFormData(newData);
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  return (
    <div>
      {/* errors */}
      {errors && !!Object.keys(errors).length && (
        <div className="mb-[20px]">
          <ErrorBox errors={errors} />
        </div>
      )}
      {/* Account form */}
      <FormItem label="ログインID（半角英数のみ８〜20）" withRequired>
        <input
          className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
          name="username"
          value={superAdminAccountFormData.username}
          onChange={handleChange}
          placeholder="dummy_user_name"
        />
      </FormItem>
      {!accountId && (
        <FormItem
          label="パスワード"
          labelClass="!items-start !pt-[12px]"
          withRequired
        >
          <div className="flex flex-col gap-[9px] w-full">
            <div className="text-secondary-dark w-full flex items-center">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] max-w-[330px] border-[#E5E7EB] rounded-[5px]"
                type="password"
                name="password"
                value={superAdminAccountFormData.password}
                onChange={handleChange}
                autoComplete="new-password"
              />
              <span className="text-[10px] flex items-center ml-[10px]">
                半角英数小文字8〜20文字（記号可）
              </span>
            </div>
            <div className="text-secondary-dark w-full flex items-center">
              <input
                className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] max-w-[330px] border-[#E5E7EB] rounded-[5px]"
                type="password"
                name="password_confirmation"
                value={superAdminAccountFormData.password_confirmation}
                onChange={handleChange}
                autoComplete="new-password"
              />
              <span className="text-[10px] flex items-center ml-[10px]">
                ※確認のためもう一度入力してください
              </span>
            </div>
          </div>
        </FormItem>
      )}
      <FormItem label="メールアドレス" withRequired>
        <div className="flex items-center">
          <input
            className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] w-full max-w-[330px] placeholder-secondary-light"
            name="email"
            value={superAdminAccountFormData.email}
            onChange={handleChange}
            placeholder="dummyusername@mail.com"
          />

          {superAdminAccountFormData.status === USER_STATUS.CONFIRMING ||
          !superAdminAccountFormData.status ? (
            <div className="min-w-[90px] ml-[16px] pl-[12px] pt-[3px] pb-[5px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
              <RiErrorWarningLine
                className="absolute top-[2px] left-[4px]"
                size={18}
              />
              <p className="pl-[12px]">確認中</p>
            </div>
          ) : (
            <div className="min-w-[90px] ml-[5px] pl-[17px] pt-[3px] pb-[5px] text-[#749F91] bg-[#D7F5F2] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
              <BsCheck2Circle
                className="absolute top-[2px] left-[4px]"
                size={18}
              />
              <p className="pl-[12px]">確認済み</p>
            </div>
          )}
        </div>
      </FormItem>
      <FormItem label="ステータス">
        {(!accountId ||
          superAdminAccountFormData.status === USER_STATUS.CONFIRMING ||
          !superAdminAccountFormData.status) && (
          <label className="inline-flex items-center mr-[15px]">
            <input
              type="radio"
              className="form-radio text-primary-light border border-success-extralight"
              name="status"
              value={USER_STATUS.CONFIRMING}
              checked={
                Number(superAdminAccountFormData.status) ===
                USER_STATUS.CONFIRMING
              }
              onChange={handleChange}
            />
            <span
              className={
                "ml-[8px] text-[12px] leading-[100%] text-secondary-dark"
              }
            >
              確認中
            </span>
          </label>
        )}
        <label className="inline-flex items-center mr-[15px]">
          <input
            type="radio"
            className="form-radio text-primary-light border border-success-extralight"
            name="status"
            value={USER_STATUS.ACTIVE}
            checked={
              Number(superAdminAccountFormData.status) === USER_STATUS.ACTIVE
            }
            onChange={handleChange}
            disabled={
              superAdminAccountFormData.status === USER_STATUS.CONFIRMING ||
              !superAdminAccountFormData.status
            }
          />
          <span
            className={`ml-[8px] text-[12px] leading-[100%] ${
              superAdminAccountFormData.status === USER_STATUS.CONFIRMING ||
              !superAdminAccountFormData.status
                ? "text-secondary-light"
                : "text-secondary-dark"
            }`}
          >
            有効
          </span>
        </label>
        <label className="inline-flex items-center mr-[15px]">
          <input
            type="radio"
            className="form-radio text-primary-light border border-success-extralight"
            name="status"
            value={USER_STATUS.INACTIVE}
            checked={
              Number(superAdminAccountFormData.status) === USER_STATUS.INACTIVE
            }
            onChange={handleChange}
            disabled={
              superAdminAccountFormData.status === USER_STATUS.CONFIRMING ||
              !superAdminAccountFormData.status
            }
          />
          <span
            className={`ml-[8px] text-[12px] leading-[100%] ${
              superAdminAccountFormData.status === USER_STATUS.CONFIRMING ||
              !superAdminAccountFormData.status
                ? "text-secondary-light"
                : "text-secondary-dark"
            }`}
          >
            停止中
          </span>
        </label>
      </FormItem>
      <FormItem label="パレット管理者氏名" withRequired>
        <div className="flex gap-[9px]  w-full">
          <div className="text-secondary-dark w-full flex items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
              name="last_name"
              value={superAdminAccountFormData.last_name}
              onChange={handleChange}
              placeholder="鈴木"
            />
          </div>
          <div className="text-secondary-dark w-full flex items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
              name="first_name"
              value={superAdminAccountFormData.first_name}
              onChange={handleChange}
              placeholder="一郎"
            />
          </div>
        </div>
      </FormItem>
      <FormItem label="パレット管理者氏名（カナ）">
        <div className="flex gap-[9px] w-full">
          <div className="text-secondary-dark w-full flex items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
              name="last_name_kana"
              value={superAdminAccountFormData.last_name_kana}
              onChange={handleChange}
              placeholder="スズキ"
            />
          </div>
          <div className="text-secondary-dark] w-full flex items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
              name="first_name_kana"
              value={superAdminAccountFormData.first_name_kana}
              onChange={handleChange}
              placeholder="イチロウ"
            />
          </div>
        </div>
      </FormItem>
      <FormItem label="プロフィール画像のアップロード">
        <div className="flex items-center">
          {superAdminAccountFormData.image ||
          superAdminAccountFormData.avatar_url ? (
            <div className="relative w-[330px] object-cover mr-4 border-gray-200 my-[5px]">
              <ImageInternalButton
                removeImage={removeImage}
                editImage={() => inputFileRef.current?.click()}
              />
              <img
                src={
                  superAdminAccountFormData.image
                    ? URL.createObjectURL(superAdminAccountFormData.image)
                    : superAdminAccountFormData.avatar_url
                }
                alt="Profile Avatar"
              />
            </div>
          ) : (
            <div
              className="w-full max-w-[330px] h-[30px] flex items-center justify-center mr-4  border-[#EFF1F0] border-[1px] my-[5px]"
              onClick={() => inputFileRef.current?.click()}
            ></div>
          )}
          <label
            htmlFor="image"
            className="flex items-center justify-center w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-secondary-light cursor-pointer"
          >
            ファイルを選択
          </label>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            className="hidden"
            ref={inputFileRef}
            onChange={handleChange}
            placeholder="ファイルを選択"
          />
          {!superAdminAccountFormData.image && (
            <div className="ml-[10px] text-[10px] font-[400] leading-[19px]">
              選択されていません
            </div>
          )}
        </div>
        {fileError && <div className="text-red-500 text-xs">{fileError}</div>}
      </FormItem>

      {/* Submit Buttons */}
      <div className="w-full flex justify-center items-center mb-[58px] mt-[66px] gap-[15px]">
        <SemiSquareButton
          className="bg-secondary-light max-w-[212px]"
          onClick={() => navigate(PATH_SUPER_ADMIN.USER_ACCOUNT.DEFAULT)}
        >
          一覧画面へ戻る
        </SemiSquareButton>
        {accountId ? (
          <FormButton
            className="bg-primary px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[16px] leading-[100%] max-w-[244px] h-[36px]"
            onClick={handleSubmit}
            isSubmitting={isSubmitting}
            label="内容を変更する"
          />
        ) : (
          <FormButton
            className="bg-primary px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[16px] leading-[100%] max-w-[244px] h-[36px]"
            onClick={handleSubmit}
            isSubmitting={isSubmitting}
            label="入力内容で登録する"
          />
        )}
        {accountId && (
          <SemiSquareButton
            onClick={handleDelete}
            className="max-w-[300px] bg-secondary text-white flex items-center max-h-[36px]"
          >
            <span className="text-white whitespace-nowrap">
              このアカウントを削除
            </span>
            <TbTrash className="ml-[15px] text-white" size={30} />
          </SemiSquareButton>
        )}
      </div>
    </div>
  );
};

export default SuperAdminAccountForm;
