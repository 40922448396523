import { getTabObj } from "../../../helpers/parseData";
import { PATH_REVENUE, PATH_USER_ACCOUNT } from "../../route/router";

const INVOICE_STATUS = {
  NOT_PAID: {
    value: 0,
    label: "未入金"
  },
  PAID: {
    value: 1,
    label: "支払済み"
  }
}

const REVENUE_TAB_ITEM = {
  INVOICE_LIST: "請求書払い",
};

const REVENUE_TAB_DATA = [
  getTabObj(REVENUE_TAB_ITEM.INVOICE_LIST, PATH_REVENUE.INVOICE_LIST),
];

export { REVENUE_TAB_ITEM, REVENUE_TAB_DATA, INVOICE_STATUS };
