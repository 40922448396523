import Swal, { SweetAlertOptions } from "sweetalert2";
import { MESSAGE_COMMON, MESSAGE_ERROR } from "../constants/message";

const swalMessage = (
  title: string | "",
  message: string | "",
  type: "error" | "success" | "warning" | "info",
  option?: SweetAlertOptions
) => {
  Swal.fire({
    title: title,
    text: message,
    icon: type,
    confirmButtonText: "OK",
    ...option,
  });
};

const swalLoading = () =>
  Swal.fire({
    footer: MESSAGE_COMMON.LOADING,
    didOpen: () => {
      Swal.showLoading();
    },
  });

const swalError = (errorMessage = "", duration = 2000) =>
  swalMessage("エラー", errorMessage, "error", {
    timer: duration,
    showConfirmButton: false,
    footer: MESSAGE_ERROR.SOMETHINGS_WENT_WRONG,
  });

const swalSuccess = () =>
  swalMessage("成功", "", "success", {
    timer: 1000,
    showConfirmButton: false,
  });

const swalInfo = (infoMessage: string) =>
  swalMessage("", infoMessage, "info", {
    timer: 1000,
    showConfirmButton: false,
  });

const swallConfirm = (
  callbackFn: () => void,
  title?: string,
  textHtml?: string,
  options: any = null,
  confirmButtonColor: string = "削除する"
) => {
  return Swal.fire({
    title: `<div style='font-weight: 600; font-size: 18px; line-height: 24px; letter-spacing: 0.2px; color: #2E2E2E;'>${
      title || "このコースを削除してよろしいですか？"
    }</div>`,
    html: textHtml
      ? `<div style='font-weight: 500; font-size: 14px; line-height: 100%; letter-spacing: 0.1px; color: #FA646A; overflow: hidden; line-height: 19.8px'>${textHtml}</div>`
      : "",
    icon: "question",
    showCancelButton: true,
    cancelButtonText: "キャンセル",
    confirmButtonText: confirmButtonColor,
    confirmButtonColor: "#FE6D73",

    focusConfirm: false,
    reverseButtons: true,
    ...options,
  }).then((result) => {
    if (result.isConfirmed) {
      callbackFn();
    }
  });
};

const swalClose = () => Swal.close();

export {
  swalMessage,
  swalLoading,
  swalClose,
  swalError,
  swalSuccess,
  swallConfirm,
  swalInfo,
};
