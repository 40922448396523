import React, { ChangeEvent, useEffect } from 'react';
import { useState } from 'react';
import { USER_STATUS } from '../../../../services/constants/user';
import { request } from '../../../../services/axios/axios';
import { paramizeObject } from '../../../../services/helpers/parseData';
import { API } from '../../../../services/constants/route/api';
import AccountFilter from './Filter';
import AccountTable from './Table';
import { Account } from '../../../../types/user/account';
import TableCommonAction from '../../commons/TableCommonAction';

type FilterType = {
  query: string;
  limit: number;
  page: number;
  isAll?: number;
  sortType?: number;
  section_id?: number | string;
};

const AccountList = () => {
  const [filter, setFilter] = useState<FilterType>({
    limit: 10,
    query: '',
    isAll: USER_STATUS.CONFIRMING,
    page: 1,
    sortType: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isFetching, setIsFetching] = useState(true);

  const resetTable = () => {
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    // update the current page state
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };

  useEffect(() => {
    const fetch = async () => {
      // swalLoading();
      setIsFetching(true);
      await request.get(
        `${API.USER_ACCOUNT.LIST}?${paramizeObject(filter)}`,
        (accountData) => {
          setAccounts(accountData.users);
          setTotalPages(accountData.last_page);
          setIsFetching(false);
        },
        () => {
          setIsFetching(false);
        },
      );
      // swalClose();
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const changeHandle = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.target.type === 'checkbox') {
      const target = e.target as HTMLInputElement;
      if (target.checked) {
        setFilter({
          ...filter,
          isAll: USER_STATUS.ACTIVE,
          page: 1,
        });
        resetTable();
        return;
      } else {
        setFilter({ ...filter, isAll: USER_STATUS.CONFIRMING, page: 1 });
        resetTable();
        return;
      }
    }
    setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
    resetTable();
  };
  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };
  return (
    <div>
      <TableCommonAction />
      <AccountFilter changeHandle={changeHandle} />
      {!isFetching ? (
        <AccountTable
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          accounts={accounts}
          totalPages={totalPages}
        />
      ) : (
        <div className="skeleton w-full h-[400px]"></div>
      )}
    </div>
  );
};

export default AccountList;
