import React from "react";

const Link = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "16"}
      height={props.width || "16"}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.33317 6.66659L6.6665 9.33325"
        stroke={props.color || "#BEBEBE"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6668 8.66659L12.0002 7.33325C12.9206 6.41278 12.9206 4.92039 12.0002 3.99992V3.99992C11.0797 3.07944 9.5873 3.07945 8.66683 3.99992L7.3335 5.33325M5.3335 7.33325L4.00016 8.66659C3.07969 9.58706 3.07969 11.0794 4.00016 11.9999V11.9999C4.92064 12.9204 6.41302 12.9204 7.3335 11.9999L8.66683 10.6666"
        stroke={props.color || "#BEBEBE"}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Link;
