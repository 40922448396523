import React from "react";
import { HiPlus } from "react-icons/hi";
import { IoMdDownload, IoMdExit } from "react-icons/io";
import { TbFileDescription } from "react-icons/tb";
import RoundedButton from "./RoundedButton";
import { Link, useLocation } from "react-router-dom";
import { PATH_USER_ACCOUNT } from "../../../services/constants/route/router";

const TableCommonAction = ({
  ...props
}: React.HTMLProps<HTMLElement>) => {
  const currentLocation = useLocation();
  return (
    <section {...props}>
      <div className="flex justify-end gap-[10px] pr-[43px]">
        {currentLocation.pathname === "/admin/student/create" ? (
          <RoundedButton isDisabled>
            <HiPlus size={20} />
            <div className="ml-[12px]">新規受講者登録</div>
          </RoundedButton>
        ) : (
          <Link to={PATH_USER_ACCOUNT.CREATE} className="rounded-[20px]">
            <RoundedButton>
              <HiPlus size={20} />
              <div className="ml-[12px] mb-[1px]">新規アカウント登録</div>
            </RoundedButton>
          </Link>
        )}
      </div>
    </section>
  );
};

export default TableCommonAction;
