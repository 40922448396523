import TabCard from "../../../components/user/commons/TabCard";
import { useTabFinder } from "../../../hooks/useTabFinder";
import UserLayout from "../../../layouts/user";
import { PATH_COMPANY_INFO } from "../../../services/constants/route/router";
import { COMPANY_TAB_DATA } from "../../../services/constants/user/pages/company";
import { WithChildren } from "../../../types/globals";

type CompanyProps = {
  title: string;
};

const CompanyBase = ({ title, children }: WithChildren<CompanyProps>) => {
  const { tabIndex } = useTabFinder(Object.values(PATH_COMPANY_INFO));

  return (
    <UserLayout>
      <TabCard tabData={COMPANY_TAB_DATA} tabIndex={tabIndex} title={title}>
        {children}
      </TabCard>
    </UserLayout>
  );
};

export default CompanyBase;
