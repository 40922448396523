import { Object } from '../../types/globals';
import CryptoJS from 'crypto-js';
import { BASE_PASSPHRASE } from '../constants/globals';

const sortArrayByKey = <T extends Object>(
  array: Array<T>,
  key: string | undefined = 'id',
): Array<T> => {
  if (!array) return [];
  return array.sort((a, b) => {
    if (typeof a[key] === 'number' && typeof b[key] === 'number')
      return Number(a[key]) - Number(b[key]);

    const textA = String(a[key]).toUpperCase(); // ignore upper and lowercase
    const textB = String(b[key]).toUpperCase(); // ignore upper and lowercase
    if (textA < textB) {
      return -1;
    }
    if (textA > textB) {
      return 1;
    }

    //data must be equal
    return 0;
  });
};

const filterUniqueArray = <T extends Object>(array: Array<T>): Array<T> => {
  if (!array) return [];
  return array.filter(
    (course, index, a) => a.findIndex((_course) => _course.id === course.id) === index,
  );
};

const paramizeObject = (object: Object) => new URLSearchParams(object);

const getTabObj = (label: string, href: string) => ({ label, href });

const getRemovedArrayItemByKey = <T extends Object>(
  array?: Array<T>,
  keyValue?: number | string | boolean,
  key: string | undefined = 'id',
): Array<T> => {
  if (!array) return [];
  return array.filter((item) => item[key] !== keyValue);
};

const bindingSortedArray = <T extends Object>(
  array: T[],
  callbackFn: (response: any) => any,
  key?: string,
) => {
  const uniqueArray = filterUniqueArray(array);
  callbackFn(sortArrayByKey(uniqueArray, key || 'order'));
};

const formatThousandByComma = (input?: number): string => {
  if (!input || !Number(input)) return '0';

  return `${input.toLocaleString('en-US')}`;
};

const formatNumberWithComma = (input?: string): number => {
  if (!input || !input.trim()) return 0;

  if (!input?.includes(',')) {
    if (isNaN(Number(input))) return 0;
    return Number(input);
  }

  const parsedInput = input.replaceAll(',', '');

  if (isNaN(Number(parsedInput))) return 0;
  return Number(parsedInput);
};

const formatMoney = (money?: number) => {
  if (!money || !Number(money)) return '0円';

  return `${money.toLocaleString('en-US')}円`;
};

const formatMoneyNoUnit = (data: string|number) => {
  return `${data.toLocaleString('en-US')}`;
}

const basicEncrypted = (content?: string | number, passphrase = BASE_PASSPHRASE) => {
  if (!String(content).trim() || !String(passphrase).trim()) return '';

  return String(CryptoJS.AES.encrypt(String(content), String(passphrase)))
    .replace('+', 'xMl3Jk')
    .replace('/', 'Por21Ld')
    .replace('=', 'Ml32');
};

const basicDecrypted = (encrypted?: string, passphrase = BASE_PASSPHRASE) => {
  if (!String(encrypted).trim() || !String(passphrase).trim()) return '';
  return CryptoJS.AES.decrypt(
    String(encrypted).replace('xMl3Jk', '+').replace('Por21Ld', '/').replace('Ml32', '='),
    String(passphrase),
  ).toString(CryptoJS.enc.Utf8);
};

const getPercent = (number?: number, total?: number): number => {
  if (!number || !total) return 0;
  if (total < number) return 100;
  return (number / total) * 100;
};

const parsePercent = (percent?: number | string): number => {
  if (!percent || !Number(percent)) return 0;
  if (Number(percent) > 100) return 100;
  return Number(percent);
};

// transform form full-width to normal
const transformZenkakuToNormalNumber = (input: string): string => {
  return input.replace(/[０-９]/g, function(char) {
    return String.fromCharCode(char.charCodeAt(0) - 65248);
  });
};

export {
  sortArrayByKey,
  paramizeObject,
  getTabObj,
  filterUniqueArray,
  getRemovedArrayItemByKey,
  bindingSortedArray,
  formatMoney,
  basicEncrypted,
  basicDecrypted,
  formatThousandByComma,
  formatNumberWithComma,
  formatMoneyNoUnit,
  getPercent,
  parsePercent,
  transformZenkakuToNormalNumber
};
