import React from "react";
import { Link } from "react-router-dom";
import { PATH_SUPER_ADMIN } from "../../../../services/constants/route/router";
import { HiOutlineUserCircle } from "react-icons/hi";
import Pagination from "../../commons/Pagination";
import { Organization } from "../../../../types/user/organization";
import OrganizationLabelStatus from "../OrganizationLabelStatus";
import LinkButton from "./LinkButton";
import { IoMdMore } from "react-icons/io";
import { ORGANIZATION_STATUS } from "../../../../services/constants/superadmin/pages/organization";
import { swalMessage } from "../../../../services/helpers/swal";
import OrganizationSupportStatusLabel from "./OrganizationSupportStatusLabel";

const OrganizationTable = ({
  handlePageChange,
  currentPage,
  organizations,
  totalPages,
  selectAll,
  handleSelectedAll,
  handleSelectedRecord,
  selectedRecord,
  isRecordSelected,
}: {
  handlePageChange: (number: number) => void;
  currentPage: number;
  organizations: Organization[];
  totalPages: number;
  selectAll: boolean;
  handleSelectedAll: () => void;
  handleSelectedRecord: (id: number) => void;
  selectedRecord: number[];
  isRecordSelected: (id: number) => boolean;
}) => {
  const handleCheckOzStatus = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    oz: Organization
  ) => {
    if (Number(oz?.status) === ORGANIZATION_STATUS.IN_ACTIVE) {
      e.preventDefault();
      swalMessage(
        "メール確認待機中です！",
        "メール確認が完了するまで編集できません",
        "error",
        {
          timer: 2000,
        }
      );
    }
  };

  return (
    <>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-base text-[#2E2E2E] uppercase bg-[#EFF1F0] text-center">
            <tr className="min-h-[50px] text-[13px] font-[500]">
              <th
                scope="col"
                className="px-[10px] py-[2px] border-x-4 border-white whitespace-nowrap "
              >
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectedAll}
                />
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              ></th>
              <th
                scope="col"
                className="2xl:px-[110px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                企業名
              </th>
              <th
                scope="col"
                className="2xl:px-[130px] xl:px-[5px] lg:px-0 px-0  py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                企業ID（サブドメイン）
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                ステータス {/*status*/}
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                導入サポート
              </th>
              {/* <th
                            scope="col"
                            className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                        >
                            契約情報閲覧
                        </th> */}
              {/* <th
                            scope="col"
                            className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                        >
                            請求履歴閲覧
                        </th> */}
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                企業情報閲覧
              </th>
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
              >
                連絡先閲覧
              </th>
              {/* <th
                            scope="col"
                            className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap"
                        >
                            マスターログイン
                        </th> */}
              <th
                scope="col"
                className="2xl:px-[30px] xl:px-[5px] lg:px-0 px-0 py-[5px] border-x-4 border-white whitespace-nowrap min-w-[50px]"
              ></th>
            </tr>
          </thead>
          <tbody>
            {organizations &&
              organizations.map((organization) => (
                <tr
                  key={organization.id}
                  className="border-b w-full text-[12px] font-[400] text-[#2E2E2E] hover:bg-secondary-extralight bg-white"
                >
                  <td className="text-center">
                    <input
                      type="checkbox"
                      checked={isRecordSelected(organization.id)}
                      onChange={() => handleSelectedRecord(organization.id)}
                    />
                  </td>
                  <td>
                    {organization.logo_url === null ? (
                      <div className="flex justify-center items-center">
                        <HiOutlineUserCircle color="#BEBEBE" size={40} />
                      </div>
                    ) : (
                      <div className="flex justify-center items-center">
                        <img
                          className="object-cover w-[40px] h-[40px]"
                          src={organization.logo_url}
                          width={40}
                          alt={organization?.name}
                        />
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4 font-medium text-[14px] text-primary whitespace-nowrap">
                    {organization.name}
                  </td>
                  <td className="px-12 py-4 whitespace-nowrap">
                    {organization.subdomain}
                  </td>
                  <td className="px-12 py-4 text-center whitespace-nowrap">
                    <OrganizationLabelStatus type={organization.status} />
                  </td>
                  {/*status*/}
                  <td className="px-12 py-4 text-center whitespace-nowrap">
                    <OrganizationSupportStatusLabel
                      status={!!organization.is_support_subscribed}
                    />
                  </td>
                  {/* <td>
                                    <Link
                                        to={`${PATH_SUPER_ADMIN.ORGANIZATION.DEFAULT}`}
                                    >
                                        <div className="flex justify-center items-center">
                                            <LinkButton
                                                className="!py-[5px] !px-[10px] !rounded-[5px] !border-[1px] w-max">
                                                <div className="font-500 font-normal text-[12px]">契約情報閲覧</div>
                                            </LinkButton>
                                        </div>
                                    </Link>
                                </td> */}
                  {/* <td>
                                    <Link
                                        to={`${PATH_SUPER_ADMIN.ORGANIZATION.DEFAULT}`}
                                    >
                                        <div className="flex justify-center items-center">
                                            <LinkButton
                                                className="!py-[5px] !px-[10px] !rounded-[5px] !border-[1px] w-max">
                                                <div className="font-500 font-normal text-[12px]">請求履歴閲覧</div>
                                            </LinkButton>
                                        </div>
                                    </Link>
                                </td> */}
                  <td>
                    <Link
                      to={`${PATH_SUPER_ADMIN.ORGANIZATION.DETAIL_INFO}?organization_id=${organization.id}`}
                    >
                      <div className="flex justify-center items-center">
                        <LinkButton className="!py-[5px] !px-[10px] !rounded-[5px] !border-[1px] w-max">
                          <div className="font-500 font-normal text-[12px]">
                            企業情報閲覧
                          </div>
                        </LinkButton>
                      </div>
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`${PATH_SUPER_ADMIN.ORGANIZATION.DETAIL_CONTACT}?organization_id=${organization.id}`}
                    >
                      <div className="flex justify-center items-center">
                        <LinkButton className="!py-[5px] !px-[10px] !rounded-[5px] !border-[1px] w-max">
                          <div className="font-500 font-normal text-[12px]">
                            連絡先閲覧
                          </div>
                        </LinkButton>
                      </div>
                    </Link>
                  </td>
                  {/* <td>
                                    <Link
                                        to={`${PATH_SUPER_ADMIN.ORGANIZATION.DEFAULT}`}
                                    >
                                        <div className="flex justify-center items-center">
                                            <LinkButton className="!py-[5px] !px-[10px] !rounded-[5px] !border-[1px] w-max">
                                                <div className="font-500 font-normal text-[12px]">マスターログイン閲覧
                                                </div>
                                            </LinkButton>
                                        </div>
                                    </Link>
                                </td> */}
                  <td className="pl-[15px]">
                    {organization.status === ORGANIZATION_STATUS.IN_ACTIVE ? (
                      <button
                        onClick={() =>
                          swalMessage(
                            "メール確認待ち中です！",
                            "メール確認が完了しませんため編集できません。",
                            "warning"
                          )
                        }
                      >
                        <IoMdMore size={25} />
                      </button>
                    ) : (
                      <Link
                        to={`${PATH_SUPER_ADMIN.ORGANIZATION.DETAIL}?organization_id=${organization.id}`}
                        onClick={(
                          e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                        ) => {
                          handleCheckOzStatus(e, organization);
                        }}
                      >
                        <IoMdMore size={25} />
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {totalPages !== 0 && (
        <Pagination
          totalItems={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default OrganizationTable;
