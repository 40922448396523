/* eslint-disable react-hooks/exhaustive-deps */
import { PropsWithChild } from '../../../types/globals';
import SuperAdminLayout from '../../../layouts/superadmin';
import TabCardInsideRender from '../../../components/user/commons/TabCardInsideRender';
import { useState } from 'react';
import { CONTRACT_SETTING_TAB_DATA } from '../../../services/constants/superadmin/pages/contractSetting';
import TabCard from '../../../components/user/commons/TabCard';
import { useTabFinder } from '../../../hooks/useTabFinder';
import { PATH_SUPER_ADMIN } from '../../../services/constants/route/router';

type AccountMainProp = {
  title?: string;
};

const SuperAdminContractSettingBase = ({ children }: PropsWithChild<AccountMainProp>) => {
  const { tabIndex } = useTabFinder(Object.values(PATH_SUPER_ADMIN.CONTRACT_PRICE_SETTING));

  return (
    <SuperAdminLayout>
      <TabCard
        tabData={CONTRACT_SETTING_TAB_DATA}
        tabIndex={tabIndex}
        title={CONTRACT_SETTING_TAB_DATA?.[tabIndex || 0]?.formLabel || ''}
      >
        {children}
      </TabCard>
    </SuperAdminLayout>
  );
};

export default SuperAdminContractSettingBase;
