import { AUTH_CONSTANT } from '../constants/auth';
import { PATH_SERVICE } from '../constants/route/router';
import { USER_SIDEBAR_DATA } from '../constants/user/sidebar';

const authUtil = {
  getAllowedInternalRoutes: <T extends typeof USER_SIDEBAR_DATA>(
    routes: T,
    permissions: string[],
  ): T => {
    if (!routes || !routes.length || !permissions || !permissions.length) {
      return [] as unknown as T;
    }

    if (permissions[0] === '*') {
      return routes;
    }

    return routes.filter((route) => {
      if (!route.page) {
        return false;
      }

      return permissions.includes(route.page);
    }) as T;
  },

  getPermissions(roleId: number): string[] {
    let result: string[] = [];
    switch (roleId) {
      case AUTH_CONSTANT.ROLE.ADMIN:
        result = ['*'];
        break;
      case AUTH_CONSTANT.ROLE.STAFF:
        result = [PATH_SERVICE.DEFAULT];
        break;
      default:
        result = [];
        break;
    }

    return result;
  },
};

export { authUtil };
