import React, { useEffect, useRef, useState } from "react";
import FormItem from "../../commons/FormItem";
import { useNavigate } from "react-router-dom";
import { AccountFormData } from "../../../../types/user/account";
import { ErrorData } from "../../../../types/globals";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { RiErrorWarningLine } from "react-icons/ri";
import SemiSquareButton from "../../commons/SemiSquareButton";
import { PATH_USER_ACCOUNT } from "../../../../services/constants/route/router";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import ErrorBox from "../../../commons/form/ErrorBox";
import { TbTrash } from "react-icons/tb";
import ServicePicker from "./ServicePicker";
import { Service } from "../../../../types/user/service";
import { USER_STATUS } from "../../../../services/constants/user";
import { BsCheck2Circle } from "react-icons/bs";
import { swallConfirm } from "../../../../services/helpers/swal";
import {
  DELETE_CONFIRM_CONTENT,
  DELETE_CONFIRM_TITLE,
} from "../../../../services/constants/message";
import RolePicker from "./RolePicker";
import FormButton from "../../../commons/form/FormButton";
import ImageInternalButton from "../../../commons/buttons/ImageInternalButton";
import Spinner from "../../../commons/icons/Animations/Spinner";
import { AUTH_CONSTANT } from "../../../../services/constants/auth";
import { useStorage } from "../../../../hooks/useStorage";
import { User } from "../../../../types/user";

const AccountForm = () => {
  const navigate = useNavigate();
  const [accountFormData, setAccountFormData] = useState<AccountFormData>({});
  const [services, setServices] = useState<Service[]>([]);
  const [roles, setRoles] = useState<Service[]>([]);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<ErrorData>({});
  const { getParamValue } = useObjectRoutes();
  const accountId = getParamValue("account_id");
  const [fileError, setFileError] = useState<string>("");
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { getItem, setItem } = useStorage();

  useEffect(() => {
    const fetchServices = async () => {
      const servicesPromise = request.get(
        API.SERVICE.LIST_All,
        (serviceList) => {
          //Swapped the order of services
          [serviceList[1], serviceList[2]] = [serviceList[2], serviceList[1]];
          setServices(serviceList || []);
        }
      );

      const rolesPromise = request.get(API.AUTH.ROLE, (roleList) => {
        setRoles(roleList || []);
      });

      await Promise.all([servicesPromise, rolesPromise]);
    };
    fetchServices();
  }, []);

  useEffect(() => {
    if (!accountId) return;

    const fetchDetail = async () => {
      await request.get(
        `${API.USER_ACCOUNT.DETAIL}?user_id=${accountId}`,
        (accountData) => {
          let newAccountData = {
            ...accountData,
            features: accountData?.features?.map(
              (feature: { id?: number }) => feature?.id
            ),
          };
          setAccountFormData(newAccountData || {});
        }
      );
    };

    fetchDetail();
  }, [accountId]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const file = (e.target as HTMLInputElement).files?.[0];
    if (name === "image" && !value) return;
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Size in MB

      // Check file type
      if (!fileType.startsWith("image/")) {
        setFileError("無効なファイルタイプです。画像を選択してください。");
        return;
      }

      // Check file size
      if (fileSize > 2) {
        setFileError("ファイルサイズが2MBの最大制限を超えています。");
        return;
      }

      setFileError("");
      setAccountFormData({ ...accountFormData, [name]: file });
      return;
    }

    setAccountFormData({ ...accountFormData, [name]: value });
  };

  const handleDelete = async () => {
    if (!accountId) return;

    const isSelf = Number(user?.id) === Number(accountId);

    swallConfirm(
      async () => {
        // swalLoading();
        setIsDeleting(true);
        await request.delete(
          `${API.USER_ACCOUNT.DELETE}?user_id=${accountId}`,
          () => {},
          () => {
            setIsDeleting(false);
            navigate(PATH_USER_ACCOUNT.DEFAULT);
          },
          (errs) => {
            setIsDeleting(false);
            setErrors(errs);
          },
          { withSuccess: true }
        );
      },
      isSelf ? DELETE_CONFIRM_TITLE.USER_SELF : DELETE_CONFIRM_TITLE.USER,
      isSelf ? DELETE_CONFIRM_CONTENT.USER_SELF : DELETE_CONFIRM_CONTENT.USER,
      {
        confirmButtonText: "削除する",
      }
    );
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let submitAction = request.post;
    let submitUrl = API.USER_ACCOUNT.CREATE;
    let submitData = {
      ...accountFormData,
    };

    if (accountId) {
      submitUrl = API.USER_ACCOUNT.UPDATE;
      submitData = { ...submitData, user_id: accountId };
    }

    if (!submitData.role_id) {
      submitData = { ...submitData, role_id: AUTH_CONSTANT.ROLE.ADMIN };
    }

    await submitAction(
      submitUrl,
      submitData,
      (data) => {
        setIsSubmitting(false);
        const user: User = JSON.parse(getItem("user") || "");
        if (user && user.id === data.id) {
          setItem("user", JSON.stringify(data));
        }

        return navigate(PATH_USER_ACCOUNT.DEFAULT);
      },
      (errors) => {
        setIsSubmitting(false);
        setErrors(errors);
      },
      { withSuccess: true, withLoading: true, hasFileRequest: true }
    );
  };

  const removeImage = () => {
    const newData: AccountFormData = {
      ...accountFormData,
      image: undefined,
      avatar_url: undefined,
      delete_avatar: 1,
    };
    setAccountFormData(newData);
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  return (
    <div>
      {/* errors */}
      {errors && !!Object.keys(errors).length && (
        <div className="mb-[20px]">
          <ErrorBox errors={errors} />
        </div>
      )}
      {/* Account form */}
      <FormItem label="アカウント名" withRequired>
        <div className="flex gap-[9px]  w-full">
          <div className="text-secondary-dark w-full flex items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
              name="last_name"
              value={accountFormData?.last_name || ""}
              onChange={handleChange}
              placeholder="鈴木"
            />
          </div>
          <div className="text-secondary-dark w-full flex items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
              name="first_name"
              value={accountFormData?.first_name || ""}
              onChange={handleChange}
              placeholder="一郎"
            />
          </div>
        </div>
      </FormItem>
      <FormItem label="アカウント名（カナ)">
        <div className="flex gap-[9px] w-full">
          <div className="text-secondary-dark w-full flex items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
              name="last_name_kana"
              value={accountFormData?.last_name_kana || ""}
              onChange={handleChange}
              placeholder="スズキ"
            />
          </div>
          <div className="text-secondary-dark] w-full flex items-center">
            <input
              className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
              name="first_name_kana"
              value={accountFormData?.first_name_kana || ""}
              onChange={handleChange}
              placeholder="イチロウ"
            />
          </div>
        </div>
      </FormItem>
      <FormItem label="メールアドレス" withRequired>
        <div className="flex items-center">
          <input
            className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] w-full max-w-[330px] placeholder-secondary-light"
            name="email"
            value={accountFormData?.email || ""}
            onChange={handleChange}
            placeholder="dummyusername@mail.com"
          />

          {accountFormData.status === USER_STATUS.CONFIRMING ||
          !accountFormData.status ? (
            <div className="min-w-[90px] ml-[16px] pl-[12px] pt-[3px] pb-[5px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
              <RiErrorWarningLine
                className="absolute top-[2px] left-[4px]"
                size={18}
              />
              <p className="pl-[12px]">確認中</p>
            </div>
          ) : (
            <div className="min-w-[90px] ml-[5px] pl-[17px] pt-[3px] pb-[5px] text-[#749F91] bg-[#D7F5F2] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
              <BsCheck2Circle
                className="absolute top-[2px] left-[4px]"
                size={18}
              />
              <p className="pl-[12px]">確認済み</p>
            </div>
          )}
        </div>
      </FormItem>
      <FormItem label="ログインID（半角英数のみ８〜20）" withRequired>
        <input
          className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0]"
          name="username"
          value={accountFormData?.username || ""}
          onChange={handleChange}
        />
      </FormItem>
      <FormItem label="権限" withRequired>
        <RolePicker
          currentRole={accountFormData?.role_id || AUTH_CONSTANT.ROLE.ADMIN}
          setFormData={setAccountFormData}
          roleList={roles}
        />
      </FormItem>
      <FormItem label="利用可能プロダクト" withRequired>
        {accountFormData.features && (
          <div className="flex items-center mb-[10px]">
            {services
              ?.filter((service) =>
                accountFormData.features?.some(
                  (feature) => feature === service?.id
                )
              )
              ?.map((chosenFeature) => (
                <div
                  className="flex items-center mr-[18px]"
                  key={chosenFeature?.id}
                >
                  <input type="checkbox" checked className="mr-[4px]" />
                  <label className="text-[12px] font-[400] leading-[18px]">
                    {chosenFeature?.name}
                  </label>
                </div>
              ))}
          </div>
        )}
        <>
          <ServicePicker
            formData={accountFormData}
            setFormData={setAccountFormData}
            serviceList={services}
          />
          <div className="text-[12px] font-[400] leading-[18px] mb-[10px]">
            このアカウントが利用できるプロダクトをお選びください。
          </div>
        </>
      </FormItem>
      <FormItem label="ステータス">
        <label className="inline-flex items-center mr-[15px]">
          <input
            type="radio"
            className="form-radio text-primary-light border border-success-extralight"
            name="status"
            value={USER_STATUS.ACTIVE}
            checked={Number(accountFormData.status) === USER_STATUS.ACTIVE}
            onChange={handleChange}
            disabled={
              accountFormData.status === USER_STATUS.CONFIRMING ||
              !accountFormData.status
            }
          />
          <span
            className={`ml-[8px] text-[12px] leading-[100%] ${
              accountFormData.status === USER_STATUS.CONFIRMING ||
              !accountFormData.status
                ? "text-secondary-light"
                : "text-secondary-dark"
            }`}
          >
            有効
          </span>
        </label>
        <label className="inline-flex items-center mr-[15px]">
          <input
            type="radio"
            className="form-radio text-primary-light border border-success-extralight"
            name="status"
            value={USER_STATUS.INACTIVE}
            checked={Number(accountFormData.status) === USER_STATUS.INACTIVE}
            onChange={handleChange}
            disabled={
              accountFormData.status === USER_STATUS.CONFIRMING ||
              !accountFormData.status
            }
          />
          <span
            className={`ml-[8px] text-[12px] leading-[100%] ${
              accountFormData.status === USER_STATUS.CONFIRMING ||
              !accountFormData.status
                ? "text-secondary-light"
                : "text-secondary-dark"
            }`}
          >
            停止中
          </span>
        </label>
      </FormItem>

      <FormItem label="プロフィール画像のアップロード">
        <div className="flex items-center">
          {accountFormData.image || accountFormData?.avatar_url ? (
            <div className="relative w-[330px] object-cover mr-4 border-gray-200 my-[5px]">
              <ImageInternalButton
                removeImage={removeImage}
                editImage={() => inputFileRef.current?.click()}
              />
              <img
                src={
                  accountFormData.image
                    ? URL.createObjectURL(accountFormData.image)
                    : accountFormData?.avatar_url
                }
                alt="Profile Avatar"
              />
            </div>
          ) : (
            <div
              className="w-full max-w-[330px] h-[30px] flex items-center justify-center mr-4  border-[#EFF1F0] border-[1px] my-[5px]"
              onClick={() => inputFileRef.current?.click()}
            ></div>
          )}
          <label
            htmlFor="image"
            className="flex items-center justify-center w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-secondary-light cursor-pointer"
          >
            ファイルを選択
          </label>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            className="hidden"
            ref={inputFileRef}
            onChange={handleChange}
            placeholder="ファイルを選択"
          />
          {!accountFormData.image && (
            <div className="ml-[10px] text-[10px] font-[400] leading-[19px]">
              選択されていません
            </div>
          )}
        </div>
        {fileError && <div className="text-red-500 text-xs">{fileError}</div>}
      </FormItem>

      {/* Submit Buttons */}
      <div className="w-full flex justify-center items-center mb-[58px] mt-[66px] gap-[15px]">
        {accountId && (
          <SemiSquareButton
            onClick={handleDelete}
            className="border-secondary text-secondary max-w-[300px] bg-white border flex items-center max-h-[36px] mr-[45px]"
            disabled={isSubmitting || isDeleting}
          >
            <div className="mr-[15px]">
              {isDeleting ? (
                <Spinner color="#7A7A7A" />
              ) : (
                <TbTrash className="text-secondary" size={30} />
              )}
            </div>
            <span className="text-secondary whitespace-nowrap">
              このアカウントを削除
            </span>
          </SemiSquareButton>
        )}
        <SemiSquareButton
          className="bg-secondary-light max-w-[180px]"
          onClick={() => navigate(PATH_USER_ACCOUNT.DEFAULT)}
          isDisabled={isSubmitting || isDeleting}
        >
          キャンセル
        </SemiSquareButton>
        <FormButton
          className="bg-primary px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[16px] leading-[100%] w-[132px] h-[36px]"
          onClick={handleSubmit}
          isSubmitting={isSubmitting}
          disabled={isDeleting}
          label="保存"
        />
      </div>
    </div>
  );
};

export default AccountForm;
