import { useEffect, useState } from 'react';
import SuperAdminContractSettingBase from '.';
import ContractSetting from '../../../components/SuperAdmin/ContractSetting/ContractSetting';
import { request } from '../../../services/axios/axios';
import { API } from '../../../services/constants/route/api';
import { Contract } from '../../../types/user/contract';
import { paramizeObject } from '../../../services/helpers/parseData';
import { PALETTE_FEATURE } from '../../../services/constants/globals';

type SuperAdminContractPriceSettingPageProps = {
  featureId?: number;
};

const SuperAdminContractPriceSettingPage = ({
  featureId = PALETTE_FEATURE.MANABITE.ID,
}: SuperAdminContractPriceSettingPageProps) => {
  const [settingData, setSettingData] = useState<Contract[]>([]);

  useEffect(() => {
    if (!featureId) return;
    setSettingData([]);

    const paramObj = {
      feature_id: featureId,
    };

    const fetcher = async () =>
      await request.get(
        `${API.SUPER_ADMIN.CONTRACT_SETTING.DETAIL}?${paramizeObject(paramObj)}`,
        (res) => setSettingData(res),
      );

    fetcher();
  }, [featureId]);

  return (
    <SuperAdminContractSettingBase>
      <ContractSetting settingData={settingData} featureId={featureId} />
    </SuperAdminContractSettingBase>
  );
};

export default SuperAdminContractPriceSettingPage;
