import SuperAdminLayout from "../../../../layouts/superadmin";
import { WithChildren } from "../../../../types/globals";

type AccountMainProp = {
    title?: string;
};

const SuperAdminOrganizationDetailBase = ({ title, children }: WithChildren<AccountMainProp>) => {

    return (
        <SuperAdminLayout>
            <section className="w-full">
                <div className="bg-white rounded-[10px] w-full min-h-[100px] pb-[50px]">
                    <div className="pt-[30px] px-[28px] mb-[20px]">
                        <div className="border-b border-secondary-light text-secondary-dark pb-[14px] font-[500] text-[16px] leading-[100%]">
                            {title}
                        </div>
                    </div>
                    <div className="mx-[43px]">{children}</div>
                </div>
            </section> 
        </SuperAdminLayout>
    );
};

export default SuperAdminOrganizationDetailBase;