import { getTabObj } from "../../../helpers/parseData";
import { PATH_SUPER_ADMIN } from "../../route/router";

const ORGANIZATION_TAB_ITEM = {
    ORGANIZATION_LIST: "契約企業一覧",
    ORGANIZATION_CREATE: "契約企業新規追加",
};

const ORGANIZATION_TAB_DATA = [
    getTabObj(ORGANIZATION_TAB_ITEM.ORGANIZATION_LIST, PATH_SUPER_ADMIN.ORGANIZATION.DEFAULT),
    getTabObj(ORGANIZATION_TAB_ITEM.ORGANIZATION_CREATE, PATH_SUPER_ADMIN.ORGANIZATION.CREATE),
];

const ORGANIZATION_STATUS = {
    IN_ACTIVE: 0,
    ACTIVE: 1,
    DE_ACTIVE: 2
};

const ORGANIZATION_SHOW_STATUS = {
    SHOW_ALL: 0,
    ONLY_SHOW_DEACTIVATED: 1
}

export { ORGANIZATION_TAB_ITEM, ORGANIZATION_TAB_DATA, ORGANIZATION_STATUS , ORGANIZATION_SHOW_STATUS};