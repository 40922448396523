import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ErrorData } from "../../../../types/globals";
import { useObjectRoutes } from "../../../../hooks/useObjectRoutes";
import { RiErrorWarningLine } from "react-icons/ri";

import { PATH_SUPER_ADMIN } from "../../../../services/constants/route/router";
import { request } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import ErrorBox from "../../../commons/form/ErrorBox";

import { BsCheck2Circle } from "react-icons/bs";
import FormItem from "../../../user/commons/FormItem";
import SemiSquareButton from "../../../user/commons/SemiSquareButton";
import { OrganizationFormType } from "../../../../types/user/organization";
import { ORGANIZATION_STATUS } from "../../../../services/constants/superadmin/pages/organization";
import Spinner from "../../../commons/icons/Animations/Spinner";

const SuperAdminOrganizationForm = () => {
  const navigate = useNavigate();
  const [superAdminOrganizationFormData, setSuperAdminOrganizationFormData] =
    useState<OrganizationFormType>({
      status: ORGANIZATION_STATUS.IN_ACTIVE,
    });
  const [errors, setErrors] = useState<ErrorData>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { getParamValue } = useObjectRoutes();
  const organizationId = getParamValue("organization_id");

  const fetchDetail = async () => {
    await request.get(
      `${API.SUPER_ADMIN.ORGANIZATION.DETAIL}?organization_id=${organizationId}`,
      (organizationData) => {
        setSuperAdminOrganizationFormData(organizationData || {});
      }
    );
  };

  useEffect(() => {
    if (!organizationId) return;

    fetchDetail();
  }, [organizationId]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    const file = (e.target as HTMLInputElement).files?.[0];
    if (file) {
      setSuperAdminOrganizationFormData({
        ...superAdminOrganizationFormData,
        [name]: file,
      });
      return;
    }

    setSuperAdminOrganizationFormData({
      ...superAdminOrganizationFormData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let submitAction = request.post;
    let submitUrl = API.SUPER_ADMIN.ORGANIZATION.CREATE;
    let submitData = {
      ...superAdminOrganizationFormData,
    };

    if (organizationId) {
      submitUrl = API.SUPER_ADMIN.ORGANIZATION.UPDATE;
      submitData = { ...submitData, organization_id: organizationId };
    }

    await submitAction(
      submitUrl,
      submitData,
      () => navigate(PATH_SUPER_ADMIN.ORGANIZATION.DEFAULT),
      (errors) => {
        setErrors(errors);
        setIsSubmitting(false);
      },
      { withSuccess: true, withLoading: true, hasFileRequest: true }
    );
  };

  return (
    <div>
      {/* errors */}
      {errors && !!Object.keys(errors).length && (
        <div className="mb-[20px]">
          <ErrorBox errors={errors} />
        </div>
      )}
      {/* Organization form */}
      <div className="text-[16px] font-normal py-[5px]">企業基本情報</div>
      <FormItem label="企業名" withRequired>
        <input
          className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
          name="name"
          value={superAdminOrganizationFormData.name}
          onChange={handleChange}
          placeholder="dummy_company_name"
        />
      </FormItem>
      <FormItem label="企業ID（サブドメイン）" withRequired>
        <input
          className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
          name="subdomain"
          value={superAdminOrganizationFormData.subdomain}
          onChange={handleChange}
        />
        <ul className="list-disc text-[8px] text-left text-[#5A5A5A] ml-[14px] py-[20px]">
          <li>
            半角英数字および「-」（ハイフン）、「.」（ピリオド／ドット）の記号にて、3字以上10字まで設定することができます。 ※サブドメインに「..」や「--」「.-」を入力しないでください。「スペース（空白）」は使用できません。
          </li>
          <li>
            英字を入力する場合、大文字小文字の区別はありません。すべて小文字で表示されます。
          </li>
          <li>先頭文字と後尾文字は英文字にしてください。</li>
        </ul>
      </FormItem>
      <div className="text-[16px] font-normal py-[5px]">請求連絡先</div>
      <FormItem label="メールアドレス" withRequired>
        <div className="flex items-center">
          <input
            className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] w-full max-w-[330px] placeholder-secondary-light"
            name="email"
            value={superAdminOrganizationFormData.email}
            onChange={handleChange}
            placeholder="dummyusername@mail.com"
          />

          {superAdminOrganizationFormData.status ===
            ORGANIZATION_STATUS.IN_ACTIVE ||
          !superAdminOrganizationFormData.status ? (
            <div className="min-w-[90px] ml-[16px] pl-[12px] pt-[3px] pb-[5px] text-danger bg-[#FFEBEB] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
              <RiErrorWarningLine
                className="absolute top-[2px] left-[4px]"
                size={18}
              />
              <p className="pl-[12px]">確認中</p>
            </div>
          ) : (
            <div className="min-w-[90px] ml-[5px] pl-[17px] pt-[3px] pb-[5px] text-[#749F91] bg-[#D7F5F2] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
              <BsCheck2Circle
                className="absolute top-[2px] left-[4px]"
                size={18}
              />
              <p className="pl-[12px]">確認済み</p>
            </div>
          )}
        </div>
      </FormItem>

      {/* Submit Buttons */}
      <div className="w-full flex justify-center items-center mb-[58px] mt-[66px] gap-[15px]">
        <SemiSquareButton
          className="bg-secondary-light max-w-[212px]"
          onClick={() => navigate(PATH_SUPER_ADMIN.ORGANIZATION.DEFAULT)}
        >
          一覧画面へ戻る
        </SemiSquareButton>
        {organizationId ? (
          <SemiSquareButton 
            className={`${isSubmitting ? "opacity-70 pointer-events-none" : ""} max-w-[244px]`} 
            onClick={handleSubmit}
          >
            {isSubmitting ? <Spinner width={17} height={17} /> : "入力内容で更新する"}
          </SemiSquareButton>
        ) : (
          <SemiSquareButton 
            className={`${isSubmitting ? "opacity-70 pointer-events-none" : ""} max-w-[244px]`}
            onClick={handleSubmit}
          >
            {isSubmitting ? <Spinner width={17} height={17} /> : "入力内容で登録する"}
          </SemiSquareButton>
        )}
      </div>
    </div>
  );
};

export default SuperAdminOrganizationForm;
