import { ElementProps } from '../../../../types/globals';

type HamburgerProps = {
  isOpen?: boolean;
} & ElementProps<HTMLDivElement>;

const Hamburger = ({ isOpen, ...props }: HamburgerProps) => {
  return (
    <div className={`relative ${props.className}`} {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="18"
        fill="none"
        viewBox="0 0 16 18"
        className="absolute left-[-33px] top-[-9px]"
      >
        <path
          fill="#ffffff"
          fillRule="evenodd"
          d="M16 17a1 1 0 01-1 1H1.5a1 1 0 110-2H15a1 1 0 011 1zm-5-8a1 1 0 01-1 1H1.5a1 1 0 010-2H10a1 1 0 011 1zm5-8a1 1 0 01-1 1H1.5a1 1 0 110-2H15a1 1 0 011 1z"
          clipRule="evenodd"
        ></path>
      </svg>

      {isOpen ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="16"
          fill="none"
          viewBox="0 0 10 16"
          className="absolute left-[-19px] top-[-8px]"
        >
          <path
            stroke="#ffffff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.2"
            d="M8 2L2 8l6 6"
          ></path>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="14"
          fill="none"
          viewBox="0 0 8 14"
          className="absolute left-[-17px] top-[-7px]"
        >
          <path
            fill="#ffffff"
            d="M.627 11.676a1 1 0 000 1.357l.161.174a1 1 0 001.468 0l5.115-5.528a1 1 0 000-1.358L2.256.793a1 1 0 00-1.468 0l-.16.174a1 1 0 000 1.357l3.69 3.998a1 1 0 010 1.356l-3.69 3.998z"
          ></path>
        </svg>
      )}
    </div>
  );
};

export default Hamburger;
