import React, { ChangeEvent, useEffect } from "react";
import { useState } from "react";
import { USER_STATUS } from "../../../../services/constants/user";
import { request } from "../../../../services/axios/axios";
import { paramizeObject } from "../../../../services/helpers/parseData";
import { API } from "../../../../services/constants/route/api";
import AccountFilter from "./Filter";
import AccountTable from "./Table";
import { SuperAdminAccount } from "../../../../types/user/account";
import TableCommonAction from "../../commons/TableCommonAction";
import { swalClose, swalLoading, swallConfirm } from "../../../../services/helpers/swal";
import { DELETE_CONFIRM_CONTENT, DELETE_CONFIRM_TITLE } from "../../../../services/constants/message";
import qs from "qs";

type FilterType = {
  query: string;
  limit: number;
  page: number;
  isAll?: number;
  sortType?: number;
  section_id?: number | string;
};

const SuperAdminAccountList = () => {
  const [filter, setFilter] = useState<FilterType>({
    limit: 10,
    query: "",
    isAll: USER_STATUS.CONFIRMING,
    page: 1,
    sortType: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [accounts, setAccounts] = useState<SuperAdminAccount[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [selectedRecord, setSelectedRecord] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const resetTable = () => {
    setCurrentPage(1);
    setSelectAll(false);
    setSelectedRecord([]);
  };

  const checkSelectAll = (currentRecord: SuperAdminAccount[]) => {
    let currentRecordIds = currentRecord?.map((account) => account.id);
    let allCurrentRecordSelected = currentRecordIds.every((id) =>
      selectedRecord.includes(id)
    );
    if (allCurrentRecordSelected) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  const handleSelectedAll = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      setSelectedRecord(accounts?.map((s) => s.id));
    } else {
      setSelectedRecord([]);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    // update the current page state
    setCurrentPage(pageNumber);
    changePaginate(pageNumber);
  };

  const handleSelectedRecord = (id: number) => {
    setSelectedRecord((prevRecords) => {
      if (prevRecords.includes(id)) {
        return prevRecords.filter((recordId) => recordId !== id)
      } else {
        return [...prevRecords, id]
      }
    })
  }

  const isRecordSelected = (id: number) => selectedRecord?.includes(id);

  useEffect(() => {
    const fetch = async () => {
      swalLoading();
      await request.get(
        `${API.SUPER_ADMIN.ACCOUNT.LIST}?${paramizeObject(filter)}`,
        (accountData) => {
          checkSelectAll(accountData.users);
          setAccounts(accountData.users);
          setTotalPages(accountData.last_page);
        }
      );
      swalClose();
    };

    const timer = setTimeout(() => {
      fetch();
    }, 500);
    return () => clearTimeout(timer);
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);



  const changeHandle = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (e.target.type === "checkbox") {
      const target = e.target as HTMLInputElement;
      if (target.checked) {
        setFilter({
          ...filter,
          isAll: USER_STATUS.ACTIVE,
          ["page"]: 1,
        });
        resetTable();
        return;
      } else {
        setFilter({ ...filter, isAll: USER_STATUS.CONFIRMING, page: 1 });
        resetTable();
        return;
      }
    }
    setFilter({ ...filter, [e.target.name]: e.target.value, page: 1 });
    resetTable();
  };

  const handleDeleteMany = async () => {
    if (!selectedRecord) return;

    const paramObj = {
      ids: selectedRecord
    }

    const queryParam = qs.stringify(paramObj);

    swallConfirm(
      async () => {
        // swalLoading();
        await request.delete(
          `${API.SUPER_ADMIN.ACCOUNT.DELETE_MANY}?${queryParam}`,
          () => { },
          () => {
            setFilter({ ...filter });
            resetTable()
          },
          () => { },
          { withSuccess: true }
        );
      },
      DELETE_CONFIRM_TITLE.USER,
      DELETE_CONFIRM_CONTENT.USER
    )
  }

  const changePaginate = (number: number) => {
    setFilter({ ...filter, page: number });
  };
  return (
    <div>
      <TableCommonAction />
      <AccountFilter changeHandle={changeHandle} handleDeleteMany={handleDeleteMany} />
      <AccountTable
        selectedRecord={selectedRecord}
        handleSelectedRecord={handleSelectedRecord}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        accounts={accounts}
        totalPages={totalPages}
        selectAll={selectAll}
        handleSelectedAll={handleSelectedAll}
        isRecordSelected={isRecordSelected}
      />
    </div>
  );
};

export default SuperAdminAccountList;
