import React, { useState, useEffect, ChangeEvent } from "react";
import FormItem from "../../../user/commons/FormItem";
import {
  LINK_ADDRESSES,
  LINK_ADDRESS_TITLE,
} from "../../../../services/constants/superadmin/pages/linkAddress";
import { LinkAddress } from "../../../../types/user/linkAddress";
import { ErrorData } from "../../../../types/globals";
import ErrorBox from "../../../commons/form/ErrorBox";
import { API } from "../../../../services/constants/route/api";
import { makeRequest, request } from "../../../../services/axios/axios";
import FormButton from "../../../commons/form/FormButton";

const LinkAddressForm = () => {
  const [linkAddress, setLinkAdress] = useState<LinkAddress>({});
  const [errors, setErrors] = useState<ErrorData>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLinkAdress({ ...linkAddress, [name]: value });
  };

  const handleUpdateOrCreate = async () => {
    setIsSubmitting(true);
    if (!!linkAddress?.id) {
      await request.patch(
        API.SUPER_ADMIN.LINK_ADDRESS.UPDATE,
        linkAddress,
        (data) => {
          setIsSubmitting(false);
          setLinkAdress(data);
          setErrors({});
        },
        (errors) => {
          setErrors(errors);
          setIsSubmitting(false);
        },
        { withSuccess: true }
      );
    } else {
      await request.post(
        API.SUPER_ADMIN.LINK_ADDRESS.CREATE,
        linkAddress,
        (data) => {
          setLinkAdress(data);
          setErrors({});
          setIsSubmitting(false);
        },
        (errors) => {
          setErrors(errors);
          setIsSubmitting(false);
        },
        { withSuccess: true }
      );
    }
  };

  useEffect(() => {
    const fetch = async () => {
      request.get(API.SUPER_ADMIN.LINK_ADDRESS.DETAIL, (data) => {
        setLinkAdress(data);
      });
    };

    fetch();
  }, []);

  return (
    <div className="text-secondary-dark text-[14px] font-[500]">
      <div>
        {errors && !!Object.keys(errors).length && (
          <div className="mb-[20px]">
            <ErrorBox errors={errors} />
          </div>
        )}
        <p className="py-[10px]">{LINK_ADDRESS_TITLE.URL_LIST}</p>
        <FormItem
          label={LINK_ADDRESSES.URL_LIST.TERM_OF_USE.TITLE}
          withRequired
        >
          <input
            className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
            name={LINK_ADDRESSES.URL_LIST.TERM_OF_USE.NAME}
            value={linkAddress?.term_of_use_url || ""}
            onChange={handleChange}
            placeholder="URLダミー"
            type="url"
          />
        </FormItem>

        <FormItem
          label={LINK_ADDRESSES.URL_LIST.PRIVACY_POLICY.TITLE}
          withRequired
        >
          <input
            className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
            name={LINK_ADDRESSES.URL_LIST.PRIVACY_POLICY.NAME}
            value={linkAddress?.privacy_policy_url || ""}
            onChange={handleChange}
            placeholder="URLダミー"
            type="url"
          />
        </FormItem>

        <FormItem
          label={LINK_ADDRESSES.URL_LIST.COMPANY_INFO_URL.TITLE}
          withRequired
        >
          <input
            className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
            name={LINK_ADDRESSES.URL_LIST.COMPANY_INFO_URL.NAME}
            value={linkAddress?.company_info_url || ""}
            onChange={handleChange}
            placeholder="URLダミー"
            type="url"
          />
        </FormItem>

        <FormItem
          label={LINK_ADDRESSES.URL_LIST.DESC_COMMERCAIL_LAW.TITLE}
          withRequired
        >
          <input
            className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
            name={LINK_ADDRESSES.URL_LIST.DESC_COMMERCAIL_LAW.NAME}
            value={linkAddress?.desc_commercial_law_url || ""}
            onChange={handleChange}
            placeholder="URLダミー"
            type="url"
          />
        </FormItem>
      </div>
      <div className="mt-[20px]">
        <p className="py-[10px]">{LINK_ADDRESS_TITLE.HELP_URL_LIST}</p>
        <FormItem
          label={LINK_ADDRESSES.HELP_URL_LIST.FREQUENTLY_QUESTION.TITLE}
          withRequired
        >
          <input
            className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
            name={LINK_ADDRESSES.HELP_URL_LIST.FREQUENTLY_QUESTION.NAME}
            value={linkAddress?.frequently_asked_questions_url || ""}
            onChange={handleChange}
            placeholder="URLダミー"
            type="url"
          />
        </FormItem>

        <FormItem
          label={LINK_ADDRESSES.HELP_URL_LIST.MANUAL_TEMPLATE.TITLE}
          withRequired
        >
          <input
            className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
            name={LINK_ADDRESSES.HELP_URL_LIST.MANUAL_TEMPLATE.NAME}
            value={linkAddress?.manual_template_url || ""}
            onChange={handleChange}
            placeholder="URLダミー"
            type="url"
          />
        </FormItem>
        <FormItem
          label={LINK_ADDRESSES.HELP_URL_LIST.CONTACT.TITLE}
          withRequired
        >
          <input
            className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light border-[#EFF1F0]"
            name={LINK_ADDRESSES.HELP_URL_LIST.CONTACT.NAME}
            value={linkAddress?.contact_url || ""}
            onChange={handleChange}
            placeholder="URLダミー"
            type="url"
          />
        </FormItem>
      </div>
      <div className="flex items-center justify-center">
        {/* <button
          className="mt-[46px] bg-primary px-[50px] py-[10px] rounded-[10px] text-white text-[14px]"
          onClick={handleUpdateOrCreate}
        >
          設定を保存
        </button> */}
        <FormButton 
          isSubmitting={isSubmitting}
          className="mt-[46px] bg-primary px-[50px] py-[10px] rounded-[10px] text-white text-[14px]"
          label="設定を保存"
          onClick={handleUpdateOrCreate}
        />
      </div>
    </div>
  );
};

export default LinkAddressForm;
