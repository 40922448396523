import React, { useEffect, useRef, useState } from "react";
import FormBlock from "../../commons/FormBlock";
import FormBlockItem from "../../commons/FormBlockItem";
import { INVOICE_FORM_DATA } from "../../../../services/constants/user/pages/company";
import { makeRequest } from "../../../../services/axios/axios";
import { API } from "../../../../services/constants/route/api";
import { swalError, swallConfirm, swalLoading, swalMessage, swalSuccess, } from "../../../../services/helpers/swal";
import ErrorBox from "../../../commons/form/ErrorBox";
import { ErrorData } from "../../../../types/globals";
import { InvoiceSetting } from "../../../../types/user/invoice";
import { Organization } from "../../../../types/user/organization";
import postal_code from "japan-postal-code";
import InvoicePreview from "../../../Export/InvoicePreview";
import FormButton from "../../../commons/form/FormButton";

const InvoiceForm = () => {
    const [ozData, setOzData] = useState<Organization>();
    const [invoiceData, setInvoiceData] = useState<InvoiceSetting>({
        payee_bank_account_type: 0,
        oz_name: ozData?.name || ''
    })
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
    const [errors, setErrors] = useState<ErrorData>({});
    const [zipCode, setZipCode] = useState<string>("");
    const [zipCodePrefix, setZipCodePrefix] = useState<string>("");
    const inputPrefixRef = useRef<HTMLInputElement>(null);

    const fetchInvoiceData = async () => {
        setIsLoading(true)
        const result = await makeRequest({
            method: "get",
            url: API.INVOICE_SETTING.DETAIL
        });

        if (!result.status) {
            swalError();
            return;
        }
        setIsLoading(false)
        setInvoiceData({ ...invoiceData, ...result.data.invoice_setting_data })
        let postalCode = result.data.invoice_setting_data.oz_postal_code
        if (postalCode && postalCode.length === 7) {
            setZipCode(postalCode.substring(0, 3));
            setZipCodePrefix(postalCode.substring(3));
        }
        setOzData(result.data.oz_data)
        if (!invoiceData.oz_name && result.data.oz_data.name) {
            setInvoiceData({ ...invoiceData, oz_name: result.data.oz_data.name })
        }
    }

    useEffect(() => {
        fetchInvoiceData();
    }, []);

    const handleChangeForm = (
        e: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLInputElement>
    ) => {
        const elementCurrent = e.currentTarget;
        let invoiceFormData;

        switch (elementCurrent.type) {
            case "radio":
                invoiceFormData = {
                    ...invoiceData,
                    [elementCurrent.name]: Number(elementCurrent.value),
                };
                break;
            default:
                invoiceFormData = {
                    ...invoiceData,
                    [elementCurrent.name]: elementCurrent.value,
                };
                break;
        }
        setInvoiceData(invoiceFormData);
    };


    const handleSubmit = async () => {
        let zipcode = `${zipCode}${zipCodePrefix}`;
        swallConfirm(async () => {
            setIsSubmitting(true);
            let url = API.INVOICE_SETTING.UPDATE;

            const result = await makeRequest({
                method: "post",
                url: url,
                data: {
                    ...invoiceData,
                    oz_postal_code: zipcode
                },
            });

            if (!result.status) {
                setErrors(result.error as ErrorData);
                setIsSubmitting(false);
                return swalError();
            }

            swalSuccess();
            setErrors({});
            setIsSubmitting(false);

        }, "請求書テンプレートは変更されます。よろしいでしょうか？", '', {
            confirmButtonText: '変更する',
        });
    };

    const syncingZipCode = () => {
        let fullZipCode = `${zipCode}${zipCodePrefix}`;
        if (!fullZipCode) {
            swalMessage('エラー', '郵便番号を入力してください。', 'error')
        }

        postal_code.get(fullZipCode, (object) => {
            setInvoiceData({
                ...invoiceData,
                oz_province: object.prefecture,
                oz_ward: object.city,
                oz_address: object.area,
                oz_building: object.street,
            })
            }
        );
    };

    const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        if (/^\d{0,3}$/.test(input)) {
            setZipCode(input);
            setInvoiceData({
                ...invoiceData,
                oz_postal_code: `${input}${zipCodePrefix}`,
            })
            if (input.length === 3 && inputPrefixRef.current) {
                inputPrefixRef.current.focus();
            }
        }
    };
    const handleZipCodePrefixChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const input = e.target.value;
        if (/^\d{0,4}$/.test(input)) {
            setZipCodePrefix(input);
            setInvoiceData({
                ...invoiceData,
                oz_postal_code: `${zipCode}${input}`,
            })
        }
    };
    const handleZipCodePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const pastedData = e.clipboardData.getData("text/plain");
        const matches = pastedData.match(/^(\d{3})-(\d{4})$/);
        if (matches) {
            setZipCode(matches[1]);
            setZipCodePrefix(matches[2]);
            e.preventDefault();
        }
    };

    const showPreviewInvoiceModal = () => {
        setIsShowPreview(true)
    }

    return (
        <>
            {
                isLoading ? <></> :
                    <>
                        <div className="text-secondary-dark mx-[-15px]">
                            {errors && !!Object.keys(errors).length && (
                                <div className="mb-[20px]">
                                    <ErrorBox errors={errors}/>
                                </div>
                            )}
                            {
                                isShowPreview ? <InvoicePreview handleClose={() => setIsShowPreview(false)} invoiceSettingData={invoiceData}/> : <></>
                            }
                            <div>
                                <form>
                                    <FormBlock title="請求書設定">
                                        <FormBlockItem
                                            label="株式会社"
                                            withInput={true}
                                            value={invoiceData?.oz_name || ""}
                                            inputName="oz_name"
                                            handleChange={handleChangeForm}
                                            isRequired={true}
                                            placeholder={'株式会社○○○○'}
                                            input_style="max-w-[300px]"
                                        />
                                        <FormBlockItem
                                            label="登録番号"
                                            withInput={true}
                                            value={invoiceData?.oz_register_number || ""}
                                            inputName="oz_register_number"
                                            handleChange={handleChangeForm}
                                            isRequired={true}
                                            input_style="max-w-[300px]"
                                            isNumberInput={true}
                                        />
                                        {/*Postal code*/}
                                        <FormBlockItem
                                            label={'郵便番号'}
                                            isRequired={true}
                                            withInput={false}>
                                            <div
                                                className="text-secondary-dark ml-[13px] w-[40%] flex gap-[7px] items-center">
                                                <input
                                                    className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0] max-w-[80px]"
                                                    name="zip_code"
                                                    onChange={handleZipCodeChange}
                                                    onPaste={handleZipCodePaste}
                                                    value={zipCode}
                                                />
                                                <div className="w-[12px] bg-secondary-light h-[1px]"></div>
                                                <input
                                                    className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0] max-w-[80px]"
                                                    name="zip_code_prefix"
                                                    ref={inputPrefixRef}
                                                    onChange={handleZipCodePrefixChange}
                                                    onPaste={handleZipCodePaste}
                                                    value={zipCodePrefix}
                                                />
                                                <div
                                                    onClick={syncingZipCode}
                                                    className={`px-[3.5px] ${
                                                        zipCode || zipCodePrefix ? "bg-primary" : "bg-[#BEBEBE]"
                                                    } text-white text-[9px] font-[500] leading-[100%] w-[52px] h-[22px] flex items-center justify-center rounded-[5px] cursor-pointer ml-[5px]`}
                                                >
                                                    自動入力
                                                </div>
                                            </div>
                                        </FormBlockItem>
                                        {/*Address data*/}
                                        <FormBlockItem
                                            label="住所"

                                            isRequired={true}
                                            titleTop={true}
                                        >
                                            <div
                                                className="text-secondary-dark ml-[13px] items-center text-[13px] leading-[100%] py-[5px] w-[85%] mr-[50px]">
                                                <div className="relative w-[300px]">
                                                    <input
                                                        name="oz_province"
                                                        className="text-[13px] h-[35px] pr-[33px] pl-[15.5px] mb-[6px] appearance-none text-secondary-dark leading-[100%] min-w-[300px] placeholder-[#BEBEBE]"
                                                        onChange={handleChangeForm}
                                                        value={invoiceData?.oz_province || ''}
                                                        placeholder="都道府県を入力"
                                                    />
                                                </div>
                                                <input
                                                    type="text"
                                                    name="oz_ward"
                                                    onChange={handleChangeForm}
                                                    value={invoiceData?.oz_ward || ''}
                                                    className="max-w-[300px] text-[13px] leading-[100%] flex items-center pl-[15.5px] mb-[6px] h-[35px] placeholder-[#BEBEBE]"
                                                    placeholder="例）港区"
                                                />
                                                <input
                                                    type="text"
                                                    name="oz_address"
                                                    onChange={handleChangeForm}
                                                    value={invoiceData?.oz_address || ''}
                                                    className="max-w-[300px] text-[13px] leading-[100%] flex items-center pl-[15.5px] mb-[6px] h-[35px] placeholder-[#BEBEBE]"
                                                    placeholder="例）赤坂1-2-34"
                                                />
                                                <input
                                                    type="text"
                                                    name="oz_building"
                                                    onChange={handleChangeForm}
                                                    value={invoiceData?.oz_building || ''}
                                                    className="max-w-[300px] text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[35px] placeholder-[#BEBEBE]"
                                                    placeholder="例）パレットビル3階"
                                                />
                                            </div>
                                        </FormBlockItem>

                                        <FormBlockItem
                                            label="電話番号"
                                            withInput={true}
                                            value={invoiceData?.oz_tel || ""}
                                            inputName="oz_tel"
                                            handleChange={handleChangeForm}
                                            isRequired={true}
                                            input_style="max-w-[300px]"
                                            isNumberInput={true}
                                        />

                                        <FormBlockItem
                                            label="FAX"
                                            withInput={true}
                                            value={invoiceData?.oz_fax || ""}
                                            inputName="oz_fax"
                                            handleChange={handleChangeForm}
                                            input_style="max-w-[300px]"
                                            isNumberInput={true}
                                        />
                                        <FormBlockItem
                                            label="担当"
                                            withInput={true}
                                            value={invoiceData?.oz_responsible_person || ""}
                                            inputName="oz_responsible_person"
                                            handleChange={handleChangeForm}
                                            isRequired={true}
                                            input_style="max-w-[300px]"
                                        />
                                        <FormBlockItem
                                            label="メールアドレス"
                                            withInput={true}
                                            value={invoiceData?.oz_email || ""}
                                            inputName="oz_email"
                                            handleChange={handleChangeForm}
                                            isRequired={true}
                                            input_style="max-w-[300px]"
                                        />
                                    </FormBlock>

                                    <FormBlock title="基本情報 ">
                                        <FormBlockItem
                                            label="銀行名"
                                            withInput={true}
                                            value={invoiceData?.payee_bank_name || ""}
                                            inputName="payee_bank_name"
                                            handleChange={handleChangeForm}
                                            isRequired={true}
                                            longInput={false}
                                            input_style="max-w-[300px]"
                                        />
                                        <FormBlockItem
                                            label="銀行コード"
                                            withInput={true}
                                            value={invoiceData?.payee_bank_code || ""}
                                            inputName="payee_bank_code"
                                            handleChange={handleChangeForm}
                                            longInput={false}
                                            input_style="max-w-[300px]"
                                        />
                                        <FormBlockItem
                                            label="支店名"
                                            withInput={true}
                                            value={invoiceData?.payee_bank_branch_name || ""}
                                            inputName="payee_bank_branch_name"
                                            handleChange={handleChangeForm}
                                            isRequired={true}
                                            longInput={false}
                                            input_style="max-w-[300px]"
                                        />
                                        <FormBlockItem
                                            label="支店コード"
                                            withInput={true}
                                            value={invoiceData?.payee_bank_branch_code || ""}
                                            inputName="payee_bank_branch_code"
                                            handleChange={handleChangeForm}
                                            longInput={false}
                                            input_style="max-w-[300px]"
                                        />
                                        <FormBlockItem label="口座区分" isRequired={true}>
                                            <div className="w-full pl-[9px] py-[10px] flex items-center gap-[20px]">
                                                {INVOICE_FORM_DATA.ACCOUNT_TYPE.map((type) => (
                                                    <div className="flex items-center gap-[5px]" key={type.value}>
                                                        <input
                                                            type="radio"
                                                            name="payee_bank_account_type"
                                                            id={`payee_bank_account_type_${type.value}`}
                                                            value={type.value}
                                                            onChange={handleChangeForm}
                                                            checked={invoiceData.payee_bank_account_type === Number(type.value)}
                                                        />
                                                        <label
                                                            htmlFor={`payee_bank_account_type_${type.value}`}
                                                            className={"cursor-pointer"}
                                                        >
                                                            {type.label}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </FormBlockItem>
                                        <FormBlockItem
                                            label="口座番号"
                                            withInput={true}
                                            value={invoiceData?.payee_bank_account_number || ""}
                                            inputName="payee_bank_account_number"
                                            isRequired={true}
                                            handleChange={handleChangeForm}
                                            longInput={false}
                                            input_style="max-w-[300px]"
                                        />
                                        <FormBlockItem
                                            label="口座名義"
                                            withInput={true}
                                            value={invoiceData?.payee_bank_account_name || ""}
                                            inputName="payee_bank_account_name"
                                            isRequired={true}
                                            handleChange={handleChangeForm}
                                            longInput={false}
                                            input_style="max-w-[300px]"
                                        />
                                    </FormBlock>
                                </form>
                                <div
                                    className="flex items-center justify-center gap-[19px] text-white text-[16px] font-[700] mt-[26px]">
                                    <button
                                        className="px-[50px] py-[10px] bg-primary rounded-[10px]"
                                        onClick={showPreviewInvoiceModal}
                                    >
                                        {INVOICE_FORM_DATA.BUTTON.PREVIEW}
                                    </button>
                                    <FormButton 
                                        isSubmitting={isSubmitting}
                                        className="px-[50px] py-[10px] bg-primary rounded-[10px]"
                                        onClick={handleSubmit}
                                        label={INVOICE_FORM_DATA.BUTTON.SUBMIT}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    );
};

export default InvoiceForm;
