import SettingBase from ".";
import ServiceList from "../../../components/user/Services/List/List";
import DeleteOz from "../../../components/user/Setting/DeleteOz";

const DeleteOZPage = () => {
  return (
    <SettingBase>
      <DeleteOz title="その他の設定 ｜退会手続き"/>
    </SettingBase>
  );
};

export default DeleteOZPage;
