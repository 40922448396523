import React, { ChangeEvent, useState } from "react";
import Button from "../../components/Login/Button";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { PATH_SUPER_ADMIN, ROUTE } from "../../services/constants/route/router";
import { decodeToken } from "react-jwt";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { swalError, swalMessage } from "../../services/helpers/swal";
import { makeRequest } from "../../services/axios/axios";
import { API } from "../../services/constants/route/api";
import { ErrorData } from "../../types/globals";
import ErrorBox from "../../components/commons/form/ErrorBox";
import SuperAdminAuthLayout from "../../layouts/SuperAdminAuthLayout";

dayjs.extend(isSameOrBefore);

const SuperAdminUpdateNewPwd = () => {
  // Link: /update-new-pwd/:token
  const [dataResetPassword, setDataResetPassword] = useState({
    username: "",
    temporary_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  const [errors, setErrors] = useState<ErrorData>({});
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  // Check token expired
  let { token } = useParams() || null;
  let navigate = useNavigate();
  if (!token) {
    return <Navigate to={ROUTE.HOME} replace={true} />;
  }
  let data = decodeToken(`${token}`) as {
    expired: string;
  };

  if (data && data.expired) {
    let expiredIn = dayjs.unix(parseInt(data.expired));
    if (!dayjs().isSameOrBefore(expiredIn)) {
      swalMessage("エラー", "リンクの有効期限が切れました。", "error");
      return <Navigate to={ROUTE.HOME} replace={true} />;
    }
  } else {
    return <Navigate to={ROUTE.HOME} replace={true} />;
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value = e.target.value;

    setDataResetPassword({ ...dataResetPassword, [name]: value });
  };

  const handleChangePwd = async () => {
    if(isSubmitting) return;
    setIsSubmitting(true);

    let result = await makeRequest({
      method: "post",
      url: API.SUPER_ADMIN.AUTH.CHANGE_PWD,
      data: dataResetPassword,
    });

    if (result.status) {
      navigate(PATH_SUPER_ADMIN.PWD_RESET_COMPLETE);
      return;
    }

    let error = result.error;
    setErrors(error || {});
    swalError();
    setIsSubmitting(false);
    return;
  };

  return (
    <SuperAdminAuthLayout>
      <form className="mb-[45px] mt-[30px] flex flex-col items-center">
        <div className="w-[670px] h-auto mb-[30px] px-[25px] ">
          <p className="mt-[30px] mb-[20px] text-[15px]">ログイン</p>
          {errors && !!Object.keys(errors).length && (
            <div className="mb-[20px] text-left">
              <ErrorBox errors={errors} />
            </div>
          )}
          <div className="mx-[20px]">
            <div className="mb-[20px] mx-[60px]">
              <input
                type="text"
                name="username"
                onChange={handleChangeInput}
                className="border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px] h-[50px]"
                placeholder="メールアドレス　または　ログインID"
                required
              />
            </div>
            <div className="mb-[30px] mx-[60px]">
              <input
                type="password"
                placeholder="メールに記載された仮パスワード"
                name="temporary_password"
                onChange={handleChangeInput}
                className="border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px] h-[50px]"
                required
              />
            </div>
          </div>

          <p className="mt-[15px] mb-[20px] text-[15px]">
            新しいパスワードを設定してください
          </p>
          <div className="text-left mx-[10px]">
            <div className="flex items-center mb-[18px]">
              <p className="w-[168px] text-[14px]">新パスワード</p>
              <p className="flex items-center justify-center bg-[#FE6D73] border rounded-[5px] text-[9px] font-[500] text-white p-[3px] w-[30px] h-[19px] mr-[21px]">
                必須
              </p>
              <input
                type="password"
                name="new_password"
                onChange={handleChangeInput}
                className="border border-warning-light text-gray-900 text-sm w-[380px] h-[50px] rounded-lg focus:ring-blue-100 max-w-[310px] focus:border-blue-100 block px-[22px] py-[14px]"
                placeholder="半角英数・記号のみ８〜20字"
                required
              />
            </div>
            <div className="flex items-center">
              <p className="w-[168px] text-[14px]">新パスワード（確認用）</p>
              <p className="flex items-center justify-center bg-[#FE6D73] border rounded-[5px] text-[9px] font-[500] p-[3px] text-white w-[30px] h-[19px] mr-[21px]">
                必須
              </p>
              <div className="w-[380px] h-[50px]">
                <input
                  type="password"
                  name="confirm_new_password"
                  onChange={handleChangeInput}
                  className="border border-warning-light text-gray-900 text-sm  rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full max-w-[310px] px-[22px] py-[14px]"
                  required
                />
                <p className="text-left text-[10px] leading-[190%] mt-[2px]">
                  ※確認のため同じパスワードを入力してください
                </p>
              </div>
            </div>
          </div>
        </div>
        <Button
          content={"送信する"}
          otherstyle={"bg-primary text-white"}
          type={"button"}
          onClick={handleChangePwd}
          isSubmitting={isSubmitting}
        />

        <Button
          content={"ログイン画面へ戻る "}
          otherstyle={"bg-secondary-light text-white mt-[10px]"}
          type={"button"}
          onClick={() => navigate(PATH_SUPER_ADMIN.LOGIN)}
        />
      </form>
    </SuperAdminAuthLayout>
  );
};

export default SuperAdminUpdateNewPwd;
