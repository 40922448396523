import { getPath } from '../../helpers/router';

const ROUTE = {
  //home
  HOME: '/',
  DOMAIN_LOGIN: '/site-login',
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  VERIFY_EMAIL: '/verify-email',
  GG_CALLBACK: '/google.callback',
  REDIRECT: '/redirect-to-subdomain',
  RESEND_VERIFY: 'resend-verify-email',
  SUBMIT_FORGOT_PASSWORD_DONE: '/email-has-been-sent',
  UPDATE_NEW_PWD: '/update-new-pwd',
  SERVICE: '/service',
  PASSWORD_RESET_COMPLETE: '/reset-password-complete',
  PROFILE: '/profile',

  USER_PREFIX: 'user',

  ADMIN_REGISTER: {
    DEFAULT: 'register',
    SEND_REGISTER_EMAIL: 'send-email-register',
    RESEND_REGISTER_EMAIL: 'resend-email-register',
    VERIFY_REGISTER_EMAIL: 'verify-email-after-register',
    REGISTER_ADMIN_ORGANIZATION: 'admin-organization-register',
  },

  SUPER_ADMIN: {
    DEFAULT: 'superadmin',
    PROFILE: 'profile',
    LOGIN: 'login',
    LOGOUT: 'logout',
    FORGOT_PASSWORD: 'forgot-password',
    UPDATE_NEW_PWD: 'update-new-pwd',
    SUBMIT_FORGOT_PASSWORD_DONE: 'email-has-been-sent',
    VERIFY_EMAIL: 'verify-email',
    RESEND_VERIFY: 'resend-verify-email',
    PASSWORD_RESET_COMPLETE: 'reset-password-complete',
  },

  //account
  ACCOUNT_MANAGEMENT: {
    DEFAULT: 'account',
    CREATE: 'create',
    DETAIL: 'detail',
  },

  ORGANIZATION_ADMIN: {
    DEFAULT: 'organization',
    CREATE: 'create',
    DETAIL_CONTACT: 'detail_contact',
    DETAIL: 'detail',
    DETAIL_INFO: 'detail_info',
    VERIFY_EMAIL_CREATE: 'verify-email-create-oz',
    RESEND_VERIFY_EMAIL_CREATE: 'resend-verify-email-create-oz',
  },

  CONTRACT_PRICE_SETTING: {
    DEFAULT: 'contract-price-setting',
    SERVICE_MANABITE: 'manabite',
    SERVICE_TOKITE: 'tokite',
  },

  COMPANY: {
    DEFAULT: 'company-info',
    PAYMENT_SETTING: 'payment-setting',
    RECEIPT_SETTING: 'receipt-setting',
    RECEIPT_HISTORY: 'receipt-history',
    INVOICE_SETTING: 'invoice-setting',
  },

  CONTRACT: {
    DEFAULT: 'contract',
    OVERVIEW: 'overview',
    PURCHASE_HISTORY: 'purchase-history',
    PAYMENT_SETTING: 'payment-setting',
    SUBSCRIPTION: 'subscription',
    SUBSCRIPTION_COMPLETE: 'subscription-complete',
  },

  REVENUE: {
    DEFAULT: 'revenue',
    INVOICE: 'invoice',
  },

  LINK_ADDRESS: {
    DEFAULT: 'link-address',
    LIST: 'list',
  },

  SETTING: {
    DEFAULT: 'company-setting',
    DELETE_OZ: 'delete-oz',
  },

  BASIC_INFO: {
    DEFAULT: 'basic-info',
    CONTACT_LIST: 'contact-lists',
  }
};

const PATH_REGISTER_ADMIN = {
  DEFAULT: getPath(ROUTE.ADMIN_REGISTER.DEFAULT),
  SEND_REGISTER_EMAIL: getPath(
    ROUTE.ADMIN_REGISTER.DEFAULT,
    ROUTE.ADMIN_REGISTER.SEND_REGISTER_EMAIL,
  ),
  RESEND_REGISTER_EMAIL: getPath(
    ROUTE.ADMIN_REGISTER.DEFAULT,
    ROUTE.ADMIN_REGISTER.RESEND_REGISTER_EMAIL,
  ),
  VERIFY_REGISTER_EMAIL: getPath(
    ROUTE.ADMIN_REGISTER.DEFAULT,
    ROUTE.ADMIN_REGISTER.VERIFY_REGISTER_EMAIL,
  ),
  REGISTER_ADMIN_ORGANIZATION: getPath(
    ROUTE.ADMIN_REGISTER.DEFAULT,
    ROUTE.ADMIN_REGISTER.REGISTER_ADMIN_ORGANIZATION,
  ),
};

const PATH_SUPER_ADMIN = {
  DEFAULT: getPath(ROUTE.SUPER_ADMIN.DEFAULT),
  PROFILE: getPath(ROUTE.SUPER_ADMIN.DEFAULT, ROUTE.SUPER_ADMIN.PROFILE),
  LOGIN: getPath(ROUTE.SUPER_ADMIN.DEFAULT, ROUTE.SUPER_ADMIN.LOGIN),
  LOGOUT: getPath(ROUTE.SUPER_ADMIN.DEFAULT, ROUTE.SUPER_ADMIN.LOGOUT),
  FORGOT_PASSWORD: getPath(ROUTE.SUPER_ADMIN.DEFAULT, ROUTE.SUPER_ADMIN.FORGOT_PASSWORD),
  UPDATE_NEW_PWD: getPath(ROUTE.SUPER_ADMIN.DEFAULT, ROUTE.SUPER_ADMIN.UPDATE_NEW_PWD),
  PWD_RESET_COMPLETE: getPath(ROUTE.SUPER_ADMIN.DEFAULT,ROUTE.SUPER_ADMIN.PASSWORD_RESET_COMPLETE),
  SUBMIT_FORGOT_PASSWORD_DONE: getPath(
    ROUTE.SUPER_ADMIN.DEFAULT,
    ROUTE.SUPER_ADMIN.SUBMIT_FORGOT_PASSWORD_DONE,
  ),
  VERIFY_EMAIL: getPath(ROUTE.SUPER_ADMIN.DEFAULT, ROUTE.SUPER_ADMIN.VERIFY_EMAIL),
  RESEND_VERIFY: getPath(ROUTE.SUPER_ADMIN.DEFAULT, ROUTE.SUPER_ADMIN.RESEND_VERIFY),
  USER_ACCOUNT: {
    DEFAULT: getPath(ROUTE.SUPER_ADMIN.DEFAULT, ROUTE.ACCOUNT_MANAGEMENT.DEFAULT),
    CREATE: getPath(
      ROUTE.SUPER_ADMIN.DEFAULT,
      ROUTE.ACCOUNT_MANAGEMENT.DEFAULT,
      ROUTE.ACCOUNT_MANAGEMENT.CREATE,
    ),
    DETAIL: getPath(
      ROUTE.SUPER_ADMIN.DEFAULT,
      ROUTE.ACCOUNT_MANAGEMENT.DEFAULT,
      ROUTE.ACCOUNT_MANAGEMENT.DETAIL,
    ),
  },
  ORGANIZATION: {
    DEFAULT: getPath(ROUTE.SUPER_ADMIN.DEFAULT, ROUTE.ORGANIZATION_ADMIN.DEFAULT),
    CREATE: getPath(
      ROUTE.SUPER_ADMIN.DEFAULT,
      ROUTE.ORGANIZATION_ADMIN.DEFAULT,
      ROUTE.ORGANIZATION_ADMIN.CREATE,
    ),
    DETAIL: getPath(
      ROUTE.SUPER_ADMIN.DEFAULT,
      ROUTE.ORGANIZATION_ADMIN.DEFAULT,
      ROUTE.ORGANIZATION_ADMIN.DETAIL,
    ),
    DETAIL_INFO: getPath(
      ROUTE.SUPER_ADMIN.DEFAULT,
      ROUTE.ORGANIZATION_ADMIN.DEFAULT,
      ROUTE.ORGANIZATION_ADMIN.DETAIL_INFO,
    ),
    DETAIL_CONTACT: getPath(
      ROUTE.SUPER_ADMIN.DEFAULT,
      ROUTE.ORGANIZATION_ADMIN.DEFAULT,
      ROUTE.ORGANIZATION_ADMIN.DETAIL_CONTACT,
    ),
    VERIFY_EMAIL_CREATE: getPath(
      ROUTE.SUPER_ADMIN.DEFAULT,
      ROUTE.ORGANIZATION_ADMIN.DEFAULT,
      ROUTE.ORGANIZATION_ADMIN.VERIFY_EMAIL_CREATE,
    ),
    RESEND_VERIFY_EMAIL_CREATE: getPath(
      ROUTE.SUPER_ADMIN.DEFAULT,
      ROUTE.ORGANIZATION_ADMIN.DEFAULT,
      ROUTE.ORGANIZATION_ADMIN.RESEND_VERIFY_EMAIL_CREATE,
    ),
  },
  LINK_ADDRESS: {
    LIST: getPath(ROUTE.SUPER_ADMIN.DEFAULT, ROUTE.LINK_ADDRESS.DEFAULT, ROUTE.LINK_ADDRESS.LIST),
  },
  CREATE: getPath(
    ROUTE.SUPER_ADMIN.DEFAULT,
    ROUTE.ORGANIZATION_ADMIN.DEFAULT,
    ROUTE.ORGANIZATION_ADMIN.CREATE,
  ),

  CONTRACT_PRICE_SETTING: {
    MANABITE: getPath(
      ROUTE.SUPER_ADMIN.DEFAULT,
      ROUTE.CONTRACT_PRICE_SETTING.DEFAULT,
      ROUTE.CONTRACT_PRICE_SETTING.SERVICE_MANABITE,
    ),
    TOKITE: getPath(
      ROUTE.SUPER_ADMIN.DEFAULT,
      ROUTE.CONTRACT_PRICE_SETTING.DEFAULT,
      ROUTE.CONTRACT_PRICE_SETTING.SERVICE_TOKITE,
    ),
    DEFAULT: getPath(ROUTE.SUPER_ADMIN.DEFAULT, ROUTE.CONTRACT_PRICE_SETTING.DEFAULT),
  },
};

const PATH_SERVICE = {
  DEFAULT: ROUTE.SERVICE,
}

const PATH_USER_ACCOUNT = {
  DEFAULT: getPath(ROUTE.ACCOUNT_MANAGEMENT.DEFAULT),
  CREATE: getPath(ROUTE.ACCOUNT_MANAGEMENT.DEFAULT, ROUTE.ACCOUNT_MANAGEMENT.CREATE),
  DETAIL: getPath(ROUTE.ACCOUNT_MANAGEMENT.DEFAULT, ROUTE.ACCOUNT_MANAGEMENT.DETAIL),
};

const PATH_COMPANY_INFO = {
  PAYMENT_SETTING: getPath(ROUTE.COMPANY.DEFAULT, ROUTE.COMPANY.PAYMENT_SETTING),
  INVOICE_SETTING: getPath(ROUTE.COMPANY.DEFAULT, ROUTE.COMPANY.INVOICE_SETTING),
  RECEIPT_SETTING: getPath(ROUTE.COMPANY.DEFAULT, ROUTE.COMPANY.RECEIPT_SETTING),
  RECEIPT_HISTORY: getPath(ROUTE.COMPANY.DEFAULT, ROUTE.COMPANY.RECEIPT_HISTORY),
  DEFAULT: getPath(ROUTE.COMPANY.DEFAULT),
};

const PATH_CONTRACT = {
  OVERVIEW: getPath(ROUTE.CONTRACT.DEFAULT, ROUTE.CONTRACT.OVERVIEW),
  PURCHASE_HISTORY: getPath(ROUTE.CONTRACT.DEFAULT, ROUTE.CONTRACT.PURCHASE_HISTORY),
  PAYMENT_SETTING: getPath(ROUTE.CONTRACT.DEFAULT, ROUTE.CONTRACT.PAYMENT_SETTING),
  DEFAULT: getPath(ROUTE.CONTRACT.DEFAULT),
  SUBSCRIPTION: getPath(ROUTE.CONTRACT.DEFAULT, ROUTE.CONTRACT.SUBSCRIPTION),
  SUBSCRIPTION_COMPLETE: getPath(ROUTE.CONTRACT.DEFAULT, ROUTE.CONTRACT.SUBSCRIPTION_COMPLETE),
};

const PATH_REVENUE = {
  INVOICE_LIST: getPath(ROUTE.REVENUE.DEFAULT, ROUTE.REVENUE.INVOICE),
  DEFAULT: getPath(ROUTE.REVENUE.DEFAULT),
};

const PATH_SETTING = {
  DELETE_OZ: getPath(ROUTE.SETTING.DEFAULT, ROUTE.SETTING.DELETE_OZ)
}

const PATH_BASIC_INFO = {
  BASIC_INFO: getPath(ROUTE.BASIC_INFO.DEFAULT),
  CONTACT_LIST: getPath(ROUTE.BASIC_INFO.DEFAULT, ROUTE.BASIC_INFO.CONTACT_LIST),
  DEFAULT: getPath(ROUTE.BASIC_INFO.DEFAULT),
};

export {
  ROUTE,
  PATH_REGISTER_ADMIN,
  PATH_SUPER_ADMIN,
  PATH_USER_ACCOUNT,
  PATH_COMPANY_INFO,
  PATH_CONTRACT,
  PATH_REVENUE,
  PATH_SERVICE,
  PATH_SETTING,
  PATH_BASIC_INFO
};
