import { TYPE_DATE_FORMAT } from '../../../../services/constants/globals';
import {
  CONTRACT_SUBSCRIPTION_STATUS,
  CONTRACT_SUBSCRIPTION_TYPE,
  SUBSCRIPTION_STEP,
} from '../../../../services/constants/user/pages/contract';
import { formatDateTime } from '../../../../services/helpers/formatTime';
import {
  getBilledAmountWithTax,
  getContractExpiredDateByPeriod,
  getSubscriptionPeriodLabel,
  getSubscriptionPeriodShortLabel,
} from '../../../../services/utils/contract';
import { ContractSubscription } from '../../../../types/user/contract';
import { CiWarning } from 'react-icons/ci';
import RequiredBox from '../../commons/RequiredBox';

type RenderContractLabelConfirmType = {
  step: string;
  formData?: ContractSubscription;
  originSubscribed?: ContractSubscription;
};

type RenderContractLabelFormStatusType = {
  formData?: ContractSubscription;
  originSubscribed?: ContractSubscription;
};

const RenderContractLabelConfirm = ({
  step,
  formData,
  originSubscribed,
}: RenderContractLabelConfirmType) => {
  switch (step) {
    case SUBSCRIPTION_STEP.CONFIRMATION:
      return (
        <>
          （{formatDateTime(new Date())}
          から開始）
        </>
      );
    case SUBSCRIPTION_STEP.CONFIRMATION_CHANGE:
      let nowDiffTextChange = '';
      let futureDiffTextChange = '';
      let renewalDate = '';

      switch (originSubscribed?.contract_type) {
        case CONTRACT_SUBSCRIPTION_TYPE.BASIC:
          nowDiffTextChange = `${originSubscribed?.user_quantity}人`;
          futureDiffTextChange = `${formData?.user_quantity}人`;
          renewalDate = formatDateTime(originSubscribed?.period_end_at || '');
          break;
        case CONTRACT_SUBSCRIPTION_TYPE.SUPPORT:
        case CONTRACT_SUBSCRIPTION_TYPE.PRODUCT:
          nowDiffTextChange = `${getSubscriptionPeriodLabel(originSubscribed.period_type)}`;
          futureDiffTextChange = `${getSubscriptionPeriodLabel(formData?.period_type)}`;
          break;
        default:
          break;
      }

      if (Number(formData?.billed_amount) <= Number(originSubscribed?.billed_amount)) {
        return (
          <>
            （現在のプラン（{nowDiffTextChange}
            ）は次回の更新日（{renewalDate}）まで継続されます。その後、新しいプラン（
            {futureDiffTextChange}）と価格で更新されます。）
          </>
        );
      } else {
        return (
          <>
            （現在のプラン（{nowDiffTextChange}）すぐに新しいプラン（
            {futureDiffTextChange}
            ）に変更されます。元々のサブスクリプションの利用日数に基づく金額が返金されます。）
          </>
        );
      }

    case SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE:
      let nowDiffTextUnsubscribe = '';

      switch (originSubscribed?.contract_type) {
        case CONTRACT_SUBSCRIPTION_TYPE.BASIC:
          nowDiffTextUnsubscribe = `${originSubscribed?.user_quantity}人`;
          break;
        case CONTRACT_SUBSCRIPTION_TYPE.SUPPORT:
        case CONTRACT_SUBSCRIPTION_TYPE.PRODUCT:
          nowDiffTextUnsubscribe = `${getSubscriptionPeriodLabel(originSubscribed?.period_type)}`;
          break;
        default:
          break;
      }
      return (
        <>
          （現在のプラン（{nowDiffTextUnsubscribe}）は次回の更新日（
          {formatDateTime(new Date(String(originSubscribed?.period_end_at)))}
          ）まで継続されます。その後、サブスクが解約されます)
        </>
      );

    default:
      return <></>;
  }
};

const RenderContractLabelFormStatus = ({
  formData,
  originSubscribed,
}: RenderContractLabelFormStatusType): JSX.Element => {
  if (!originSubscribed || !originSubscribed.id || !formData || !formData.id) return <></>;

  const periodType = originSubscribed.period_type;
  if (originSubscribed.status === CONTRACT_SUBSCRIPTION_STATUS.INUSE_INTERVAL) {
    return (
      <>
        現在のプランは次回の更新日（
        {formatDateTime(
          getContractExpiredDateByPeriod(
            new Date(originSubscribed.period_start_at || ''),
            periodType,
          ),
          TYPE_DATE_FORMAT.SHORT_DEFAULT,
        )}
        ）に自動更新されます。
      </>
    );
  }

  if (originSubscribed.status === CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD) {
    let nowDiffText = '';
    let futureDiffText = '';

    if (originSubscribed.in_scheduled_subscribed && originSubscribed.in_scheduled_subscribed.id) {
      switch (originSubscribed?.contract_type) {
        case CONTRACT_SUBSCRIPTION_TYPE.BASIC:
          nowDiffText = `${originSubscribed.user_quantity}人／
          ${getSubscriptionPeriodShortLabel(originSubscribed.period_type)}`;

          futureDiffText = `${originSubscribed.in_scheduled_subscribed.user_quantity}人／
          ${getSubscriptionPeriodShortLabel(originSubscribed.in_scheduled_subscribed.period_type)}`;
          break;

        case CONTRACT_SUBSCRIPTION_TYPE.SUPPORT:
        case CONTRACT_SUBSCRIPTION_TYPE.PRODUCT:
          nowDiffText = `${getSubscriptionPeriodLabel(originSubscribed.period_type)}`;

          futureDiffText = `${getSubscriptionPeriodLabel(
            originSubscribed.in_scheduled_subscribed.period_type,
          )}`;
          break;
        default:
          break;
      }

      return (
        <>
          現在のプラン（{nowDiffText}
          ）は次回の更新日（
          {formatDateTime(
            getContractExpiredDateByPeriod(
              new Date(originSubscribed.period_start_at || ''),
              periodType,
            ),
          )}
          ）まで継続されます。その後、新しいプラン（
          {futureDiffText}
          ）と価格（
          {getBilledAmountWithTax(Number(originSubscribed.in_scheduled_subscribed?.billed_amount))}
          円／
          {getSubscriptionPeriodShortLabel(originSubscribed.in_scheduled_subscribed.period_type)}
          ）で更新されます。
        </>
      );
    }

    switch (originSubscribed?.contract_type) {
      case CONTRACT_SUBSCRIPTION_TYPE.BASIC:
        nowDiffText = `${originSubscribed.user_quantity}人／
        ${getSubscriptionPeriodShortLabel(originSubscribed.period_type)}`;
        break;

      case CONTRACT_SUBSCRIPTION_TYPE.SUPPORT:
      case CONTRACT_SUBSCRIPTION_TYPE.PRODUCT:
        nowDiffText = `${getSubscriptionPeriodLabel(originSubscribed.period_type)}`;
        break;
      default:
        break;
    }

    return (
      <>
        現在のプラン（{nowDiffText}
        ）は次回の更新日（
        {formatDateTime(
          getContractExpiredDateByPeriod(
            new Date(String(originSubscribed.period_start_at)),
            periodType,
          ),
        )}
        ）まで継続されます。その後、解約されます。
      </>
    );
  }
  return <></>;
};

const RenderUnSubscribeSubscriptionAnnouncement = () => {
  return (
    <div className="w-[95%] mt-[20px] mb-[20px] ">
      <div className="w-[90%] mb-[15px]">
        <p className="text-red-500 inline">「自動更新の解除」は退会ではございません。</p>
        <p className="inline">無料プランの範囲内で引き続きご利用いただけます</p>
      </div>
      <p className="mx-[5px] mb-[10px] text-[14px]">
        ※ 無料プラン（自動更新の解除）への移行の際は以下にご注意ください
      </p>
      <div className="w-full gap-x-[10px] gap-y-[20px] flex justify-start pl-[15px] pr-[10px] mt-[10px]">
        <CiWarning className="text-red-500" size={'25px'} />
        <div className="text-[12px] w-full">
          <div className="text-red-500">契約満了時までは現在のプランが継続されます</div>
          <div className="">
            更新日以降はサブスク契約が解約されます。契約期間中にアカウント情報とストレージ上のデータ確認をお願いいたします。
          </div>
        </div>
      </div>
      <div className="w-full gap-x-[10px] gap-y-[20px] flex justify-start pl-[15px] pr-[10px] mt-[10px]">
        <CiWarning className="text-red-500" size={'25px'} />
        <div className="text-[12px] w-full">
          <div className="text-red-500">無料プランのアカウント数には上限があります。</div>
          <div className="">
            アカウント数が超過したまま「自動更新を解除する」と全ユーザーがサービスにログインできなくなります。
          </div>
          <div>自動更新を解除する前に現在のアカウント数を必ずご確認ください。</div>
        </div>
      </div>
      <div className="w-full gap-x-[10px] gap-y-[20px] flex justify-start pl-[15px] pr-[10px] mt-[15px]">
        <CiWarning className="text-red-500" size={'25px'} />
        <div className="text-[12px] w-full">
          <div className="text-red-500">無料プランのストレージ容量には上限があります。</div>
          <div className="inline">
            <div className="inline">
              ストレージにアップロードされたデータが無料プランの容量を超えている場合、「自動更新を解除する」とデータはすべて削除されます。
            </div>
            <div className="text-red-500 inline">データの復元はできません。</div>
            <div className="inline">
              自動更新を解除する前に現在のストレージ使用容量を必ずご確認ください。
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type RenderConfirmPassWordUnSubscribeSubscriptionType = {
  handleChange?: (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ) => void;
};

const RenderConfirmPassWordUnSubscribeSubscription = ({
  handleChange,
}: RenderConfirmPassWordUnSubscribeSubscriptionType) => {
  return (
    <div className="w-full">
      <div className="text-[16px] font-bold">
        上記の内容を確認した上で、自動更新の解除をしてもよろしいですか？
      </div>
      <div className="mt-[5px] mb-[40px]">
        <input
          type="checkbox"
          onChange={handleChange}
          name="confirmation"
          className="form-checkbox text-primary border-[1px] border-[#EBE4D8] rounded-[3px]"
        />
        <span className="ml-2 mr-[5px] font-400 text-[14px] text-gray-600 whitespace-nowrap">
          上記の内容を確認し、自動更新の解除に同意しました
        </span>
      </div>
      <div>
        <div className="flex text-[12px] font-[500] text-gray-600 leading-[100%] mb-[10px]">
          <div className="mr-[10px]">パスワードを入力してください</div>
          <RequiredBox />
        </div>
        <div className="text-secondary-dark">
          <input
            className="text-[16px] w-[100%] leading-[100%] flex items-center pl-[15.5px] h-[40px] border border-[#ebe4d8] rounded placeholder-secondary-light"
            type="password"
            name="password"
            onChange={handleChange}
            placeholder="パスワード"
          />
        </div>
      </div>
    </div>
  );
};

export {
  RenderContractLabelConfirm,
  RenderContractLabelFormStatus,
  RenderUnSubscribeSubscriptionAnnouncement,
  RenderConfirmPassWordUnSubscribeSubscription,
};
