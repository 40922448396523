import { SidebarChildItem, SidebarItem } from "../../../types/user/main";
import {
  PATH_BASIC_INFO,
  PATH_COMPANY_INFO,
  PATH_CONTRACT,
  PATH_REVENUE,
  PATH_SETTING,
  PATH_USER_ACCOUNT,
} from "../route/router";
import User from "../../../components/commons/icons/SideBar/User";
import Pencil from "../../../components/commons/icons/SideBar/Pencil";
import City from "../../../components/commons/icons/SideBar/City";
import Monitor from "../../../components/commons/icons/SideBar/Monitor";
import Setting from "../../../components/commons/icons/SideBar/Setting";
import LineChartUp from "../../../components/commons/icons/SideBar/LineChartUp";
import Wallet from "../../../components/commons/icons/SideBar/Wallet";

const child = (
  href: SidebarChildItem["href"],
  label: SidebarChildItem["label"]
) => ({
  href: href,
  label: label,
});

const item = (
  label: SidebarItem["label"],
  href: SidebarItem["href"],
  icon: React.FC,
  page: SidebarItem["page"],
  ...childs: SidebarItem["childs"]
) => ({
  label: label,
  href: href,
  icon: icon,
  page: page,
  childs: childs.map(({ href, label }: SidebarChildItem) => child(href, label)),
});

const USER_SIDEBAR_DATA = [
  item("ホーム", "/service", Monitor, "/service"),
  item(
    "アカウント管理",
    "",
    User,
    "/user",
    child(PATH_USER_ACCOUNT.DEFAULT, "アカウント一覧"),
    child(PATH_USER_ACCOUNT.CREATE, "アカウント新規追加")
  ),

  // SidebarItem hidden for future phases
  item(
    "契約状況",
    "",
    Pencil,
    "/contract",
    child(PATH_CONTRACT.OVERVIEW, "契約管理")
    // child(PATH_CONTRACT.PURCHASE_HISTORY, '請求履歴'),
    // child(PATH_CONTRACT.PAYMENT_SETTING, 'お支払い方法'),
  ),
  item(
    "企業情報",
    "",
    City,
    "/basic-info",
    child(PATH_BASIC_INFO.BASIC_INFO, "企業情報"),
    child(PATH_BASIC_INFO.CONTACT_LIST, "請求連絡先")
  ),
  // item("会社基本情報", City, child("/#", "企業情報"), child("/#", "連絡先")),
  // item("お知らせ", Sound, child("/#", "お知らせ"), child("/#", "特定商取引について")),
  item(
    "販売方法設定",
    "",
    Wallet,
    "/company-info",
    child(PATH_COMPANY_INFO.PAYMENT_SETTING, "決済方法設定"),
    child(PATH_COMPANY_INFO.INVOICE_SETTING, "請求書設定"),
    child(PATH_COMPANY_INFO.RECEIPT_SETTING, "領収書設定"),
    child(PATH_COMPANY_INFO.RECEIPT_HISTORY, "領収書発行履歴")
  ),
  item(
    "商品売上",
    "",
    LineChartUp,
    "/revenue",
    child(PATH_REVENUE.INVOICE_LIST, "パレットLMS")
  ),
  item(
    "その他の設定",
    "",
    Setting,
    "/company-setting",
    child(PATH_SETTING.DELETE_OZ, "退会手続き")
  ),
];

export { USER_SIDEBAR_DATA };
