import React from "react";
import CompanyBase from ".";
import ReceiptHistoryList from "../../../components/user/Company/ReceiptHistory/List";

const ReceiptHistoryPage = () => {
  return (
    <CompanyBase title="販売決済方法 ｜領収書発行履歴">
      <ReceiptHistoryList />
    </CompanyBase>
  );
};

export default ReceiptHistoryPage;
