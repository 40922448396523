import FormButton from "../../../components/commons/form/FormButton";
import FormItem from "../../../components/user/commons/FormItem";
import SemiSquareButton from "../../../components/user/commons/SemiSquareButton";
import UserLayout from "../../../layouts/user";
import { useEffect, useRef, useState } from "react";
import { makeRequest, request } from "../../../services/axios/axios";
import { swalMessage } from "../../../services/helpers/swal";
import Swal from "sweetalert2";
import { API } from "../../../services/constants/route/api";
import {
  AccountFormData,
  MyProfileFormData,
} from "../../../types/user/account";
import { Service } from "../../../types/user/service";
import { ErrorData } from "../../../types/globals";
import ErrorBox from "../../../components/commons/form/ErrorBox";
import { BsCheck2Circle } from "react-icons/bs";
import ImageInternalButton from "../../../components/commons/buttons/ImageInternalButton";
import {
  PATH_SERVICE,
  PATH_USER_ACCOUNT,
  ROUTE,
} from "../../../services/constants/route/router";
import { useNavigate } from "react-router-dom";
import { IoMdCheckmark } from "react-icons/io";

const AdminProfile = () => {
  const navigate = useNavigate();
  const [accountFormData, setAccountFormData] = useState<MyProfileFormData>({});
  const [services, setServices] = useState<Service[]>([]);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<ErrorData>({});
  const user = JSON.parse(localStorage.getItem("user") || "");
  const [fileError, setFileError] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const servicesPromise = request.get(
        API.SERVICE.LIST_All,
        (serviceList) => {
          //Swapped the order of services
          [serviceList[1], serviceList[2]] = [serviceList[2], serviceList[1]];
          setServices(serviceList || []);
        }
      );

      const userDetailPromise = request.get(
        API.USER.DETAIL_INFO,
        (userDetailData) => {
          let data = {
            ...userDetailData,
            features: userDetailData?.features?.map(
              (feature: { id?: number }) => feature?.id
            ),
          };
          setAccountFormData(data);
        }
      );

      Promise.all([servicesPromise, userDetailPromise]);
    };

    fetchData();
  }, []);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const file = (e.target as HTMLInputElement).files?.[0];
    if (name === "image" && !value) return;
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Size in MB

      // Check file type
      if (!fileType.startsWith("image/")) {
        setFileError("無効なファイルタイプです。画像を選択してください。");
        return;
      }

      // Check file size
      if (fileSize > 2) {
        setFileError("ファイルサイズが2MBの最大制限を超えています。");
        return;
      }

      setFileError("");
      setAccountFormData({ ...accountFormData, [name]: file });
      return;
    }

    setAccountFormData({ ...accountFormData, [name]: value });
  };

  const removeImage = () => {
    const newData: MyProfileFormData = {
      ...accountFormData,
      image: undefined,
      avatar_url: undefined,
      delete_avatar: 1,
    };
    setAccountFormData(newData);
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    let submitAction = request.post;
    let submitUrl = API.USER.UPDATE_PROFILE;
    let submitData = {
      ...accountFormData,
    };

    if (user.id) {
      submitData = { ...submitData, user_id: user?.id };
    }

    await submitAction(
      submitUrl,
      submitData,
      (user) => {
        setIsSubmitting(false);
        localStorage.setItem("user", JSON.stringify(user));
        let data = {
          ...user,
          features: user?.features?.map(
            (feature: { id?: number }) => feature?.id
          ),
        };
        setAccountFormData(data);
        setErrors({});
        return navigate(ROUTE.PROFILE);
      },
      (errors) => {
        setIsSubmitting(false);
        setErrors(errors);
      },
      { withSuccess: true, withLoading: true, hasFileRequest: true }
    );
  };

  return (
    <UserLayout>
      <section className="w-full">
        <div className="bg-white rounded-[10px] w-full min-h-[100px] pb-[50px]">
          <div className="pt-[30px] px-[28px] mb-[20px]">
            <div className="border-b border-secondary-light text-secondary-dark pb-[14px] font-[500] text-[16px] leading-[100%]">
              アカウント管理 ｜アカウント情報の変更
            </div>
          </div>

          <div className="mx-[43px]">
            <div>
              {/* errors */}
              {errors && !!Object.keys(errors).length && (
                <div className="mb-[20px]">
                  <ErrorBox errors={errors} />
                </div>
              )}
              {/* Account form */}
              <FormItem label="アカウント名" withRequired>
                <div className="flex gap-[9px]  w-full">
                  <div className="text-secondary-dark w-full flex items-center">
                    <input
                      className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
                      name="last_name"
                      value={accountFormData?.last_name || ""}
                      onChange={handleChange}
                      placeholder="鈴木"
                    />
                  </div>
                  <div className="text-secondary-dark w-full flex items-center">
                    <input
                      className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
                      name="first_name"
                      value={accountFormData?.first_name || ""}
                      onChange={handleChange}
                      placeholder="一郎"
                    />
                  </div>
                </div>
              </FormItem>
              <FormItem label="アカウント名（カナ)">
                <div className="flex gap-[9px] w-full">
                  <div className="text-secondary-dark w-full flex items-center">
                    <input
                      className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
                      name="last_name_kana"
                      value={accountFormData?.last_name_kana || ""}
                      onChange={handleChange}
                      placeholder="スズキ"
                    />
                  </div>
                  <div className="text-secondary-dark] w-full flex items-center">
                    <input
                      className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] placeholder-secondary-light"
                      name="first_name_kana"
                      value={accountFormData?.first_name_kana || ""}
                      onChange={handleChange}
                      placeholder="イチロウ"
                    />
                  </div>
                </div>
              </FormItem>
              <FormItem label="メールアドレス" withRequired>
                <div className="flex items-center">
                  <div className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] w-full max-w-[330px] placeholder-secondary-light">
                    {accountFormData?.email || ""}
                  </div>
                  <div className="min-w-[90px] ml-[5px] pl-[17px] pt-[3px] pb-[5px] text-[#749F91] bg-[#D7F5F2] rounded-[5px] whitespace-nowrap relative text-xs align-middle inline-block">
                    <BsCheck2Circle
                      className="absolute top-[2px] left-[4px]"
                      size={18}
                    />
                    <p className="pl-[12px]">確認済み</p>
                  </div>
                </div>
              </FormItem>
              <FormItem label="ログインID（半角英数のみ８〜20）" withRequired>
                <input
                  className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px]  border-[#EFF1F0]"
                  name="username"
                  value={accountFormData?.username || ""}
                  onChange={handleChange}
                />
              </FormItem>
              <FormItem label="権限" withRequired>
                <div className="text-[13px] leading-[100%] flex items-center pl-[15.5px] h-[30px] w-full max-w-[330px] placeholder-secondary-light">
                  {accountFormData?.role?.name || ""}
                </div>
              </FormItem>
              <FormItem label="利用可能プロダクト" withRequired>
                {accountFormData.features && (
                  <div className="flex items-center mb-[10px]">
                    {services
                      ?.filter((service) =>
                        accountFormData.features?.some(
                          (feature) => feature === service?.id
                        )
                      )
                      ?.map((chosenFeature) => (
                        <div
                          className="flex items-center mr-[18px]"
                          key={chosenFeature?.id}
                        >
                          <div className="w-[17px] h-[17px] border-[1px] border-secondary-lighter rounded-[5px] bg-secondary-lighter  ">
                            <IoMdCheckmark color="white" />
                          </div>
                          <label className="text-[12px] font-[400] leading-[18px]">
                            {chosenFeature?.name}
                          </label>
                        </div>
                      ))}
                  </div>
                )}
              </FormItem>
              <FormItem label="プロフィール画像のアップロード">
                <div className="flex items-center">
                  {accountFormData.image || accountFormData?.avatar_url ? (
                    <div className="relative w-[330px] object-cover mr-4 border-gray-200 my-[5px]">
                      <ImageInternalButton
                        removeImage={removeImage}
                        editImage={() => inputFileRef.current?.click()}
                      />
                      <img
                        src={
                          accountFormData.image
                            ? URL.createObjectURL(accountFormData.image)
                            : accountFormData?.avatar_url
                        }
                        alt="Profile Avatar"
                      />
                    </div>
                  ) : (
                    <div
                      className="w-full max-w-[330px] h-[30px] flex items-center justify-center mr-4  border-[#EFF1F0] border-[1px] my-[5px]"
                      onClick={() => inputFileRef.current?.click()}
                    ></div>
                  )}
                  <label
                    htmlFor="image"
                    className="flex items-center justify-center w-[105px] h-[20px] bg-[#EFF1F0] font-[400] text-[12px] rounded-[2px] border-[1px] border-secondary-light cursor-pointer"
                  >
                    ファイルを選択
                  </label>
                  <input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    className="hidden"
                    ref={inputFileRef}
                    onChange={handleChange}
                    placeholder="ファイルを選択"
                  />
                  {!accountFormData.image && (
                    <div className="ml-[10px] text-[10px] font-[400] leading-[19px]">
                      選択されていません
                    </div>
                  )}
                </div>
                {fileError && (
                  <div className="text-red-500 text-xs">{fileError}</div>
                )}
              </FormItem>

              {/* Submit Buttons */}
              <div className="w-full flex justify-center items-center mb-[58px] mt-[66px] gap-[15px]">
                <SemiSquareButton
                  className="bg-secondary-light max-w-[180px]"
                  onClick={() => navigate(PATH_SERVICE.DEFAULT)}
                  isDisabled={isSubmitting}
                >
                  キャンセル
                </SemiSquareButton>
                <FormButton
                  className="bg-primary px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[16px] leading-[100%] w-[132px] h-[36px]"
                  onClick={handleSubmit}
                  isSubmitting={isSubmitting}
                  label="保存"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  );
};

export default AdminProfile;
