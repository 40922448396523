import { SUBSCRIPTION_CONST } from '../constants/user/pages/contract';

const isNumber = (checkTarget: any) => typeof checkTarget == 'number';

const isBetween = (currentNumber?: number, min?: number, max?: number) => {
  if (!isNumber(currentNumber) || !isNumber(min) || !isNumber(max)) return false;

  return Number(currentNumber) >= Number(min) && Number(currentNumber) <= Number(max);
};

const isBigger = (number?: number, targetCompare?: number) => {
  if (!isNumber(number) || !isNumber(targetCompare)) return false;

  return Number(number) > Number(targetCompare);
};

const isBiggerEqual = (number?: number, targetCompare?: number) => {
  if (!isNumber(number) || !isNumber(targetCompare)) return false;

  return Number(number) >= Number(targetCompare);
};

const isSmaller = (number?: number, targetCompare?: number) => {
  if (!isNumber(number) || !isNumber(targetCompare)) return false;

  return Number(number) < Number(targetCompare);
};

const preventFloatInput = (e: React.KeyboardEvent<HTMLInputElement>) =>
  ['.', '-'].includes(e.key) && e.preventDefault();

const preventMathematicalConstantInput = (e: React.KeyboardEvent<HTMLInputElement>) =>
  ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

const numberPreventer = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (['.', '-'].includes(e.key) || ['e', 'E', '+', '-'].includes(e.key)) {
    e.preventDefault();
    return;
  }
};

const replaceJSX = (input: string, findValue: string, replace: JSX.Element) => {
  if (!input || !input.trim()) return '';

  return input
    .split(findValue)
    .flatMap((item) => [item, replace])
    .slice(0, -1);
};

export {
  isNumber,
  isBetween,
  isBigger,
  isSmaller,
  isBiggerEqual,
  preventFloatInput,
  preventMathematicalConstantInput,
  numberPreventer,
  replaceJSX,
};
