import { ItemType } from "../../../types/globals";
import { IoSettingsOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { ROUTE } from "../route/router";

const USER_MENU = {
  LOGOUT: "ログアウト",
  PROFILE: "プロフィール編集",
};

const USER_MENU_DATA: ItemType[] = [
  {
    label: USER_MENU.PROFILE,
    href: ROUTE.PROFILE,
    icon: IoSettingsOutline,
  },
  {
    label: USER_MENU.LOGOUT,
    href: ROUTE.LOGOUT,
    icon: MdLogout,
  },
];

const USER_MENU_BORDER_INDEX = [0, 3, 7, 10];

export { USER_MENU, USER_MENU_BORDER_INDEX, USER_MENU_DATA };
