import UserLayout from "../../../layouts/user";
import { WithChildren } from "../../../types/globals";

type SettingMainProp = {
  title?: string;
};

const SettingBase = ({ title, children }: WithChildren<SettingMainProp>) => {

  return (
    <UserLayout>
      {children}
    </UserLayout>
  );
};

export default SettingBase;
