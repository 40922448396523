import { ChangeEvent, useState } from "react";

import Button from "../../Login/Button";
import { useStepProvider } from "../../../context/StepsContext";
import { makeRequest } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import { ErrorData } from "../../../types/globals";
import ErrorBox from "../../commons/form/ErrorBox";

const CreatePasswordForm = () => {
    const [dataRegister, setDataRegister] = useState({
        password: "",
        password_confirmation: "",
    });
    const [isChecked, setIsChecked] = useState<boolean>(false)
    const [isCheckedConfirm, setIsCheckedConfirm] = useState<boolean>(false)
    const [errors, setErrors] = useState<ErrorData>({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { nextStep, setDataStep } = useStepProvider()

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        let name = e.target.name;
        let value = e.target.value;

        setDataRegister({ ...dataRegister, [name]: value });
    };

    const handleSubmitPassword = async () => {
        setIsSubmitting(true);
        const result = await makeRequest({
            method: "post",
            url: API.ADMIN_REGISTER.VALIDATE_REGISTER_ORGANIZATION_PWD,
            data: { ...dataRegister },
        });

        if (!result.status) {
            setIsSubmitting(false);
            setErrors(result.error as ErrorData);
            return;
        }
        setIsSubmitting(false);
        setDataStep(curr => ({
            ...curr,
            dataRegister
        }))

        nextStep();
    };

    return (
        <div className="mb-[45px] mt-[30px] flex flex-col items-center">
            <div className="md:w-[550px] sm:w-[450px] w-[370px] mb-[25px] px-[25px] py-[30px] bg-secondary-morelighter">
                <p className="mb-[20px] text-[20px] font-normal">
                    パスワードを設定してください
                </p>
                <p className="text-[14px] font-normal">
                    パスワードを設定して登録を完了してください。
                </p>
                {errors && Object.keys(errors).length > 0 && (<ErrorBox errors={errors}/>)}
                <div className="mb-[20px] mt-[30px]">
                    <p className="text-[14px] font-normal text-left mb-[10px]">
                        パスワード（半角英数・記号のみ８〜20字）
                    </p>
                    <input
                        type={isChecked ? "text" : "password"}
                        name="password"
                        onChange={handleChangeInput}
                        className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px] mb-[10px]"
                        placeholder="半角英数・記号のみ８〜20字"
                        required
                    />
                    <div className="flex justify-start items-center">
                        <input
                            id="hidden_pw"
                            type="checkbox"
                            checked={isChecked}
                            name={"password_confirmation"}
                            onChange={() => setIsChecked(!isChecked)}
                            className="w-[15px] h-[15px] border border-warning-light rounded"
                        />
                        <label
                            htmlFor="hidden_pw"
                            className="ml-2 text-[#5A5A5A] text-[12px] font-normal dark:text-gray-300"
                        >
                            パスワードを表示
                        </label>
                    </div>
                </div>
                <div className="mb-[20px] mt-[30px]">
                    <p className="text-[14px] font-normal text-left mb-[10px]">
                        パスワード（確認用）
                    </p>
                    <input
                        type={isCheckedConfirm ? "text" : "password"}
                        name="password_confirmation"
                        onChange={handleChangeInput}
                        className="min-h-[50px] border border-warning-light text-gray-900 text-sm rounded-lg focus:ring-blue-100 focus:border-blue-100 block w-full px-[22px] py-[14px] mb-[10px]"
                        placeholder="半角英数・記号のみ８〜20字"
                        required
                    />
                    <div className="flex justify-start items-center">
                        <input
                            id="hidden_pw_confirmation"
                            type="checkbox"
                            name={"hidden_pw_confirmation"}
                            onChange={() => setIsCheckedConfirm(!isCheckedConfirm)}
                            className="w-[15px] h-[15px] border border-warning-light rounded"
                        />
                        <label
                            htmlFor="hidden_pw_confirmation"
                            className="ml-2 text-[#5A5A5A] text-[12px] font-normal dark:text-gray-300"
                        >
                            パスワードを表示
                        </label>
                    </div>
                </div>

                <Button
                    content={"次に進む"}
                    otherstyle={"bg-primary text-white"}
                    type={"button"}
                    onClick={handleSubmitPassword}
                    isSubmitting={isSubmitting}
                />
            </div>
        </div>
    );
};

export default CreatePasswordForm;

