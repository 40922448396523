import SuperAdminOrganizationBase from ".";
import SuperAdminOrganizationList from "../../../components/SuperAdmin/Organization/List/List";

const SuperAdminOrganizationListPage = () => {
    return (
        <SuperAdminOrganizationBase title="契約企業管理｜契約企業一覧">
            <SuperAdminOrganizationList />
        </SuperAdminOrganizationBase>
    );
};

export default SuperAdminOrganizationListPage;
