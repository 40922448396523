import React from 'react';
import { useNavigate, useParams } from "react-router-dom";

const RedirectToSubdomain = () => {
  const subdomain = localStorage.getItem('subdomain');

  if (subdomain && subdomain !== window.location.host) {
    window.location.host = subdomain;
  }

  let navigate = useNavigate();
  let { path } = useParams();

  setTimeout(() => {
    navigate(`/${path}`);
  }, 1000)

  return (
    <div>
      Redirecting, please wait ...
    </div>
  );
};

export default RedirectToSubdomain;
