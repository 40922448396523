import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { USER_SIDEBAR_DATA } from "../../services/constants/superadmin/sidebar";
import SuperAdminSidebarItem from "./SidebarItem";
import { PATH_SUPER_ADMIN } from "../../services/constants/route/router";
import HamburgerSuperadmin from "../../components/commons/icons/SideBar/HamburgerSuperadmin";
import { useLayoutContext } from "../../context/LayoutContext";
import { SidebarItem as SidebarItemProps } from "../../types/user/main";

const SuperAdminSidebar = () => {
  const location = useLocation();
  const storedOpenItem = localStorage.getItem("openItem");
  const [openItem, setOpenItem] = useState<number | null>(
    storedOpenItem ? Number(storedOpenItem) : null
  );
  const { sidebarToggle, setSidebarToggle } = useLayoutContext();

  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem("openItem", openItem?.toString() || "");
  }, [openItem]);

  const handleItemClick = (index: number | null, data: SidebarItemProps) => {

    if (index === -1) {
      setOpenItem(null);
      setTimeout(() => {
        navigate(PATH_SUPER_ADMIN.USER_ACCOUNT.DEFAULT);
      }, 10);
      return;
    }

    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }

    if (sidebarToggle) {
      if (data.href.length > 0 && data.childs.length <= 0) {
        navigate(`${data.href}`);
      }
      if (data.href.length <= 0 && data.childs.length > 0) {
        navigate(`${data.childs[0]['href']}`);
      }
    }
  };

  return (
    <section
      className="w-full h-full bg-primary text-white shadow-sm transition-all"
      style={{
        maxWidth: sidebarToggle ? "60px" : "250px",
      }}
    >
      <div className="h-0 min-h-[38px] flex justify-end items-center cursor-pointer">
        <HamburgerSuperadmin
          onClick={() => setSidebarToggle(!sidebarToggle)}
          isOpen={!sidebarToggle}
        />
      </div>
      <div className="whitespace-nowrap">
        {USER_SIDEBAR_DATA.map((data, index) => (
          <SuperAdminSidebarItem
            data={data}
            key={index}
            currentPath={location.pathname}
            isOpen={index === openItem}
            onItemClick={() => {
              handleItemClick(index, data);
              // setSidebarToggle(false);
            }}
            isMinimize={!!sidebarToggle}
          />
        ))}
      </div>
    </section>
  );
};

export default SuperAdminSidebar;
