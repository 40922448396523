import BasicInfoBasePage from "./index";
import ErrorBox from "../../../components/commons/form/ErrorBox";
import FormButton from "../../../components/commons/form/FormButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeRequest, request } from "../../../services/axios/axios";
import { API } from "../../../services/constants/route/api";
import {
  swalError,
  swallConfirm,
  swalLoading,
} from "../../../services/helpers/swal";
import { PATH_BASIC_INFO } from "../../../services/constants/route/router";
import { ContactData } from "../../../types/user/contactData";
import ContactInfoForm from "../../../components/user/ContactInfo/ContactInfoForm";
import PlusWhiteBg from "../../../components/commons/icons/PlusMinus/PlusWhiteBg";
import { ContactErrorData } from "../../../types/Contact/ContactErrorType";

const ContactListPage = () => {
  const navigate = useNavigate();
  const [contactData, setContactData] = useState<ContactData[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<ContactErrorData>();
  const [countContactSaved, setCountContactSaved] = useState<number>(0);
  const contactDataInit = {
    email: "",
    first_name: "",
    last_name: "",
  };

  useEffect(() => {
    const fetchListContactData = async () => {
      const result = await makeRequest({
        method: "get",
        url: `${API.BASIC_INFO.LIST_CONTACTS}`,
      });

      if (!result.data) return swalError();
      let countContact = result.data.length;
      setCountContactSaved(countContact);
      if (countContact === 0) {
        setContactData([contactDataInit]);
        return;
      }
      if (countContact === 1 && countContactSaved <= 1) {
        setIsDeleting(true);
      }
      setContactData(result.data);
    };

    fetchListContactData();
  }, []);

  useEffect(() => {
    if (contactData.length == 1) {
      setIsDeleting(true);
    } else {
      setIsDeleting(false);
    }
  }, [contactData]);

  const addNewContact = () => {
    setContactData([...contactData, contactDataInit]);
  };

  const handleDeleteContact = (
    contact_id: number | string | undefined,
    index?: number | string | undefined
  ) => {
    if (!contact_id) {
      setContactData((prevState) => {
        prevState = prevState.filter(
          (_, contact_index) => contact_index !== index
        );
        return [...prevState];
      });
      return;
    }

    if (contactData.length === 1 || countContactSaved <= 1) {
      return;
    }

    swallConfirm(
      async () => {
        setIsDeleting(true);
        let result = await request.delete(
          `${API.BASIC_INFO.DELETE_CONTACT}`,
          {
            contact_id: contact_id,
          },
          () => {
            setIsDeleting(false);
            setContactData((prevState) => {
              prevState = prevState.filter((data) => data.id != contact_id);
              return [...prevState];
            });
            if (countContactSaved > 0) {
              setCountContactSaved(countContactSaved - 1);
            }
          },
          (errs) => {
            setIsDeleting(false);
            setErrors(errs);
          },
          { withSuccess: true }
        );
      },
      "削除してよろしいですか？",
      "",
      {
        confirmButtonText: "削除する",
      }
    );
  };

  const handleChangeInput = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const { name, value } = e.target;

    setContactData((prevState) => {
      // @ts-ignore
      prevState[index][name] = value;
      return [...prevState];
    });
  };

  const handleSubmitBasicInfo = async () => {
    setIsSubmitting(true)
    let submitAction = request.post;
    let submitUrl = API.BASIC_INFO.UPDATE_LIST_CONTACTS;
    let submitData = {
      contacts: [...contactData],
    };

    // swalLoading();
    let result = await submitAction(
      submitUrl,
      submitData,
      (result) => {
        navigate(PATH_BASIC_INFO.CONTACT_LIST);
        setCountContactSaved(result.length);
      },
      setErrors,
      { withSuccess: true, withLoading: true, hasFileRequest: true }
    );

    if (result) {
      setContactData([...result]);
      setErrors({});
      setIsSubmitting(false);
    }

    setIsSubmitting(false);
  };

  return (
    <BasicInfoBasePage title="企業情報 ｜請求連絡先">
      <section className="w-full">
        {contactData &&
          contactData.length > 0 &&
          contactData.map((contactDatum, index) => {
            let hiddenTrash = false;
            if (countContactSaved === 1 && index === 0) {
              hiddenTrash = true;
            }
            return (
              <div key={`contactData-${index}`}>
                <ContactInfoForm
                  handleChangeInput={handleChangeInput}
                  index={index}
                  contactData={contactDatum}
                  handleDeleteContact={handleDeleteContact}
                  isDisableDeleteButton={isDeleting}
                  errorData={
                    errors !== undefined && errors[index] !== undefined
                      ? errors[index]
                      : undefined
                  }
                  hiddenTrash={hiddenTrash}
                  errors={errors || {}}
                  setErrors={setErrors}
                />
              </div>
            );
          })}
        {/*Add new contact button*/}
        <div className="flex items-center gap-[20px]">
          <button
            className="px-[20px] py-[20px] ml-[20px] border-2 border-[#BEBEBE] rounded-[5px] font-[700] text-[14px] text-[#2E2E2E] leading-[100%] w-[300px] h-[34px] flex justify-start items-center"
            onClick={addNewContact}
            // disabled={isDeleting}
          >
            <PlusWhiteBg />
            <p className="ml-[20px]">新しい請求連絡先を追加する</p>
          </button>

          <div className="text-[12px] font-[400] text-[#2E2E2E]">
            すべての請求関連メールがご登録いただいたメールアドレス宛に送信されます
          </div>
        </div>

        {/* Submit Buttons */}
        <div className="w-full flex justify-center items-center mb-[58px] mt-[66px] gap-[15px]">
          <FormButton
            className="bg-primary px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[16px] leading-[100%] w-[220px] h-[34px]"
            onClick={handleSubmitBasicInfo}
            label="内容を保存する"
            isSubmitting={isSubmitting}
            // disabled={isDeleting}
          />
        </div>
      </section>
    </BasicInfoBasePage>
  );
};

export default ContactListPage;
