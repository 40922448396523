/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export interface StepItemType {
  name: string
  component: ReactNode
}


export interface StepContextType {
  current: number
  data: {
    [key: string]: any
  }
  setDataStep: Dispatch<
    SetStateAction<{
      [key: string]: any
    }>
  >
  steps: StepItemType[]
  nextStep: (name?: string, data?: any) => void
  prevStep: () => void
  isBack: boolean
  setIsBack: Dispatch<SetStateAction<boolean>>
}

const StepContext = createContext<StepContextType | undefined>(undefined)

export const useStepProvider = (): StepContextType => {
  const stepState = useContext<StepContextType | undefined>(StepContext)

  if (stepState === undefined) {
    throw new Error('useStepProvider must be used within a StepProvider tag')
  }

  return stepState
}

export default function StepProvider({
  children,
  steps,
  defaultStep = 1,
}: {
  children: ReactNode
  steps: StepItemType[]
  defaultStep?: number
}) {
  const [currentStep, setCurrentStep] = useState<number>(() =>
    defaultStep > steps.length ? 1 : defaultStep,
  )

  const [isBack, setIsBack] = useState<boolean>(false)

  const [dataStep, setDataStep] = useState<{
    [key: string]: any
  }>({})

  const nextStep = useCallback(
    (name?: string, data?: any) => {
      if (currentStep < steps.length) {
        if (name && data) {
          setDataStep((dataStepCurr) => ({
            ...dataStepCurr,
            [name]: data,
          }))
        }
        setCurrentStep(currentStep + 1)
        setIsBack(false)
      }
    },
    [currentStep, steps.length],
  )

  const prevStep = useCallback(() => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1)
      setIsBack(true)
    }
  }, [currentStep])

  const stepState: StepContextType = useMemo(
    () => ({
      current: currentStep,
      data: dataStep,
      nextStep,
      prevStep,
      steps,
      setDataStep,
      isBack,
      setIsBack,
    }),
    [currentStep, dataStep, nextStep, prevStep, steps, isBack],
  )
  return (
    <StepContext.Provider value={stepState}>{children}</StepContext.Provider>
  )
}
