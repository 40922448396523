import ContractBasePage from '.';
import ContractPaymentSetting from '../../../components/user/Contract/PaymentSetting/ContractPaymentSetting';

const ContractPaymentSettingPage = () => {
  return (
    <ContractBasePage title="サービス一覧">
      <ContractPaymentSetting />
    </ContractBasePage>
  );
};

export default ContractPaymentSettingPage;
