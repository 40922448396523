import Home from "../pages";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import {
  PATH_COMPANY_INFO,
  PATH_USER_ACCOUNT,
  ROUTE,
  PATH_CONTRACT,
  PATH_REGISTER_ADMIN,
  PATH_REVENUE,
  PATH_SETTING, PATH_BASIC_INFO,
} from '../services/constants/route/router';
import { RouteObject } from 'react-router';
import RedirectToSubdomain from '../pages/RedirectToSubdomain';
import FinishSubmitForgotPwd from '../pages/auth/FinishSubmitForgotPwd';
import UpdateNewPwd from '../pages/auth/UpdateNewPwd';
import VerifyEmail from '../pages/VerifyEmail';
import ResendVerification from '../pages/ResendVerification';
import ServiceListPage from '../pages/user/Services/List';
import Logout from '../pages/Logout';
import AccountListPage from '../pages/user/Account/List';
import AccountCreatePage from '../pages/user/Account/Create';
import PaymentSettingPage from '../pages/user/Company/PaymentSetting';
import ContractOverviewPage from '../pages/user/Contract/Overview';
import NotFoundPage from '../pages/404';
import ContractPurchaseHistoryPage from '../pages/user/Contract/PurchaseHistory';
import ContractPaymentSettingPage from '../pages/user/Contract/PaymentSetting';
import SubscriptionPage from '../pages/user/Contract/Subscription';
import SubscriptionCompletePage from '../pages/user/Contract/SubscriptionComplete';
import SendEmailRegisterAdmin from '../pages/adminregister/SendEmailRegisterAdmin';
import VerifyRegisterEmail from '../pages/adminregister/VerifyRegisterEmail';
import ResendEmailRegisterAdmin from '../pages/adminregister/ResendEmailRegisterAdmin';
import RegisterAdminOrganization from '../pages/adminregister/RegisterAdminOrganization';
import InvoiceSettingPage from "../pages/user/Company/InvoiceSetting";
import InvoiceListPage from "../pages/user/Revenue/List";
import PwdResetComplete from "../pages/auth/PwdResetComplete";
import AdminProfile from "../pages/user/Account/Profile";
import DeleteOZPage from "../pages/user/Setting/DeleteOrganization";
import BasicInfoDetailPage from "../pages/user/BasicInfo/BasicInfoDetail";
import ContactListPage from "../pages/user/BasicInfo/ContactList";
import ReceiptSettingPage from "../pages/user/Company/ReceiptSetting";
import ReceiptHistoryPage from "../pages/user/Company/ReceiptHistory";

type CustomRouterObject = {};

const userRouter: Array<RouteObject & CustomRouterObject> = [
  {
    path: ROUTE.HOME,
    element: <Home />,
  },
  {
    path: ROUTE.PROFILE,
    element: <AdminProfile />,
  },
  {
    path: ROUTE.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTE.LOGOUT,
    element: <Logout />,
  },
  {
    path: ROUTE.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: ROUTE.VERIFY_EMAIL,
    element: <VerifyEmail />,
  },
  {
    path: ROUTE.RESEND_VERIFY,
    element: <ResendVerification />,
  },
  {
    path: ROUTE.GG_CALLBACK,
    element: <Home />,
  },
  {
    path: `${ROUTE.REDIRECT}/:path`,
    element: <RedirectToSubdomain />,
  },
  {
    path: ROUTE.SUBMIT_FORGOT_PASSWORD_DONE,
    element: <FinishSubmitForgotPwd />,
  },
  {
    path: ROUTE.PASSWORD_RESET_COMPLETE,
    element: <PwdResetComplete />,
  },
  {
    path: `${ROUTE.UPDATE_NEW_PWD}/:token`,
    element: <UpdateNewPwd />,
  },
  {
    path: ROUTE.SERVICE,
    element: <ServiceListPage />,
  },
  {
    path: PATH_REGISTER_ADMIN.DEFAULT,
    children: [
      {
        path: PATH_REGISTER_ADMIN.DEFAULT,
        element: <SendEmailRegisterAdmin />,
      },
      {
        path: PATH_REGISTER_ADMIN.VERIFY_REGISTER_EMAIL,
        element: <VerifyRegisterEmail />,
      },
      {
        path: PATH_REGISTER_ADMIN.RESEND_REGISTER_EMAIL,
        element: <ResendEmailRegisterAdmin />,
      },
      {
        path: PATH_REGISTER_ADMIN.REGISTER_ADMIN_ORGANIZATION,
        element: <RegisterAdminOrganization />,
      },
    ],
  },
  {
    path: PATH_USER_ACCOUNT.DEFAULT,
    children: [
      { index: true, element: <AccountListPage /> },
      {
        path: PATH_USER_ACCOUNT.CREATE,
        element: <AccountCreatePage />,
      },
      {
        path: PATH_USER_ACCOUNT.DETAIL,
        element: <AccountCreatePage />,
      },
    ],
  },
  {
    // path: PATH_COMPANY_INFO.DEFAULT,
    children: [
      {
        path: PATH_COMPANY_INFO.PAYMENT_SETTING,
        element: <PaymentSettingPage />,
      },
      {
        path: PATH_COMPANY_INFO.INVOICE_SETTING,
        element: <InvoiceSettingPage />,
      },
      {
        path: PATH_COMPANY_INFO.RECEIPT_SETTING,
        element: <ReceiptSettingPage />,
      },
      {
        path: PATH_COMPANY_INFO.RECEIPT_HISTORY,
        element: <ReceiptHistoryPage />,
      },
    ],
  },
  {
    path: PATH_CONTRACT.DEFAULT,
    children: [
      { index: true, element: <ContractOverviewPage /> },
      {
        path: PATH_CONTRACT.OVERVIEW,
        element: <ContractOverviewPage />,
      },
      {
        path: PATH_CONTRACT.PURCHASE_HISTORY,
        element: <ContractPurchaseHistoryPage />,
      },
      {
        path: PATH_CONTRACT.PAYMENT_SETTING,
        element: <ContractPaymentSettingPage />,
      },
      {
        path: PATH_CONTRACT.SUBSCRIPTION,
        element: <SubscriptionPage />,
      },
      {
        path: PATH_CONTRACT.SUBSCRIPTION_COMPLETE,
        element: <SubscriptionCompletePage />,
      },
    ],
  },
  {
    path: PATH_REVENUE.DEFAULT,
    children: [
      {
        path: PATH_REVENUE.INVOICE_LIST,
        element: <InvoiceListPage />,
      },
    ],
  },
  {
    path: PATH_SETTING.DELETE_OZ,
    children: [
      {
        path: PATH_SETTING.DELETE_OZ,
        element: <DeleteOZPage />
      }
    ]
  },
  {
    path: PATH_BASIC_INFO.DEFAULT,
    children: [
      {
        path: PATH_BASIC_INFO.BASIC_INFO,
        element: <BasicInfoDetailPage />,
      },
      {
        path: PATH_BASIC_INFO.CONTACT_LIST,
        element: <ContactListPage />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export default userRouter;
