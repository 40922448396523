import React from 'react';

const SemiSquareButton = ({
  children,
  isDisabled = false,
  className,
  ...props
}: {
  children: React.ReactNode;
  isDisabled?: boolean;
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      disabled={isDisabled}
      className={`bg-primary px-[50px] py-[10px] text-white rounded-[10px] font-[700] text-[16px] leading-[100%] ${isDisabled ? 'bg-secondary-disabled cursor-not-allowed' : 'bg-primary'
        } ${className}`
      }
      {...props}
    >
      {children}
    </button>
  );
};

export default SemiSquareButton;