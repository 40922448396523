import { Link } from "react-router-dom";
import { DIRECTION } from "../../services/constants/globals";
import { SidebarItem as SidebarItemProps } from "../../types/user/main";
import SuperAdminSidebarDirection from "./SidebarDirection";

type SidebarProps = {
  data: SidebarItemProps;
  currentPath: string;
  isOpen: boolean;
  onItemClick: () => void;
  isMinimize?: boolean;
};

const SuperAdminSidebarItem = ({
  data,
  currentPath,
  isOpen,
  onItemClick,
  isMinimize,
}: SidebarProps) => {
  const { label, childs, icon: IconComponent } = data;

  return (
    <>
      <div
        className="bg-primary flex justify-between items-center cursor-pointer min-h-[40px] px-[10px] hover:bg-primary-lesslight w-full"
        onClick={onItemClick}
      >
        <div
          className={`flex items-center text-[13px] font-[500] leading-[100%] text-white w-full ${
            isMinimize ? " justify-center" : "gap-[8px]"
          }`}
        >
          <div className="text-[23px] text-white flex items-center gap-[10px] ml-[10px]">
            <IconComponent color="#fff" width={23} height={23} />
            <div className="text-[13px]">{!isMinimize && label}</div>
          </div>

          {!isMinimize && (
            <div className="flex w-full justify-end items-center ">
              <SuperAdminSidebarDirection
                onDirection={isOpen ? DIRECTION.UP : DIRECTION.DOWN}
              />
            </div>
          )}
        </div>
      </div>
      {!isMinimize && isOpen && childs && (
        <>
          {childs.map(({ href, label }, index) => (
            <Link to={href} className="w-full cursor-pointer" key={index}>
              <div
                className={`min-h-[36px] flex items-center pl-[52px] text-[12px] font-[500] leading-[100%] hover:bg-primary-lesslight ${
                  currentPath === href
                    ? "text-white bg-primary-lesslight"
                    : "text-white bg-primary-dark"
                }`}
              >
                {label}
              </div>
            </Link>
          ))}
        </>
      )}
    </>
  );
};

export default SuperAdminSidebarItem;
