import React, { useEffect, useState } from 'react';
import { SetStateAction, ValueSelectType } from '../../../../../types/globals';
import FormBlock from '../../../Company/Payment/FormBlock';
import {
  CONTRACT_SUBSCRIPTION_PERIOD,
  CONTRACT_SUBSCRIPTION_STATUS,
  PERIOD_RADIO_DATA,
  STORAGE_CONTRACT_NUMBER_OPTIONS,
  STORAGE_PLUS_PLAN_PACKAGE_AMOUNT,
  SUBSCRIPTION_CONST,
  SUBSCRIPTION_STEP,
} from '../../../../../services/constants/user/pages/contract';
import { Contract, ContractSubscription } from '../../../../../types/user/contract';
import { formatMoney, getPercent, parsePercent } from '../../../../../services/helpers/parseData';
import { isNumber, preventFloatInput } from '../../../../../services/helpers/etc';
import {
  getBasicBilledPrice,
  getBilledAmountWithTax,
  getContractExpiredDateByPeriod,
  getStorageBillPrice,
  getStorageChangedBilledAmount,
  getSubscriptionPeriodLabel,
  getTaxAmount,
} from '../../../../../services/utils/contract';
import FormLine from '../Basic/FormLine';
import RadioBox from '../../../../commons/form/RadioBox';
import { swalError } from '../../../../../services/helpers/swal';
import { request } from '../../../../../services/axios/axios';
import SubmitButton from '../SubmitButton';
import { useObjectRoutes } from '../../../../../hooks/useObjectRoutes';
import { formatDateTime } from '../../../../../services/helpers/formatTime';
import { PALETTE_FEATURE, TYPE_DATE_FORMAT } from '../../../../../services/constants/globals';
import { API } from '../../../../../services/constants/route/api';
import SelectMacBook from '../../../../commons/selectMacBook/SelectMacBook';
import PriceChangeCalculation from './PriceChangeCalculation';
import { MdArrowRightAlt } from 'react-icons/md';

type StorageSubscriptionFormProps = {
  subscription?: ContractSubscription;
  subscribedPriceList?: Contract[];
  latestPriceList?: Contract[];
  setStep?: SetStateAction<string>;
  formData?: ContractSubscription;
  setFormData?: SetStateAction<ContractSubscription>;
  setTitle: SetStateAction<string>;
  originSubscribed?: ContractSubscription;
};

type RenderContractStatusLabelType = {
  formData?: ContractSubscription;
  originSubscribed?: ContractSubscription;
  latestPriceList?: Contract[];
};

type RenderMonthlyBillType = {
  originSubscribed?: ContractSubscription;
  latestPriceList?: Contract[];
};

const RenderMonthlyBill = ({ originSubscribed, latestPriceList }: RenderMonthlyBillType) => {
  return (
    <>
      {latestPriceList &&
        latestPriceList[0] &&
        latestPriceList[0].price_yearly &&
        formatMoney(
          originSubscribed?.period_type === CONTRACT_SUBSCRIPTION_PERIOD.YEARLY
            ? latestPriceList[0]?.price_yearly
            : latestPriceList[0]?.price_monthly,
        )}
    </>
  );
};

const RenderContractStatusLabel = ({
  originSubscribed,
  latestPriceList,
}: RenderContractStatusLabelType) => {
  const planCapacity =
    latestPriceList && latestPriceList[0]?.storage_capacity
      ? latestPriceList[0]?.storage_capacity
      : STORAGE_PLUS_PLAN_PACKAGE_AMOUNT;
  // contract cancelled
  if (
    originSubscribed?.status === CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD &&
    !originSubscribed.in_scheduled_subscribed
  ) {
    return (
      <>
        現在のプラン（{getSubscriptionPeriodLabel(originSubscribed?.period_type)} {planCapacity}GBx
        {originSubscribed?.product_quantity}） は次回の更新日（
        {formatDateTime(getContractExpiredDateByPeriod(new Date(), originSubscribed?.period_type))}
        ） まで継続されます。その後、解約されます。
      </>
    );
  }

  // if contract in schedule
  if (originSubscribed?.in_scheduled_subscribed) {
    // if exist billed amount
    if (
      originSubscribed?.in_scheduled_subscribed?.billed_amount &&
      originSubscribed?.billed_amount
    ) {
      if (
        originSubscribed?.in_scheduled_subscribed?.billed_amount <= originSubscribed?.billed_amount
      ) {
        return (
          <>
            現在のプラン（{getSubscriptionPeriodLabel(originSubscribed?.period_type)} {planCapacity}
            GBx{originSubscribed?.product_quantity}） は次回の更新日（
            {formatDateTime(
              getContractExpiredDateByPeriod(new Date(), originSubscribed?.period_type),
            )}
            ） まで継続されます。その後、新しいプラン（
            {getSubscriptionPeriodLabel(originSubscribed?.in_scheduled_subscribed.period_type)}{' '}
            {planCapacity}GBx{originSubscribed?.in_scheduled_subscribed.product_quantity}） と価格（
            {<RenderMonthlyBill latestPriceList={latestPriceList} />}円／月）で更新されます。
          </>
        );
      } else {
        return (
          <>
            現在のプランは次回の更新日（
            {formatDateTime(originSubscribed?.period_end_at, TYPE_DATE_FORMAT.SHORT_DEFAULT)}）
            に自動更新されます。
          </>
        );
      }
    }
    return <></>;
  }
  // else
  else {
    return (
      <>
        現在のプランは次回の更新日（
        {formatDateTime(originSubscribed?.period_end_at, TYPE_DATE_FORMAT.SHORT_DEFAULT)}）
        に自動更新されます。
      </>
    );
  }
};

const StoragePlanForm = ({
  subscription,
  subscribedPriceList,
  latestPriceList,
  formData,
  setStep,
  setFormData,
  setTitle,
  originSubscribed,
}: StorageSubscriptionFormProps) => {
  const [usedStorage, setUsedStorage] = useState<number>(0);
  const [purchasedStorage, setPurchasedStorage] = useState<number>(0);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { navigate } = useObjectRoutes();
  const freeCapacity = localStorage.getItem('free-setting-capacity');
  const storageUsedPercentage = getPercent(
    Number(megabytesToGigabytes(usedStorage)),
    purchasedStorage + Number(freeCapacity),
  );
  const planCapacity =
    latestPriceList && latestPriceList[0]?.storage_capacity
      ? latestPriceList[0]?.storage_capacity
      : STORAGE_PLUS_PLAN_PACKAGE_AMOUNT;

  useEffect(() => {
    const fetcher = async () => {
      setIsFetching(true);

      await request.get(`${API.STORAGE_PLAN_SUBSCRIPTION.GET_USED_STORAGE}`, (res) => {
        setUsedStorage(res.used_storage);
        setIsFetching(false);
      });
    };

    fetcher();
    setTitle('契約状況 ｜契約管理 ｜オプション機能の変更');
  }, []);

  useEffect(() => {
    if (originSubscribed && originSubscribed?.product_quantity) {
      setPurchasedStorage(originSubscribed.product_quantity * planCapacity);
    }
  }, [originSubscribed]);

  const choiceSelectOption = (value: string | number) => {
    if (!isNumber(Number(value)) || Number(value) < 0) return;
    if (Number(value) > SUBSCRIPTION_CONST.LIMIT_QUANTITY) return;

    let additionData: ContractSubscription = {};
    const finalPrice = getStorageBillPrice(
      latestPriceList,
      Number(value),
      formData?.period_type === CONTRACT_SUBSCRIPTION_PERIOD.YEARLY,
    );

    const changedBilledAmount = getStorageChangedBilledAmount(
      latestPriceList || [],
      Number(originSubscribed?.product_quantity),
      Number(value),
    );

    additionData = {
      ...additionData,
      billed_amount: finalPrice,
      changed_billed_amount: changedBilledAmount,
    };
    setFormData?.({ ...formData, product_quantity: Number(value), ...additionData });
  };

  const changeHandle = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = e.currentTarget;

    if (!isNumber(Number(value)) || Number(value) < 0) return;

    let additionData: ContractSubscription = {};
    if (name === 'period_type') {
      const finalPrice = getBasicBilledPrice(
        latestPriceList,
        formData?.product_quantity,
        Number(value) === CONTRACT_SUBSCRIPTION_PERIOD.YEARLY,
      );
      additionData = {
        ...additionData,
        billed_amount: finalPrice,
      };
    }

    setFormData?.({ ...formData, [name]: Number(value), ...additionData });
  };

  const thTableStyles = 'h-[30px] flex items-center justify-center bg-success-lighter';

  const submitHandle = async () => {
    if (!formData?.billed_amount) {
      swalError();
      return;
    }

    setStep?.(
      formData?.id ? SUBSCRIPTION_STEP.CONFIRMATION_CHANGE : SUBSCRIPTION_STEP.CONFIRMATION,
    );
  };

  const returnHandle = () => {
    navigate(-1);
  };

  const unsubscribeHandle = () => {
    if (!formData?.billed_amount) {
      swalError();
      return;
    }
    setStep?.(SUBSCRIPTION_STEP.CONFIRMATION_UNSUBSCRIBE);
  };

  function megabytesToGigabytes(megabytes: number, isFixed = true) {
    if (isFixed) return (megabytes / 1024).toFixed(2);
    return megabytes / 1024;
  }

  return (
    <div className="pt-[20px]">
      {!isFetching && originSubscribed ? (
        <FormBlock title="ご契約中のストレージ容量">
          <div className="mt-[7px]" />
          {formData?.id && (
            <FormLine label="サブスク状態">
              <div
                className={`text-red-500 w-full py-[5px] pl-[14px] pr-[32px] flex items-center gap-[20px]`}
              >
                <RenderContractStatusLabel
                  originSubscribed={originSubscribed}
                  latestPriceList={latestPriceList}
                />
              </div>
            </FormLine>
          )}
          <FormLine label="基本ストレージ容量">
            <div className={`w-full py-[5px] pl-[14px] pr-[32px] flex items-center gap-[20px]`}>
              <p>{freeCapacity}GB</p>
            </div>
          </FormLine>
          <FormLine label="購入済みストレージ容量">
            <div className={`w-full py-[5px] pl-[14px] pr-[32px] flex items-center gap-[20px]`}>
              <p>{purchasedStorage}GB</p>
            </div>
          </FormLine>
          <FormLine label="利用中のストレージ容量">
            <div className={`w-full py-[5px] pl-[14px] pr-[32px] flex items-center gap-[20px]`}>
              <p className="">
                使用済み：{megabytesToGigabytes(usedStorage)}GB／
                {purchasedStorage + Number(freeCapacity)}GB
              </p>
              <div className="w-full max-w-[160px] overflow-hidden rounded-full max-[320px]:max-w-[200px] max-sm:flex-1 max-sm:max-w-[60px] max-sm:mr-[5px] max-md:max-w-[80px] max-md:mt-0">
                <div className="w-full rounded-full h-[12px] bg-success-extralight">
                  <div
                    style={{
                      width: `${parsePercent(storageUsedPercentage)}%`,
                      backgroundColor: '#227C9D',
                    }}
                    className={`${'bg-primary-light'} h-[12px] rounded-full`}
                  ></div>
                </div>
              </div>
            </div>
          </FormLine>
        </FormBlock>
      ) : (
        <>
          <div className="skeleton h-[150px] mb-[20px]"></div>
        </>
      )}

      {latestPriceList?.length ? (
        <div className="">
          <FormLine label={`${formData?.feature_id === PALETTE_FEATURE.MANABITE.ID ? '受講者' : '受験者'}アカウント数`}>
            {originSubscribed && originSubscribed.id && (
              <>
                <div className="flex items-center leading-[100%]">
                  <div
                    className={`w-[10%] flex items-center gap-[7px] ml-[20px] ${
                      !latestPriceList?.length ? 'pointer-events-none' : ''
                    }`}
                  >
                    <input
                      type="number"
                      className="w-[80px] h-[30px] text-right text-[16px] bg-[#F7F6F6] pointer-events-none"
                      defaultValue={Number(originSubscribed?.product_quantity) * planCapacity || ''}
                    />
                    <span className="">GB</span>
                  </div>
                </div>

                <MdArrowRightAlt size={24} className="ml-[15px] text-secondary-light" />
              </>
            )}

            <div className="flex items-center leading-[100%]">
              <div
                className={`w-[10%] flex items-center gap-[7px] ml-[15px] ${
                  !latestPriceList?.length ? 'pointer-events-none' : ''
                }`}
              >
                <input
                  type="number"
                  className="w-[80px] h-[30px] text-right text-[16px] bg-[#F7F6F6] pointer-events-none"
                  value={Number(formData?.product_quantity) * planCapacity || ''}
                />
                <span className="">GB</span>
              </div>
            </div>
          </FormLine>
          <FormLine label="ストレージ追加">
            <div className="w-full py-[20px] pl-[3%] px-[5%]">
              <div className="flex w-full gap-[5px]">
                <div className={`w-[55%] ${thTableStyles}`}>ストレージ追加</div>
                <div className={`w-[12%] ${thTableStyles}`}>数量</div>
                <div className={`w-[20%] ${thTableStyles}`}>
                  {formData?.period_type === CONTRACT_SUBSCRIPTION_PERIOD.YEARLY ? '年額' : '月額'}
                  払い
                </div>
                <div className={`w-[25%] !bg-primary-light text-white ${thTableStyles}`}>
                  ご契約金額
                </div>
              </div>
              <div className="flex py-[25px] items-center gap-[5px]">
                <div className="w-[55%] text-center text-primary text-[14px] font-[500] leading-[100%]">
                  ストレージ（{planCapacity}GB）のご契約
                </div>
                <div className="w-[12%] flex justify-center">
                  {/* <select
                    name="product_quantity"
                    id=""
                    className="text-[14px] pr-[30px] pl-[10px] appearance-none text-secondary-dark"
                    onChange={changeHandle}
                    value={formData?.product_quantity}
                  >
                    {STORAGE_CONTRACT_NUMBER_OPTIONS.map((item, index) => (
                      <option value={item.value} className="" key={index}>
                        {item.label}
                      </option>
                    ))}
                  </select> */}

                  <SelectMacBook
                    onChange={choiceSelectOption}
                    className="text-[14px] text-secondary-dark"
                    valueArr={STORAGE_CONTRACT_NUMBER_OPTIONS as ValueSelectType[]}
                    valueChoice={formData?.product_quantity || ''}
                  />
                </div>
                <div className="w-[20%] text-center text-[14px] font-[400] leading-[100%]">
                  {latestPriceList[0] && formatMoney(latestPriceList[0]?.price_monthly)}
                </div>
                <div className="w-[25%] text-center text-[14px] font-[400] leading-[100%]">
                  {latestPriceList[0] &&
                  latestPriceList[0].price_monthly &&
                  formData?.product_quantity
                    ? formatMoney(latestPriceList[0]?.price_monthly * formData?.product_quantity)
                    : formatMoney(0)}
                </div>
              </div>

              <div className="flex py-[20px] items-center gap-[5px] border-t border-secondary-light">
                <div className="w-[50%] text-[14px] font-[500] leading-[100%] text-center ml-[17px]">
                  小計
                </div>
                <div className="w-[50%] text-right text-[18px] font-[500] leading-[100%] pr-[10px]">
                  {formatMoney(formData?.billed_amount)}
                </div>
              </div>

              <div className="flex py-[20px] items-center gap-[5px]">
                <div className="w-[50%] text-[10px] font-[500] leading-[100%] text-center mr-[40px]">
                  消費税額（10％対象）
                </div>
                <div className="w-[50%] text-right text-[12px] font-[500] leading-[100%] pr-[10px]">
                  {formatMoney(getTaxAmount(formData?.billed_amount))}
                </div>
              </div>

              <div className="flex py-[20px] items-center gap-[5px]">
                <div className="w-[50%] text-primary text-[14px] font-[500] leading-[100%] text-center ml-[30px]">
                  合計
                </div>
                <div className="w-[50%] text-right text-[18px] font-[500] leading-[100%] pr-[10px]">
                  {formatMoney(getBilledAmountWithTax(formData?.billed_amount))}
                </div>
              </div>
            </div>
          </FormLine>

          {originSubscribed?.id && (
            <FormLine label="決済金額">
              <div className="w-full flex items-center justify-center max-w-[1000px] py-[25px] gap-[20px]">
                <PriceChangeCalculation
                  contracts={latestPriceList}
                  subscription={formData}
                  originSubscribed={originSubscribed}
                />
              </div>
            </FormLine>
          )}
        </div>
      ) : (
        <>
          <div className="skeleton h-[150px] mt-[5px]"></div>
        </>
      )}

      {/* Submit Buttons */}
      <div className="w-full flex justify-center items-center mb-[58px] mt-[66px] gap-[15px]">
        <SubmitButton type="return" onClick={returnHandle}>
          キャンセル
        </SubmitButton>
        <SubmitButton
          onClick={submitHandle}
          disabled={
            !Number(formData?.product_quantity) ||
            (originSubscribed?.status !== CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD &&
              formData?.period_type === originSubscribed?.period_type &&
              formData?.product_quantity === originSubscribed?.product_quantity)
          }
        >
          プラン登録を確認する
        </SubmitButton>

        {/* {formData?.id ? (
          <SubmitButton
            type="unsubscribe"
            onClick={unsubscribeHandle}
            disabled={
              originSubscribed?.status === CONTRACT_SUBSCRIPTION_STATUS.INUSE_FOR_END_OF_PERIOD &&
              !originSubscribed?.in_scheduled_subscribed?.id
            }
          >
            サブスクを解約する
          </SubmitButton>
        ) : (
          <></>
        )} */}
      </div>
    </div>
  );
};

export default StoragePlanForm;
