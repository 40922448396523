import InvoiceBase from ".";
import InvoiceList from "../../../components/user/Revenue/Invoice/List";

const InvoiceListPage = () => {
  return (
    <InvoiceBase title="商品販売 ｜ 請求書払い">
      <InvoiceList />
    </InvoiceBase>
  );
};

export default InvoiceListPage;
